import React from "react";
import "./App.css";
import { ChakraProvider, Container } from "@chakra-ui/react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Login from "./Components/Login";
import SignUp from "./Components/SignUp";
import ForgotPassword from "./Components/Forgot-Password";
import VerifyMail from "./Components/Verify-mail";
import Home from "./Components/user/Home";
import Profil from "./Components/user/Profil";
import List from "./Components/user/List";
import Cards from "./Components/user/Cards";
import Settings from "./Components/Settings";
import Wallets from "./Components/user/Wallets";
import NewPassword from "./Components/New-Password";
import Notification from "./Components/notification/Notification";
import Index from "./Components/user/Index";
import Documents from "./Components/Documents";
import Chat from "./Components/user/Chat";
import SingleMessage from "./Components/user/SingleMessage";
import UnknownMessage from "./views/UnknownMessage";
import TranslateContext from "./Translation/TranslateContext";
import Initializer from "./Components/fetchData/Initializer";
import Protector from "./Components/Protector";
import SingleProfil from "./Components/user/SingleProfil";
import AboutHelp from "./Components/static/About-help";
import Bookmarks from "./Components/user/Bookmarks";
import DeleteAccount from "./Components/DeleteAccount";
import Fans from "./Components/user/Following";
import Followers from "./Components/user/Followers";
import Following from "./Components/user/Following";
import AddCard from "./Components/AddCard";
import Inbox from "./Components/messenger/Inbox";
import EchoContext from "./contexts/echo";
import MyShop from "./Components/shop/MyShop";
import MarketShop from "./Components/shop/MarketShop";
import AddToShop from "./Components/shop/AddToShop";
import SingleMarketProduct from "./Components/shop/SingleMarketProduct";
import Contact from "./Components/static/Contact";
import Terms from "./Components/static/Terms";
import Privacy from "./Components/static/Privacy";
import { useDoc, useSpeech } from "./store/store";
import Success from "./Components/payment/Success";
import Error from "./Components/payment/Error";
import InProcess from "./Components/payment/InProcess";
import SinglePost from "./Components/post/SinglePost";
import VerifyMailCode from "./Components/VerifyMailCode";

function App() {
  const local = localStorage.getItem("lang");
  const { setLang } = useSpeech();
  const { setDoc } = useDoc()
  React.useEffect(() => {
    setLang(local ? local : "FR");
    setDoc(local ? local : "FR")
  }, [local, setDoc, setLang]);
  return (
    <Container maxW="container.xl">
      <ChakraProvider>
        <Initializer>
          <EchoContext>
            <TranslateContext>
              <Router>
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="aboutus" element={<AboutHelp />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/new-password" element={<NewPassword />} />
                  <Route path="/verify" element={<VerifyMail />} />
                  <Route path="/verify/:token" element={<VerifyMailCode />} />
                  <Route path="/payment/success" element={<Success />} />
                  <Route path="/payment/error" element={<Error />} />
                  <Route path="/payment/in-process" element={<InProcess />} />
                  <Route element={<Protector />}>
                    <Route path="/chat" element={<Chat />}>
                      <Route index element={<UnknownMessage />} />
                      <Route path=":id" element={<SingleMessage />} />
                    </Route>
                    <Route path="/home" element={<Home />}>
                      <Route index element={<Index />} />
                      <Route path="profil" element={<Profil />} />
                      <Route path="post/:id" element={<SinglePost />} />
                      <Route path="profil/:id" element={<SingleProfil />} />
                      <Route path="list" element={<List />} />
                      <Route path="documents" element={<Documents />} />
                      <Route path="following" element={<Following />} />
                      <Route
                        path="remove-account"
                        element={<DeleteAccount />}
                      />
                      <Route path="bookmarks" element={<Bookmarks />} />
                      <Route path="fans" element={<Followers />} />
                      <Route path="cards" element={<Cards />} />
                      <Route path="newcard" element={<AddCard />} />
                      <Route path="settings" element={<Settings />} />
                      <Route path="shop" element={<MyShop />} />
                      <Route path="addtoshop" element={<AddToShop />} />
                      <Route path="market/:id" element={<MarketShop />} />
                      <Route
                        path="market/product/:id"
                        element={<SingleMarketProduct />}
                      />
                      <Route path="wallet" element={<Wallets />} />
                      <Route path="notification" element={<Notification />} />

                    </Route>


                  </Route>
                </Routes>
              </Router>
            </TranslateContext>
          </EchoContext>
        </Initializer>
      </ChakraProvider>
    </Container>
  );
}

export default App;
