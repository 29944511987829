import React, { PropsWithChildren, useEffect, useState } from "react";
import { VStack, Text, Container, Box, useBreakpointValue } from "@chakra-ui/react";
import {
  useMyPost,
  usePost,
  useSuggestion,
  useUser,
  useNotification,
  useNotificationCount,
  useCards,
  useWallets,
  useWalletsHistory,
  useLoader,
  useChatUserList,
  useSpeech,
  useBookmark,
  useActiveFollowing,
  useExpiredFollowing,
  useActiveFollower,
  useExpiredFollower,
  useFollower,
  useFollowing,
  useAccountList,
  useDevice,
} from "../../store/store";
import {
  getPost,
  getProfile,
  getMyPost,
  getSuggestion,
  getNotificationCount,
  getNotification,
  getCardsList,
  getWalletsIndex,
  getWalletsHistory,
  getChatUser,
  getPostBookmark,
  getActiveFollowing,
  getExpiredFollowing,
  getActiveFollower,
  getExpiredFollower,
  getFollowers,
  getFollowings,
  getBillingAccountList,
} from "../../server/api";
type Props = PropsWithChildren<{}>;

export default function Initializer({ children }: Props) {
  const { setSugData } = useSuggestion();
  const { setAccountList } = useAccountList();
  const { setActive } = useActiveFollowing();
  const { setFollowing } = useFollowing();
  const { setActiveFollower } = useActiveFollower();
  const { setFollower } = useFollower();
  const { setExpired } = useExpiredFollowing();
  const { setExpiredFollower } = useExpiredFollower();
  const { setLang } = useSpeech();
  const { setPost, setTotal, setIsLoading } = usePost();
  const { setLoader } = useLoader();
  const { setBookmark } = useBookmark();
  const { setMyPost,setMyTotal, setMyIsLoading } = useMyPost();
  const { setNotificationCount } = useNotificationCount();
  const { setNotification } = useNotification();
  const { setCards } = useCards();
  const { setUser } = useUser();
  const { setWallets } = useWallets();
  const { setWalletsHistory } = useWalletsHistory();
  const { setChatList } = useChatUserList();
  const token = localStorage.getItem("f-token");
  const local = localStorage.getItem("lang");
  const device = useBreakpointValue({base: true,lg:false})
  const { setDevice } = useDevice()

  useEffect(() => {
    setDevice(device ? "mobile" : "web")
    if (token) {
      getProfile().then((res) => {
        setUser(res?.data?.data);
      });
      setLang(local ? local : "FR");
    }
  }, [device, local, setDevice, setLang, setUser, token]);

  useEffect(() => {
    if (token) {
      getSuggestion().then((res) => {
        setSugData(res?.data?.data);
      });
    }
  }, [setSugData, token]);

  useEffect(() => {
    if (token) {
      getChatUser().then((res) => {
        setChatList(res?.data?.data);
      });
    }
  }, [setChatList, token]);

  useEffect(() => {
    if (token) {
      (async () => {
        setLoader(true);
        const [
          post,
          myPost,
          bookmark,
          following,
          activeFollowing,
          activeFollower,
          follower,
          expiredFollowing,
          expiredFollower,
          notificationCount,
          notification,
          cardsList,
          walletsIndex,
          walletsHistory,
          accountList,
        ] = await Promise.all([
          getPost(0),
          getMyPost(0),
          getPostBookmark(0),
          getFollowings(),
          getActiveFollowing(),
          getActiveFollower(),
          getFollowers(),
          getExpiredFollowing(),
          getExpiredFollower(),
          getNotificationCount(),
          getNotification(0),
          getCardsList(),
          getWalletsIndex(),
          getWalletsHistory(),
          getBillingAccountList(),
        ]);
        setPost(post?.data?.data?.posts);
        setTotal(post?.data?.data?.total);
        setIsLoading(false);
        setMyPost(myPost?.data?.data?.posts);
        setMyTotal(myPost?.data?.data?.total)
        setMyIsLoading(false);
        setBookmark(bookmark.data?.data);
        setFollowing(following.data?.data);
        setActive(activeFollowing.data?.data);
        setActiveFollower(activeFollower.data?.data);
        setFollower(follower.data?.data);
        setExpired(expiredFollowing.data?.data);
        setExpiredFollower(expiredFollower.data?.data);
        setNotificationCount(notificationCount?.data?.data);
        setNotification(notification?.data?.data);
        setCards(cardsList?.data?.data?.cards);
        setWallets(walletsIndex?.data?.data);
        setWalletsHistory(walletsHistory?.data?.data);
        setAccountList(accountList?.data?.data);
        setLoader(false);
      })();
    }
  }, [setAccountList, setActive, setActiveFollower, setBookmark, setCards, setExpired, setExpiredFollower, setFollower, setFollowing, setIsLoading, setLoader, setMyIsLoading, setMyPost, setMyTotal, setNotification, setNotificationCount, setPost, setTotal, setWallets, setWalletsHistory, token]);

  return <div>{children}</div>;
}
