import React from 'react'
import { useEcho } from '../contexts/echo'

export default function useEchoListener(channelName, eventName, callback) {
    const echo = useEcho()

    React.useEffect(() => {
        const sub = echo.channel(channelName).listen(eventName, callback)
        return () => {
            sub.stopListening(eventName, callback)
        }
    }, [eventName, callback, echo, channelName])

    return null
}
