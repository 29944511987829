/* eslint-disable jsx-a11y/alt-text */
import { Box, VStack, useBreakpointValue,Text, Button } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import chapo from "../../img/chapo.png";
import teesh from "../../img/teesh.png";
import pull from "../../img/pull.png";
import sout from "../../img/sout.png";
import gobe from "../../img/gobe.png";

import React,{ useState} from "react";
import { Check, Plus } from "phosphor-react";

export default function MyShopList() {
  const device = useBreakpointValue({ base: "3", lg: "5" });
  const [validate, setValidate] = useState(false)
  return (
    <VStack w="full">
      <Grid
        w="full"
        templateColumns={`repeat(${device}, 1fr)`}
        p={4}
        flexWrap={"wrap"}
        gap={2}
      >
        <GridItem w="full" p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={chapo} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem w="full" p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={sout} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem w="full" p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={pull} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem w="full" p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={gobe} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>

      </Grid>
    </VStack>
  );
}
