/* eslint-disable jsx-a11y/alt-text */
import { Text, VStack, Box, Container, HStack } from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import aboutlogo from "../../img/aboutlogo.svg";
import { Link } from "react-router-dom";
import { useDoc } from "../../store/store";

export default function Terms() {
  const { doc } = useDoc();
  return doc === "FR" ? <TermsFR /> : <TermsEN />;
}

export function TermsEN() {
  const inboxScrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    inboxScrollRef.current?.scrollIntoView();
  }, []);
  return (
    <Container maxW="container.xl">
      <div ref={inboxScrollRef} />
      <Link to="/">
        <HStack
          py={4}
          w="full"
          alignItems={"center"}
          justifyContent="flex-start"
        >
          <img src={aboutlogo} height="300px" width="300px" />
        </HStack>
      </Link>
      <VStack
        w="full"
        paddingBottom={8}
        textAlign={"left"}
        alignItems={"flex-start"}
        justifyContent="center"
      >
        <br />
        <br />
        <p className="c3 mt-5">
          <span className="c0">
            https://followme.fans - GENERAL CONDITIONS - AUGUST 2021
          </span>
        </p>
        <Box color="#005591" fontWeight={700}>
          <p className="c3">
            <span className="c4">
              IMPORTANT - PLEASE READ THIS DOCUMENT CAREFULLY BEFORE CREATING AN
              ACCOUNT AND USING THE WEBSITE SERVICES AND KEEP A COPY OF IT.
            </span>
          </p>
          <p className="c1">
            <span className="c4"></span>
          </p>
          <p className="c3">
            <span className="c4">
              BY CONTINUING TO NAVIGATE, YOU UNDERSTAND AND AGREE THAT
              NAVIGATION ON THE SITE, AS WELL AS THESE GENERAL CONDITIONS OF
              USE, ARE SUBJECT TO FRENCH LAW, AS WELL AS THE JURISDICTION OF THE
              FRENCH COURTS.
            </span>
          </p>

          <p className="c3 my-4">
            <span className="c4">PREAMBLE:</span>
          </p>
        </Box>

        <p className="c3">
          <span>SAS DGO registered in 187 rue de Lourmel PARIS, </span>
          <span className="c6">SIRET</span>
          <span className="c6 c11">&nbsp;</span>
          <span className="c6 c9">85159275800029, TVA FR46851592758.</span>
        </p>

        <p className="c3">
          <span className="c0">
            It provides content sharing services between Creators and Users
            through its website available at the following address
            https://www.followme.fans
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Users can thus consult the Content published by the Creators, while
            the Creators can publish this Content through their Account, in a
            public or restricted manner and as part of the use of the Services.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The purpose of these presents is to define the conditions of use of
            the Website Services by Users.
          </span>
        </p>

        <p className="c3 mb-3">
          <p>
            The User must, prior to his Registration, verify that the Services
            offered by SAS DGO are suitable for him by:{" "}
          </p>
          <br />{" "}
          <p className="c8 mt-3">FULL READING OF THESE GENERAL CONDITIONS.</p>
        </p>
        <p className="c1">
          <span className="c4"></span>
        </p>
        <p className="c3">
          <span className="c0">
            In addition, the User expressly acknowledges having benefited from
            complete pre-contractual information from SAS DGO, in accordance
            with the provisions of Articles L111-1 to L111-8 of the Consumer
            Code, in particular by the prior provision of Terms and conditions.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User understands and accepts in particular that SAS DGO has no
            control over the frequency of activity, or the type of content
            offered by the Creators, who carry out their activity independently.
          </span>
        </p>

        <p className="c1">
          <span className="c8"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 1. Definitions</span>
        </Box>
        <p className="c1">
          <span className="c4"></span>
        </p>

        <p className="c3">
          <span className="c0">
            The terms and expressions referred to below receive, when preceded
            by a capital letter, for the purposes of the interpretation and
            execution hereof, the following definition:
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            - User Refers to any major visitor registered or not on the Website,
            and wishing to have access to the Services as well as the Content
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            - Account Means the space reserved for the User on the Website,
            created from the creation of the account
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            - General Conditions Means the general conditions of use which are
            the subject of these
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Registration Means the act for a User to register in this capacity
            on the Website for the purpose of benefiting from the Services
          </span>
        </p>

        <p className="c3">
          <span>- SAS DGO registered in 187 rue de Lourmel PARIS, </span>
          <span className="c6">SIRET</span>
          <span className="c6 c11">&nbsp;</span>
          <span className="c6">85159275800029, TVA FR46851592758</span>
        </p>

        <p className="c3">
          <span className="c0">
            - Creator Refers to any natural person who has created a
            &quot;Creator&quot; type account on the page reserved for this
            purpose, and who publishes Content online on the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">- Parties Means SAS DGO and the User</span>
        </p>

        <p className="c3">
          <span className="c0">
            - Services Means the services offered by SAS DGO to Users on the
            Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            - Content Means the photographs and videos posted on the Website by
            the Creators
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            - Website Means the website accessible at the following URL:
            <a href="https://www.followme.fans">https://www.followme.fans</a> as
            well as all the functionalities accessible on it
          </span>
        </p>

        <p className="c1">
          <span className="c8"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 2. Scope of application</span>
        </Box>

        <p className="c3">
          <span className="c0">
            These General Conditions of the Website govern and define the terms
            and conditions of use of the Services offered by SAS DGO to Users on
            the Website, access to Content through the Website, and more
            generally any use of the Website. Internet by the User.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            They are submitted to the User prior to Registration on the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is required to read the General Conditions and guarantees
            to have accepted them, prior to his Registration, by checking the
            box provided for this purpose.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Failure to accept the General Conditions prevents any Registration.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Parties agree that their relations will be governed exclusively
            by these General Conditions.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Any deviation from the General Conditions can only be enforced
            against the Parties if it has been duly accepted and formalized in a
            writing signed by the latter.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The fact that one of the Parties does not penalize behavior that
            derogates from the General Conditions does not imply acceptance of
            the derogatory behavior, or waiver of prosecution against the
            perpetrator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            These General Conditions may be subject to subsequent changes. SAS
            DGO reserves the right to modify them at any time.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event of an update of the General Conditions, they must again
            be accepted by the User prior to accessing the Services.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Any modification will be directly opposable to the User as soon as
            it is brought to his attention.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Website is available free of charge on the Internet and is
            accessible to all provided they have Internet access.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Access to certain Services or Content is however subject to payment
            of a sum indicated on the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The payment of the sums provided for in this case is subject to the
            provisions of these General Conditions.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            All costs relating to access to the Website, such as hardware,
            software or Internet access costs, are the sole responsibility of
            visitors to the Website who are solely responsible for the proper
            functioning of their equipment as well as their access. to the
            Internet.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            It is their responsibility in particular to verify the deactivation
            of all filters and other software likely to block access to the
            Website, or to set them up, if necessary, after consulting the
            Website for the purposes of protecting minors.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event that one or more of the provisions of the present would
            be considered as illegal, unenforceable or inapplicable by court
            decision, the other mentions will remain in force.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The fact that one of the Parties does not avail itself of a
            commitment by the other Party to any of the obligations referred to
            in the General Conditions cannot be interpreted for the future as a
            waiver of the obligation in cause.
          </span>
        </p>

        <p className="c1">
          <span className="c8"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 3. Terms of access to the Website</span>
        </Box>
        <br />
        <br />

        <p className="c3">
          <span className="c2">3.1. Purpose of the Website</span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c0">
            The purpose of the Website is to offer Services to the User as well
            as to allow him to access Content under the conditions set out
            below.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">3.2. Access to the Website</span>
        </p>
        <p className="c3">
          <span className="c0">
            Access to the Website is strictly reserved for adults.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Access to it is strictly prohibited for minors, as defined by the
            national legislation applicable to the User, or in any event, under
            the age of 18.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            SAS DGO encourages parents to monitor their children&#39;s use of
            any Internet access, and recommends the implementation of parental
            protection measures, content filters and other protection software.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            SAS DGO strives to allow, but does not guarantee, access to the
            Website 24 hours a day, 7 days a week, except in the event of force
            majeure or an event beyond its control, and subject to possible
            maintenance interventions necessary for the proper functioning of
            the Website and / or interventions for the purpose of improving and
            / or modifying the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            When technically possible, SAS DGO will notify the User of any
            foreseeable interruption twenty-four hours in advance.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Under no circumstances can SASDGO be held responsible for
            interruptions to access to the Website and their consequences,
            whatever the extent, except gross negligence or willful misconduct
            by SAS DGO which directly resulted in said interruption of access.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">3.3. Security and viruses</span>
        </p>
        <p className="c3">
          <span className="c0">
            It is up to each User and more generally to any person visiting the
            Website, to take all appropriate measures to protect themselves
            against the contamination of their data, software or hardware by
            viruses possibly circulating through the Website or the information
            that are published there, and manage its data backups.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User expressly accepts and acknowledges that the use of the
            Website is carried out under his own supervision only, and that he
            is fully responsible for any damage or damage caused to his computer
            system or to any terminal accessing the Internet network and any
            loss of data that may result from downloading or using the Website.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">3.4. Use of the Website</span>
        </p>
        <p className="c3">
          <span className="c0">
            The User expressly accepts that the use of the Website, the
            information and tools included or accessible via the Website is
            carried out under his own responsibility.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is prohibited from carrying out any downloading or
            operations, or adopting any behavior of any kind on the Website
            which could have the consequence of hampering its proper
            functioning, in any way whatsoever.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In any event, the User is solely responsible for any damage of any
            kind that may be caused by his actions on the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User refrains from any action likely to harm the reputation of
            the Website, of SAS DGO, or the personality of its managers,
            employees, partners, and in particular Creators registered on the
            Website.
          </span>
        </p>

        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 4. Access to Services</span>
        </Box>
        <br />
        <br />

        <p className="c3">
          <span className="c0">
            As part of the use of the Services, the User guarantees to provide
            SAS DGO exclusively with truthful, up-to-date information and, more
            generally, to behave in good faith.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            This commitment is decisive of his right to continue using the
            Website and the Services, as well as his access to the Content,
            regardless of any payment that the User may have made.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The communication of a false address, or of a fictitious tax
            domicile, in particular for the purpose of avoiding the applicable
            VAT rates, constitutes an offense of VAT fraud, as defined in
            article 1741 of the General Code of taxes, punishable by 5 years
            imprisonment, and a fine of &euro; 500,000.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In addition, any attempt detected by SAS DGO on the part of a User
            to evade the application of the VAT rate in force in his country of
            residence will lead to the closure of the User&#39;s Account, to its
            definitive ban, in addition to possible legal proceedings for the
            purpose of repairing the damage suffered by SAS DGO.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">
            4.1. Services and Content accessible without Registration
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            The Website allows consultation of the Content that the Creators
            have chosen to make public, even in the absence of a Registration.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Users can also access the Creators personal profile page without
            registering.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            On this page, the User can access the nickname, as well as the
            public information provided by the Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Content made public appears on the home page, as well as on the
            personal page of the Creators without &quot;blurring&quot;, and can
            be viewed by all visitors to the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            On the other hand, the Contents appearing &ldquo;blurred&rdquo; on
            the private profile page of the Creator are reserved for the Users
            having carried out a Registration, and having subscribed to a paying
            subscription to the Creator having shared the Contents.
          </span>
        </p>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c2">4.2. Registration procedure</span>
        </p>
        <p className="c3">
          <span className="c0">
            To be able to use all the Services, the User must register and
            create an Account.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The creation of the account is free, and is done through the form
            available at the following address:
            https://followme.fans/register.php, accessible from the home page of
            the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The user then has the choice between connecting through their
            FACEBOOK account, or creating an account with SAS DGO directly.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.2.1. Register via Facebook</span>
        </p>
        <p className="c3">
          <span className="c0">
            The creation of the Account through a Facebook account is done
            through the dedicated button &quot;register with Facebook&quot;.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is then returned to the FACEBOOK.COM site, in order to
            authenticate.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            He is then informed of the information sent by the company FACEBOOK
            to SAS DGO.
          </span>
        </p>

        <p className="c3">
          <span className="c0">This information includes:</span>
        </p>
        <p className="c3">
          <span className="c0">- the name,</span>
        </p>
        <p className="c3">
          <span className="c0">- profile picture,</span>
        </p>
        <p className="c3">
          <span className="c0">- email-address.</span>
        </p>

        <p className="c3">
          <span className="c0">
            He is also informed that his Registration does not allow SAS DGO to
            publish on Facebook.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User has the option, before his Registration, not to send his
            email address by clicking on &quot;Edit this&quot;.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event of registration through a FACEBOOK account, the User
            guarantees that the FACEBOOK account used for registration is his
            personal and nominative account, and that all the information
            transmitted by FACEBOOK to DGO is accurate and complete.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.2.2. Classic registration</span>
        </p>

        <p className="c3">
          <span className="c0">
            If he chooses the Classic Registration, the User is then invited to
            enter a pseudonym, which will be used to identify him, as well as an
            e-mail address.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User must also choose a password with a sufficient level of
            security and which he must keep strictly secret.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The CNIL considers that a good password must have 12 characters and
            4 different types: lowercase, uppercase, numbers and special
            characters.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            This password must not disclose anything about the User and be
            specific to the Website and his Account.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User must then accept these General Conditions.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            A confirmation email containing a registration confirmation link is
            then sent to the address provided.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            By clicking on the confirmation link, the User completes his
            Registration to the Website.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.3. Subscription to Creators</span>
        </p>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.3.1. Subscription content</span>
        </p>
        <p className="c3">
          <span className="c0">
            Following his Registration, the User can subscribe to Active
            Creators on the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            To do this, he must click on the &quot;Subscribe&quot; button
            juxtaposed with the public photographs of the Creators, or else
            appearing on their public profile.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            It is then returned to a page describing the prices of the
            subscription to this Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Through the subscription, the User obtains access to exclusive
            Content, access to uncensored media (removal of &quot;blurring&quot;
            on the Content of the Creator), as well as the possibility of
            getting closer to the Creator to formulate private media requests to
            him.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Each subscription is only valid for the Creator to which the User
            has subscribed.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Each subscription thus gives access only to the exclusive uncensored
            Content of the single Creator to which the User has subscribed, as
            well as the possibility of making private media requests to this
            single Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User understands and accepts that SAS DGO is not responsible and
            does not exercise any control over the frequency, quality and
            quantity of the sharing of Content by the Creators, who carry out
            their activity in complete independence.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            It is up to the User to carry out research on each Creator, relating
            in particular to their activity on the Website, before subscribing
            to any subscription.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User may at any time suspend the renewal of his subscription,
            for any reason, and in particular in the event of no activity on the
            part of a Creator.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.3.2. Choice of subscription offer</span>
        </p>
        <p className="c3">
          <span className="c0">
            In accordance with Articles L. 111-1 and L. 111-4 of the Consumer
            Code, the essential characteristics and prices of the Services
            offered by SAS DGO as part of the subscription are available on the
            Website in the form of a description showing its main
            characteristics as well as in the stipulations of the General
            Conditions.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User may, at any time, obtain any additional information or an
            answer to his questions on the characteristics of the Services by
            contacting SAS DGO by email at the following address:
            contact@followme.fans
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            When subscribing to a subscription, the User must choose between
            multiple terms offered by the Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The price including tax will be generated automatically according to
            the declared country of residence, and will be communicated to him
            before any purchase on the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In application of the provisions of article 259 B 12e of the General
            Tax Code, the company SAS DGO collects French VAT (20%).
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The &quot;monthly&quot; offer constitutes a monthly debit from the
            payment method chosen by the User, the renewal of which can be
            interrupted at any time.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If the subscription is interrupted, the User loses access to the
            Exclusive Content of the chosen Creator 1 month after payment of the
            last monthly payment.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The &quot;annual&quot; offer generates a single payment of a lump
            sum at the price set by the Creator, including applicable taxes in
            France, allowing access for one year to the Creator&#39;s exclusive
            Content.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The annual subscription is, at its expiration, renewed
            automatically, for successive periods of 1 month, at the price set
            by the Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The renewal can be interrupted by the User at any time.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In this case, the User loses access to the Exclusive Content of the
            Creator chosen 1 month after payment of the last monthly payment.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is then invited to enter their billing information,
            including their first name, last name, address, postal code, city
            and country.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            A summary of the price is then presented to him, including the price
            including tax, the VAT, and the total that will be invoiced by SAS
            DGO.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            He is then invited to accept the General Conditions, before
            proceeding to payment.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User expresses his consent by choosing to click on &quot;I
            accept the General Conditions of Sale&quot;.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Otherwise, ordering a subscription is not possible.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.4. Purchase</span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.4.1. Online payment</span>
        </p>
        <p className="c3">
          <span className="c0">
            When the User decides to make a payment, he is sent to a secure
            payment page.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            A choice is then offered to him between several means of payment.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            He must then click on &quot;I accept the General Conditions of
            Sale&quot;.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If the User decides to use the credit card payment module deployed
            on the Website, his payment data will be sent directly to STRIPE,
            which will be in charge of the transaction.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In other cases, whatever the means of payment used, the payment will
            be administered by the company STRIPE.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            All banking data is processed by a secure payment module, which does
            not transmit any payment information to SAS DGO.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            For security and confidentiality reasons, the User is warned that
            the payment may require validation by connecting to third-party
            services.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If the payment is accepted, the User then obtains access to the
            Exclusive Content distributed by the Creator to which he has
            subscribed.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.4.2. Fraud</span>
        </p>
        <p className="c3">
          <span className="c0">
            In the event of a financial transaction reported as potentially
            fraudulent by payment organizations or banking institutions, or in
            the event of suspicious activity on the account of any User, SAS DGO
            may qualify the User&#39;s Account as a &quot;Monitored
            Account&quot;.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Notwithstanding the stipulations of this Article, SAS DGO reserves
            the right to withhold sums paid through a Monitored Account for a
            maximum period not exceeding 6 months, from the date of payment, or
            until the date of payment. establishment of the legitimacy of
            payments.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The sums thus withheld will not give any access to the Content until
            the expiration of the period, or the establishment of their
            legitimacy.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event that a quantity greater than 1/3 of the Users
            Subscribed to a Creator are Monitored Accounts, SAS DGO may suspend
            the Creator&#39;s account, and may treat all the sums paid to the
            Creator as sums paid by Monitored Users.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.4.3. Right to retract</span>
        </p>
        <p className="c3">
          <span className="c0">
            By accessing the Content, following any order on the Website, in
            accordance with Articles L. 221-18 et seq. Of the Consumer Code, the
            User acknowledges that SAS DGO has fully performed its obligations,
            and he waives benefit from the withdrawal period of fourteen (14)
            days resulting from the provisions of the Consumer Code.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Indeed, access to Exclusive Content being immediate, the User
            understands and accepts that the provisions of 1 &deg; of Article
            L221-28 of the Consumer Code apply:
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            &quot;The right of withdrawal cannot be exercised for contracts:
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            1 &deg; The provision of services fully performed before the end of
            the withdrawal period and whose performance has begun after the
            express prior consent of the consumer and express waiver of his
            right of withdrawal &rdquo;.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.5. Private media</span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">
            4.5.1. Private media access request procedure
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Once subscribed to a Creator, the User has the opportunity to make a
            private media communication request to this Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User has the option of requesting the communication of Content
            in a specific format, or including specific content aspects.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Creator has the possibility, but in any case, no obligation, to
            respond to the request, and to offer a price including tax for the
            communication of the private media to the User.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If the User accepts the price offered by the Creator, he then
            proceeds to payment through the Website of the price.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Creator will then communicate to the User of the private media
            within 72 hours of payment of the amount agreed by the User.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Creator will make private media available to the User through
            the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Content communicated in this context must comply with the
            specifications provided by the User, subject to their acceptance by
            the Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User acknowledges that a private medium is not necessarily a
            medium exclusively intended for the User but a medium which
            corresponds exactly to the request of the User and which is not
            accessible free of charge on any other medium.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Creator may therefore reuse any private media communicated to
            the User, provided, however, that it is not made accessible free of
            charge, and this, on any medium for a minimum period of two years.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event of non-communication by the private media Creator
            within the prescribed time limits, the User may request a
            reimbursement from SAS DGO of the full amount paid.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event of non-compliance of the Content produced with the
            request made by the User, the latter may file a complaint with SAS
            DGO, which will examine his request.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If the request is considered to be well founded, SAS DGO will refund
            the full amount paid by the User.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">
            4.5.2. Procedure for communicating private media to a Creator
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Once subscribed to a Creator, the User has the opportunity to make a
            private media communication request by him to this Creator, who can
            undertake to view it for a fee.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Creator has the possibility, but in any event, no obligation, to
            respond to the request, and to offer a price including tax for the
            communication of a private media from the User to the Creator, and
            its viewing guaranteed by the Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If the User accepts the price offer including tax formulated by the
            Creator, he then proceeds to payment through the Website of the
            price including taxes.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In this case, the User will be invited to upload their private media
            to the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The Creator must then proceed to the viewing of the private media
            within 72 hours of payment of the sum agreed by the User.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event that the Private Media Creator does not view it on
            time, the User may request a refund from SAS DGO of the full amount
            paid.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.5.3. Private media ownership</span>
        </p>
        <p className="c3">
          <span className="c0">
            The provision of private media does not constitute an assignment of
            copyright, or a license to the content of the media.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Neither does it constitute a contract relating to the right to the
            image of the Creator or the User, who remain the full owners of the
            private media, and of all the related rights.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The provision of private media produced by a Creator is made solely
            for the personal use of the User, in the context of website
            navigation.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The provision of private media by a User to a Creator is made solely
            for the attention of the Creator, as part of the performance of the
            Services.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Any downloading, sharing, making available, reproduction, or any
            other form of use or distribution excluding consultation on the
            Website infringes the rights of the Creator or the User, and of SAS
            DGO, and engages the responsibility of the User or the Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            SAS DGO will then be free to proceed with the deletion of the
            User&#39;s account at its sole fault, without any reimbursement for
            the sums paid by the User being payable by the latter.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            SAS DGO will then also be free to invoice the User an amount equal
            to 10 times the amount of the communication price of the private
            media agreed with the Creator, for the violation by the User of his
            contractual commitment of non-communication of private media.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The payment of this sum does not exclude the questioning of the
            responsibility of the User for the violation of the rights of the
            Creator, who will in any event retain the right to request
            compensation for his entire damage resulting therefrom.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The payment of this sum does not also exclude the questioning of the
            responsibility of the User for the violation of other rights of SAS
            DGO, which will retain in any case the right to request the repair
            of its full prejudice resulting from contractual violations
            committed by the User.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The entire amount thus collected will be paid by SAS DGO to the
            Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In addition to this ability to delete the Account, and the invoicing
            of this additional sum, SAS DGO reserves the right to take any other
            legal action against the User in order to assert his rights, or to
            assist the Creator. or any interested third party, in their own
            actions against the User, on the basis in particular of copyright,
            image rights, creator personality rights and contractual liability.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the case of the provision of private media by the User to the
            Creator, the latter has undertaken not to carry out any
            reproduction, provision, sharing or downloading outside the
            framework of the performance of the Services.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.6. Updating the User account</span>
        </p>
        <p className="c3">
          <span className="c0">
            Registration gives the User access to the pages dedicated to him on
            the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User can update their Account information whenever they want.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            They can also access their billing information, private media
            inquiries, and purchase history.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User, however, undertakes to maintain full and accurate
            information on his Account at all times, and therefore to modify his
            Account at each change of situation.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.6.1. Customization of the User Account</span>
        </p>
        <p className="c3">
          <span className="c0">
            The User can also personalize his Account if he wishes:
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            By modifying his nickname, or his password;
          </span>
        </p>
        <p className="c3">
          <span className="c0">By including a profile photo.</span>
        </p>
        <p className="c3">
          <span className="c0">
            The User undertakes never to use any photograph likely to infringe
            public order in any way whatsoever, containing nude or topless
            photos in particular, or infringing the rights of others, or
            presenting an illegal character.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User&#39;s Account is strictly personal, and the User agrees to
            keep his password secret and not to disclose it to any third party
            for any reason whatsoever.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Any connection or use of the User&#39;s Account by a third party of
            its own accord may result in the suspension or deletion of the
            Account by SAS DGO, to the sole fault of the User, without any
            reimbursement for the sums already paid by it cannot be demanded.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event of loss or forgetting of his password, the User must
            reset it without delay by using the &quot;forgot password&quot;
            button and then entering the e-mail address previously indicated and
            linked to his Account.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is solely responsible for the consequences of the use of
            his Account and recognizes that any connection or transmission of
            data made using his Account will be deemed to have been made by him.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">4.6.2. Deletion of the User Account</span>
        </p>
        <p className="c3">
          <span className="c0">
            At any time, the User can send a request to SAS DGO to obtain the
            deletion of his Account.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            This request can be made automatically through the &quot;account
            deletion&quot; section of the user&#39;s profile.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            This request can also be made by email to the address:
            contact@followme.fans, indicating &quot;DELETION&quot; in the
            subject line of the email, and should be sent from the email address
            associated with the Account.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If a request is sent in the forms prescribed in this article, SAS
            DGO undertakes to delete the account, and all related information
            within fifteen days of receipt of the e-mail.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Any request to delete the account is final, and does not give rise
            to any refund, regardless of the subscription periods paid in
            advance.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User understands and accepts that recourse to the deletion of
            the account by request sent by email necessarily entails a
            processing time greater than the automated deletion by means of the
            form on the User profile.
          </span>
        </p>

        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c7">ARTICLE 5. General provisions</span>
        </Box>
        <br />
        <br />

        <p className="c3 hspace-bottom">
          <span className="c2">5.1. Code of conduct</span>
        </p>
        <p className="c3">
          <span className="c0">
            When browsing the Website, and in particular when sending private
            media communication requests, the User undertakes and guarantees
            that he will not infringe any legal or regulatory provision, and
            that he will not will not behave contrary to public order and good
            morals and in particular:
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            - Aimed at obtaining paid sex with a Creator;
          </span>
        </p>
        <p className="c3">
          <span className="c0">- Encouraging or condoning violence;</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Condoning or promoting any form of discrimination based on race,
            ethnicity, religion, disability, - - - Gender, age, gender
            orientation / identity;
          </span>
        </p>
        <p className="c3">
          <span className="c0">- Inciting hatred;</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Presenting a racist, homophobic or discriminatory character;
          </span>
        </p>
        <p className="c3">
          <span className="c0">- Constituting defamation or insult;</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Constituting any threat in particular made against a Creator (or
            anyone);
          </span>
        </p>
        <p className="c3">
          <span className="c0">- Constituting harassment;</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Constituting an unauthorized disclosure of personal and / or
            confidential information of third parties, and in particular of
            Creators and other Users, including the names, first names,
            addresses, social media, telephone numbers, e-mail address of
            natural persons identified by pseudonyms, or any other element
            allowing their identification, or infringing their privacy.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User guarantees that he will use the tools for contacting the
            Creator and SAS DGO in good faith, with the sole aim of enabling the
            Services to be carried out, in accordance with these General
            Conditions.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            For all the services provided within the framework of the Website,
            the User irrevocably undertakes to use exclusively the Services set
            up by SAS DGO and never to act for the purpose of bypassing the
            system of payment of sums due set up on the website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is thus prohibited, for example, from paying sums of money
            to the Creator for services performed on the Website through any
            means other than the Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is informed that SAS DGO can verify the content of
            communications at the request of the Creator.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            SAS DGO pays particular attention to establishing a climate of trust
            between Creators and Users.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Consequently, any abusive behavior against the Creators, in
            particular during exchanges relating to the communication of private
            media, will result in an immediate suspension of the User&#39;s
            account, without prejudice to any legal action that may be brought,
            and damages and interest that could be charged by the Creator or SAS
            DGO, if applicable.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User must in particular refrain from any infringement of the
            legislation protecting minors, and their image.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Any infringement of this legislation which will be brought to the
            attention of SAS DGO will be the subject of immediate denunciation
            to the competent authorities, and communication to the authorities
            of all the information in the possession of SAS DGO.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User undertakes to report any violation found of the
            stipulations of this article, or of the legislation in force to SAS
            DGO, by means of the &ldquo;report&rdquo; button attached to the
            Contents, or by contacting SAS DGO directly at the support address
            contatct@followme.fans.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">
            5.2.Non-compliance with the General Conditions
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            In the event of non-compliance by any User with the General
            Conditions resulting in the establishment of specific procedures by
            SAS DGO, a lump sum of 100 euros including tax may be invoiced by
            SAS DGO to the User, in compensation for the costs of processing the
            file. by SAS DGO, without prejudice to any damages or recovery
            indemnities.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">5.3. Intellectual property</span>
        </p>
        <p className="c3">
          <span className="c0">
            All intellectual property rights, in particular copyrights,
            trademarks, image rights and related rights relating to the general
            structure of the Website as well as to the texts, logos, and any
            other element making up the Website , belonging to SAS DGO, to the
            companies of its group or to their managers remain the exclusive
            property of SAS DGO or their respective holders.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Any exploitation, commercial or not, in particular any downloading,
            copying, reproduction, distribution, transmission, dissemination,
            adaptation, translation or representation, in whole or in part, of
            the Website, and in particular of its general structure or its logo,
            illustrations and Contents present on the Website, by any current or
            future means and process, on any current or future medium, without
            the prior written authorization of SAS DGO or their respective
            owners is prohibited and may give rise to legal proceedings, in
            particular with regard to counterfeiting.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In general, any total or partial reproduction, modification or use
            of these brands, illustrations, images and logos, Content etc. for
            any reason and on any medium whatsoever, without the express prior
            consent of SAS DGO, or of their holders, is strictly prohibited.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The same applies to any combination or conjunction with any other
            brand, symbol, logo and more generally any distinctive sign intended
            to form a composite logo. The same applies to all copyrights,
            designs, models and patents that are the property of SAS DGO, the
            companies in its group, their managers or their partners, in
            particular Creators.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The databases appearing on the Website are protected by the
            provisions of articles L341-1 et seq. Of the Intellectual Property
            Code. In particular, the extraction and reuse, quantitatively or
            qualitatively substantial, of the content of the databases contained
            on the Website are prohibited. Any offender is liable to the
            penalties referred to in Articles L 343-1 et seq. Of the
            Intellectual Property Code.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            It is strictly forbidden to use any program intended to suck the
            content of the Website, with the exception of robots used by
            companies operating search engines.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The literary and artistic property rights relating to the Content,
            the rights to the image of the Creators are the property of the
            Creators and their authors.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The dissemination of Content on the Website does not constitute an
            assignment or a license for the benefit of Users, and does not grant
            any rights to Users, regardless of subscription or amount spent.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Payment for a subscription does not confer any right on the User
            over the Content to which access is authorized, other than the right
            to access and consult the Content in the context of browsing the
            Website.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            It is expressly prohibited to download, reproduce by any means, or
            distribute the Content shared by the Creators on the Website on any
            other medium.
          </span>
        </p>

        <p className="c1">
          <span className="c7"></span>
        </p>

        <br />
        <br />
        <p className="c3 hspace">
          <span className="c7">ARTICLE 6. Personal data</span>
        </p>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c0">
            SAS DGO, as part of the Services, collects a certain amount of
            personal data from Users, necessary for the proper functioning of
            the Website and the performance of the Services.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User acknowledges having read the personal data management
            charter, and having been informed of his rights, as well as the
            terms of their exercise.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User acknowledges that each time personal data concerning him
            has been collected, he has been informed by SAS DGO.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            By creating an Account, the User accepts that the personal data he
            communicates to SAS DGO is subject to the processing necessary for
            the implementation of the Services, in particular in accordance with
            the laws, regulations and other applicable mandatory provisions.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            SAS DGO does not transmit this personal data to any third party,
            except with the express consent of the User.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The personal data collected by SAS DGO are neither sold nor
            communicated to third parties outside the conditions provided for in
            this article.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User&#39;s personal data will only be used for commercial
            prospecting purposes with their express consent.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In accordance with the law &quot;Informatique et
            Libert&eacute;s&quot; of January 6, 1978, of Regulation (EU)
            2016/679 &quot;RGPD&quot;, the User has at any time the right to
            access, query, modify, rectify and delete personal data concerning
            him.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            If the User wishes to no longer receive information by e-mail from
            SAS DGO, other than the information necessary for the use of the
            Services, he can:
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            click on the unsubscribe link at the bottom of e-mails sent by SAS
            DGO;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            or, if he prefers, report it at any time to SAS DGO by e-mail to
            contact@followme.fans, it being understood that the processing time
            is longer by this means than by the unsubscribe link.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            SAS DGO keeps this information for one year from the end of the
            contractual relationship with the User. Any created account
            remaining inactive for an uninterrupted period of one year will see
            the personal data collected and linked to this account deleted and
            its account anonymized.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Personal data whose retention is mandatory (accounting documents,
            etc.) will be kept in the form of an archive for the entire
            mandatory period under the applicable regime.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            All rights relating to personal data can be exercised with SAS DGO
            by e-mail at the address: contact@followme.fans.
          </span>
        </p>

        <p className="c1">
          <span className="c7"></span>
        </p>

        <br />
        <br />
        <p className="c3 hspace">
          <span className="c7">ARTICLE 7. Cookies</span>
        </p>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c0">
            For the proper operation of the Website, SAS DGO may have to place
            cookies in the User&#39;s browser.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            For statistical purposes, SAS DGO may need to collect browsing
            information through the use of cookies.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User is free to accept or refuse cookies by configuring his
            browser (disabling all or some of the cookies - see web browser
            manual or help function).
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            Disabling cookies may result in the unavailability of certain Site
            Services.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The User can, moreover, at any time delete the recording of cookies,
            or cookies already saved on his computer, by configuring the privacy
            protection options of his Internet browser (for example: Tools&gt;
            Erase my tracks &gt; Cookies, on Mozilla Firefox and Tools&gt;
            Delete browsing history&gt; Delete cookies, on Microsoft Internet
            Explorer).
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The CNIL website also indicates the procedure for managing and
            deleting cookies on your browser.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            These explanations are available at the following address:
            https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            The cookies placed, if any, have an activity period of twelve (12)
            months at most.
          </span>
        </p>

        <br />
        <br />
        <p className="c3 hspace">
          <span className="c7">
            ARTICLE 8. Applicable law and competent jurisdiction
          </span>
        </p>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c0">
            These General Conditions are written in English and the resulting
            transactions are governed and subject to French law.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event that they are translated into one or more foreign
            languages, only the French text will prevail in the event of a
            dispute.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In the event of a dispute, the Parties undertake, before any
            referral to a court, to attempt to find an amicable agreement.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            In accordance with articles of the Consumer Code L611-1 and
            following and R612-1 and following, it is provided that for any
            dispute of a contractual nature relating to the execution of the
            sales contract and / or the provision of services that could not be
            be resolved within the framework of a complaint previously lodged
            with SAS DGO, the Consumer may resort to mediation free of charge.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            He will contact the National Association of Mediators (ANM) either:
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            by mail, by writing to 62 rue Tiquetonne 75002 PARIS
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            by e-mail by completing the online referral form at the following
            address www.anm-conso.com
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            In any event, the User is informed that recourse to a mediator, or
            to any form of attempted amicable resolution of a possible dispute
            is not mandatory, that he can put an end to it at any time, and that
            this does not deprive him of the right to bring legal action.
          </span>
        </p>

        <p className="c3 mt-4">
          <span className="c0">
            The User acknowledges having read the legal notices.
          </span>
        </p>
      </VStack>
    </Container>
  );
}

export function TermsFR() {
  const inboxScrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    inboxScrollRef.current?.scrollIntoView();
  }, []);
  return (
    <Container maxW="container.xl">
      <div ref={inboxScrollRef} />
      <Link to="/">
        <HStack py={4} w="full" alignItems={"center"} justifyContent="center">
          <img src={aboutlogo} height="300px" width="300px" />
        </HStack>
      </Link>
      <VStack
        w="full"
        paddingBottom={8}
        textAlign={"left"}
        alignItems={"flex-start"}
        justifyContent="center"
      >
        <br />
        <br />
        <Box mt={5}>
          <span className="c0">
            https://followme.fans - CONDITIONS GÉNÉRALES - AOÛT 2021
          </span>
        </Box>
        <Box color="#005591" fontWeight={700}>
          <p className="c3">
            <span className="c4">
              IMPORTANT - VEUILLEZ LIRE ATTENTIVEMENT CE DOCUMENT AVANT DE CRÉER
              UN COMPTE ET UTILISER LES SERVICES DU SITE WEB ET EN GARDER UNE
              COPIE.
            </span>
          </p>

          <p className="c3">
            <span className="c4">
              EN CONTINUANT À NAVIGUER, VOUS COMPRENEZ ET ACCEPTEZ QUE LA
              NAVIGATION SUR LE SITE, AINSI QUE LES PRÉSENTES CONDITIONS
              GÉNÉRALES D'UTILISATION, SONT SOUMIS À LA LOI FRANÇAISE, AINSI QUE
              LA COMPÉTENCE DES TRIBUNAUX FRANÇAIS.
            </span>
          </p>

          <p className="c3 mt-3">
            <span className="c4">PRÉAMBULE:</span>
          </p>
        </Box>

        <p className="c3">
          <span>SAS DGO immatriculée au 187 rue de Lourmel PARIS,</span>
          <span className="c6">SIRET</span>
          <span className="c6 c11">&nbsp;</span>
          <span className="c6 c9">85159275800029, TVA FR46851592758.</span>
        </p>
        <p className="c3 mb-3">
          <span className="c0">
            Elle fournit des services de partage de contenu entre les créateurs
            et les utilisateurs via son site Web disponible à l'adresse suivante
            https://www.followme.fans
          </span>
        </p>
        <p className="c3 mb-3">
          <span className="c0">
            Les Utilisateurs peuvent ainsi consulter le Contenu publié par les
            Créateurs, tandis que les Créateurs peuvent publier ce Contenu via
            leur Compte, de manière publique ou restreinte et dans le cadre de
            l'utilisation des Services.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les présentes ont pour objet de définir les conditions d'utilisation
            des Services du Site par les Utilisateurs.
          </span>
        </p>
        <p className="c3 mt-3">
          <span>
            L'Utilisateur doit, préalablement à son Inscription, vérifier que
            les Services proposés par la SAS DGO lui conviennent en :{" "}
          </span>
          <br />{" "}
          <p className="c8 mt-5 mb-3">
            LECTURE COMPLÈTE DE CES CONDITIONS GÉNÉRALES.
          </p>
        </p>
        <p className="c1">
          <span className="c4"></span>
        </p>
        <p className="c3">
          <span className="c0">
            En outre, l'Utilisateur reconnaît expressément avoir bénéficié d'une
            information précontractuelle complète auprès de SAS DGO,
            conformément aux dispositions des articles L111-1 à L111-8 du Code
            de la consommation, notamment par la disposition préalable des
            Termes et conditions.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur comprend et accepte notamment que la SAS DGO n'a aucun
            contrôle sur la fréquence d'activité, ou le type de contenu proposé
            par les Créateurs, qui exercent leur activité de manière
            indépendante.
          </span>
        </p>
        <p className="c1">
          <span className="c8"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 1. Définitions</span>
        </Box>
        <p className="c1">
          <span className="c4"></span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur comprend et accepte notamment que la SAS DGO n'a aucun
            contrôle sur la fréquence d'activité, ou le type de contenu proposé
            par les Créateurs, qui exercent leur activité de manière
            indépendante:
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Utilisateur désigne tout visiteur majeur inscrit ou non sur le
            Site, et souhaitant avoir accès aux Services aussi bien que le
            contenu
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Compte Désigne l'espace réservé à l'Utilisateur sur le Site, créé
            dès la création du compte
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Conditions Générales désigne les conditions générales
            d'utilisation qui font l'objet des présentes
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Inscription désigne le fait pour un Utilisateur de s'inscrire en
            cette qualité sur le Site dans le but de bénéficier des Services
          </span>
        </p>
        <p className="c3">
          <span>- SAS DGO immatriculée au 187 rue de Lourmel PARIS,</span>
          <span className="c6">SIRET</span>
          <span className="c6 c11">&nbsp;</span>
          <span className="c6">85159275800029, TVA FR46851592758</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Créateur Désigne toute personne physique ayant créé un compte de
            type "Créateur" sur la page réservée à cet effet, et qui publie du
            Contenu en ligne sur le Site Web
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Parties Signifie SAS DGO et l'Utilisateur
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Services désigne les services proposés par la SAS DGO aux
            Utilisateurs sur le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Contenu désigne les photographies et vidéos mises en ligne sur le
            Site par les Créateurs.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Site Internet Désigne le site Internet accessible à l'URL suivante
            : https://www.followme.fans ainsi que tous les fonctionnalités
            accessibles sur celui-ci.
          </span>
        </p>
        <p className="c1">
          <span className="c8"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 2. Champ d'application</span>
        </Box>
        <p className="c3">
          <span className="c0">
            Les présentes Conditions Générales du Site régissent et définissent
            les conditions d'utilisation des Services proposés par la SAS DGO
            aux Utilisateurs sur le Site, l'accès aux Contenus via le Site, et
            plus généralement tout l'utilisation du site Web. Internet par
            l'Utilisateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Elles sont soumises à l'Utilisateur préalablement à son Inscription
            sur le Site
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur est tenu de prendre connaissance des Conditions
            Générales et garantit les avoir acceptées, préalablement à son
            inscription, en cochant la case prévue à cet effet.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le non-respect des Conditions Générales empêche toute Inscription.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les Parties conviennent que leurs relations seront régies
            exclusivement par les présentes Conditions Générales.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Tout écart par rapport aux Conditions Générales ne pourra être
            opposable aux Parties que s'il a été dûment accepté et formalisé par
            un écrit signé par ce dernier.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le fait que l'une des Parties ne sanctionne pas un comportement
            dérogeant aux Conditions Générales ne n'implique pas l'acceptation
            du comportement dérogatoire, ni la renonciation aux poursuites
            contre l'auteur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les présentes Conditions Générales peuvent faire l'objet de
            modifications ultérieures. SAS DGO se réserve le droit de modifier à
            tout moment.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de mise à jour des Conditions Générales, celles-ci devront
            être à nouveau acceptées par l'Utilisateur avant accéder aux
            Services.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Toute modification sera directement opposable à l'Utilisateur dès
            qu'elle sera portée à sa connaissance.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Site est disponible gratuitement sur Internet et est accessible à
            tous sous réserve d'avoir Internet accès.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'accès à certains Services ou Contenus est toutefois soumis au
            paiement d'une somme indiquée sur le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le paiement des sommes prévues dans ce cas est soumis aux
            dispositions des présentes Conditions Générales.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Tous les frais liés à l'accès au Site, tels que les frais de
            matériel, de logiciel ou d'accès à Internet, sont les seuls
            responsabilité des visiteurs du Site qui sont seuls responsables du
            bon fonctionnement de leur équipement ainsi que leur accès à
            l'internet.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il leur appartient notamment de vérifier la désactivation de tous
            les filtres et autres logiciels susceptibles de bloquer l'accès au
            Site, ou de les mettre en place, le cas échéant, après avoir
            consulté le Site aux fins de protéger les mineurs.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Dans le cas où une ou plusieurs des dispositions des présentes
            seraient considérées comme illégales, inopposables ou inapplicables
            par décision de justice, les autres mentions resteront en vigueur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le fait pour l'une des Parties de ne pas se prévaloir d'un
            engagement de l'autre Partie envers l'un des obligations visées aux
            Conditions Générales ne peuvent être interprétées pour l'avenir
            comme une renonciation aux obligation en cause.
          </span>
        </p>
        <p className="c1">
          <span className="c8"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 3. Conditions d'accès au Site</span>
        </Box>
        <br />
        <br />
        <p className="c3">
          <span className="c2">3.1. Objet du site Web</span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c0">
            Le Site a pour objet d'offrir des Services à l'Utilisateur ainsi que
            de lui permettre d'accéder au Contenu dans le cadre des conditions
            énoncées ci-dessous
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">3.2. Accès au site Web</span>
        </p>
        <p className="c3">
          <span className="c0">
            L'accès au Site est strictement réservé aux personnes majeures.{" "}
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Son accès est strictement interdit aux mineurs, au sens de la
            législation nationale applicable à l'Utilisateur, ou en tout
            événement, de moins de 18 ans.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            SAS DGO encourage les parents à surveiller l'utilisation par leurs
            enfants de tout accès Internet et recommande la mise en œuvre de
            mesures de protection parentale, de filtres de contenu et d'autres
            logiciels de protection.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La SAS DGO s'efforce de permettre, mais ne garantit pas, l'accès au
            Site 24h/24, 7j/7, sauf en cas de force majeure ou d'événement
            indépendant de sa volonté, et sous réserve d'une éventuelle
            maintenance interventions nécessaires au bon fonctionnement du Site
            et/ou interventions aux fins d' améliorer et/ou modifier le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Lorsque cela est techniquement possible, la SAS DGO notifiera à
            l'Utilisateur toute interruption prévisible dans les vingt-quatre
            heures à l'avance.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            La SAS DGO ne pourra en aucun cas être tenue pour responsable des
            interruptions d'accès au Site et de leurs conséquences, quelle qu'en
            soit l'étendue, sauf faute lourde ou intentionnelle de la SAS DGO
            qui aurait directement a entraîné ladite interruption d'accès.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">3.3. Sécurité et virus</span>
        </p>
        <p className="c3">
          <span className="c0">
            Il appartient à chaque Utilisateur et plus généralement à toute
            personne visitant le Site, de prendre toutes les mesures appropriées
            pour se protéger contre la contamination de leurs données, logiciels
            ou matériels par des virus éventuellement circulant sur le Site ou
            les informations qui y sont publiées, et gérer ses sauvegardes de
            données.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur accepte et reconnaît expressément que l'utilisation du
            Site est effectuée sous sa propre surveillance uniquement, et qu'il
            est entièrement responsable de tout dommage ou dommage causé à son
            système informatique ou à tout terminal accédant au réseau Internet
            et toute perte de données pouvant résulter du téléchargement ou de
            l'utilisation du site Web.
          </span>
        </p>

        <p className="c3 hspace-bottom">
          <span className="c2">3.4. Utilisation du site Web</span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur accepte expressément que l'utilisation du Site, des
            informations et des outils inclus ou accessibles via le Site est
            réalisé sous sa propre responsabilité.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur s'interdit d'effectuer tout téléchargement ou
            opération, ou d'adopter tout comportement de quelque nature que ce
            soit sur le Site qui pourrait avoir pour conséquence d'entraver son
            bon fonctionnement, de quelque manière que ce soit.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En tout état de cause, l'Utilisateur est seul responsable de tout
            dommage de toute nature qui pourrait être causé par ses actes sur le
            site Web.
          </span>
        </p>

        <p className="c3">
          <span className="c0">
            L'Utilisateur s'interdit toute action susceptible de porter atteinte
            à la réputation du Site, de la SAS DGO, ou à la personnalité de ses
            dirigeants, salariés, partenaires, et notamment des Créateurs
            inscrits sur le Site.
          </span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c4">ARTICLE 4. Accès aux Services</span>
        </Box>
        <br />
        <br />
        <p className="c3">
          <span className="c0">
            Dans le cadre de l'utilisation des Services, l'Utilisateur s'engage
            à fournir à la SAS DGO exclusivement des informations véridiques,
            des informations à jour et, plus généralement, de se comporter de
            bonne foi.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Cet engagement est déterminant de son droit de continuer à utiliser
            le Site et les Services, ainsi que son accès au Contenu,
            indépendamment de tout paiement que l'Utilisateur aurait pu
            effectuer.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La communication d'une fausse adresse, ou d'un domicile fiscal
            fictif, notamment dans le but d'éviter les taux de TVA applicables,
            constitue un délit de fraude à la TVA, au sens de l'article 1741 du
            Code général des impôts, passible de 5 ans d'emprisonnement et de
            500 000 € d'amende.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En outre, toute tentative détectée par la SAS DGO de la part d'un
            Utilisateur de se soustraire à l'application du taux de TVA en
            vigueur dans son pays de résidence entraînera la fermeture du Compte
            de l'Utilisateur, son bannissement définitif, en plus à
            d'éventuelles poursuites judiciaires aux fins de réparation du
            préjudice subi par la SAS DGO.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">
            4.1. Services et contenus accessibles sans inscription
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Site permet la consultation du Contenu que les Créateurs ont
            choisi de rendre public.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les utilisateurs peuvent également accéder à la page de profil
            personnel des créateurs sans s'inscrire.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Sur cette page, l'Utilisateur peut accéder au pseudonyme, ainsi
            qu'aux informations publiques fournies par le Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Contenu rendu public apparaît sur la page d'accueil, ainsi que
            sur la page personnelle des Créateurs sans « flou » et peut être
            consulté par tous les visiteurs du site Web.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En revanche, les Contenus apparaissent « flous » sur la page de
            profil privé du Créateur sont réservés aux les Utilisateurs ayant
            effectué une Inscription, et ayant souscrit un abonnement payant au
            Créateur avoir partagé le Contenu.
          </span>
        </p>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c2">4.2. Procédure d'inscription</span>
        </p>
        <p className="c3">
          <span className="c0">
            Pour pouvoir utiliser tous les Services, l'Utilisateur doit
            s'inscrire et créer un Compte.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La création du compte est gratuite, et se fait via le formulaire
            disponible à l'adresse suivante :
            https://www.followme.fans/register.php, accessible depuis la page
            d'accueil du Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'utilisateur a alors le choix entre se connecter via son compte
            FACEBOOK, ou créer un compte avec SAS DGO directement.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.2.1. Inscrivez-vous via Facebook</span>
        </p>
        <p className="c3">
          <span className="c0">
            La création du Compte via un compte Facebook se fait via le bouton
            dédié « s'inscrire avec Facebook".
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur est alors renvoyé sur le site FACEBOOK.COM, afin de
            s'authentifier
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il est alors informé des informations transmises par la société
            FACEBOOK à la SAS DGO.
          </span>
        </p>
        <p className="c3">
          <span className="c0">Ces informations comprennent :</span>
        </p>
        <p className="c3">
          <span className="c0">- le nom</span>
        </p>
        <p className="c3">
          <span className="c0">- image de profil,</span>
        </p>
        <p className="c3">
          <span className="c0">- adresse e-mail.</span>
        </p>
        <p className="c3">
          <span className="c0">
            Il est également informé que son Inscription ne permet pas à la SAS
            DGO de publier sur Facebook.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur a la possibilité, avant son Inscription, de ne pas
            envoyer son adresse email en cliquant sur « Modifier ceci ».
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas d'inscription via un compte FACEBOOK, l'Utilisateur garantit
            que le compte FACEBOOK utilisé pour l'inscription est son compte
            personnel et nominatif, et que toutes les informations transmises
            par FACEBOOK à DGO est précis et complet.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.2.2. Inscription classique</span>
        </p>
        <p className="c3">
          <span className="c0">
            S'il choisit l'Inscription Classique, l'Utilisateur est alors invité
            à saisir un pseudonyme, qui lui servira à l'identifier, ainsi qu'une
            adresse e-mail.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur doit également choisir un mot de passe avec un niveau
            de sécurité suffisant et qu'il doit conserver strictement secret.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La CNIL considère qu'un bon mot de passe doit comporter 12
            caractères et 4 types différents : minuscule, majuscules, chiffres
            et caractères spéciaux.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Ce mot de passe ne doit rien révéler sur l'Utilisateur et être
            propre au Site et à son Compte.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur doit alors accepter les présentes Conditions
            Générales.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Un email de confirmation contenant un lien de confirmation
            d'inscription est alors envoyé à l'adresse renseignée.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cliquant sur le lien de confirmation, l'Utilisateur finalise son
            Inscription au Site.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.3. Abonnement aux créateurs</span>
        </p>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.3.1. Contenu de l'abonnement</span>
        </p>
        <p className="c3">
          <span className="c0">
            Suite à son Inscription, l'Utilisateur peut souscrire à des
            Créateurs Actifs sur le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Pour ce faire, il doit cliquer sur le bouton "S'inscrire" juxtaposé
            aux photographies publiques des Créateurs, ou autre apparaissant sur
            leur profil public.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il est alors renvoyé vers une page décrivant les tarifs de
            l'abonnement à ce Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Grâce à l'abonnement, l'Utilisateur obtient l'accès à des Contenus
            exclusifs, l'accès à des médias non censurés (suppression de « flou
            » sur le Contenu du Créateur), ainsi que la possibilité de se
            rapprocher du Créateur pour lui formuler des demandes de médias
            privés.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Chaque abonnement n'est valable que pour le Créateur auquel
            l'Utilisateur a souscrit.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Chaque abonnement donne ainsi accès uniquement au Contenu exclusif
            non censuré du Créateur unique auquel l'Utilisateur s'est abonné,
            ainsi que la possibilité de faire des demandes de médias privés à
            cet unique Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur comprend et accepte que la SAS DGO n'est pas
            responsable et n'exerce aucun contrôle sur la fréquence, la qualité
            et la quantité de partage de Contenu par les Créateurs, qui exercent
            leur activité en indépendance totale.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il appartient à l'Utilisateur d'effectuer des recherches sur chaque
            Créateur, relatives notamment à son activité sur le Site, avant de
            souscrire à tout abonnement.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur peut suspendre à tout moment le renouvellement de son
            abonnement, pour quelque raison que ce soit, et notamment dans le
            cas d'absence d'activité de la part d'un Créateur.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.3.2. Choix de l'offre d'abonnement</span>
        </p>
        <p className="c3">
          <span className="c0">
            Conformément aux articles L. 111-1 et L. 111-4 du Code de la
            consommation, les caractéristiques essentielles et les prix des
            Services proposés par la SAS DGO dans le cadre de l'abonnement sont
            disponibles sur le Site Internet sous la forme d'un descriptif
            reprenant ses principales caractéristiques ainsi que dans les
            stipulations des Conditions Générales.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur peut, à tout moment, obtenir toute information
            complémentaire ou une réponse à ses questions sur le
            caractéristiques des Services en contactant la SAS DGO par email à
            l'adresse suivante : contact@followme.fans
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Lors de la souscription à un abonnement, l'Utilisateur doit choisir
            entre plusieurs offres aux prix fixés par le Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le prix TTC sera généré automatiquement en fonction du pays de
            résidence déclaré, et sera lui être communiqué avant tout achat sur
            le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En application des dispositions de l'article 259 B 12e du Code
            général des impôts, la société SAS DGO perçoit TVA française (20%).
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'offre « mensuelle » constitue un prélèvement mensuel sur le mode
            de paiement choisi par l'Utilisateur, le renouvellement peut être
            interrompu à tout moment.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas d'interruption de l'abonnement, l'Utilisateur perd l'accès au
            Contenu Exclusif du Créateur choisi 1mois après le paiement de la
            dernière mensualité.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'offre « annuelle » génère un paiement unique d'une somme
            forfaitaire au prix fixé par le Créateur, comprenant taxes
            applicables en France, permettant l'accès pendant un an aux Contenus
            exclusifs du Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'abonnement annuel est, à son expiration, renouvelé par tacite
            reconduction, par périodes successives de 1 mois, au prix fixé par
            le Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le renouvellement peut être interrompu par l'Utilisateur à tout
            moment.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Dans ce cas, l'Utilisateur perd l'accès au Contenu Exclusif du
            Créateur choisi 1 mois après le paiement de la dernière mensualité.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur est alors invité à saisir ses informations de
            facturation, notamment ses nom, prénom, adresse, code, ville et
            pays.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Un récapitulatif du prix lui est alors présenté, comprenant le prix
            TTC, la TVA, et le total qui seront facturés par la SAS DGO.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il est alors invité à accepter les Conditions Générales, avant de
            procéder au paiement.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur exprime son consentement en choisissant de cliquer sur
            « J'accepte les Conditions Générales de Vente ».
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Dans le cas contraire, la commande d'un abonnement n'est pas
            possible
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.4. Acheter</span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.4.1. Paiement en ligne</span>
        </p>
        <p className="c3">
          <span className="c0">
            Lorsque l'Utilisateur décide d'effectuer un paiement, il est dirigé
            vers une page de paiement sécurisée.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Un choix lui est alors proposé entre plusieurs moyens de paiement.{" "}
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il doit ensuite cliquer sur « J'accepte les Conditions Générales de
            Vente ».
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Si l'Utilisateur décide d'utiliser le module de paiement par carte
            bancaire déployé sur le Site, ses données de paiement seront envoyé
            directement à STRIPE, qui sera en charge de la transaction.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Toutes les données bancaires sont traitées par un module de paiement
            sécurisé, qui ne transmet aucune information de paiement à la SAS
            DGO.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Pour des raisons de sécurité et de confidentialité, l'Utilisateur
            est averti que le paiement peut nécessiter une validation par
            connexion à des services tiers.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Si le paiement est accepté, l'Utilisateur obtient alors l'accès au
            Contenu Exclusif diffusé par le Créateur pour auquel il a souscrit.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.4.2. Fraude</span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de transaction financière signalée comme potentiellement
            frauduleuse par les organismes de paiement ou bancaires
            institutions, ou en cas d'activité suspecte sur le compte de tout
            Utilisateur, la SAS DGO pourra qualifier Compte en tant que « compte
            surveillé ».
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Nonobstant les stipulations du présent article, la SAS DGO se
            réserve le droit de retenir les sommes versées par l'intermédiaire
            d'un Compte surveillé pour une durée maximale n'excédant pas 6 mois,
            à compter de la date de paiement, ou jusqu'à la date de paiement.
            l'établissement de la légitimité des paiements.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les sommes ainsi retenues ne donneront accès au Contenu qu'à
            l'expiration du délai, ou l'établissement de leur légitimité.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Dans le cas où une quantité supérieure à 1/3 des Utilisateurs
            Abonnés à un Créateur sont des Comptes Suivis, La SAS DGO pourra
            suspendre le compte du Créateur, et pourra considérer toutes les
            sommes versées au Créateur comme des sommes versées par Utilisateurs
            surveillés.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.4.3. Droit de rétractation</span>
        </p>
        <p className="c3">
          <span className="c0">
            En accédant au Contenu, suite à toute commande sur le Site,
            conformément aux articles L. 221-18 et suivants. De du Code de la
            consommation, l'Utilisateur reconnaît que la SAS DGO a pleinement
            exécuté ses obligations, et il renonce bénéficier du délai de
            rétractation de quatorze (14) jours résultant des dispositions du
            Code de la consommation.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En effet, l'accès au Contenu Exclusif étant immédiat, l'Utilisateur
            comprend et accepte que les dispositions du 1° de l'article L221-28
            du code de la consommation s'appliquent :
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            « Le droit de rétractation ne peut être exercé pour les contrats :
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            1° La prestation de services intégralement exécutés avant la fin du
            délai de rétractation et dont l'exécution a commencé après le
            consentement exprès préalable du consommateur et sa renonciation
            expresse à son droit de rétractation ».
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.5. Médias privés</span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">
            4.5.1. Procédure de demande d'accès aux médias privés
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Une fois abonné à un Créateur, l'Utilisateur a la possibilité
            d'effectuer une demande de communication sur un média privé auprès
            de ce Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur a la possibilité de demander la communication de
            Contenus dans un format spécifique, ou incluant des aspects de
            contenu.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Créateur a la possibilité, mais en aucun cas, aucune obligation,
            de répondre à la demande, et de proposer un prix TTC pour la
            communication des médias privés à l'Utilisateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Si l'Utilisateur accepte le prix proposé par le Créateur, il procède
            alors au paiement via le Site Internet.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Créateur communiquera alors à l'Utilisateur du média privé dans
            les 72 heures suivant le paiement du montant accepté par
            l'Utilisateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Créateur mettra des médias privés à la disposition de
            l'Utilisateur via le Site Web.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Contenu communiqué dans ce cadre doit être conforme aux
            spécifications fournies par l'Utilisateur, sous réserve leur
            acceptation par le Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur reconnaît qu'un support privé n'est pas nécessairement
            un support exclusivement destiné à l'Utilisateur mais un support qui
            correspond exactement à la demande de l'Utilisateur et qui n'est pas
            accessible gratuitement sur tout autre support.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Créateur pourra donc réutiliser tout support privé communiqué à
            l'Utilisateur, à condition toutefois qu'il ne soit pas rendu
            accessible gratuitement, et ce, sur tout support pendant une durée
            minimale de deux ans.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de non communication par le Créateur du média privé dans les
            délais prescrits, l'Utilisateur pourra demander un remboursement à
            la SAS DGO de l'intégralité de la somme versée.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de non-conformité du Contenu produit à la demande formulée
            par l'Utilisateur, ce dernier pourra déposer une réclamation auprès
            de la SAS DGO, qui examinera sa demande.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Si la demande est considérée comme fondée, la SAS DGO remboursera
            l'intégralité des sommes versées par l'Utilisateur.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">
            4.5.2. Procédure de communication d'un média privé à un Créateur
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Une fois abonné à un Créateur, l'Utilisateur a la possibilité
            d'effectuer une demande de communication sur un média privé par lui
            à ce Créateur, qui peut s'engager à le visionner contre
            rémunération.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Créateur a la possibilité, mais en tout état de cause, aucune
            obligation, de répondre à la demande, et de proposer un prix TTC
            pour la communication d'un média privé de l'Utilisateur au Créateur,
            et sa visualisation garanti par le Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Si l'Utilisateur accepte l'offre de prix TTC formulée par le
            Créateur, il procède alors au paiement via le Site Internet du prix
            TTC.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Dans ce cas, l'Utilisateur sera invité à mettre en ligne ses médias
            privés sur le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Créateur doit alors procéder au visionnage du média privé dans
            les 72 heures suivant le paiement de la somme accepté par
            l'Utilisateur
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Dans le cas où le Créateur de Média Privé ne le visionnerait pas à
            temps, l'Utilisateur pourra demander un remboursement à SAS DGO du
            montant total payé.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.5.3. Propriété des médias privés</span>
        </p>
        <p className="c3">
          <span className="c0">
            La mise à disposition de médias privés ne constitue pas une cession
            de droit d'auteur ou une licence sur le contenu de média.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il ne constitue pas non plus un contrat portant sur le droit à
            l'image du Créateur ou de l'Utilisateur, qui restent le pleins
            propriétaires des médias privés et de tous les droits y afférents.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La mise à disposition de supports privés produits par un Créateur
            est faite uniquement pour l'usage personnel de l'Utilisateur, dans
            le contexte de navigation sur le site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La mise à disposition de supports privés par un Utilisateur à un
            Créateur est faite à la seule attention du Créateur, dans le cadre
            de l'exécution des Services.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Tout téléchargement, partage, mise à disposition, reproduction, ou
            toute autre forme d'utilisation ou de distribution excluant
            consultation sur le Site porte atteinte aux droits du Créateur ou de
            l'Utilisateur, et de la SAS DGO, et engage la responsabilité de
            l'Utilisateur ou du Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La SAS DGO sera alors libre de procéder à la suppression du compte
            de l'Utilisateur à sa seule faute, sans aucun remboursement des
            sommes versées par l'Utilisateur étant à la charge de ce dernier
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La SAS DGO sera alors également libre de facturer à l'Utilisateur un
            montant égal à 10 fois le montant de la prix de communication des
            médias privés convenu avec le Créateur, pour la violation par
            l'Utilisateur de ses engagement contractuel de non-communication des
            médias privés.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le paiement de cette somme n'exclut pas la mise en cause de la
            responsabilité de l'Utilisateur pour la violation des les droits du
            Créateur, qui conservera en tout état de cause le droit de demander
            réparation résultant de l'intégralité de son préjudice.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le paiement de cette somme n'exclut pas également la mise en cause
            de la responsabilité de l'Utilisateur pour la violation des autres
            droits de la SAS DGO, qui conservera en tout état de cause le droit
            de demander la réparation dans son intégralité du préjudice
            résultant des violations contractuelles commises par l'Utilisateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'intégralité de la somme ainsi encaissée sera versée par la SAS DGO
            au Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Outre cette faculté de suppression du Compte, et la facturation de
            cette somme supplémentaire, la SAS DGO se réserve le droit d'exercer
            toute autre action en justice à l'encontre de l'Utilisateur afin de
            faire valoir ses droits, ou d'assister le Créateur, ou tout tiers
            intéressé, dans ses propres actions à l'encontre de l'Utilisateur,
            sur le fondement notamment du droit d'auteur, droit à l'image,
            droits de la personnalité du créateur et responsabilité
            contractuelle.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de mise à disposition de supports privés par l'Utilisateur au
            Créateur, ce dernier s'est engagé à ne pas porter toute
            reproduction, mise à disposition, partage ou téléchargement en
            dehors du cadre de l'exécution du Prestations de service
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.6. Mise à jour du compte utilisateur</span>
        </p>
        <p className="c3">
          <span className="c0">
            L'inscription donne accès à l'Utilisateur aux pages qui lui sont
            dédiées sur le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur peut mettre à jour les informations de son Compte
            quand il le souhaite.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Ils peuvent également accéder à leurs informations de facturation,
            aux demandes de renseignements des médias privés et à l'historique
            des achats.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur s'engage toutefois à conserver à tout moment des
            informations complètes et exactes sur son Compte, et donc de
            modifier son Compte à chaque changement de situation.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">
            4.6.1. Personnalisation du Compte Utilisateur
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur peut également personnaliser son Compte s'il le
            souhaite :
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En modifiant son pseudo, ou son mot de passe.
          </span>
        </p>
        <p className="c3">
          <span className="c0">En incluant une photo de profil.</span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur s'engage à ne jamais utiliser de photographie
            susceptible de porter atteinte à l'ordre public de quelque manière
            que ce soit, contenant notamment des photos de nus ou seins nus, ou
            portant atteinte aux droits d'autrui, ou présentant un autre
            caractère illégal.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le Compte Utilisateur est strictement personnel, et l'Utilisateur
            s'engage à garder secret son mot de passe et à ne pas le divulguer à
            un tiers pour quelque raison que ce soit.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Toute connexion ou utilisation du Compte de l'Utilisateur par un
            tiers de son propre chef peut entraîner la suspension ou suppression
            du Compte par la SAS DGO, aux seules fautes de l'Utilisateur, sans
            aucun remboursement des sommes déjà payée par elle ne peut être
            exigée.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de perte ou d'oubli de son mot de passe, l'Utilisateur doit
            le réinitialiser sans délai en utilisant "mot de passe oublié" puis
            en saisissant l'adresse e-mail précédemment indiquée et liée à son
            Compte.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur est seul responsable des conséquences de l'utilisation
            de son Compte et reconnaît que toute connexion ou transmission de
            données effectuée à l'aide de son Compte sera effectuée par lui.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">4.6.2. Suppression du compte utilisateur</span>
        </p>
        <p className="c3">
          <span className="c0">
            A tout moment, l'Utilisateur peut adresser une demande à la SAS DGO
            pour obtenir la suppression de son Compte.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Cette demande peut être effectuée automatiquement via la rubrique «
            suppression de compte » du profil de l'utilisateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Cette demande peut également être faite par email à l'adresse :
            contact@followme.fans, en indiquant “SUPPRESSION” dans la ligne
            d'objet de l'e-mail et doit être envoyé à partir de l'adresse e-mail
            associée au compte.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Si une demande est adressée dans les formes prescrites au présent
            article, la SAS DGO s'engage à supprimer le compte, et tout
            informations y afférentes dans les quinze jours suivant la réception
            de l'e-mail.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Toute demande de suppression de compte est définitive, et ne donne
            lieu à aucun remboursement, quel que soit l'abonnement payé
            d'avance.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur comprend et accepte que le recours à la suppression du
            compte envoyé par email entraîne nécessairement un temps de
            traitement supérieur à la suppression automatisée au moyen du
            formulaire sur le profil Utilisateur.
          </span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c7">ARTICLE 5. Dispositions générales</span>
        </Box>
        <br />
        <br />
        <p className="c3 hspace-bottom">
          <span className="c2">5.1. Code de conduite</span>
        </p>
        <p className="c3">
          <span className="c0">
            Lors de la navigation sur le Site, et notamment lors de l'envoi de
            demandes de communication sur des médias privés, l'Utilisateur
            s'engage et garantit qu'il n'enfreint aucune disposition légale ou
            réglementaire, et qu'il ne ne pas se comporter de manière contraire
            à l'ordre public et aux bonnes mœurs et notamment :
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Vise à obtenir des relations sexuelles rémunérées avec un Créateur
            ;
          </span>
        </p>
        <p className="c3">
          <span className="c0">- Encourager ou tolérer la violence ;</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Tolérer ou promouvoir toute forme de discrimination fondée sur la
            race, l'origine ethnique, la religion, le handicap, le sexe, âge,
            orientation/identité de genre ;
          </span>
        </p>
        <p className="c3">
          <span className="c0">- Incitation à la haine ;</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Présenter un caractère raciste, homophobe ou discriminatoire ;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Constituant une diffamation ou une insulte ;
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            - Constituant toute menace notamment portée à l'encontre d'un
            Créateur (ou de toute personne) ;
          </span>
        </p>
        <p className="c3">
          <span className="c0">- Constituant du harcèlement ;</span>
        </p>
        <p className="c3">
          <span className="c0">
            - Constituant une divulgation non autorisée d'informations
            personnelles et/ou confidentielles de tiers, et en notamment des
            Créateurs et autres Utilisateurs, notamment les noms, prénoms,
            adresses, réseaux sociaux, numéros de téléphone, adresse e-mail des
            personnes physiques identifiées par des pseudonymes, ou tout autre
            élément permettant leur identification ou portant atteinte à leur
            vie privée.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur garantit qu'il utilisera les outils permettant de
            contacter le Créateur et la SAS DGO de bonne foi, avec dans le seul
            but de permettre la réalisation des Services, conformément aux
            présentes Conditions Générales.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Pour tous les services fournis dans le cadre du Site, l'Utilisateur
            s'engage irrévocablement à utiliser exclusivement les Services mis
            en place par la SAS DGO et à ne jamais agir dans le but de
            contourner le système de paiement des sommes dues mis en place sur
            le site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur s'interdit ainsi, par exemple, de verser des sommes
            d'argent au Créateur pour des prestations réalisées sur le site Web
            par tout autre moyen que le site Web.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur est informé que la SAS DGO peut vérifier le contenu
            des communications à la demande du Créateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La SAS DGO porte une attention particulière à l'instauration d'un
            climat de confiance entre les Créateurs et les Utilisateurs.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En conséquence, tout comportement abusif à l'encontre des Créateurs,
            notamment lors des échanges relatifs aux communication de médias
            privés, entraînera une suspension immédiate du compte de
            l'Utilisateur, sans préjudice de toute action en justice qui
            pourrait être intentée, et des dommages et intérêts qui pourraient
            être perçus par le Créateur ou SAS DGO, le cas échéant.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur doit notamment s'abstenir de toute infraction à la
            législation protégeant les mineurs, et leur image.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Toute infraction à cette législation qui sera portée à la
            connaissance de la SAS DGO fera l'objet de dénonciation immédiate
            aux autorités compétentes, et communication aux autorités de toutes
            les informations en possession de la SAS DGO.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur s'engage à signaler toute violation constatée des
            stipulations du présent article, ou de la législation en vigueur à
            la SAS DGO, au moyen du bouton « signaler » joint aux Contenus, ou
            en contactant directement la SAS DGO à l'adresse d'assistance
            contatct@followme.fans.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">5.2. Non-respect des Conditions Générales</span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de non-respect par tout Utilisateur des Conditions Générales
            entraînant la mise en place de procédures spécifiques par la SAS
            DGO, une somme forfaitaire de 100 euros TTC pourra être facturée par
            la SAS DGO a Utilisateur, en dédommagement des frais de traitement
            du dossier. par la SAS DGO, sans préjudice de tous dommages-intérêts
            ou indemnités de recouvrement.
          </span>
        </p>
        <p className="c3 hspace-bottom">
          <span className="c2">5.3. Propriété intellectuelle</span>
        </p>
        <p className="c3">
          <span className="c0">
            Tous droits de propriété intellectuelle, notamment droits d'auteur,
            marques, droits à l'image et droits voisins relatifs à la structure
            générale du Site ainsi qu'aux textes, logos, et tout autre élément
            composant le Site Internet, appartenant à la SAS DGO, aux sociétés
            de son groupe ou à leurs dirigeants restent la propriété exclusive
            de la SAS DGO ou de leurs titulaires respectifs.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Toute exploitation, commerciale ou non, notamment tout
            téléchargement, copie, reproduction, diffusion, transmission,
            diffusion, adaptation, traduction ou représentation, en tout ou
            partie, du Site, et notamment de sa structure générale ou de son
            logo, illustrations et Contenus présents sur le Site, par tous
            moyens et procédés actuels ou futurs, sur tout support actuel ou
            futur, sans l'autorisation écrite préalable de SAS DGO ou leurs
            titulaires respectifs est interdite et pourra donner lieu à des
            poursuites judiciaires, notamment auprès de en matière de
            contrefaçon.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            D'une manière générale, toute reproduction totale ou partielle,
            modification ou utilisation de ces marques, illustrations, images et
            logos, Contenu etc. pour quelque motif et sur quelque support que ce
            soit, sans l'accord exprès et préalable de SAS DGO, ou de leurs
            titulaires, est strictement interdite.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il en est de même de toute combinaison ou conjonction avec toute
            autre marque, symbole, logo et plus généralement tout signe
            distinctif destiné à former un logo composite. Il en va de même pour
            tous les droits d'auteur, dessins, modèles et brevets qui sont la
            propriété de la SAS DGO, des sociétés de son groupe, de leurs
            dirigeants ou de leurs associés, en créateurs particuliers.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les bases de données figurant sur le Site sont protégées par les
            dispositions des articles L341-1 et suivants. Du Code de la
            propriété intellectuelle. En particulier, l'extraction et la
            réutilisation, quantitativement ou qualitativement substantielles,
            du contenu des bases de données contenues sur le Site sont
            interdites. Tout contrevenant est passible des peines mentionnés aux
            articles L 343-1 et suivants. Du Code de la Propriété
            Intellectuelle.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il est strictement interdit d'utiliser tout programme destiné à
            aspirer le contenu du Site, à l'exception de robots utilisés par les
            entreprises exploitant des moteurs de recherche.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les droits de propriété littéraire et artistique relatifs au
            Contenu, les droits à l'image des Créateurs sont les propriété des
            Créateurs et de leurs auteurs.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La diffusion de Contenu sur le Site ne constitue pas une cession ou
            une licence au profit de Utilisateurs, et n'accorde aucun droit aux
            Utilisateurs, quel que soit l'abonnement ou le montant dépensé.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le paiement d'un abonnement ne confère aucun droit à l'Utilisateur
            sur le Contenu auquel l'accès est autorisé, autre que le droit
            d'accéder et de consulter le Contenu dans le cadre de la navigation
            sur le Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il est expressément interdit de télécharger, reproduire par quelque
            moyen que ce soit ou distribuer le Contenu partagé par le Créateurs
            sur le Site sur tout autre support.
          </span>
        </p>
        <p className="c1">
          <span className="c7"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c7">ARTICLE 6. Données personnelles</span>
        </Box>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c0">
            La SAS DGO, dans le cadre des Services, collecte un certain nombre
            de données personnelles des Utilisateurs, nécessaires au bon
            fonctionnement du Site et l'exécution des Services.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur reconnaît avoir pris connaissance de la charte de
            gestion des données personnelles, et avoir été informé de ses
            droits, ainsi que des modalités de leur exercice.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur reconnaît que chaque fois que des données personnelles
            le concernant ont été collectées, il a été informé par la SAS DGO.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En créant un Compte, l'Utilisateur accepte que les données
            personnelles qu'il communique à la SAS DGO fassent l'objet de
            traitements nécessaires à la mise en œuvre des Services, notamment
            conformément aux lois, règlements et autres dispositions impératives
            applicables
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La SAS DGO ne transmet ces données personnelles à aucun tiers, sauf
            accord exprès de l'Utilisateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les données personnelles collectées par la SAS DGO ne sont ni
            vendues ni communiquées à des tiers en dehors des conditions prévues
            au présent article.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les données personnelles de l'Utilisateur ne seront utilisées à des
            fins de prospection commerciale qu'avec son accord exprès.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Conformément à la loi « Informatique et Libertés » du 6 janvier
            1978, du Règlement (UE) 2016/679 “RGPD”, l'Utilisateur dispose à
            tout moment d'un droit d'accès, d'interrogation, de modification, de
            rectification et de suppression des données personnelles le
            concernant.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Si l'Utilisateur souhaite ne plus recevoir d'informations par
            courrier électronique de la part de la SAS DGO, autres que les
            informations nécessaire à l'utilisation des Services, il peut :
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Cliquer sur le lien de désinscription en bas des e-mails envoyés par
            la SAS DGO
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Ou, s'il le préfère, le signaler à tout moment à la SAS DGO par
            e-mail à contact@followme.fans, étant entendu que le temps de
            traitement est plus long par ce moyen que par le lien de
            désabonnement.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La SAS DGO conserve ces informations pendant un an à compter de la
            fin de la relation contractuelle avec l'Utilisateur. Tout compte
            créé restant inactif pendant une période ininterrompue d'un an verra
            les données personnelles collectées et liées à ce compte supprimé et
            son compte anonymisé.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les données personnelles dont la conservation est obligatoire
            (documents comptables, etc.) seront conservées sous forme d'archive
            pendant toute la période obligatoire au titre du régime applicable.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Tous les droits relatifs aux données personnelles peuvent être
            exercés auprès de la SAS DGO par courrier électronique à l'adresse :
            contact@followme.fans.
          </span>
        </p>
        <p className="c1">
          <span className="c7"></span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c7">ARTICLE 7. Cookies</span>
        </Box>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c0">
            Pour le bon fonctionnement du Site, la SAS DGO peut être amenée à
            placer des cookies dans le navigateur de l'Utilisateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            A des fins statistiques, la SAS DGO peut être amenée à collecter des
            informations de navigation par l'utilisation de cookies.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur est libre d'accepter ou de refuser les cookies en
            configurant son navigateur (désactivation de tout ou partie des
            cookies voir le manuel du navigateur Web ou la fonction d'aide).
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            La désactivation des cookies peut entraîner l'indisponibilité de
            certains Services du Site.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            L'Utilisateur peut, par ailleurs, à tout moment supprimer
            l'enregistrement de cookies, ou de cookies déjà enregistrés sur son
            ordinateur, en configurant les options de protection de la vie
            privée de son navigateur Internet (par exemple : Outils &gt; Effacer
            les données de navigation &gt; Cookies, sur Mozilla Firefox et
            Outils &gt; Supprimer l'historique de navigation &gt; Supprimer les
            cookies, sur Microsoft Internet Explorateur).
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Le site de la CNIL indique également la procédure de gestion et de
            suppression des cookies sur votre navigateur.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Ces explications sont disponibles à l'adresse suivante :
            https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Les cookies déposés, le cas échéant, ont une durée d'activité de
            douze (12) mois au maximum.
          </span>
        </p>
        <br />
        <br />
        <Box fontFamily={"Inter-extra"} color="#005591">
          <span className="c7">
            ARTICLE 8. Droit applicable et juridiction compétente
          </span>
        </Box>
        <p className="c1">
          <span className="c2"></span>
        </p>
        <p className="c3">
          <span className="c0">
            Les présentes Conditions Générales sont rédigées en langue française
            et les transactions qui en résultent sont régies et soumises à la
            loi française.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de traduction dans une ou plusieurs langues étrangères, seul
            le texte français fera foi dans le cas de litige.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En cas de litige, les Parties s'engagent, avant toute saisine de
            tribunal, à tenter de trouver un règlement amiable une entente.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Conformément aux articles du Code de la consommation L611-1 et
            suivants et R612-1 et suivants, il est entendu que pour tout litige
            de nature contractuelle relatif à l'exécution du contrat de vente
            et/ou à la prestation de services qui n'a pu être résolue dans le
            cadre d'une réclamation préalablement déposée auprès de SAS DGO, le
            consommateur peut recourir gratuitement à la médiation.
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            Il prendra contact avec l'Association Nationale des Médiateurs (ANM)
            soit :
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            par courrier, en écrivant au 62 rue Tiquetonne 75002 PARIS
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            par e-mail en remplissant le formulaire de parrainage en ligne à
            l'adresse suivante www.anm-conso.com
          </span>
        </p>
        <p className="c3">
          <span className="c0">
            En tout état de cause, l'Utilisateur est informé que le recours à un
            médiateur, ou à toute forme de tentative de règlement amiable d'un
            éventuel litige n'est pas obligatoire, qu'il peut y mettre fin à
            tout moment, et que cela ne prive pas lui du droit d'intenter une
            action en justice
          </span>
        </p>
        <p className="c3 mt-4">
          <span className="c0">
            L'Utilisateur reconnaît avoir pris connaissance des mentions
            légales.
          </span>
        </p>
      </VStack>
    </Container>
  );
}
