import { HStack, Box, Text, Button } from "@chakra-ui/react";
import { X } from "phosphor-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpeech } from "../../store/store";

export default function CookiesTag() {
  const [show, setShow] = useState("flex");
  const { lang } = useSpeech();
  return (
    <HStack bg="#fff" p={4} maxW={"58ch"} display={show}>
      <Box textAlign={"left"}>
        <Text fontFamily={"inter"} fontSize={"12px"}>
          {lang.coo1}{" "}
          <Link to="/privacy">
            {" "}
            <span style={{ color: "blue", textDecoration: "underline" }}>
              {lang.coo2}{" "}
            </span>
          </Link>
          <Box
          m={1}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          cursor={"pointer"}
            onClick={() => setShow("none")}
            bg="#005591"
            p={1}
            color="#fff"
            w="70px"
          >
            {lang.coo3}
          </Box>
        </Text>
      </Box>
      <Button p={2} onClick={() => setShow("none")} variant="ghost">
        <X size={18} />
      </Button>
    </HStack>
  );
}
