import { HStack, useBreakpointValue, VStack } from '@chakra-ui/react'
import { useParams } from 'react-router'
import Menu from '../../views/Menu'
import { Outlet } from 'react-router'
import MenuMobile from '../../views/Menu-mobile'
import Header from './Header'
import Messages from './Messages'
import styles from '../../Styles/home.module.css'

export default function Chat() {
 const width = useBreakpointValue({ base: '100%', lg: '91%' })
 const isMobileDevice = useBreakpointValue({ base: true, lg: false })
 const { id } = useParams()
 return (
  <VStack
   w='full'
   h={['', '', '100vh', '100vh', '100vh']}
   alignItems={'center'}
   justifyContent='center'
   spacing={0}
  >
   <Header />
   <HStack w='full' alignItems={'center'} justifyContent={'center'}>
    <VStack
     w={width}
     className={styles.container}
     alignItems={'center'}
     justifyContent='center'
     spacing={0}
     position='relative'
    >
     <HStack
      w='full'
      spacing={0}
      alignItems='flex-start'
      justifyContent={'flex-start'}
     >
      <VStack
       w='288px'
       display={['none', 'none', 'flex', 'flex', 'flex']}
       h='90vh'
      >
       <Menu />
      </VStack>
      <HStack w='full'>
       <VStack w='full' py={0} spacing={0} h={'90vh'} overflowY={'auto'}>
        <HStack h='90vh' w='full' spacing={0}>
         <VStack w='500px' h='100%' overflowY={'auto'}>
          {!isMobileDevice && <Messages />}
          {isMobileDevice && !id && <Messages />}
          {isMobileDevice && id && <Outlet />}
         </VStack>
         <VStack
          w='full'
          display={['none', 'none', 'flex', 'flex', 'flex']}
          h='90vh'
         >
          <Outlet />
         </VStack>
        </HStack>
       </VStack>
      </HStack>
     </HStack>
     <VStack display={['flex', 'flex', 'none', 'none', 'none']}>
      <MenuMobile />
     </VStack>
    </VStack>
   </HStack>
  </VStack>
 )
}
