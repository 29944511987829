import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Avatar,
} from "@chakra-ui/react";
import {
  useToast,
  HStack,
  Box,
  Text,
  Button,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useCards } from "../../store/store";
import { Navigate, useNavigate } from "react-router";
import { UserSubscription } from "../../server/api";

export default function CashSubscriptionButton({ profile, payment }: any) {
  const { cards } = useCards();
  const toast = useToast()
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bool, setBool] = useState<boolean>(false);
  const [grille, setGrille] = React.useState<any>({
    month: "",
    price: "",
  });
  const size = useBreakpointValue({ base: "10px", lg: "12px" });
  const tabs = [
    {
      month: "1 MOIS",
      price: `${payment?.subscription_info?.monthly_amount_formatted}`,
    },
    {
      month: "3 MOIS (-10%)",
      price: `${payment?.subscription_info?.triannual_amount_formatted}`,
    },
    {
      month: "6 MOIS (-20%)",
      price: `${payment?.subscription_info?.semiannual_amount_formatted}`,
    },
    {
      month: "1 AN (-30%)",
      price: `${payment?.subscription_info?.yearly_amount_formatted}`,
    },
  ];

  const subPay = React.useCallback(
    (month: any, price: any) => {
      onOpen();
      setGrille({ ...grille, month: month, price: price });
    },
    [grille, onOpen]
  );

  const paymentSubmit = React.useCallback(() => {
    setBool(true)
    UserSubscription(
      profile?.user?.user_unique_id,
      grille.month === "1 MOIS"
        ? "months"
        : grille.month === "3 MOIS (-10%)"
        ? "3months"
        : grille.month === "6 MOIS (-20%)"
        ? "6months"
        : grille.month === "1 AN (-30%)"
        ? "years"
        : ""
    ).then((res)=>{
      if(res?.data?.success){
        setBool(false)
        onClose()
        toast({
          title: "Lancement du processus.",
          description: `${res?.data?.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      else{
        setBool(false)
        onClose()
        toast({
          title: "Echec du processus",
          description: `${res?.data?.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    })
  }, [grille.month, onClose, profile?.user?.user_unique_id, toast]);
  return (
    <VStack
      w="full"
      p={4}
      minH="119px"
      rounded="6px"
      bg="rgba(159, 201, 242, 0.25)"
    >
      <VStack w="full" p={0} alignItems={"flex-start"} justifyContent="center">
        <Box>
          <Text fontWeight={"600"} fontSize="12px" color="#65676A">
            ABONNEMENT
          </Text>
        </Box>
        <HStack w="full" spacing={1}>
          {tabs.map((data, i) => {
            return (
              <VStack
                key={i}
                w="full"
                alignItems="center"
                justifyContent={"center"}
              >
                <Button
                  w="full"
                  p={0}
                  onClick={() =>
                    cards?.length === 0
                      ? navigate("/home/cards")
                      : subPay(data.month, data.price)
                  }
                  colorScheme="blue"
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  bg="#005591"
                  color="#fff"
                  rounded="6px"
                >
                  <Text fontWeight={"600"} fontSize={size}>
                    {data.month}
                  </Text>
                </Button>
                <Text fontSize={size} fontWeight="600">
                  {data.price}
                </Text>
              </VStack>
            );
          })}
        </HStack>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded="15.23px" w="357px">
          <ModalHeader fontFamily={"Inter"} fontSize="lg">
            S'abonner
          </ModalHeader>
          <Divider />
          <ModalCloseButton border="2px solid #000" p={0} rounded="full" />
          <ModalBody>
            <VStack w="full" spacing={4}>
              <HStack w="full">
                <Avatar src={profile?.user?.picture} w="64.93px" h="64.93px" />
                <VStack alignItems="flex-start" spacing={0}>
                  <Box>
                    <Text
                      fontWeight={"600"}
                      fontSize="12.199px"
                      fontFamily={"Inter"}
                    >
                      {profile?.user?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight={"400"}
                      fontSize="8.71px"
                      fontFamily={"Inter"}
                      color="#65676A"
                    >
                      {profile?.user?.username}
                    </Text>
                  </Box>
                </VStack>
              </HStack>
              <VStack
                w="full"
                h="109px"
                alignItems={"center"}
                justifyContent="center"
                rounded="4px"
                bg="rgba(159, 201, 242, 0.25)"
              >
                <Box>
                  <Text fontFamily={"Inter-extra"} color="#005591">
                    Abonnement mensuel
                  </Text>
                </Box>
                <HStack>
                  <Button
                    colorScheme="blue"
                    fontSize="10.42px"
                    fontFamily={"Inter"}
                    h="31px"
                    bg="#005591"
                    color="#fff"
                  >
                    {grille?.month}
                  </Button>
                  <Box>
                    <Text
                      fontFamily={"Inter"}
                      fontSize="12px"
                      fontWeight="600"
                      color="#005591"
                    >
                      {grille?.price}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              w="100px"
              h="38px"
              fontSize="14px"
              fontFamily={"Inter"}
              colorScheme="grey"
              bg="grey"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              w="149px"
              h="38px"
              colorScheme="green"
              fontSize="14px"
              onClick={paymentSubmit}
              fontFamily={"Inter"}
              bg="#34A853"
              isLoading={bool}
              color="#fff"
            >
              Payer maintenant
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
