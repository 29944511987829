import { VStack, Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { post_delete,getMyPost } from "../../server/api";
import { useToast } from "@chakra-ui/react";
import { TrashSimple } from "phosphor-react";
import { useMyPost, useSpeech } from "../../store/store";

export default function PostDeleteButton({ postId }: any) {
  const toast = useToast();
  const { setMyPost } = useMyPost()
  const { lang } = useSpeech()
  const [bool, setBool] = React.useState(false);
  const handleDeletePost = async () => {
    setBool(true);
    await post_delete(postId).then((res) => {
      if (res.data.success) {
        setBool(false);
        getMyPost(0).then(res=> setMyPost(res.data.data.posts))
        toast({
          title: `${res.data.message}`,
          status: "success",
          isClosable: true,
          position: "bottom-right",
        });
        
      } else {
        setBool(false);
        toast({
          title: "Post introuvable",
          status: "error",
          isClosable: true,
          position: "bottom-right",
        });
      }
    });
  };
  return (
    <VStack w='full' px={2}>
      <HStack  w="full"  bg="transparent"  display="flex" cursor={"pointer"} alignItems={"center"} justifyContent={"flex-start"} onClick={handleDeletePost} color="red">
        <TrashSimple />
        <Text mr={4} fontSize={"sm"}>{lang.delete_post}</Text>
      </HStack>
    </VStack>
  );
}
