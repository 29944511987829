/* eslint-disable jsx-a11y/alt-text */
import { VStack, HStack, Box, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { bookmarkOption, getPost } from "../../server/api";
import { ThumbsUp } from "phosphor-react";
import favo from "../../img/favo.svg";
import favomark from "../../img/favmark.svg";
import { usePost } from "../../store/store";

export default function Bookmarks({ bookData }:any) {
  const toast = useToast();
  const [mention, setMention] = useState(false);
  const { setPost } = usePost();

  React.useEffect(() => {
    setMention(bookData?.is_user_bookmarked === 1 ? true : false);
  }, [bookData?.is_user_bookmarked]);

  const liked = useCallback(
    async (id: any) => {
      await bookmarkOption(bookData?.post_id);
    },
    [bookData?.post_id]
  );
  return (
    <VStack>
      <HStack spacing={2} alignItems="center" justifyContent={"center"}>
        <Box cursor={"pointer"}>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              setMention(!mention);
              liked(!mention ? "liker" : "dislike");
            }}
            src={mention === true ? favomark : favo}
          />
        </Box>
      </HStack>
    </VStack>
  );
}
