import React, { useState } from "react";
import {
  HStack,
  VStack,
  Box,
  Text,
  Input,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { useUser } from "../store/store";

export default function SubscriptionPrice({ callback } :any) {
  const width = useBreakpointValue({ base: "full", lg: "529px" });
  const wx = useBreakpointValue({ base: "full", lg: "100px" });
  const { user } = useUser()
  const pix = useBreakpointValue({ base: "8px", lg: "10px" });
  const [value, setValue] = React.useState(user?.user_subscription ? "6" : "3");
  const [amount, setAmount] = useState({
    monthly: "0.00",
    triannual: "0.00",
    semiannual: "0.00",
    yearly: "0.00",
  });
callback(amount)
  const tarifs = [
    {
      value: "1",
      price1: "2,90 €",
      price2: "7,91 €",
      price3: "14,50 €",
      price4: "26,77 €",
    },
    {
      value: "2",
      price1: "4,90 €",
      price2: "13,36 €",
      price3: "24,50 €",
      price4: "45,23 €",
    },
    {
      value: "3",
      price1: "9,90 €",
      price2: "27,00 €",
      price3: "49,50 €",
      price4: "91,38 €",
    },
    {
      value: "4",
      price1: "19,90 €",
      price2: "54,27 €",
      price3: "99,50 €",
      price4: "183,69 €",
    },
    {
      value: "5",
      price1: "29,90 €",
      price2: "81,55 €",
      price3: "149,50 €",
      price4: "276,00 €",
    },
  ];

  return (
    <VStack w="full">
      <HStack>
        <Text
          fontFamily={"Inter-extra"}
          fontWeight="800"
          color="#005591"
          fontSize="14px"
        >
          PRIX DE L’ABONNEMENT
        </Text>
      </HStack>
      <VStack w="full" spacing={1} overflowX={"auto"}>
        <HStack
          w={width}
          px={2}
          alignItems={"center"}
          justifyContent="space-between"
          rounded="4px"
          fontFamily={"Inter"}
          h="50px"
          bg="#fff"
        >
          <Radio visibility="hidden" colorScheme="blue"></Radio>

          <Box
            w="100px"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            textAlign={"center"}
            h="32px"
            rounded="6.25px"
            bg="#005591"
            color="#fff"
          >
            <Text fontSize={pix}>1 MOIS</Text>
          </Box>
          <Box
            w="100px"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            textAlign={"center"}
            h="32px"
            rounded="6.25px"
            bg="#005591"
            color="#fff"
          >
            <Text fontSize={pix}>3 MOIS (-10%)</Text>
          </Box>
          <Box
            w="100px"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            textAlign={"center"}
            h="35px"
            rounded="6.25px"
            bg="#005591"
            color="#fff"
          >
            <Text fontSize={pix}>6 MOIS(-20%) </Text>
          </Box>
          <Box
            w="100px"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            textAlign={"center"}
            h="35px"
            rounded="6.25px"
            bg="#005591"
            color="#fff"
          >
            <Text fontSize={pix}>12 MOIS(-30%)</Text>
          </Box>
        </HStack>
        <RadioGroup w={width} onChange={setValue} value={value}>
          {tarifs.map((data, i) => {
            return (
              <HStack
                key={i}
                w={width}
                px={2}
                my={1}
                alignItems={"center"}
                justifyContent="space-between"
                rounded="4px"
                fontFamily={"Inter"}
                h="50px"
                bg="#E0EAF5"
              >
                <Box
                  display="flex"
                  rounded="full"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  bg="#fff"
                >
                  <Radio colorScheme="blue" value={`${data.value}`}></Radio>
                </Box>

                <Box
                  w="100px"
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  textAlign={"center"}
                  h="32px"
                  rounded="6.25px"
                  bg="#fff"
                >
                  <Text fontSize={"10px"}>{data.price1}</Text>
                </Box>
                <Box
                  w="100px"
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  textAlign={"center"}
                  h="32px"
                  rounded="6.25px"
                  bg="#fff"
                >
                  <Text fontSize={"10px"}>{data.price2}</Text>
                </Box>
                <Box
                  w="100px"
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  textAlign={"center"}
                  h="32px"
                  rounded="6.25px"
                  bg="#fff"
                >
                  <Text fontSize={"10px"}>{data.price3}</Text>
                </Box>
                <Box
                  w="100px"
                  display="flex"
                  alignItems={"center"}
                  justifyContent="center"
                  textAlign={"center"}
                  h="32px"
                  rounded="6.25px"
                  bg="#fff"
                >
                  <Text fontSize={"10px"}>{data.price4}</Text>
                </Box>
              </HStack>
            );
          })}
          <HStack
            px={2}
            my={1}
            alignItems={"center"}
            justifyContent="space-between"
            rounded="4px"
            fontFamily={"Inter"}
            h="50px"
            bg="#E0EAF5"
          >
            <Box
              display="flex"
              rounded="full"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              bg="#fff"
            >
              <Radio colorScheme="blue" value="6"></Radio>
            </Box>

            <Box
              display="flex"
              alignItems={"center"}
              justifyContent="center"
              textAlign={"center"}
              rounded="6.25px"
              bg="#fff"
            >
              <Input
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                textAlign={"center"}
                w={wx}
                h="32px"
                defaultValue={user?.monthly_amount}
                onChange={(e) =>
                  setAmount({ ...amount, monthly: e.target.value })
                }
                type="text"
                fontSize={"10px"}
              />
            </Box>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent="center"
              textAlign={"center"}
              rounded="6.25px"
              bg="#fff"
            >
              <Input
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                textAlign={"center"}
                w={wx}
                defaultValue={user?.triannual_amount}
                onChange={(e) =>
                  setAmount({ ...amount, triannual: e.target.value })
                }
                h="32px"
                type="text"
                fontSize={"10px"}
              />
            </Box>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent="center"
              textAlign={"center"}
              rounded="6.25px"
              bg="#fff"
            >
              <Input
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                textAlign={"center"}
                w={wx}
                h="32px"
                type="text"
                defaultValue={user?.semiannual_amount}
                onChange={(e) =>
                  setAmount({ ...amount, semiannual: e.target.value })
                }
                fontSize={"10px"}
              />
            </Box>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent="center"
              textAlign={"center"}
              rounded="6.25px"
              bg="#fff"
            >
              <Input
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                textAlign={"center"}
                w={wx}
                h="32px"
                type="text"
                defaultValue={user?.yearly_amount}
                onChange={(e) =>
                  setAmount({ ...amount, yearly: e.target.value })
                }
                fontSize={"10px"}
              />
            </Box>
          </HStack>
        </RadioGroup>
      </VStack>
    </VStack>
  );
}
