/* eslint-disable jsx-a11y/alt-text */
import {
  Avatar,
  HStack,
  Box,
  Text,
  Button,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import add from "../../img/add.svg";
import map from "../../img/map.svg";
import shop from "../../img/shop.svg";
import kdo from "../../img/kdo.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MediaTabs from "../MediaTabs";
import { useNavigate } from "react-router";
import { useSpeech, useUser } from "../../store/store";
import ToDoPost from "../post/ToDoPost";

export default function Profil() {
  const margpic = useBreakpointValue({ base: "0", lg: "8px" });
  const padx = useBreakpointValue({ base: "2", lg: "4" });
  const editBtnH = useBreakpointValue({ base: "30px", lg: "50px" });
  const editBtnW = useBreakpointValue({ base: "122px", lg: "194px" });
  const editBtnFont = useBreakpointValue({ base: "12px", lg: "16px" });
  const { lang } = useSpeech()
  const { user } = useUser();

  var d = new Date();
  let dateLocale = d.toLocaleString("fr-FR", {
    month: "numeric",
    day: "numeric"
  });

  return (
    <VStack w="full" p={0}>
      <VStack
        w="full"
        position="relative"
        backgroundImage={user?.cover}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        minH={"200px"}
      >
        <HStack w="full" p={4} justifyContent="flex-start">
          <HStack alignItems={"flex-start"}>
            <VStack alignItems={"flex-start"} fontFamily="Inter" color="#fff">
              <Text fontWeight={"500"}>{user?.name}</Text>
              <HStack>
                <Text fontSize={"10px"}>
                  {user?.total_posts}{" "}
                  {user?.total_posts > 1 ? "Publications" : "Publication"}
                </Text>
                <Text fontSize={"10px"}> - 0 {lang.like}</Text>
              </HStack>
            </VStack>
          </HStack>

        </HStack>
        <HStack
          px={2}
          position="absolute"
          zIndex={"10"}
          py={"140px"}
          w="full"
          justifyContent={"flex-start"}
        >
          <HStack>
            <Avatar
              h="100px"
              w="100px"
              src={user?.picture}
              mx={margpic}
              rounded="full"
            />
            <Button
              h={"80px"}
              w="80px"
              bgImage={shop}
              colorScheme="transparent"
              rounded="full"
            ></Button>
          </HStack>
        </HStack>
      </VStack>
      <HStack px={padx} w="full" zIndex={"12"} justifyContent={"flex-end"}>
        <HStack>
          <Link to="/home/settings"><Button cursor={"pointer"} w={editBtnW} h={editBtnH} bg="#fff" fontSize={editBtnFont} fontFamily={"Inter"} border="1px solid #ddd" rounded="162px">{lang?.edit_btn}</Button></Link>
        </HStack>
      </HStack>
      <VStack w="full" px={4} fontFamily="Inter" alignItems={"flex-start"}>
        <VStack w="full" alignItems={"flex-start"} spacing={0}>
          <Text fontWeight={"700"} fontSize={["18px", "18px", "28px", "28px"]}>
            {user?.name}
          </Text>
          <HStack w="full" p={0} alignItems={"center"} justifyContent={"space-between"}>
            <Text color="#65676A" fontSize={"14px"}>
              @{user?.username}
            </Text>
            <Box rounded="full" bg="#65676A"></Box>
            <HStack w='full'>
              <Text color="#65676A" fontSize={"14px"}>
                {lang?.view_dat} {dateLocale}
              </Text>

            </HStack>

            <HStack display={["flex", "flex", "none", "none"]} w="full" justifyContent={"flex-start"} py={4}>
              {user?.address && (
                <HStack>
                  <Box>
                    <img src={map} width="12px" height={"15.75px"} />
                  </Box>
                  <Text fontSize={"14px"} color="#47B2CF">{user?.address}</Text>
                </HStack>
              )}
            </HStack>

          </HStack>
        </VStack>
        {user?.about !== null && (
          <Box display={["none", "none", "flex", "flex"]} py={4}>
            <Text fontSize={"14px"} dangerouslySetInnerHTML={{
              __html: `${user?.about}`,
            }}
            ></Text>
          </Box>
        )}
        {user?.about !== null && (
          <VStack display={["flex", "flex", "none", "none"]} alignItems={"flex-start"}>
            <Box py={4}>
              <Text fontSize={"14px"} dangerouslySetInnerHTML={{
                __html: `${user?.about}`,
              }}
              ></Text>
            </Box>
          </VStack>
        )}

        <HStack display={["none", "none", "flex", "flex"]} w="full" justifyContent={"flex-start"} py={4}>
          {user?.address && (
            <HStack>
              <Box>
                <img src={map} />
              </Box>
              <Text color="#47B2CF">{user?.address}</Text>
            </HStack>
          )}
        </HStack>


      </VStack>

      <MediaTabs />
    </VStack>
  );
}
