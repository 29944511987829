import create from "zustand";
import { Translator } from "../Translation/Translator";
import { devtools } from "zustand/middleware";

export const useSpeech = create(set => ({
    lang: Translator("FR"),
    setLang: (value) => set({ lang: Translator(value) })
}))

export const useDoc = create(set => ({
    doc: "FR",
    setDoc: (value) => set({ doc: value })
}))

export const useDevice = create(set => ({
    device: "mobile",
    setDevice: (value) => set({ device: value })
}))

export const useLoader = create(set => ({
    loader: false,
    setLoader: (value) => set({ loader: value })
}))

export const useFollowing = create(set => ({
    following: undefined,
    setFollowing: (value) => set({ following: value })
}))

export const useFollower = create(set => ({
    follower: undefined,
    setFollower: (value) => set({ follower: value })
}))

export const useActiveFollowing = create(set => ({
    active: undefined,
    setActive: (value) => set({ active: value })
}))

export const useActiveFollower = create(set => ({
    activeFollower: undefined,
    setActiveFollower: (value) => set({ activeFollower: value })
}))

export const useExpiredFollowing = create(set => ({
    expired: undefined,
    setExpired: (value) => set({ expired: value })
}))

export const useExpiredFollower = create(set => ({
    expiredFollower: undefined,
    setExpiredFollower: (value) => set({ expiredFollower: value })
}))

export const useBookmark = create(set => ({
    bookmark: undefined,
    setBookmark: (value) => set({ bookmark: value })
}))


export const usePost = create(set => ({
    post: undefined,
    setPost: (value) => set({ post: value }),
    isLoading: true,
    setIsLoading: (value) => set({ isLoading: value }),
    total: 0,
    setTotal: (value) => set({ total: value })
}))

export const useSuggestion = create(set => ({
    sugData: undefined,
    setSugData: (value) => set({ sugData: value })
}))

export const useUser = create(set => ({
    user: undefined,
    setUser: (value) => set({ user: value })
}))

export const useMyPost = create(set => ({
    mypost: undefined,
    setMyPost: (value) => set({ mypost: value }),
    isMyLoading: true,
    setMyIsLoading: (value) => set({ isMyLoading: value }),
    myTotal: 0,
    setMyTotal: (value) => set({ myTotal: value })
}))

export const useNotificationCount = create(set => ({
    notificationCount: undefined,
    setNotificationCount: (value) => set({ notificationCount: value })
}))

export const useNotification = create(set => ({
    notification: undefined,
    setNotification: (value) => set({ notification: value }),
    isLoading: true,
    setIsLoading: (value) => set({ isLoading: value }),
    total: 0,
    setTotal: (value) => set({ total: value })
}))

export const useCards = create(set => ({
    cards: undefined,
    setCards: (value) => set({ cards: value })
}))

export const useWallets = create(set => ({
    wallets: undefined,
    setWallets: (value) => set({ wallets: value })
}))

export const useWalletsHistory = create(set => ({
    walletsHistory: undefined,
    setWalletsHistory: (value) => set({ walletsHistory: value })
}))

export const useChatUserList = create(set => ({
    chatList: undefined,
    setChatList: (value) => set({ chatList: value })
}))

export const useInbox = create(devtools(set => ({
    activeTchat: undefined,
    setActiveTchat: (value) => set({ activeTchat: value }),
    messages: [],
    setMessages: (value) => set({ messages: value }),
    isLoading: true,
    setIsLoading: (value) => set({ isLoading: value }),
    total: 0,
    setTotal: (value) => set({ total: value }),
    page: 1,
    setPage: (value) => set({ page: value }),
})))

export const useAccountList = create(set => ({
    accountList: undefined,
    setAccountList: (value) => set({ accountList: value })
}))

export const useBoxId = create(set => ({
    boxId: undefined,
    setBoxId: (value) => set({ boxId: value })
}))