/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import {
  HStack,
  Text,
  Input,
  VStack,
  useDisclosure,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  Box,
  Avatar,
} from "@chakra-ui/react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import kad from "../../img/kad.svg";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  useToast,
} from "@chakra-ui/react";
import styles from "../../Styles/home.module.css";
import { sendTips } from "../../server/api";

export default function TipsBoxXender({
  profile,
  postId,
  userId,
  fromInbox = false,
  onTipSent,
}: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tip, setTip] = useState({
    amount: "5",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorTrack, setErrorTrack] = useState(false)
  const [error, setError] = useState("")
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const tipData = {
        amount: tip.amount,
        message: tip.message,
        post_id: postId || "",
        user_id: userId,
        from_inbox: fromInbox,
      };
      const { data } = await sendTips(tipData);
      if (data.success) {
        console.log(data);
        onClose();
        onTipSent?.();
        setTip({
          amount: "5",
          message: "",
        });
      }
      else {
        setError(data.error);
        setErrorTrack(true)
        setTimeout(() => {
          setErrorTrack(false)
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const valid = parseInt(tip.amount) >= 5;

  return (
    <VStack>
      <Box cursor={"pointer"} onClick={onOpen} p={0}>
        <img src={kad} />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded="15.23px" w="357px">
          <ModalHeader color="#344054" fontFamily={"Inter"} fontSize="15.23px">
            Envoyer un Tip
          </ModalHeader>
          <Divider />
          <ModalCloseButton border="2px solid #000" p={0} rounded="full" />
          <ModalBody>
            <VStack w="full" spacing={4}>
              <HStack w="full">
                <Avatar src={profile?.to_userpicture} w="42px" h="42px" />
                <VStack alignItems="flex-start" spacing={0}>
                  <Box>
                    <Text
                      fontWeight={"600"}
                      fontSize="12.199px"
                      fontFamily={"Inter"}
                    >
                      {profile?.to_displayname}
                    </Text>
                  </Box>
                </VStack>
              </HStack>
              <VStack w="full" p={1} rounded="6px" border="1px solid #E8E6EA">
                <FormControl>
                  <FormLabel
                    color="#A5A8AC"
                    fontSize="8px"
                    fontFamily={"Inter"}
                  >
                    Montant de la pointe
                  </FormLabel>
                  <HStack w="full">
                    <Input
                      px={0}
                      value={tip.amount}
                      onChange={(e) =>
                        setTip({ ...tip, amount: e.target.value })
                      }
                      className={styles.inputs}
                      defaultValue={5}
                    />
                    <Text>€</Text>
                  </HStack>
                </FormControl>
              </VStack>
              <VStack w="full">
                <FormControl rounded="6px" border="1px solid #E8E6EA">
                  <Textarea
                    fontSize="12px"
                    value={tip.message}
                    onChange={(e) =>
                      setTip({ ...tip, message: e.target.value })
                    }
                    px={2}
                    maxLength={200}
                    fontFamily={"Inter"}
                    className={styles.inputs}
                    placeholder="Ecrire un message"
                  />
                </FormControl>
                <HStack w="full" justifyContent={"flex-end"}>
                  <Text fontSize="14px" fontFamily={"Inter"} color="#A5A8AC">
                    {tip.message.length}/200
                  </Text>
                </HStack>
              </VStack>
              {errorTrack && <Alert status='error'>
                <AlertIcon />
                <AlertDescription>{error}</AlertDescription>
              </Alert>}
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              w="100px"
              h="38px"
              fontSize="14px"
              fontFamily={"Inter"}
              colorScheme="red"
              bg="grey"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              w="149px"
              h="38px"
              colorScheme="green"
              fontSize="14px"
              fontFamily={"Inter"}
              bg="#34A853"
              color="#fff"
              isLoading={isSubmitting}
              isDisabled={isSubmitting || !valid}
              onClick={handleSubmit}
            >
              Envoyer Tip
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
