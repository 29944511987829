/* eslint-disable jsx-a11y/alt-text */
import { VStack, HStack, Text } from "@chakra-ui/react";
import React, { useRef, useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useBreakpointValue,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import styles from "../../Styles/carousel.module.css";
import { Autoplay, Pagination, Navigation, Keyboard, Scrollbar } from "swiper";
import ImageLocked from "./ImageLocked";
import { VideoLoading } from "../Loading";
import VideoRender from "../../function/VideoRender";

export default function OtherImageBoxAssets({ imageData }: any) {
  const onlyFile = imageData[0];
  const swiperRef = useRef<any>();
  const nav = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const msgW = useBreakpointValue({ base: "182px", lg: "247px" });
  const [image, setImage] = useState();
  const handleCarousel = useCallback(
    (link: any) => {
      setImage(link)
      onOpen()
    },
    [onOpen]
  )
  return (
    <VStack
      w={msgW}
      h="full"
      display="flex"
      alignItems="center"
      justifyContent={"center"}
    >

      {/* free image view */}
      {imageData?.length === 1 && onlyFile?.amount === 0 &&
        onlyFile?.file_type === "image" && (
          <img
            onClick={() => handleCarousel(onlyFile?.file)}
            style={{ height: "100%", width: "100%" }}
            src={onlyFile?.file}
          />
        )}

      {/*image view after paid */}
      {imageData?.length === 1 && onlyFile?.amount > 0 && onlyFile?.is_paid &&
        onlyFile?.file_type === "image" && (
          <img
            onClick={() => handleCarousel(onlyFile?.file)}
            style={{ height: "100%", width: "100%" }}
            src={onlyFile?.file}
          />
        )}
      {/* free video view */}
      {imageData?.length === 1 &&
        onlyFile?.file_type === "video" &&
        onlyFile?.amount === 0 &&
        (onlyFile.is_generate ? (
          <VideoRender postFile={onlyFile?.file} />
        ) : (
          <VideoLoading />
        ))}

      {/* image view locked */}
      {
        imageData?.length === 1 &&
        onlyFile?.amount > 0 &&
        onlyFile?.file_type === "image" &&
        onlyFile?.is_paid === 0 &&
        imageData?.length < 2 && <ImageLocked id={onlyFile?.chat_message_id} info={onlyFile} amount={onlyFile?.amount} />
      }
      {/* video view locked */}

      {
        imageData?.length === 1 &&
        onlyFile?.amount > 0 &&
        onlyFile?.file_type === "video" &&
        onlyFile?.is_paid === 0 &&
        imageData?.length < 2 && <ImageLocked id={onlyFile?.chat_message_id} info={onlyFile} amount={onlyFile?.amount} />
      }

      {imageData?.length === 1 && onlyFile?.amount > 0 && onlyFile?.is_paid &&
        onlyFile?.file_type === "video" && (
          <VideoRender postFile={onlyFile?.file} />
        )}

      {imageData?.length > 1 && (
        <Swiper
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          ref={swiperRef as any}
          navigation={nav}
          slidesPerView={1}
          scrollbar={true}
          slidesPerGroupSkip={1}
          modules={[Autoplay, Scrollbar, Navigation, Keyboard, Pagination]}
          className={styles.scontainer}
        >
          {imageData?.length > 1 &&
            imageData?.map((data: any, index: any, t: any) => {
         
              return (
                <SwiperSlide className={styles.schild}>
                  <>
                    {data?.file_type === "image" && data?.amount === 0 && (
                      <img
                        style={{
                          width: `${msgW}`,
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={data?.file}
                      />
                    )}
                    {data?.file_type === "image" && data?.amount > 0 && data?.is_paid === 0 && (
                      <ImageLocked info={data} amount={data?.amount} />
                    )}
                    {data?.file_type === "image" && data?.amount > 0 && data?.is_paid === 1 && (
                      <img
                        style={{
                          width: `${msgW}`,
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={data?.file}
                      />
                    )}
                  </>
                </SwiperSlide>
              );
            })}
        </Swiper>
      )}


      <Modal onClose={onClose} isCentered size="lg" isOpen={isOpen}>
        <ModalOverlay bg="rgb(0,0,0,0.9)" />
        <ModalContent bg="transparent" p={0} m={0} rounded="0px">
          <ModalBody p={0} m={0}>
            {imageData?.length === 1 && onlyFile?.file_type === "image" && (
              <img
                src={image}
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
