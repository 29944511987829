import AWS from "aws-sdk";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 as _S3 } from "@aws-sdk/client-s3";


export const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
export const REGION = process.env.REACT_APP_AWS_REGION;
export const MAX_FILE_SIZE = 1024 * 1024 * 1024 * 3 // 3GB;

export const generateRandomId = () =>
  `${(Math.random() + 1).toString(36).substring(2) +
  (Math.random() + 1).toString(36).substring(2)
  }`;

export function blobToFile(theBlob, fileName) {
  console.log("theBlob", theBlob)
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  /* theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob; */
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob?.type,
  });
}

export const generateFileName = (fileName) => {
  const ext = fileName.split(".")[fileName.split(".").length - 1];
  const name = `${generateRandomId()}.${ext}`;

  return name;
};
const AWS_KEYS = {
  accessKeyId: process.env.REACT_APP_AWS_CLIENT_ID,
  secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
}

AWS.config.update(AWS_KEYS);

export const S3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
/* 
export const AwsS3 = new Upload({
    client: new S3({}) || new S3.Client
}) */

export const simpleUpload = (file, fileName, setProgress) => {
  return S3.putObject({
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: `inputs/${fileName}`
  })
    .on("httpUploadProgress", (evt) => {
      const progress = Math.round((evt.loaded / evt.total) * 100);
      setProgress(progress);
    })
    .send(async (err, data) => {
      if (err) {
        console.log(err);
      } else {
        console.log(data);
        return data;
      }
    });
}

export const simpleFileUpload = (file, fileName) => {
  return S3.putObject({
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: `inputs/${fileName}`
  })
}

export const multipartUploadFile = async (file, fileName, setProgress) => {
  try {
    const params = {
      Bucket: S3_BUCKET,
      Body: file,
      Key: `inputs/${fileName}`,
      ACL: "public-read"
    }
    const upload = new Upload({
      client: new _S3({ region: REGION, credentials: AWS_KEYS }) || new S3Client({ region: REGION, credentials: AWS_KEYS }),
      params
    });

    upload.on("httpUploadProgress", (evt) => {
      const _progress = Math.round((evt.loaded / evt.total) * 100)
      //console.log("progress", _progress)
      setProgress(_progress);
    })

    const result = await upload.done()
    console.log("result", result)
    return result
  } catch (error) {
    console.error(error);
  }
}

export const getAwsUploadParams = (file) => {
  return {
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: generateFileName(file.name),
  };
};

export const AWS_UPLOAD_BASE_URL = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/inputs/`;
