/* eslint-disable jsx-a11y/alt-text */
import { Box, VStack, HStack,Text, Button,useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";
import shop from "../../img/shop.svg";
import shoping from "../../img/shopping-cart.svg";
import { Grid, GridItem } from "@chakra-ui/react";
import { Check, Plus } from "phosphor-react";
import pull from "../../img/pull.png";
import sout from "../../img/sout.png";
import gobe from "../../img/gobe.png";
import chapo from "../../img/chapo.png";
import { useNavigate, useParams } from "react-router";
import { getOtherProfile } from "../../server/api";

export default function MarketShop() {
  const [user, setUser] = useState<any>();
  const [validate, setValidate] = useState(false)
  const device = useBreakpointValue({ base: "3", lg: "5" });
  const { id } = useParams();
  const navigate = useNavigate()
  React.useEffect(() => {
    getOtherProfile(id).then((res) => {
      setUser(res?.data?.data);
    });
  }, [id]);
  return (
    <VStack w="full">
      <VStack
        w="full"
        minH="200px"
        backgroundImage={user?.user?.cover}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        position="relative"
      >
        <VStack
          position="absolute"
          zIndex={99}
          bg="linear-gradient(180deg, rgba(24, 25, 26, 0.6) 0%, rgba(0, 81, 129, 0.09) 121.92%);"
          w="full"
          h="200px"
        >
          <HStack w="full" p={4} justifyContent="flex-start">
            <HStack alignItems={"flex-start"}>
              <VStack alignItems={"flex-start"} fontFamily="Inter" color="#fff">
                <Text fontWeight={"500"}>{user?.user?.name}</Text>
                <HStack>
                  <Text fontSize={"10px"}>
                    {user?.user?.total_posts}{" "}
                    {user?.user?.total_posts > 1
                      ? "Publications"
                      : "Publication"}
                  </Text>
                  <Text fontSize={"10px"}> - 0 J’aime</Text>
                </HStack>
              </VStack>
            </HStack>
          </HStack>
          <Button
            h={"100px"}
            position="absolute"
            bottom={-12}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize={"cover"}
            w="100px"
            bgImage={shop}
            colorScheme="transparent"
            rounded="full"
          ></Button>
        </VStack>
      </VStack>
      <HStack w="full" px={["2", "2", "0", "0"]} justifyContent={"flex-end"}>
        <Button
          p={4}
          fontSize={"sm"}
          colorScheme={"transparent"}
          
          h="38px"
          bg="#DDE3F2"
          color="#fff"
          rounded="60px"
        >
          <img src={shoping} />
          <Text ml={2} fontFamily={"Inter"} color="#005591">Panier</Text>
        </Button>
      </HStack>
      <Grid
        w="full"
        templateColumns={`repeat(${device}, 1fr)`}
        p={4}
        flexWrap={"wrap"}
        gap={2}
      >
        <GridItem w="full" cursor={"pointer"} onClick={()=>navigate("/home/market/product/10")} p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={chapo} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem cursor={"pointer"} onClick={()=>navigate("/home/market/product/10")} w="full" p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={sout} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem cursor={"pointer"} onClick={()=>navigate("/home/market/product/10")} w="full" p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={pull} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem cursor={"pointer"} onClick={()=>navigate("/home/market/product/10")} w="full" p={1} position={"relative"} rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)">
            <img src={gobe} />
            <VStack spacing={0} w='full' alignItems={"flex-start"} textAlign={"left"}>
                <Box>
                    <Text fontSize={"16px"} fontFamily={"Inter"}>Vie shirt</Text>
                </Box>
                <Box>
                    <Text fontSize={"9px"} color="rgba(13, 85, 144, 0.4)" fontFamily={"Inter"}>Dédicace</Text>
                </Box>
                <Box>
                    <Text fontSize={"12px"} fontFamily={"Inter-extra"}>30€</Text>
                </Box>
            </VStack>
            <Box  position="absolute" zIndex={10} bottom={2} right={2}>
            {!validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(true)} p={1} w='24px' bg="#0D5590" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Plus size={24} color="#fff" /></Box>}
            {validate && <Box cursor={"pointer"} h='24px' onClick={()=>setValidate(false)} p={1} w='24px' bg="green" display="flex" alignItems={"center"} justifyContent={"center"} rounded="full"><Check size={24} color="#fff" /></Box>}

            </Box>
        </GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>
        <GridItem w="full" rounded="13.42px" h="145.92px" bg="rgba(159, 201, 242, 0.25)"></GridItem>

      </Grid>
    </VStack>
  );
}
