/* eslint-disable jsx-a11y/alt-text */
import React,{ useRef } from 'react';
import { Box, HStack, Input, Text, Textarea, VStack,useBreakpointValue } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react';
import { Tabs, TabList, InputGroup, Tab, InputRightElement } from '@chakra-ui/react'
import galery from "../../img/galery.svg"
import { CurrencyEur } from 'phosphor-react';

export default function AddToShop() {
    const device = useBreakpointValue({ base: "3", lg: "5" });
    const ref = useRef<any>();
    const upload = async(input: any) => {
    
      console.log(input);
        }
  return (
    <VStack w='full' paddingBottom={16}>
<Grid w='full' templateColumns={`repeat(${device}, 1fr)`} p={4} flexWrap={"wrap"} gap={2}>
  <GridItem w='106.13px' onClick={() => {
                ref?.current?.click() as any;
              }} cursor={"pointer"} border="1.01049px solid #A5A8AC" display="flex" alignItems={"center"} justifyContent={"center"} rounded="4px" h='106.13px' bg='#eee'>
      <img src={galery} height={"42px"} width="42px" />
      <Input
                type="file"
                multiple
                accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                ref={ref as any}
                hidden
                onChange={(e) => {
                  upload(e.target.files);
                }}
              />
  </GridItem>
  <GridItem w='106.13px' rounded="4px" h='106.13px' bg='#eee' />
  <GridItem w='106.13px' rounded="4px" h='106.13px' bg='#eee' />
</Grid>
<HStack w='full' px={4}>
    <Box>
        <Text fontFamily={"Inter"} fontSize={"12px"} color="#0E5490">Cliquez pour ajouter une photo ou vidéo du produit</Text>
    </Box>
</HStack>
<Tabs w='full' py={8} px={4} variant='unstyled'>
  <TabList w='full'>
    <Tab rounded="60px" h='38px' mr={4} border="1px solid #0E5490" w='50%' fontSize={"12px"} bg="#fff" color="#0E5490" _selected={{ color: 'white', bg: '#0E5490' }} fontFamily={"Inter"}>Produit physique</Tab>
    <Tab rounded="60px" h='38px' border="1px solid #0E5490" w='50%' fontSize={"12px"} color="#0E5490" _selected={{ color: 'white', bg: '#0E5490' }} fontFamily={"Inter"}>Produit digital</Tab>
  </TabList>
</Tabs>
<VStack w='full' px={4} spacing={4}>
    <Input h='55px' placeholder="Nom du produit" />
    <Textarea placeholder="Description" />
    <HStack w='full'>
    <InputGroup>
   
    <Input h='55px' placeholder='Prix TTC' />
    <InputRightElement children={<CurrencyEur color='grey' />} />
  </InputGroup>
  <InputGroup>
   
   <Input h='55px' placeholder='Frais de port' />
   <InputRightElement children={<CurrencyEur color='grey' />} />
 </InputGroup>
    </HStack>
</VStack>
<VStack w='full' py={4}>
    <Box>
        <Text fontFamily={"Inter"} fontSize={"12px"} color="#0E5490">Sélectionnez les tailles disponibles</Text>
    </Box>
    <Box>
        <Text fontFamily={"Inter"} fontSize={"10px"} color="#A5A8AC">(Ne rien cocher si le produit est digial)</Text>
    </Box>
</VStack>

<Tabs w='full' py={8} px={4} variant='unstyled'>
  <TabList w='full'>
   <HStack w='full'>
   <Tab rounded="60px" h='38px' border="1px solid #0E5490" w='50%' fontSize={"12px"} color="#0E5490" _selected={{ color: 'white', bg: '#0E5490' }} fontFamily={"Inter"}>S</Tab>
    <Tab rounded="60px" h='38px' border="1px solid #0E5490" w='50%' fontSize={"12px"} color="#0E5490" _selected={{ color: 'white', bg: '#0E5490' }} fontFamily={"Inter"}>M</Tab>
    <Tab rounded="60px" h='38px' border="1px solid #0E5490" w='50%' fontSize={"12px"} color="#0E5490" _selected={{ color: 'white', bg: '#0E5490' }} fontFamily={"Inter"}>L</Tab>
    <Tab rounded="60px" h='38px' border="1px solid #0E5490" w='50%' fontSize={"12px"} color="#0E5490" _selected={{ color: 'white', bg: '#0E5490' }} fontFamily={"Inter"}>XS</Tab>
    <Tab rounded="60px" h='38px' border="1px solid #0E5490" w='50%' fontSize={"12px"} color="#0E5490" _selected={{ color: 'white', bg: '#0E5490' }} fontFamily={"Inter"}>XL</Tab>
   </HStack>
  </TabList>
</Tabs>
    </VStack>
  )
}
