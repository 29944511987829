/* eslint-disable jsx-a11y/alt-text */
import {
  Container,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
  Box,
} from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import aboutlogo from "../../img/logo.svg";
import faqImg from "../../img/fqmg.svg";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useDoc, useSpeech } from "../../store/store";

export default function AboutHelp() {
  const { doc } = useDoc();

  return doc === "FR" ? <FaqFR /> : <FaqEN />;
}

export function FaqEN() {
  const { lang } = useSpeech();
  const fontS = useBreakpointValue({ base: "18px", lg: "28px" });
  const inboxScrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    inboxScrollRef.current?.scrollIntoView();
  }, []);
  return (
    <VStack
      w="full"
      minH="100vh"
      bgGradient={
        "linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"
      }
    >
      <Container maxW="container.xl">
        <div ref={inboxScrollRef} />
        <HStack py={4} w="full" alignItems={"center"} justifyContent="center">
          <img src={aboutlogo} height="300px" width="300px" />
        </HStack>

        <VStack textAlign={"center"} spacing={0} py={4}>
          <Text
            fontWeight={"300"}
            fontFamily="Inter"
            fontSize={fontS}
            color="#005591"
          >
            {lang.titleI1}
          </Text>
          <Text
            fontWeight={"900"}
            fontFamily="Inter-extra"
            fontSize={fontS}
            color="#005591"
          >
            {lang.titleI2}
          </Text>
        </VStack>
        <VStack
          textAlign={"left"}
          alignItems="flex-start"
          my={8}
          p={4}
          fontFamily="Inter"
          bg="rgba(159, 201, 242, 0.25)"
          w="full"
          color="#344054"
          fontSize={"16px"}
          rounded="6px"
        >
          <Text fontFamily="Inter-extra">
            Today's artists and content creators are stars.
          </Text>
          <Text>
            Hard workers, who put all their energy, intelligence and talent into
            one goal: to give to their community.
          </Text>
          <Text>
            Content creators keep the platforms that host them alive. But these
            platforms don't always keep their creators alive.
          </Text>
          <Text fontFamily="Inter-extra">
            With followme.fans, we believe it's time to give creators more
            financial independence.
          </Text>
          <Text>
            followme.fans is a premium social network designed to give creators
            the best possible chance for success, by three principles.
          </Text>
          <Text>
            <span
              style={{
                fontWeight: "bold",
                color: "#005591",
                fontFamily: "Inter-extra",
              }}
            >
              • More innovative :
            </span>{" "}
            Your personal social network in web application with your name
            domain to easily manage your community*
          </Text>
          <Text>
            <span
              style={{
                fontWeight: "bold",
                color: "#005591",
                fontFamily: "Inter-extra",
              }}
            >
              • Fairer :
            </span>{" "}
            While other platforms charge commissions up to 30%, followme.fans
            remains at 10%, without VAT deducted.
          </Text>
          <Text>
            <span
              style={{
                fontWeight: "bold",
                color: "#005591",
                fontFamily: "Inter-extra",
              }}
            >
              • More serene :
            </span>{" "}
            followme.fans is the only application on the market to offer a
            support for creators to move on to monetizing their content.
          </Text>
          <Text
            style={{
              fontWeight: "bold",
              color: "#005591",
              fontFamily: "Inter-extra",
            }}
          >
            Each creator is the owner of his clients on his private space and
            can at any time transmit or sell it, allowing an additional
            valuation of his work over time.
          </Text>
          <Text> Join the platform that treats you like a star.</Text>
          <VStack py={2} w="full" alignItems={"flex-start"}>
            <Text fontFamily={"Inter-extra"} color="#005591">
              followme.fans
            </Text>
          </VStack>
        </VStack>

        <VStack w="full">
          <HStack>
            <Text fontSize={"45px"} color="#005591" fontFamily="Poppins">
              FAQ
            </Text>
          </HStack>
        </VStack>
        <Grid
          w="full"
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          templateColumns="repeat(2, 1fr)"
          gap={6}
        >
          <GridItem w="100%">
            <Accordion w="full" defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      What does the service offer?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans is a very simple tool to provide your community
                  with more exclusive and complete video or photo content,
                  allowing you quick and stable additional income.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      What are the different means of monetization?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans offers the sale of subscription or monetization
                  of posts, tips, sale of personalized media via chat,
                  merchandising shop.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      What are the transaction fees?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans only takes 10% commission on the sale + €0.30
                  for transaction costs. Without VAT deducted because depending
                  on your status you will pay it or not.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      What is my final compensation on sales?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  Example €100 collected = €89.70 for you.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      How are the prices calculated?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  The recommended prices (from €2.50 per month) per
                  followme.fans are set following a market study on the sector.
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>

          <GridItem w="100%">
            <Accordion w="full" defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Can I create my own rates?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  Yes everything is possible via your administration interface
                  but it is not necessarily recommended at the beginning.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      What is the sales settlement period?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  After your request, an automatic bank transfer is sent within
                  2-3 days.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      How do I get my subscribers to come?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans offers you a nominative domain name, promote it
                  on your social networks. followme.fans communicates on its
                  side through different media to attract new potential
                  customers.
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>
        </Grid>
        <VStack w="full" alignItems="center" textAlign="center" py={8}>
          <Box w="full">
            <Text>
              A question or request? Do not hesitate we are here for you
              contact@followme.fans
            </Text>
          </Box>
          <Box w="full">
            <Text>
              demo account {""}
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href="https://www.followme.fans/followmefans"
              >
                www.followme.fans/followmefans
              </a>
            </Text>
          </Box>
        </VStack>
      </Container>
    </VStack>
  );
}

export function FaqFR() {
  const { lang } = useSpeech();
  const fontS = useBreakpointValue({ base: "18px", lg: "28px" });
  const inboxScrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    inboxScrollRef.current?.scrollIntoView();
  }, []);
  return (
    <VStack
      w="full"
      minH="100vh"
      bgGradient={
        "linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"
      }
    >
      <Container maxW="container.xl">
        <div ref={inboxScrollRef} />
        <HStack py={4} w="full" alignItems={"center"} justifyContent="center">
          <img src={aboutlogo} height="300px" width="300px" />
        </HStack>

        <VStack textAlign={"center"} spacing={0} py={8}>
          <Text
            fontWeight={"300"}
            fontFamily="Inter"
            fontSize={fontS}
            color="#005591"
          >
            {lang.titleI1}
          </Text>
          <Text
            fontWeight={"900"}
            fontFamily="Inter-extra"
            fontSize={fontS}
            color="#005591"
          >
            {lang.titleI2}
          </Text>
        </VStack>
        <VStack
          textAlign={"left"}
          alignItems="flex-start"
          my={8}
          p={4}
          fontFamily="Inter"
          bg="rgba(159, 201, 242, 0.25)"
          w="full"
          color="#344054"
          fontSize={"16px"}
          rounded="6px"
        >
          <Text fontFamily="Inter-extra">
            Aujourd’hui les artistes et créateurs de contenu sont des stars.
          </Text>
          <Text>
            Des travailleurs acharnés, qui mettent toute leur énergie, leur
            intelligence et leur talent dans un seul but : donner à leur
            communauté.
          </Text>
          <Text>
            Les créateurs de contenu sont ceux qui font vivre les plateformes
            qui les hébergent. Mais ces plateformes ne les font pas toujours
            vivre.
          </Text>
          <Text fontFamily="Inter-extra">
            Chez followme.fans, nous pensons qu’il est temps de donner aux
            créateurs plus d’indépendance financière.
          </Text>
          <Text>
            followme.fans est un réseau social premium conçu pour donner aux
            créateurs toutes leurs chances de réussir, grâce à trois principes.
          </Text>
          <Text>
            <span
              style={{
                fontWeight: "bold",
                color: "#005591",
                fontFamily: "Inter-extra",
              }}
            >
              • Plus innovant :
            </span>{" "}
            Ton réseau social personnel en web application avec ton nom de
            domaine pour gérer facilement ta communauté*.
          </Text>
          <Text>
            <span
              style={{
                fontWeight: "bold",
                color: "#005591",
                fontFamily: "Inter-extra",
              }}
            >
              • Plus équitable :
            </span>{" "}
            Alors que les autres plateformes pratiquent des commissions jusqu'à
            30%, followme.fans reste à 10%, sans TVA déduite.
          </Text>
          <Text>
            <span
              style={{
                fontWeight: "bold",
                color: "#005591",
                fontFamily: "Inter-extra",
              }}
            >
              • Plus serein :
            </span>{" "}
            followme.fans est la seule application du marché à proposer un
            accompagnement aux créateurs pour passer à la monétisation de leurs
            contenus.
          </Text>
          <Text
            style={{
              fontWeight: "bold",
              color: "#005591",
              fontFamily: "Inter-extra",
            }}
          >
            Chaque créateur est propriétaire de ses clients sur son espace privé
            et peut à tout moment le transmettre ou le vendre, permettant une
            valorisation supplémentaire de son travail dans le temps.
          </Text>
          <Text> Rejoignez la plateforme qui vous traite comme une star.</Text>
          <VStack py={2} w="full" alignItems={"flex-start"}>
            <Text fontFamily={"Inter-extra"} color="#005591">
              followme.fans
            </Text>
          </VStack>
        </VStack>

        <VStack w="full">
          <HStack>
            <Text fontSize={"45px"} color="#005591" fontFamily="Poppins">
              FAQ
            </Text>
          </HStack>
        </VStack>
        <Grid
          w="full"
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          templateColumns="repeat(2, 1fr)"
          gap={6}
        >
          <GridItem w="100%">
            <Accordion w="full" defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Que propose le service?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans est un outil très simple pour proposer à votre
                  communauté du contenu vidéo ou photo plus exclusif et plus
                  complet, vous permettant des revenus supplémentaires rapides
                  et stables.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Quels sont les différents moyens de monétisation?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans propose la vente d’abonnement ou monétisation de
                  post, tips, vente de média personnalisés via le chat, boutique
                  de merchandising.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Quels sont les frais sur les transactions?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans prend seulement 10% de commission sur le vente
                  +0,30€ pour les frais d’opérations. Sans TVA déduite car selon
                  votre statut vous la paierez ou non.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Quelle est ma rémunération finale sur les ventes?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  Exemple 100€ encaissé = 89,70€ pour vous.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Comment sont calculés les prix?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  Les tarifs conseillés (à partir de 2,50€ par mois) par
                  followme.fans sont fixés suite à une étude de marché sur le
                  secteur
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>

          <GridItem w="100%">
            <Accordion w="full" defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Puis-je créer mes propres tarifs?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  Oui tout est possible via votre interface d'administration
                  mais cela n'est pas forcément conseillé au début.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Quel est le délai de règlement des ventes?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  Après votre demande, un virement bancaire automatique est
                  envoyé sous 2-3 jours.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton bg="#fff" py={8}>
                    <Box
                      as="span"
                      fontFamily={"inter"}
                      fontWeight={700}
                      color="#005591"
                      flex="1"
                      textAlign="left"
                    >
                      Comment faire pour que mes abonnés viennent?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel fontFamily={"inter"} pb={4}>
                  followme.fans vous offre un nom de domaine nominatif,
                  faites-en la promotion sur vos réseaux sociaux. followme.fans
                  communique de son côté par le biais de différents médias pour
                  attirer de nouveaux clients potentiels.
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>
        </Grid>
        <VStack
          w="full"
          spacing={4}
          alignItems="center"
          textAlign="center"
          py={8}
        >
          <Box w="full">
            <Text>
              Une question ou demande? N'hésitez pas nous sommes la pour vous
              contact@followme.fans
            </Text>
          </Box>
          <Box w="full">
            <Text>
              compte démo{" "}
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                href="https://www.followme.fans/followmefans"
              >
                www.followme.fans/followmefans
              </a>
            </Text>
          </Box>
        </VStack>
      </Container>
    </VStack>
  );
}
