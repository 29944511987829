/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState,useEffect, PropsWithChildren } from 'react';
import { Translator } from './Translator';
const betContext = React.createContext(undefined)
export const useLang = () => {
  return React.useContext(betContext)
}

export default function TranslateContext({children}) {
  
  const [option, setOption] = React.useState("FR")
useEffect(() => {
  const local = localStorage.setItem("local-lang",option)
}, [option]);
  const text = React.useMemo(() => {
    let txt = Translator(option)
    return txt
  }, [option])
  const transmettor = React.useCallback(
    (newLang) => {
    setOption(newLang)

    },
    [setOption]
  )
  return (
    <betContext.Provider value={{ text, transmettor }}>
      {children}
    </betContext.Provider>
  );
}
