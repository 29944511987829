/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  HStack,
  VStack,
  Text,
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  useMediaQuery,
  FormHelperText,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import SocialButton from "./socialAuth/SocialButton";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import Logo from "../img/logo.svg";
import fb from "../img/fb.svg";
import folme from "../img/folme.svg";
import fm from "../img/followMob.png";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../Styles/home.module.css";
import { ControlData, NewUser, addUserByFacebook } from "../server/api";
import SignFooter from "./SignFooter";
import { useDevice, useSpeech } from "../store/store";
import CookiesTag from "./static/CookiesTag";

export default function SignUp() {
  const devices = useBreakpointValue({base: true,lg:false})
  const { lang } = useSpeech();
  const toast = useToast();
  const { device, setDevice } = useDevice()
  const putButton = useBreakpointValue({ base: "358px", lg: "418px" });
  const [start, setStart] = useState(false);
  const position = useBreakpointValue<any>({
    base: "bottom",
    lg: "bottom-right",
  });
  const [checkUser, setCheckUser] = useState<boolean>(true);
  const Tpix = useBreakpointValue({ base: "22px", lg: "32px" });
  const fontS = useBreakpointValue({ base: "18px", lg: "28px" });
  const [user, setUser] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
  });
  const breakpoints = {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  };
  React.useEffect(() => {
    setDevice(devices ? "mobile" : "web")
    if (localStorage.getItem("f-token")) {
      window.location.assign("/home");
    }
  }, [devices, setDevice]);
  const zUser = z.object({
    name: z.string(),
    username: z.string(),
    email: z.string().email({ message: "Votre adresse mail est invalide" }),
    password: z.string(),
  });

  const observe = zUser.safeParse({
    name: user.name,
    username: user.username,
    email: user.email,
    password: user.password,
  });

  React.useMemo(async () => {
    let result = await ControlData(user.username).then((res) => {
      return res.data.success;
    });
    return setCheckUser(result);
  }, [user.username]);

  const submitAddUser = React.useCallback(() => {
    setStart(true);
    setTimeout(async () => {
      setStart(false);
      if (observe.success) {
        await NewUser(user.name, user.username, user.email, user.password,device).then(
          (res) => {
            if (res.data.success) {
              localStorage.setItem("email",res.data.data.email)
              localStorage.setItem("userId",res.data.data.user_id)
              localStorage.setItem("token",res.data.data.token)
              toast({
                description: `${res.data.message}`,
                status: "success",
                position: `${position}` as any,
                duration: 1000,
                isClosable: true,
              });
              setUser({
                name: "",
                username: "",
                email: "",
                password: "",
              });
            setTimeout(() => {
                window.location.href="/verify"
              }, 3000); 
            } else {
              toast({
                description: `${res.data.error}`,
                status: "error",
                position: `${position}` as any,
                duration: 5000,
                isClosable: true,
              });
            }
          }
        );
      }
    }, 3000);
  }, [device, observe.success, position, toast, user.email, user.name, user.password, user.username]);
  
  const handleFacebookLogin = async (user: any) => {
    const { data } = await addUserByFacebook(user._profile.id, user._profile.firstName, user._profile.lastName, user._profile.email, user._profile.profilePicURL)
    console.log(data);
    if (data.success) {
      localStorage.setItem("f-token", data.data.token);
      localStorage.setItem("profil", JSON.stringify(data.data));
      localStorage.setItem("userId", data.data.user_id);
      window.location.assign("/home");
    }
  };
  const handleSocialLoginFailure = (err: any) => {
    console.error(err);
  };
  return (
    <HStack
      w="full"
      bg="#f5f5f5"
      bgGradient={
        "linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"
      }
      h="100vh"
      position={"relative"}
      spacing={0}
    >
      <VStack
        w="50%"
        display={["none", "none", "flex", "flex", "flex"]}
        position={"relative"}
        h="100vh"
        bg="#005591"
        alignItems={"center"}
        justifyContent="center"
      >
        <Box cursor={"pointer"}>
          <img src={folme} />
        </Box>
      </VStack>

      <VStack
        w={["100%","100%","50%","50%"]}
        h={"100vh"}
        paddingBottom={8}
        overflowY={"auto"}
        bg="#f5f5f5"
        bgGradient={
          "linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"
        }
      >
        <CookiesTag />
        <Box
          display={["flex", "flex", "none", "none"]}
          px={10}
          py={4}
          alignItems={"center"}
          justifyContent="center"
          w="full"
        >
          <img src={Logo} height="400px" width="400px" />
        </Box>

        <VStack
          w="full"
          spacing={"60px"}
          py={4}
          textAlign="center"
          fontFamily={"Inter"}
        >
          <VStack spacing={0}>
            <Text
              fontWeight={"300"}
              fontFamily="Inter"
              fontSize={fontS}
              color="#005591"
            >
              {lang.titleI1}
            </Text>
            <Text
              fontWeight={"900"}
              fontFamily="Inter-extra"
              fontSize={fontS}
              color="#005591"
            >
              {lang.titleI2}
            </Text>
          </VStack>
          <Box>
            <Text
              fontWeight={"900"}
              color="#005591"
              fontSize={Tpix}
              fontStyle="normal"
              lineHeight="120%"
              fontFamily={"Inter-black"}
            >
              {lang?.SingupText}
            </Text>
          </Box>
        </VStack>

        <VStack
          w={"100%"}
          alignItems="center"
          justifyContent={"center"}
          h="full"
          px={8}
          spacing={2}
        >
          <VStack spacing={2}>
            <FormControl>
              <Input
                fontFamily={"Inter"}
                bg="#fff"
                w={["358px","358px","475px","475px"]}
                h="55px"
                className={styles.inputs}
                border="1px solid #E8E6EA"
                errorBorderColor="crimson"
                type="text"
                py={6}
                value={user.name || ""}
                onChange={(e) => {
                  setUser({ ...user, name: e.target.value });
                }}
                rounded="4px"
                placeholder={lang?.namePut}
              />
            </FormControl>
            <FormControl isInvalid={!checkUser}>
              <Input
                fontFamily={"Inter"}
                type="text"
                bg="#fff"
                w={["358px","358px","475px","475px"]}
                h="55px"
                className={styles.inputs}
                py={6}
                value={user.username || ""}
                onChange={(e) => {
                  setUser({ ...user, username: e.target.value });
                }}
                border="1px solid #E8E6EA"
                placeholder={lang?.nameUser}
              />
              {user.username.length > 3 && checkUser && (
                <FormHelperText color="green">pseudo validé !</FormHelperText>
              )}
              {!checkUser && (
                <FormErrorMessage>Ce nom existe déjà !</FormErrorMessage>
              )}
            </FormControl>
            <FormControl isInvalid={user.email.length > 8 && !observe.success}>
              <Input
                fontFamily={"Inter"}
                type="email"
                className={styles.inputs}
                bg="#fff"
                w={["358px","358px","475px","475px"]}
                h="55px"
                py={6}
                value={user.email || ""}
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value });
                }}
                border="1px solid #E8E6EA"
                placeholder={lang.adressPut}
              />
              {user.email.length > 5 && !observe.success && (
                <FormErrorMessage>Adresse mail invalide !</FormErrorMessage>
              )}
            </FormControl>
            <FormControl>
              <Input
                fontFamily={"Inter"}
                type="password"
                w={["358px","358px","475px","475px"]}
                className={styles.inputs}
                h="55px"
                py={6}
                value={user.password || ""}
                onChange={(e) => {
                  setUser({ ...user, password: e.target.value });
                }}
                bg="#fff"
                border="1px solid #E8E6EA"
                placeholder={lang?.passPut}
              />
              {user.password.length < 8 && (
                <FormHelperText
                  fontSize={"12px"}
                  fontFamily="Inter"
                  color="red.400"
                >
                  {lang?.numError}
                </FormHelperText>
              )}
            </FormControl>
          </VStack>
          <HStack py={4}>
            <Box w="154.37px" border="1px solid #E6E6E6" h="0px"></Box>
            <Text>Ou</Text>
            <Box w="154.37px" border="1px solid #E6E6E6" h="0px"></Box>
          </HStack>
          <VStack>
            <SocialButton
              provider="facebook"
              appId={"913868612815977"}
              onLoginSuccess={handleFacebookLogin}
              onLoginFailure={handleSocialLoginFailure}
              className="social-button"
              id="facebook-connect"
            >
              <Button
               w={["358px","358px","418px","418px"]}
                bg="#fff"
                fontFamily={"Inter"}
                py={6}
                h="54px"
                border="1px solid #005591"
              >
                <img src={fb} />
                <Text ml={4}>{lang?.fbSignup}</Text>
              </Button>
            </SocialButton>

            <Button
              colorScheme="blue"
              py={6}
              disabled={!observe.success}
              isLoading={start}
              bg="#005591"
              fontFamily={"Inter"}
              onClick={submitAddUser}
              w={putButton}
              h="54px"
              color="#ffffff"
            >
              {lang.signupBtn}
            </Button>
          </VStack>
        </VStack>
        <VStack w="full" py={4} fontSize="12px" spacing={0} textAlign="center">
          <Text fontFamily={"Inter"}>{lang?.terms1}</Text>
          <Link to="/privacy">
            <Text color="#005591" fontFamily={"Inter"}>
              {lang?.terms2}
            </Text>
          </Link>
        </VStack>
        <VStack w="full" py={8} spacing={2}>
          <Text fontFamily={"Inter"} fontSize="14px">
            {lang?.yesAccount}
          </Text>
          <Link to="/">
            <Text fontFamily={"Inter"} fontSize="14px" color="#005591">
              {lang?.LoginFollow}
            </Text>
          </Link>
        </VStack>
        <SignFooter />
      </VStack>
    </HStack>
  );
}
