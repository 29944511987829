/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  VStack,
  HStack,
  Box,
  Text,
  Input,
  useBreakpointValue,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React,{useState,useCallback} from "react";
import styles from "../../Styles/home.module.css";
import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Avatar,
} from "@chakra-ui/react";
import kdo from "../../img/kdo.svg";
import { TipsPayment } from "../../server/api";

export default function TipsButton({ id } :any) {
  const toast = useToast()
  const [bool, setBool] = useState<boolean>(false);
  const imgpix = useBreakpointValue({ base: "40px", lg: "50px" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tip, setTip] = useState({
      amount: "5",
      message:""
  })

const tipPayment = useCallback(
  () => {
    setBool(true)
    TipsPayment(tip?.amount,tip?.message,id,"").then((res)=>{
      if(res?.data?.success){
        setBool(false)
        console.log(res?.data);
        onClose()
        setTip({...tip,message:""})
        toast({
          title: "Cadeau envoyé.",
          description: `${res?.data?.message}`,
          status: "success",
          duration: 2000,
          isClosable: true,
        }); 
     /* setTimeout(() => {
          window.location.assign(`${res?.data?.url_redirect}`);
        }, 3000); */
      } 
      else{
        setBool(false)
        setTip({...tip,message:""})
        onClose()
        toast({
          title: "Echec d'envoi",
          description: `${res?.data?.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    })
  },
  [id, onClose, tip, toast],
)
  return (
    <VStack>
      <Button
        border="0.5px solid #eeeeee"
        h={imgpix}
        w={imgpix}
        p={2}
        onClick={onOpen}
        bg="#fff"
        rounded="full"
      >
        <img src={kdo} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded="15.23px" w="357px">
          <ModalHeader color="#344054" fontFamily={"Inter"} fontSize="15.23px">
            Envoyer un Tip
          </ModalHeader>
          <Divider />
          <ModalCloseButton border="2px solid #000" p={0} rounded="full" />
          <ModalBody>
            <VStack w='full' spacing={4}>
            <VStack w="full" p={1} rounded="6px" border="1px solid #E8E6EA">
              <FormControl>
                <FormLabel color="#A5A8AC" fontSize="8px" fontFamily={"Inter"}>
                  Montant de la pointe
                </FormLabel>
                <HStack w='full'>
                <Input px={0} value={tip.amount} onChange={(e)=>setTip({...tip,amount: e.target.value})} className={styles.inputs} defaultValue={5} />
                <Text>€</Text>
                </HStack>
              </FormControl>
            </VStack>
            <VStack w="full">
              <FormControl rounded="6px" border="1px solid #E8E6EA">
                <Textarea
                fontSize="12px"
                value={tip.message} onChange={(e)=>setTip({...tip,message: e.target.value})}
                px={2}
                maxLength={200}
                  fontFamily={"Inter"}
                  className={styles.inputs}
                  placeholder="Ecrire un message"
                />
              </FormControl>
              <HStack w="full" justifyContent={"flex-end"}>
                <Text fontSize="14px" fontFamily={"Inter"} color="#A5A8AC">
                  {tip.message.length}/200
                </Text>
              </HStack>
            </VStack>
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              w="100px"
              h="38px"
              fontSize="14px"
              fontFamily={"Inter"}
              colorScheme="red"
              bg="grey"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              w="149px"
              h="38px"
              colorScheme="green"
              fontSize="14px"
              fontFamily={"Inter"}
              onClick={tipPayment}
              bg="#34A853"
              isLoading={bool}
              color="#fff"
            >
              Envoyer Tip
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
