/* eslint-disable jsx-a11y/alt-text */
import {
  VStack,
  HStack,
  Box,
  Text,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowLeft } from "phosphor-react";
import React from "react";
import { Link } from "react-router-dom";

import group from "../../img/Group.svg";
import { useWallets, useCards } from "../../store/store";
import AddBankAccount from "../AddBankAccount";
import CashOutButton from "../../Components/button/CashOutButton";
import MyTransactions from "../MyTransactions";

export default function Wallets() {
  const { wallets } = useWallets();
  const { cards } = useCards();
  const width = useBreakpointValue({ base: "50%", lg: "30%" });
  const device = useBreakpointValue({ base: true, lg: false });
  const finder = cards?.find((index:any)=>index?.is_default===1)
  return (
    <VStack w="full" bg="#EAEDF6">
      <VStack
        w="full"
        alignItems={"center"}
        justifyContent="flex-end"
        minH={"184px"}
        bgGradient={
          "radial-gradient(119.82% 245.04% at 89.74% 14.61%, #0092FA 0%, #042347 100%)"
        }
      >
        {device && (
          <HStack
            px={4}
            justifyContent={"space-around"}
            color="#fff"
            w="full"
            h="70px"
          >
            <Link to="/home">
              <ArrowLeft size={24} />
            </Link>
            <Text fontFamily={"Inter-extra"} fontSize={"20px"}>
              Portefeuille
            </Text>
            <Text visibility={"hidden"}>emojis</Text>
          </HStack>
        )}
        <VStack
          backgroundBlendMode={"overlay"}
          alignItems="center"
          justifyContent={"space-around"}
          rounded="11px"
          bg="linear-gradient(210.18deg, #143351 -7.28%, rgba(20, 51, 81, 0) 55.52%)"
          minH="150px"
        >
          <HStack px={8} w="full" justifyContent={"flex-end"}>
            <Box bg="#fff" p={2}>
              <img src={group} />
            </Box>
          </HStack>
          <HStack fontFamily={"Inter-extra"} px={8} w="full" color="#fff">
            <Box>
              <Text>XXXX</Text>
            </Box>
            <Box>
              <Text>XXXX</Text>
            </Box>
            <Box>
              <Text>XXXX</Text>
            </Box>
            <Box>
              <Text>XXXX</Text>
            </Box>
          </HStack>
        </VStack>
      </VStack>
      <VStack w="full">
        <HStack w="full" fontFamily={"Inter"} p={8} justifyContent="center">
          <VStack w={width} py={4} spacing={0} rounded="md" bg="#DDE3F2">
            <Box>
              <Text fontSize={"14px"}>Solde Total</Text>
            </Box>
            <Box>
              <Text fontWeight={"800"}>
                {wallets?.user_wallet?.total_formatted}
              </Text>
            </Box>
          </VStack>
          <VStack py={4} w={width} spacing={0} rounded="md" bg="#DDE3F2">
            <Box>
              <Text fontSize={"14px"}>Solde Actuel</Text>
            </Box>
            <Box>
              <Text fontWeight={"800"}>
                {wallets?.user_wallet?.remaining_formatted}
              </Text>
            </Box>
          </VStack>
        </HStack>
        <CashOutButton />
        <Box py={4}>
          <AddBankAccount />
        </Box>
      </VStack>
      <VStack w="full" alignItems={"flex-start"}>
        <Box px={4}>
          <Text>Transactions</Text>
        </Box>
        <MyTransactions />
      </VStack>
    </VStack>
  );
}
