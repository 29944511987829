import React, { useCallback,useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  Heading,
  useDisclosure,
  Box,
  Text,
  Button,
  VStack,
} from "@chakra-ui/react";
import { freeFollow, unFollow } from "../../server/api";

export default function FreeSubscriptionButton({ payment, id, unique }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bool, setBool] = useState<boolean>(false);

  const follow = useCallback(() => {
    setBool(true);
    setTimeout(() => {
      freeFollow(unique, 1).then((res) => {
        window.location.reload();
      });
    }, 500);
  }, [unique]);

  const onpenMod = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const unfollow = useCallback(() => {
    setBool(true);
    setTimeout(() => {
      unFollow(id).then((res) => {
        window.location.reload();
      });
    }, 500);
  }, [id]);
  return (
    <VStack
      w="full"
      p={4}
      minH="119px"
      rounded="6px"
      bg="rgba(159, 201, 242, 0.25)"
    >
      <VStack w="full" alignItems={"flex-start"}>
        <Box>
          <Text fontWeight={"600"} fontSize="12px" color="#65676A">
            ABONNEMENT
          </Text>
        </Box>
        <Button
          isLoading={bool}
          onClick={() =>
            payment?.unsubscribe_btn_status === 0 ? follow() : onpenMod()
          }
          w="full"
          colorScheme="blue"
          display="flex"
          alignItems={"center"}
          bg="#005591"
          color="#fff"
          rounded="6px"
        >
          {payment?.unsubscribe_btn_status === 0 && (
            <Text fontWeight={"600"} color="#fff" fontSize={"12px"}>
              {payment?.payment_text}
            </Text>
          )}
          {payment?.unsubscribe_btn_status === 1 && (
            <Text fontWeight={"600"} color="#fff" fontSize={"12px"}>
              SE DESABONNER
            </Text>
          )}
        </Button>
      </VStack>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent m={2}>
          <ModalHeader fontFamily={"Inter-extra"} fontSize={"md"}>
            Se désabonner
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Inter"}>
              Êtes-vous sûr de vouloir annuler l'abonnement ?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} size="sm" variant="ghost" onClick={onClose}>
              annuler
            </Button>
            <Button colorScheme="blue"  isLoading={bool} onClick={() => unfollow()} size="sm">
              oui
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
