/* eslint-disable jsx-a11y/alt-text */
import {
  HStack,
  VStack,
  Box,
  Input,
  Button,
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  useBreakpointValue,
  useDisclosure,
  Avatar,
  Badge,
  useToast,
} from "@chakra-ui/react";
import {
  AWS_UPLOAD_BASE_URL,
  simpleFileUpload,
  generateFileName,
  getAwsUploadParams,
  MAX_FILE_SIZE,
  multipartUploadFile,
  S3,
  S3_BUCKET,
} from "../function/aws";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import camera from "../img/camera.svg";
import document from "../img/document.svg";
import trash from "../img/trash.svg";
import shop from "../img/shops.svg";
import React, { useRef, useState } from "react";
import AccountType from "./AccountType";
import { EditProfile, Logout } from "../server/api";
import ChangePassword from "./ChangePassword";
import { useSpeech, useUser } from "../store/store";
import { Link } from "react-router-dom";

export default function Settings() {
  const toast = useToast();
  const [bool, setBool] = useState<boolean>(false);
  const { setUser } = useUser();
  const profile = useUser().user;
  const [cover_files, setCover_files] = useState<any>("");
  const [profile_files, setProfile_files] = useState<any>("");
  const [info, setInfo] = useState({
    username: "",
    name: "",
    about: "",
    address: "",
    cover: "",
    picture: "",
    vip_url: "",
  });

  const [custom, setCustom] = useState<any>();
  const onCustom = (value: any) => {
    setCustom(value);
  };

  const fileUploader = async (input: any) => {
    await S3.putObject(
      {
        ACL: "public-read",
        Body: input[0],
        Bucket: S3_BUCKET as any,
        Key: `inputs/${input[0].name}`,
      },
      (err: any, data: any) => {
        if (err) {
          console.error(err);
        }

      }
    )
    return `${AWS_UPLOAD_BASE_URL}${input[0].name}`
  };

  const ref = useRef<any>();
  const refProfile = useRef<any>();
  const upload = (input: any) => {
    fileUploader(input).then((res) => {
      setInfo({ ...info, cover: res });
    })
    setCover_files(URL.createObjectURL(input[0]));
  };
  const uploadProfile = (input: any) => {
    fileUploader(input).then((res) => {
      setInfo({ ...info, picture: res });
    })
    setProfile_files(URL.createObjectURL(input[0]));
  };

  const editSubmit = React.useCallback(async () => {
    setBool(true);
    setTimeout(async () => {
      await EditProfile(
        info.username.length > 0 ? info.username : profile?.username,
        info.name.length > 0 ? info.name : profile?.name,
        info.picture,
        info.cover,
        info.address.length > 0 ? info.address : profile?.address,
        info.about.length > 0 ? info.about : profile?.about,
        custom?.monthly.length > 0 ? custom?.monthly : profile?.monthly_amount,
        custom?.triannual.length > 0
          ? custom?.triannual
          : profile?.triannual_amount,
        custom?.semiannual.length > 0
          ? custom?.semiannual
          : profile?.semiannual_amount,
        custom?.yearly.length > 0 ? custom?.yearly : profile?.yearly_amount,
        info.vip_url.length > 0 ? info.vip_url : profile?.vip_url || ""
      )
        .then((res) => {
          if (res.data?.success) {
            setUser(res?.data?.data);
            setBool(false);
            toast({
              title: "Changement réussi.",
              description: `${res.data.message}`,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          } else {
            setBool(false);
            toast({
              title: "Echec de changement",
              description: `${res.data.error}`,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          setBool(false);
          toast({
            title: "Fichiers trop volumineux",
            status: "error",
            position: "top",
            isClosable: true,
            duration: 3000,
          });
        });
    }, 500);
  }, [
    custom?.monthly,
    custom?.semiannual,
    custom?.triannual,
    custom?.yearly,
    info.about,
    info.address,
    info.cover,
    info.name,
    info.picture,
    info.username,
    info.vip_url,
    profile?.about,
    profile?.address,
    profile?.monthly_amount,
    profile?.name,
    profile?.semiannual_amount,
    profile?.triannual_amount,
    profile?.username,
    profile?.vip_url,
    profile?.yearly_amount,
    setUser,
    toast,
  ]);
  return (
    <VStack w="full" p={2} paddingBottom={32}>
      <VStack
        w="full"
        position={"relative"}
        backgroundImage={cover_files.length > 0 ? cover_files : profile?.cover}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        minH={"174px"}
        rounded="8px"
      >
        <VStack
          position={"absolute"}
          bg="linear-gradient(180deg, rgba(24, 25, 26, 0.6) 0%, rgba(0, 81, 129, 0.09) 121.92%)"
          w="full"
          rounded="8px"
          minH={"174px"}
          zIndex="10"
        >
          <HStack
            w="full"
            fontFamily={"Inter"}
            py={2}
            px={4}
            color="#fff"
            justifyContent={"flex-start"}
          >
            <Text fontSize={"14px"}>
              {profile?.total_posts} Publications -{" "}
            </Text>
            <Text fontSize={"14px"}>0 J’aime</Text>
          </HStack>
          <HStack w="full" justifyContent={"flex-end"} p={4}>
            <Button
              onClick={() => {
                ref?.current?.click() as any;
              }}
              bg="#D9D9D9"
              p={2}
              rounded="full"
            >
              <img src={camera} />
            </Button>
            <Input
              type="file"
              accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
              ref={ref as any}
              hidden
              onChange={(e) => {
                upload(e.target.files);
              }}
            />
          </HStack>

          <Avatar
            h="150px"
            position={"absolute"}
            src={profile_files.length > 0 ? profile_files : profile?.picture}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize={"cover"}
            w="150px"
            style={{ transform: "translate(0,100px)" }}
            rounded="full"
          >
            <Badge
              position={"absolute"}
              bottom="4"
              right={"0"}
              onClick={() => {
                refProfile?.current?.click() as any;
              }}
              cursor="pointer"
              bg="#D9D9D9"
              p={2}
              rounded="full"
            >
              <img src={camera} />
            </Badge>
            <Input
              type="file"
              accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
              ref={refProfile as any}
              hidden
              onChange={(e) => {
                uploadProfile(e.target.files);
              }}
            />
          </Avatar>
        </VStack>
      </VStack>
      <VStack
        w="full"
        alignItems={"center"}
        justifyContent="center"
        minH={"80px"}
      ></VStack>
      <VStack w="full" px={4} spacing={8} fontFamily={"Inter"}>
        <FormControl>
          <VStack
            w="full"
            spacing={0}
            alignItems={"flex-start"}
            rounded="8px"
            border="1px solid #E8E6EA"
          >
            <FormLabel px={4} fontSize={"10px"} color="#A5A8AC">
              Nom d’Utilisateur
            </FormLabel>
            <Input
              defaultValue={profile?.username}
              onChange={(e) => setInfo({ ...info, username: e.target.value })}
              variant={"ghost"}
            />
          </VStack>
          <FormHelperText fontSize={"10px"} color="#65676A">
            {profile?.share_link}
          </FormHelperText>
        </FormControl>
        <FormControl>
          <VStack
            w="full"
            spacing={0}
            alignItems={"flex-start"}
            rounded="8px"
            border="1px solid #E8E6EA"
          >
            <FormLabel px={4} fontSize={"10px"} color="#A5A8AC">
              Nom et Prénom
            </FormLabel>
            <Input
              defaultValue={profile?.name}
              onChange={(e) => setInfo({ ...info, name: e.target.value })}
              variant={"ghost"}
            />
          </VStack>
        </FormControl>
        <FormControl>
          <VStack
            w="full"
            spacing={0}
            alignItems={"flex-start"}
            rounded="8px"
            border="1px solid #E8E6EA"
          >
            <FormLabel px={4} fontSize={"14px"} color="#A5A8AC">
              A propos
            </FormLabel>
            <Input
              variant={"ghost"}
              defaultValue={profile?.about}
              onChange={(e) => setInfo({ ...info, about: e.target.value })}
            />
          </VStack>
        </FormControl>
        <FormControl>
          <VStack
            w="full"
            spacing={0}
            alignItems={"flex-start"}
            rounded="8px"
            border="1px solid #E8E6EA"
          >
            <FormLabel px={4} fontSize={"14px"} color="#A5A8AC">
              Emplacement
            </FormLabel>
            <Input
              variant={"ghost"}
              defaultValue={profile?.address}
              onChange={(e) => setInfo({ ...info, address: e.target.value })}
            />
          </VStack>
        </FormControl>
        {profile?.is_document_verified === 2 && (
          <FormControl>
            <VStack
              w="full"
              spacing={0}
              alignItems={"flex-start"}
              rounded="8px"
              border="1px solid #E8E6EA"
            >
              <FormLabel px={4} fontSize={"14px"} color="#A5A8AC">
                Lien de la page VIP
              </FormLabel>
              <Input
                defaultValue={profile?.vip_url}
                variant={"ghost"}
                onChange={(e) => setInfo({ ...info, vip_url: e.target.value })}
              />
            </VStack>
          </FormControl>
        )}
      </VStack>
      <AccountType callback={onCustom} />
      <FormControl px={2}>
        <Button
          w="full"
          isLoading={bool}
          onClick={() => editSubmit()}
          bg="#005591"
          color="#fff"
          fontFamily={"Inter"}
          colorScheme="blue"
        >
          VALIDER
        </Button>
      </FormControl>
      <VStack w="full" spacing={4} py={8} fontFamily={"Inter"} px={4}>
        <HStack
          w="full"
          cursor={"pointer"}
          onClick={() => {
            window.location.assign("/home/documents");
          }}
        >
          <img src={document} height="21px" width="21px" />
          <Text fontSize={"14px"} color="#344054">
            Documents
          </Text>
        </HStack>
        <HStack w="full">
          <img src={shop} height="21px" width="21px" />
          <Text fontSize={"14px"} color="#344054">
            Shop{" "}
            <span style={{ color: "#A5A8AC", fontSize: "12px" }}>
              (Bientôt disponible)
            </span>
          </Text>
        </HStack>
      </VStack>
      <VStack
        w="full"
        alignItems={"flex-start"}
        spacing={4}
        fontFamily={"Inter"}
        px={4}
      >
        <ChangePassword />
        <Link to="/home/remove-account">
          <HStack w="full">
            <img src={trash} height="21px" width="21px" />
            <Text fontSize={"14px"} color="#344054">
              Supprimer le compte
            </Text>
          </HStack>
        </Link>
      </VStack>
      <LogoutButton />
    </VStack>
  );
}

export const LogoutButton = () => {
  const log = useDisclosure();
  const { lang } = useSpeech()
  const logOut = React.useCallback(() => {
    Logout(
      localStorage?.getItem("userId"),
      localStorage?.getItem("f-token")
    ).then((res) => {
      if (res.data.success) {
        localStorage?.removeItem("userId");
        localStorage?.removeItem("f-token");
        window.location.assign("/");
      }
    });
  }, []);
  return (
    <HStack w="full" alignItems={"center"} justifyContent="center" py={8}>
      <Button
        w="358px"
        fontFamily={"Inter"}
        h="50px"
        fontSize={"14px"}
        fontWeight="500"
        onClick={log.onOpen}
        bg="#DDE3F2"
      >
        {lang.m_logout}
      </Button>
      <Modal isOpen={log.isOpen} onClose={log.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>{lang.logout_title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{lang.logout_msg}</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} variant="ghost" onClick={log.onClose}>
              {lang.no}
            </Button>
            <Button size="sm" onClick={logOut} colorScheme="blue">
              {lang.yes}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};
