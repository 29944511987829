/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
  VStack,
  Button,
  useBreakpointValue,
  HStack,
  Box,
  Avatar,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { BookmarkSimple, CaretRight } from "phosphor-react";
import { useActiveFollowing, useFollower, useUser } from "../../store/store";
import starfav from "../../img/starFav.svg";
import stars from "../../img/stars.svg";
import upl from "../../img/upl.svg";
import kdo from "../../img/kdo.svg";
import { useNavigate } from "react-router";
import { favSaveOption, getFollowers } from "../../server/api";

export default function All() {
  const place = useBreakpointValue({ base: "1", lg: "2" });
  const { follower,setFollower } = useFollower()
  const toast = useToast()
  const navigate = useNavigate()

  const favOption =   React.useCallback(
    async(id:any) => {
      const favResponse = await favSaveOption(id)
      const newdata = await getFollowers()
      setFollower(newdata?.data?.data ?? [])
      toast({
        position: "bottom",
        render: () => (
          <HStack color="white" rounded="lg" p={3} bg="green">
            <BookmarkSimple />
            <Text fontFamily={"Inter"}>{favResponse?.data?.message}</Text>
          </HStack>
        ),
      });
    },
    [setFollower, toast],
  )
  return (
    <VStack w="full" py={4}>
      <Grid w="full" templateColumns={`repeat(${place}, 1fr)`} gap={2}>
        {follower?.followers?.map((data: any, i: any) => {
          return (
            <GridItem
              key={i}
            
              w="100%"
              rounded="5px"
              minH="159.4px"
              position="relative"
              p={2}
              bg="rgba(159, 201, 242, 0.25)"
            >
              <VStack
                cursor={"pointer"}
                w="full"
                fontFamily={"Inter"}
                rounded="5px"
                h="60px"
                backgroundImage={data?.otherUser?.cover}
                backgroundPosition="center"
                backgroundSize={"cover"}
                backgroundRepeat="no-repeat"
              >
                <HStack
                  w="full"
                  px={4}
                  py={1}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                ></HStack>
                <HStack w="full" justifyContent={"flex-end"}>
                  <Box>
                    <CaretRight size={24} color="#fff" />
                  </Box>
                </HStack>
                <HStack
                  w="full"
                  position={"absolute"}
                  py={4}
                  zIndex="2"
                  px={4}
                  justifyContent={"flex-start"}
                >
                  <Avatar
                    w="64px"
                    h="64px"
                    src={data?.otherUser?.picture}
                    rounded="full"
                  />
                </HStack>
              </VStack>
              <HStack w="full">
                <Box ml={6} visibility="hidden">
                  <Text color="#000" fontFamily={"Inter"} fontSize="14px">
                    Boruto UZUMAKI
                  </Text>
                </Box>
                <VStack
                  w="full"
                  fontFamily={"Inter"}
                  onClick={()=>navigate(`/home/profil/${data?.otherUser?.user_unique_id}`)}
                  spacing={0}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-around"}
                >
                  <Box>
                    <Text color="#000" fontFamily={"Inter"} fontSize="14px">
                      {data?.otherUser?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text color="#65676A" fontFamily={"Inter"} fontSize="10px">
                      @{data?.otherUser?.username}
                    </Text>
                  </Box>
                </VStack>
                <Box mr={6} rounded="full" p={1} border="0.488944px solid #ddd">
                  <img src={upl} />
                </Box>
              </HStack>
              <HStack px={2} w="full" justifyContent={"space-between"}>
                <HStack
                  border="0.488944px solid #005591"
                  p={2}
                  alignItems="center"
                  justifyContent="space-between"
                  bg="#C6DEF7"
                  rounded="full"
                >
                  <Box
                    mr={6}
                    rounded="full"
                    h="24px"
                    p={1}
                    w="24px"
                    border="0.488944px solid #f5f5f5"
                  >
                    <img src={kdo} />
                  </Box>
                  <Text fontSize={"9.77px"} color="#005591" fontFamily="Inter">
                    Tips
                  </Text>
                  <Box
                    visibility={"hidden"}
                    mr={6}
                    rounded="full"
                    h="19px"
                    p={1}
                    w="19px"
                    border="0.488944px solid #ddd"
                  >
                    <img src={upl} />
                  </Box>
                </HStack>
                <Button onClick={()=>favOption(data?.u_id)} p={0} bg="rgba(159, 201, 242, 0.5)">
                {data?.otherUser?.is_fav_user===1 ? <img src={starfav} /> : <img src={stars} />}
                </Button>
              </HStack>
            </GridItem>
          );
        })}
      </Grid>
    </VStack>
  );
}
