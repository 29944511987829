import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import MyCards from "../MyCards";
import MyTransactions from "../MyTransactions";

export default function Cards() {
  return (
    <VStack w="full">
      <Tabs w='full' p={0} variant="unstyled">
        <TabList p={2} bg="#fff">
          <Tab w='full' boxShadow={"0px 2px 6px rgba(0, 0, 0, 0.15)"} mr={2} h='47px' bg="#fff" rounded="lg" fontFamily={"Inter"} _selected={{ color: "#fff", fontWeight:"700", bg: "#005591" }}>mes cartes</Tab>
          <Tab w='full'  boxShadow={"0px 2px 6px rgba(0, 0, 0, 0.15)"} h='47px' bg="#fff" rounded="lg" fontFamily={"Inter"} _selected={{ color: "#fff",fontWeight:"700", bg: "#005591" }}>mes transactions</Tab>
        </TabList>
        <TabPanels w='full' bg="#DDE3F2" style={{padding:0}}>
          <TabPanel w='full' bg="#DDE3F2">
            <MyCards />
          </TabPanel>
          <TabPanel>
            <MyTransactions />
          </TabPanel>
        </TabPanels>
      </Tabs>
  
    </VStack>
  );
}
