/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/alt-text */
import {
  Avatar,
  HStack,
  Box,
  Text,
  Button,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import msg from "../../img/chat.svg";
import up from "../../img/upl.svg";
import map from "../../img/map.svg";
import shop from "../../img/shop.svg";
import kdo from "../../img/kdo.svg";
import React, { useState, useRef } from "react";
import { ArrowLeft, BookmarkSimple, Star } from "phosphor-react";
import FreeSubscriptionButton from "../../Components/button/FreeSubscriptionButton";
import MediaTabs from "../MediaTabs";
import CashSubscriptionButton from "../../Components/button/CashSubscriptionButton";
import { Navigate, Outlet, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import SingleMediaTabs from "../SingleMediaTabs";

import CopyToClipboard from "react-copy-to-clipboard";
import {
  favSaveOption,
  getOtherProfile,
  getOtherProfilePost,
} from "../../server/api";
import TipsButton from "../button/TipsButton";
import ProfilBookmarks from "../mentions/ProfilBookmarks";

export default function SingleProfil() {
  const margpic = useBreakpointValue({ base: "0", lg: "8px" });
  const coderef = useRef<any>();
  const padx = useBreakpointValue({ base: "0", lg: "4" });
  const [user, setUser] = useState<any>();
  const toast = useToast();
  const [total, setTotal] = useState<any>();
  const [stat, setStat] = useState<any>();
  const [needSub, setNeedSub] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const [post, setPost] = useState();
  const imgpix = useBreakpointValue({ base: "40px", lg: "50px" });
  var d = new Date();
  const navigate = useNavigate();
  let dateLocale = d.toLocaleString("fr-FR", {
    month: "numeric",
    day: "numeric",
  });
  const { id } = useParams();
  /*  const profile = useSuggestion()?.sugData?.users
    const user = profile?.find((index: any) => index.user_unique_id === id)
    */
  React.useEffect(() => {
    getOtherProfile(id).then((res) => {
      setUser(res?.data?.data);
      setStat(res?.data?.data);
      setPaymentInfo(res?.data?.data?.payment_info);
    });
    getOtherProfilePost(id, 0).then((res) => {
      setPost(res?.data?.data);
      setTotal(res?.data?.data?.total);
    });
  }, [id]);

  const copy = React.useCallback(() => {
    toast({
      title: "Lien copié dans le press-papier",
      duration: 2000,
      position: "top",
      isClosable: true,
    });
  }, [toast]);

  const needSubscribe = React.useCallback(() => {
    setNeedSub(true);
  }, []);


  return (
    <VStack w="full">
      <VStack
        w="full"
        position="relative"
        backgroundImage={user?.user?.cover}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        minH={"200px"}
      >
        <HStack
          w="full"
          h="50%"
          position={"absolute"}
          alignItems="flex-start"

          zIndex="99"
          p={4}
          justifyContent="space-between"
        >
          <HStack alignItems={"flex-start"}>
            <VStack alignItems={"flex-start"} fontFamily="Inter" color="#fff">
              <Text fontWeight={"500"}>{user?.user?.name}</Text>
              <HStack>
                <Text fontSize={"10px"}>
                  {user?.user?.total_posts}{" "}
                  {user?.user?.total_posts > 1 ? "Publications" : "Publication"}
                </Text>
                <Text fontSize={"10px"}> - 0 J’aime</Text>
              </HStack>
            </VStack>
          </HStack>
          <ProfilBookmarks user={user} />
        </HStack>
        <HStack
          px={2}
          position="absolute"
          zIndex={"10"}
          py={"140px"}
          w="full"
          justifyContent={"flex-start"}
        >
          <HStack>
            <Avatar
              h="100px"
              w="100px"
              src={user?.user?.picture}
              mx={margpic}
              rounded="full"
            />

            <Button
              h={"80px"}
              onClick={() =>
                navigate(`/home/market/${user?.user?.user_unique_id}`)
              }
              w="80px"
              bgImage={shop}
              colorScheme="transparent"
              rounded="full"
            ></Button>
          </HStack>
        </HStack>
      </VStack>
      <HStack px={padx} zIndex={"12"} w="full" justifyContent={"flex-end"}>
        <HStack>
          <Button
            border="0.5px solid #eeeeee"
            bg="#fff"
            rounded="full"
            h={imgpix}
            onClick={() =>
              paymentInfo?.unsubscribe_btn_status === 1
                ? navigate(`/chat/${user?.user?.user_id}`)
                : needSubscribe()
            }
            w={imgpix}
            p={2}
          >
            <img src={msg} />
          </Button>
          <TipsButton id={user?.user?.user_id as any} />
          <CopyToClipboard text={coderef?.current?.textContent} onCopy={copy}>
            <Button
              border="0.5px solid #eeeeee"
              bg="#fff"
              rounded="full"
              h={imgpix}
              w={imgpix}
              p={2}
            >
              <Text ref={coderef} hidden>
                {user?.user?.share_link}
              </Text>
              <img src={up} />
            </Button>
          </CopyToClipboard>
        </HStack>
      </HStack>
      <VStack w="full" px={4} fontFamily="Inter" alignItems={"flex-start"}>
        <VStack w="full" alignItems={"flex-start"} spacing={0}>
          <Text fontWeight={"700"} fontSize={["18px", "18px", "28px", "28px"]}>
            {user?.user?.name}
          </Text>
          <HStack
            w="full"
            p={0}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text color="#65676A" fontSize={"14px"}>
              @{user?.user?.username}
            </Text>
            <Box rounded="full" bg="#65676A"></Box>
            <HStack w="full">
              <Text color="#65676A" fontSize={"14px"}>
                {user?.user.updated_formatted}
              </Text>
            </HStack>

            <HStack
              display={["flex", "flex", "none", "none"]}
              w="full"
              justifyContent={"flex-start"}
              py={4}
            >
              {user?.user?.address && (
                <HStack>
                  <Box>
                    <img src={map} width="12px" height={"15.75px"} />
                  </Box>
                  <Text fontSize={"14px"} color="#47B2CF">
                    {user?.user?.address}
                  </Text>
                </HStack>
              )}
            </HStack>
          </HStack>
        </VStack>

        <Box py={4} display={["none", "none", "flex", "flex"]}>
          {user?.user?.about !=="null" || null &&
            <Box py={4}>
              <Text
                fontSize={"14px"}
                dangerouslySetInnerHTML={{
                  __html: `${user?.user?.about}`,
                }}
              ></Text>
            </Box>
          }
        </Box>

        <VStack
          display={["flex", "flex", "none", "none"]}
          alignItems={"flex-start"}
        >
          {user?.user?.about !=="null" || null && (
            <Box py={4}>
              <Text
                fontSize={"14px"}
                dangerouslySetInnerHTML={{
                  __html: `${user?.user?.about}`,
                }}
              ></Text>
            </Box>
          )}
        </VStack>
        {needSub && (
          <HStack w="full" bg="red.100" p={2} rounded="4px">
            <Text fontSize="sm">Abonnez-vous avant d'envoyer un message</Text>
          </HStack>
        )}

        <HStack
          w="full"
          display={["none", "none", "flex", "flex"]}
          justifyContent={"flex-start"}
          py={4}
        >
          {user?.user?.address && (
            <HStack>
              <Box>
                <img src={map} />
              </Box>
              <Text color="#47B2CF">{user?.user?.address}</Text>
            </HStack>
          )}
        </HStack>

        {paymentInfo?.is_free_account === 1 && (
          <FreeSubscriptionButton
            id={user?.user?.user_id as any}
            payment={paymentInfo as any}
            unique={user?.user?.user_unique_id as any}
          />
        )}

        {paymentInfo?.is_free_account === 0 &&
          paymentInfo?.is_user_needs_pay === 1 && (
            <CashSubscriptionButton
              profile={user}
              payment={user?.payment_info as any}
            />
          )}
      </VStack>
      <SingleMediaTabs
        post={post as any}
        total={total as any}
        stat={stat as any}
      />
    </VStack>
  );
}
