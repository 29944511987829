/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Box,
  HStack,
  Text,
  Avatar,
  useBreakpointValue,
  FormControl,
  FormHelperText,
  Button,
  VStack,
  Input,
  Divider,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import HashLoader from "react-spinners/HashLoader";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import more from "../../img/more.svg";
import send from "../../img/send.svg";
import gallery from "../../img/gallery.svg";
import galery from "../../img/galery.svg";
import video from "../../img/video.svg";
import arrowleft from "../../img/arrowleft.svg";
import { Gift, TrashSimple } from "phosphor-react";
import { useNavigate, useParams } from "react-router";
import { useBoxId, useChatUserList, useInbox, useLoader } from "../../store/store";
import {
  blockUserTchat,
  deleteUserTchat,
  getChatMessages,
  sendMessage,
} from "../../server/api";
import { IMessage } from "../../interface/types";
import useEchoListener from "../../hooks/useEchoListener";
import {
  AWS_UPLOAD_BASE_URL,
  MAX_FILE_SIZE,
  generateFileName,
  multipartUploadFile,
  simpleFileUpload,
} from "../../function/aws";
import OtherImageBoxAssets from "../messenger/OtherImageBoxAssets";
import TipsBoxXender from "../messenger/TipsBoxXender";
import MyBoxImage from "../messenger/MyBoxImage";

export default function SingleMessage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast()
  const { setBoxId } = useBoxId();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const boxOption = useDisclosure();
  const device = useBreakpointValue({ base: true, lg: false });
  const [boxName, setBoxName] = useState("");
  const [boxParam, setBoxParam] = useState("");
  const msgW = useBreakpointValue({ base: "199px", lg: "263px" });
  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const { loader } = useLoader()
  const [text, setText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState([]);
  const [videosFiles, setVideosFiles] = useState([]);
  const position = useBreakpointValue<any>({
    base: "bottom",
    lg: "bottom-right",
  });
  const {
    setIsLoading,
    setTotal,
    setActiveTchat,
    activeTchat,
    messages,
    setMessages,
  } = useInbox();

  const { chatList } = useChatUserList();

  const inboxScrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (inboxScrollRef.current) {
      //alert("scroll");
      inboxScrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    inboxScrollRef.current?.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    setBoxId(id);
  }, [id, scrollToBottom, setBoxId]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        setIsLoading(true);
        const { data } = await getChatMessages({
          to_user_id: id,
          from_user_id: localStorage.getItem("userId"),
        });
        if (data.success) {
          //console.log("DATA", data.data);
          setMessages(data.data?.messages);
          // console.log(data.data?.messages);
          setTotal(data.data?.total);
          setIsLoading(false);
          /* setTimeout(() => {
            scrollToBottom();
          }, 500); */
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchMessages();
    }
  }, [id, setIsLoading, setMessages, setTotal, scrollToBottom]);

  useEffect(() => {
    if (id) {
      const threads = chatList?.users;
      const thread = threads?.find((u: any) => u.to_user_id === parseInt(id));
      setActiveTchat(thread);
    }
  }, [id, chatList, setActiveTchat]);

  //handle message received
  const handleAddMessage = useCallback(
    (newMessage: IMessage) => {
      console.log(newMessage)
      if (
        newMessage.to_user_id == activeTchat?.to_user_id ||
        newMessage.from_user_id == activeTchat?.to_user_id
      ) {
        let tab = [...messages, newMessage];
        setMessages(tab);

        //scrollToBottom();
      }
    },
    [activeTchat?.to_user_id, messages, setMessages]
  );

  useEchoListener(
    "user." + localStorage.getItem("userId"),
    "MessageChatEvent",
    handleAddMessage
  );

  const newTrade = useCallback((e: any) => {
    console.log(e);
  }, []);

  useEchoListener("test-channel", "NewTrade", newTrade);

  const valid =
    (text.length > 0 || files.length > 0 || videosFiles.length > 0) &&
    !activeTchat.to_user.pay_before_send_message;

  const handleSubmit = async () => {
    try {
      const { user_picture, to_user_id, from_user, from_user_id } = activeTchat;
      const chatData = {
        from_user_id,
        to_user_id,
        message: text,
        type: "uu",
        user_picture,
        loggedin_user_id: localStorage.getItem("userId"),
        created: "1 second ago",
        from_username: from_user.username,
        from_displayname: from_user.name,
        from_userpicture: user_picture,
        from_user_unique_id: "",
        to_username: "",
        to_displayname: "",
        to_userpicture: "",
        to_user_unique_id: "",
        amount: "", //paid ? amount : "",
        to_others: false, //toOthers,
      };

      if (valid) {
        setIsSubmitting(true);
        const { data } = await sendMessage(chatData);
        if (data.success) {
          setIsSubmitting(false);
          setText("");
        }
        else {
          toast({
            description: `${data.error}`,
            status: "error",
            position: `${position}` as any,
            duration: 5000,
            isClosable: true,
          });
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleBoxOption = useCallback(
    (name: string, option: string) => {
      boxOption.onOpen();
      setBoxName(name);
      setBoxParam(option);
    },
    [boxOption]
  );

  const isActiveTchatBlocked = () => {
    if (activeTchat) {
      return (
        chatList?.users_block?.find(
          (u: any) => u.block_user_id === activeTchat.to_user_id
        ) ||
        chatList?.users_blocked?.find(
          (u: any) => u.blocked_to === activeTchat.to_user_id
        )
      );
    } else {
      return false;
    }
  };

  const onMediaModalClose = () => {
    onClose();
    if (isVideoSelected) {
      setIsVideoSelected(false);
    }
  };

  return (
    activeTchat && (
      <VStack w="full" h="full" bg="#fff" spacing={0}>
        <HStack
          position={["fixed", "fixed", "relative", "relative"]}
          zIndex={10}
          bg="#fff"
          borderBottom="1px solid #F1F2F6"
          w="full"
          justifyContent={"space-between"}
          py={2}
          px={4}
        >
          <HStack spacing={4}>
            {device && (
              <Box
                onClick={() => {
                  setActiveTchat(undefined);
                  navigate("/chat");
                }}
              >
                <img src={arrowleft} />
              </Box>
            )}
            <HStack
              cursor={"pointer"}
              onClick={() =>
                navigate(`/home/profil/${activeTchat?.to_username}`)
              }
            >
              <Avatar
                name={activeTchat.to_displayname}
                src={activeTchat.to_userpicture}
              />
              <VStack textAlign={"left"} spacing={1} alignItems={"flex-start"}>
                <Box>
                  <Text
                    fontFamily={"Inter"}
                    textTransform={"uppercase"}
                    px={2}
                    fontSize="14px"
                    fontWeight={"900"}
                    color="#005591"
                  >
                    {activeTchat.to_displayname}
                  </Text>
                </Box>
                <Box>
                  <Text
                    fontFamily={"Inter"}
                    textTransform={"uppercase"}
                    px={2}
                    fontSize="8px"
                    fontWeight={"900"}
                    color="#000"
                  >
                    {activeTchat.time_formatted}
                  </Text>
                </Box>
              </VStack>
            </HStack>
          </HStack>
          <Menu>
            <MenuButton as={Button} bg="#fff">
              <img src={more} />
            </MenuButton>
            <MenuList>
              <MenuItem
                fontFamily={"Inter"}
                onClick={() =>
                  handleBoxOption(activeTchat.to_username, "bloqued")
                }
                bg="#fff"
              >
                {isActiveTchatBlocked() ? "Débloquer" : "Bloquer"}
              </MenuItem>
              <MenuItem
                fontFamily={"Inter"}
                onClick={() =>
                  handleBoxOption(activeTchat.to_username, "deleted")
                }
                color="red"
              >
                Effacer
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
        {loader ? (
          <VStack w="full" h="full" alignItems={"center"} justifyContent="center">
            <HashLoader color="#005591" size={30} />
          </VStack>
        ) : (<VStack
          w="full"
          h="full"
          overflowY={"auto"}
          paddingTop={["36", "36", "8", "8"]}
        >
          {messages?.length > 0
            ? messages?.map((message: IMessage, i: number) => {
              const isOwner =
                localStorage.getItem("userId") ===
                message.from_user_id?.toString();
              return (
                <HStack
                  key={i}
                  w="full"
                  px={4}
                  alignItems="flex-end"
                  justifyContent={isOwner ? "flex-end" : "flex-start"}
                >
                  {!isOwner && (
                    <Avatar
                      h="40px"
                      w="40px"
                      name={activeTchat.to_userpicture}
                      src={activeTchat.to_userpicture}
                    />
                  )}
                  <VStack spacing={0}>
                    <HStack
                      w={msgW}
                      bg={isOwner ? "#005591" : "#F7F7F7"}
                      color={isOwner ? "#fff" : "#1B1A57"}
                      justifyContent={"space-between"}
                      p={2}
                      borderRadius={isOwner ? "8px 8px 0px 8px" : "8px"}
                    >
                      <Text fontSize={"14px"} fontFamily="Inter">
                        {message.message}
                        <HStack>
                          {message?.is_tips_message && isOwner && <Text fontFamily="Inter" fontSize="10px">vous avez envoyé un cadeau</Text>}
                          {message?.is_tips_message && !isOwner && <Text fontFamily="Inter" color="#005591" fontSize="10px">vous avez reçu un cadeau</Text>}
                          {message?.is_tips_message && <Gift color={isOwner ? "yellow" : "#1B1A57" } />}
                        </HStack>
                        {message.chat_assets.length > 0 && isOwner && (
                          <MyBoxImage imageData={message.chat_assets} />
                        )}
                        {message.chat_assets.length > 0 && !isOwner && (
                          <OtherImageBoxAssets imageData={message.chat_assets} />
                        )}
                      </Text>
                    </HStack>

                    <HStack
                      justifyContent={isOwner ? "flex-end" : "flex-start"}
                      w={msgW}
                    >
                      <Text color="#A1A1BC" fontSize="12px">
                        {message?.created}
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
              );
            })
            : null}
          <div ref={inboxScrollRef} />
        </VStack>)}

        {!isActiveTchatBlocked() && (
          <HStack
            w="full"
            position={["fixed", "fixed", "relative", "relative", "relative"]}
            zIndex="10"
            px={["2", "2", "0", "0", "0"]}
            bottom={"0px"}
            bg="#fff"
            borderTop={"1px solid #eee"}
            borderBottom="1px solid #eee"
          >
            <HStack w="full" py={4}>
              <HStack px={2} spacing={2}>
                <Box cursor={"pointer"} onClick={onOpen} p={0}>
                  <img src={gallery} />
                </Box>
                <Box
                  cursor={"pointer"}
                  p={0}
                  onClick={() => {
                    setIsVideoSelected(true);
                    onOpen();
                  }}
                >
                  <img src={video} />
                </Box>
                <TipsBoxXender
                  profile={activeTchat}
                  userId={activeTchat.to_user.user_id}
                  fromInbox={true}
                />
              </HStack>
              <HStack spacing={1} w="full">
                <Input
                  bg="#F2F2F2"
                  w="full"
                  placeholder="Ecrire un nouveau message"
                  h="32px"
                  rounded="47px"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  disabled={isSubmitting}
                />
                {!isSubmitting ? (
                  <Box onClick={handleSubmit} cursor={"pointer"} p={0}>
                    <img src={send} />
                  </Box>
                ) : (
                  <Spinner size={"sm"} />
                )}
              </HStack>
            </HStack>
          </HStack>
        )}
        <TchatUserOption
          isOpen={boxOption.isOpen}
          onClose={boxOption.onClose}
          name={boxName}
          option={boxParam}
          isBlocked={isActiveTchatBlocked()}
        />
        <TchatMediaModal
          isOpen={isOpen}
          onClose={onMediaModalClose}
          text={text}
          setText={setText}
          isVideo={isVideoSelected}
        />
      </VStack>
    )
  );
}

export const TchatMediaModal = ({
  isOpen,
  onClose,
  text,
  setText,
  isVideo,
}: {
  isOpen: boolean;
  onClose: () => void;
  text: string;
  setText: (text: string) => void;
  isVideo: boolean;
}) => {
  const modW = useBreakpointValue({ base: "357px", lg: "537px" });
  const modH = useBreakpointValue({ base: "377px", lg: "555px" });

  const [amount, setAmount] = useState(0);
  const [paid, setPaid] = useState(false);
  const [toOthers, setToOthers] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [videos, setVideos] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [submitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [filesUrl, setFilesUrl] = useState("");
  const [filesView, setFilesView] = useState(false);

  const { activeTchat } = useInbox();
  //console.log("videoInputRef", videoInputRef);
  useEffect(() => {
    if (isOpen && isVideo) {
      setTimeout(() => {
        if (videoInputRef?.current) {
          selectVideoFile();
        }
      }, 0);
    }
  }, [isOpen, isVideo, videoInputRef]);

  const selectImageFile = () => {
    const fileInput: HTMLInputElement = imageInputRef.current as any; //as React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    fileInput?.click();
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const url = reader.result as string;
          setPreviews((p) => [...p, url]);
        };
        if (file) {
          reader.readAsDataURL(file);
          setFiles((f) => [...f, file]);
        }
      });
    }
  };

  const deleteImage = (index: number) => {
    const _files = files.filter((_, i) => i !== index);
    setFiles(_files);
    const _previews = previews.filter((_, i) => i !== index);
    setPreviews(_previews);
  };

  const selectVideoFile = () => {
    const fileInput: HTMLInputElement = videoInputRef.current as any; //as React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    fileInput?.click();
  };

  const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setFilesView(true);
      const videoFile = files[0];
      setFilesUrl(URL.createObjectURL(files[0]));
      setVideos([videoFile]);
    }
  };

  const valid =
    (text.length > 0 || files.length > 0 || videos.length > 0) &&
    !activeTchat.to_user?.pay_before_send_message;

  const handleMessageSend = async (chatData: any) => {
    try {
      const { data } = await sendMessage(chatData);
      if (data.success) {
        setIsSubmitting(false);
        //reset states
        setText("");
        onClose();
        setVideos([]);
        setFiles([]);
        setPreviews([]);
        setProgress(0);
      } else {
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const { user_picture, to_user_id, from_user, from_user_id } = activeTchat;
      const chatData = {
        from_user_id,
        to_user_id,
        message: text,
        type: "uu",
        user_picture,
        loggedin_user_id: localStorage.getItem("userId"),
        created: "1 second ago",
        from_username: from_user.username,
        from_displayname: from_user.name,
        from_userpicture: user_picture,
        from_user_unique_id: "",
        to_username: "",
        to_displayname: "",
        to_userpicture: "",
        to_user_unique_id: "",
        amount: paid ? amount : "",
        to_others: toOthers,
      } as any;

      if (valid) {
        setIsSubmitting(true);
        //console.log("files", files);
        if (files.length > 0) {
          files.forEach((file, i) => {
            const key = `chat_img_${i}`;
            chatData[key] = file;
          });
          chatData.files = files;
          chatData.file_type = "image";
        }
        //it no video selected
        if (videos.length === 0) {
          await handleMessageSend(chatData);
        } else {
          //upload selected video and send message
          const videoFile = videos[0];
          const fileName = generateFileName(videoFile.name);
          if (videoFile.size < MAX_FILE_SIZE) {
            const upload = simpleFileUpload(videoFile, fileName);
            upload
              .on("httpUploadProgress", (evt) => {
                const progress = Math.round((evt.loaded / evt.total) * 100);
                setProgress(progress);
              })
              .send(async (err, data) => {
                if (err) {
                  console.log(err);
                }
                if (data) {
                  //console.log("data", data);
                  const uploadUrl = `${AWS_UPLOAD_BASE_URL}${fileName}`;
                  console.log("uploadUrl", uploadUrl);
                  chatData.videos = fileName;
                  await handleMessageSend(chatData);
                  //return uploadUrl;
                }
              });
          } else {
            //multipart upload for large video file
            await multipartUploadFile(videoFile, fileName, setProgress);
            const uploadUrl = `${AWS_UPLOAD_BASE_URL}${fileName}`;
            console.log("Mutlipart uploadUrl", uploadUrl);
            chatData.videos = fileName;
            await handleMessageSend(chatData);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        boxShadow="0px -7.61702px 30.4681px rgba(70, 96, 135, 0.1)"
        w={modW}
        minH={modH}
        rounded="15.23px"
      >
        <ModalHeader fontFamily={"Inter"} fontSize={"md"}>
          Ajouter média
        </ModalHeader>
        <ModalCloseButton p={0} rounded="full" border="1px solid  #000" />
        <Divider />
        <ModalBody>
          <HStack w="full">
            <input
              type="file"
              accept="video/*"
              onChange={handleVideoChange}
              ref={videoInputRef}
              hidden
            />
            <Box
              display="flex"
              flexWrap="wrap"
              rounded="5.72px"
              alignItems={"center"}
              justifyContent="center"
              h="128px"
              bg="rgba(232, 230, 234, 0.5)"
              w="128px"
              cursor={"pointer"}
              onClick={selectImageFile}
            >
              <img src={galery} />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={imageInputRef}
                hidden
                multiple
              />
            </Box>
            {previews.map((p, i) => (
              <Box key={i}>
                <Box rounded="5.72px" overflow={"hidden"} w="128" h="128">
                  <img
                    src={p}
                    style={{
                      width: 128,
                      height: 128,
                      objectFit: "cover",
                    }}
                  />
                  <TrashSimple
                    onClick={() => {
                      deleteImage(i);
                    }}
                    style={{ color: "red", cursor: "pointer" }}
                    size={32}
                  />
                </Box>
              </Box>
            ))}
            {filesView && (
              <VStack
                h="128px"
                bg="rgba(232, 230, 234, 0.5)"
                w="128px"
                alignItems={"center"}
                justifyContent="center"
              >
                <VStack>
                  {progress === 100 && (
                    <video
                      src={filesUrl}
                      style={{ height: "50px", width: "50px" }}
                    />
                  )}
                  <Text fontWeight={"bold"} fontSize="8px">
                    {videos[0]?.name}
                  </Text>
                </VStack>
                {progress === 100 ? (
                  <Text fontWeight={"bold"} color="green">
                    Terminé
                  </Text>
                ) : (
                  <CircularProgress
                    size="64px"
                    value={progress}
                    color="#005591"
                  >
                    <CircularProgressLabel>{progress}%</CircularProgressLabel>
                  </CircularProgress>
                )}
              </VStack>
            )}
          </HStack>
          <HStack py={4}>
            <Checkbox
              w="full"
              checked={paid}
              onChange={(e) => {
                setPaid(e.target.checked);
              }}
            >
              <Text fontFamily={"Inter"} fontSize="12px">
                Media payant
              </Text>
            </Checkbox>
            <Checkbox
              w="full"
              checked={toOthers}
              onChange={(e) => {
                setToOthers(e.target.checked);
              }}
            >
              <Text fontFamily={"Inter"} fontSize="12px">
                Envoyer à tous mes abonnés
              </Text>
            </Checkbox>
          </HStack>
          <VStack>
            <Textarea
              w="full"
              fontFamily={"Inter"}
              fontSize={"14px"}
              placeholder="Ecrire un message..."
              h="138px"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
            <FormControl>
              <Input
                fontFamily={"Inter-extra"}
                defaultValue={"5"}
                value={amount}
                onChange={(e) => {
                  setAmount(parseInt(e.target.value, 10));
                }}
                type="number"
              />
              <FormHelperText
                fontSize="12px"
                fontFamily={"Inter"}
                color="#A5A8AC"
              >
                Minimum 5€
              </FormHelperText>
            </FormControl>
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button
            w="125px"
            fontFamily={"Inter"}
            h="38px"
            bg="grey"
            colorScheme="red"
            color="#fff"
            mr={3}
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            fontFamily={"Inter"}
            w="125px"
            colorScheme="green"
            h="38px"
            bg="#34A853"
            color="#fff"
            onClick={handleSubmit}
            isLoading={submitting}
            disabled={!valid || submitting}
          >
            Ajouter
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const TchatUserOption = ({
  isOpen,
  onClose,
  name,
  option,
  isBlocked,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  //const { chatList } = useChatUserList();
  const { activeTchat, setActiveTchat } = useInbox();
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { data } =
        option === "bloqued" ? await onBlock() : await onDelete();
      if (data.success) {
        if (option === "bloqued") {
          onClose();
        } else if (option === "deleted") {
          setActiveTchat(null);
          onClose();
          navigate(`/chat`);
        }
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  //console.log("activeTchat", activeTchat);

  const onBlock = () => {
    const inputData = {
      user_id: activeTchat.to_user?.user_id,
      is_other_profile: 1,
    };
    return blockUserTchat(inputData);
  };

  const onDelete = () => {
    const inputData = {
      to_user_id: activeTchat.to_user_id,
    };
    return deleteUserTchat(inputData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent m={2}>
        <ModalCloseButton />
        <ModalBody py={8}>
          {option === "bloqued" && (
            <Text fontFamily={"Inter"}>
              Voulez-vous vraiment {isBlocked ? "débloquer" : "bloquer"} {name}{" "}
              ?
            </Text>
          )}
          {option === "deleted" && (
            <Text fontFamily={"Inter"}>
              Voulez-vous vraiment supprimer cette conversation?
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontFamily={"Inter"}
            mr={3}
            size="sm"
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onSubmit}
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            {option === "bloqued" && (
              <Text fontFamily={"Inter"}>
                {isBlocked ? "Débloquer" : "Bloquer"}
              </Text>
            )}
            {option === "deleted" && (
              <Text fontFamily={"Inter"}>Supprimer la conversation</Text>
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
