/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  HStack,
  VStack,
  Text,
  FormControl,
  Input,
  Button,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
} from "@chakra-ui/react";
import Logo from "../img/folme.svg";
import lock from "../img/lock.png";
import msg from "../img/msg.png";
import fb from "../img/fb.png";
import fm from "../img/logo.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SignFooter from "./SignFooter";
import { ResendMail } from "../server/api";

export default function VerifyMail() {
  const device = useBreakpointValue({ base: true, lg: false });
  const width = useBreakpointValue({ base: "100%", lg: "50%" });
  const widthContain = useBreakpointValue({ base: "100%", lg: "70%" });
  const Tpix = useBreakpointValue({ base: "24px", lg: "32px" });
  const [load, setLoad] = useState(false)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState("")
  const token = localStorage.getItem("token")
  const userId = localStorage.getItem("userId")

  const handleResendMail = React.useCallback(
    async() => {
      setLoad(true)
     await ResendMail(userId, token).then((res) => {
        if (res.data.success) {
          setMessage(res.data.data.msg)
          setShow(true)
          localStorage.removeItem("email")
          localStorage.removeItem("userId")
          localStorage.removeItem("token")
          setLoad(false)
        }
        else {
          console.log(res.data);
        }
      })
    },
    [token, userId],
  )
  return (
    <HStack
      w="full"
      minH="100vh"
      bgGradient={"linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"}
      spacing={0}
    >
      {!device && (
        <VStack
          w="50%"
          h="100vh"
          bg="#005591"
          alignItems={"center"}
          justifyContent="space-around"
        >
          <Box cursor={"pointer"}>
            <img src={Logo} />
          </Box>
        </VStack>
      )}
      <VStack
        w={width}
        h="100vh"
        overflowY={"auto"}
        bg="#f5f5f5"
        fontFamily={"Inter"}
        textAlign="center"
        alignItems={"center"}
        bgGradient={"linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"}
        justifyContent="space-around"
      >
        {device && (
          <Link to="/">
            <Box
              display="flex"
              mt={8}
              alignItems={"center"}
              justifyContent="center"
              w="full"
            >
              <img src={fm} />
            </Box>
          </Link>
        )}
        <Box py={8}>
          <Text
            fontWeight={"900"}
            color="#005591"
            fontSize={Tpix}
            fontFamily={"Inter"}
          >
            Validez le lien dans l’email
          </Text>
        </Box>
        <VStack w={widthContain} h="full" px={8} spacing={8}>
          <Box>
            <Text fontFamily={"Inter"}>
              Nous vous avons envoyé lien à <span style={{ color: "#005591", fontWeight: "bold" }}>{localStorage?.getItem("email")}</span> veuillez
              cliquer dessus pour afin de confirmer la création de votre compte
            </Text>
          </Box>
          <Box>
            <Text color="rgba(87, 102, 117, 0.8)">Pensez à vérifier vos spam : )</Text>
          </Box>
        </VStack>
        <VStack w={widthContain} h="full" px={8} spacing={4}>
          <Box hidden={show}>
            <Text color="#005591" fontWeight={"bold"} fontFamily={"Inter"}>
              OR
            </Text>
          </Box>
          <Button hidden={show} onClick={handleResendMail} bg="grey" isLoading={load} fontSize={"10px"} colorScheme="grey" p={6} color="#fff">Cliquez ici pour renvoyer l'email de confirmation</Button>
          {show && <Text fontFamily={"Inter"} fontSize={"14px"} color="green">Le lien de vérification a été envoyé avec succès</Text>}
        </VStack>

        {!device && <VStack w="full" py={8}>
          <Link to="/signup">
            <Text fontFamily={"Inter"} color="#005591">
              S'inscrire sur FollowMe
            </Text>
          </Link>
        </VStack>}
        {device && <SignFooter />}
      </VStack>
    </HStack>
  );
}
