/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import {
  VStack,
  HStack,
  Box,
  useBreakpointValue,
  Text,
  CircularProgress,
} from "@chakra-ui/react";
import Logo from "../img/folme.svg";
import React, { useEffect, useState, PropsWithChildren } from "react";
import { VideoCamera } from "phosphor-react";

type Props = PropsWithChildren<{}>;
export default function Loading({ children }: Props) {
  const [loader, setLoader] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [loader]);
  const picSize = useBreakpointValue({ base: "100px", lg: "200px" });
  return (
    <div>
      {loader ? (
        <VStack
          w="full"
          h="100vh"
          alignItems={"center"}
          justifyContent="center"
          bg="#005591"
        >
          <Box cursor={"pointer"}>
            <img src={Logo} height={picSize} width={picSize} />
          </Box>
        </VStack>
      ) : (
        <>{children}</>
      )}
    </div>
  );
}

export const VideoLoading = () => {
  return (
    <Box bg="black" w='full' h='full' py="8">
      <HStack justifyContent={"center"}>
        <CircularProgress 
        isIndeterminate
         color="gray"
         size={"50px"}
         thickness='5px'
         />
        <VideoCamera size={18} style={{ marginLeft: -34 }} />
      </HStack>
      <Text fontSize="12" textAlign="center" color="#ddd" mt="2">
        Vidéo en cours de traitement, veuillez revenir dans quelques minutes
      </Text>
    </Box>
  );
};
