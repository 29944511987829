/* eslint-disable jsx-a11y/alt-text */
import {
    Box,
    HStack,
    VStack,
    Text,
    useBreakpointValue,
} from "@chakra-ui/react";
import Logo from "../img/folme.svg";
import fm from "../img/logo.svg";
import React from "react";
import { Link, useParams } from "react-router-dom";
import SignFooter from "./SignFooter";
import { VerifyMail } from "../server/api";

export default function VerifyMailCode() {
    const device = useBreakpointValue({ base: true, lg: false });
    const width = useBreakpointValue({ base: "100%", lg: "50%" });
    const widthContain = useBreakpointValue({ base: "100%", lg: "70%" });
    const Tpix = useBreakpointValue({ base: "24px", lg: "32px" });
    const [message, setMessage] = React.useState("")
    const [error, setError] = React.useState("")
    const { token } = useParams()
    console.log(token);

    React.useEffect(() => {
        VerifyMail(token).then((res => {
            console.log(res.data);
            if (res.data.success) {
                setMessage(res.data.message);
                localStorage.setItem("f-token", res.data.data.token);
                localStorage.setItem("profil", JSON.stringify(res.data.data));
                localStorage.setItem("userId", res.data.data.user_id);
                setTimeout(() => {
                    window.location.assign("/home");
                  }, 3000);
            }
            else {
                setError("Your token is invalid please verify your email");
            }
        }))
    }, [])
    return (
        <HStack
            w="full"
            minH="100vh"
            bgGradient={"linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"}
            spacing={0}
        >
            {!device && (
                <VStack
                    w="50%"
                    h="100vh"
                    bg="#005591"
                    alignItems={"center"}
                    justifyContent="space-around"
                >
                    <Box cursor={"pointer"}>
                        <img src={Logo} />
                    </Box>
                </VStack>
            )}
            <VStack
                w={width}
                h="100vh"
                overflowY={"auto"}
                bg="#f5f5f5"
                fontFamily={"Inter"}
                textAlign="center"
                alignItems={"center"}
                bgGradient={"linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"}
                justifyContent="space-around"
            >
                {device && (
                    <Link to="/">
                        <Box
                            display="flex"
                            mt={8}
                            alignItems={"center"}
                            justifyContent="center"
                            w="full"
                        >
                            <img src={fm} />
                        </Box>
                    </Link>
                )}
                <VStack w='full' py={8}>
                <Box py={8}>
                    <Text
                        fontWeight={"900"}
                        color="#005591"
                        fontSize={Tpix}
                        fontFamily={"Inter"}
                    >
                        Validation du mail token
                    </Text>
                </Box>
                <VStack w={widthContain} h="full" px={8} spacing={8}>
                    {message.length > 0 && <Box>
                        <Text fontFamily={"Inter"} color="green">
                            {message}
                        </Text>
                    </Box>}
                    {error.length > 0 && <Box>
                        <Text fontFamily={"Inter"} color="red">
                            {error}
                        </Text>
                    </Box>}
                </VStack>
                </VStack>

                {!device && <VStack w="full" py={8}>
                    <Link to="/signup">
                        <Text fontFamily={"Inter"} color="#005591">
                            S'inscrire sur FollowMe
                        </Text>
                    </Link>
                </VStack>}
                {device && <SignFooter />}
            </VStack>
        </HStack>
    );
}
