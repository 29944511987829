/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
import { HStack, VStack, Button, Box, Text, useToast } from "@chakra-ui/react";
import {
  Avatar,
  WrapItem,
  useBreakpointValue,
  InputRightElement,
  Input,
  CircularProgress,
  InputGroup,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import "../../Styles/carousel.module.css";
import like from "../../img/likeheart.svg";
import HashLoader from "react-spinners/HashLoader";
import comment from "../../img/comment.svg";
import emojis from "../../img/emojis.svg";
import send from "../../img/send.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation, Keyboard, Scrollbar } from "swiper";
import React, { useState, useRef, Fragment } from "react";
import {
  getMyPost,
  postComment
} from "../../server/api";
import CopyToClipboard from "react-copy-to-clipboard";
import styles from "../../Styles/carousel.module.css";
import { useMyPost, useUser, useSpeech, useLoader } from "../../store/store";
import { useNavigate } from "react-router";
import {
  Copy,
  PencilSimple,
  DotsThree,
} from "phosphor-react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import PostDeleteButton from "../button/PostDeleteButton";
import PostEditButton from "../button/PostEditButton";
import Likes from "../mentions/Likes";
import Bookmarks from "../mentions/Bookmarks";
import { VideoLoading } from "../Loading";
import OnlyFollow from "../OnlyFollow";
import { LazyLoadImage } from "react-lazy-load-image-component";
import VideoRender from "../../function/VideoRender";

export default function MyPost() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const pop = useDisclosure()
  const { loader } = useLoader();
  const loaderSize = useBreakpointValue({ base: "30", lg: "50" });
  const { lang } = useSpeech()
  const [show, setShow] = useState<boolean>(true);
  const post = useMyPost()?.mypost;
  const { setMyPost, setMyIsLoading, isMyLoading, myTotal } = useMyPost();
  const [showComment, setShowComment] = useState<boolean>(true);
  const [comments, setComments] = useState();
  const { user: profile } = useUser();
  const [files, setFiles] = useState<any>();
  const nav = useBreakpointValue({ base: false, lg: true });
  const [user, setUser] = useState<any>(
    JSON.parse(localStorage.getItem("profil") as any)
  );
  const swiperRef = useRef<any>();
  const [ref, inView] = useInView({
    threshold: 1,
  });
  const [tabCom, setTabCom] = useState<any[]>([]);
  const coderef = useRef<any>();
  const navigate = useNavigate();

  const toast = useToast();
  const copy = React.useCallback(() => {
    toast({
      title: "Lien copié dans le press-papier",
      duration: 2000,
      position: "top",
      isClosable: true,
    });
  }, [toast]);

  const hasMore = post?.length > 9 && post?.length < myTotal;


  React.useEffect(() => {
    const loadMorePosts = async () => {
      //is there is more than 9 posts on first page and all posts haven't been loaded yet
      try {
        const skip = post.length;
        const { data } = await getMyPost(skip);
        if (data.success) {
          setMyPost([...post, ...data.data.posts]);
          setMyIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (inView && hasMore && !isMyLoading) {
      setMyIsLoading(true);
      loadMorePosts();
    }
  }, [inView, isMyLoading, hasMore, post, setMyIsLoading, setMyPost]);


  const getTab = React.useMemo(() => {
    return tabCom;
  }, [tabCom]);

  const postComments = React.useCallback(
    (id: any) => {
      return postComment(id, comments).then((res) => {
        if (res.data.success) {
          let data = {
            picture: profile?.picture,
            name: profile?.name,
            message: comments,
          };
          let tab = [...tabCom, data];
          setTabCom(tab);
        }
      });
    },
    [comments, profile?.name, profile?.picture, tabCom]
  );

  const handleMediaClick = (file: any) => {
    onOpen();
    setFiles(file);
  };

  return (
    <VStack w="full" paddingBottom={32} spacing={4}>
      {loader ? (
        <VStack w="full" h="full" alignItems={"center"} justifyContent="center">
          <HashLoader color="#005591" size={loaderSize} />
        </VStack>
      ) : (<>
        {post?.map((data: any, i: any) => {
          return (
            <>
              <HStack
                w="full"
                px={2}
                key={data?.id}
                fontFamily={"Inter"}
                justifyContent={"space-between"}
              >
                <HStack w="full"  alignItems={"flex-start"}>
                  <WrapItem>
                    <Avatar
                      onClick={() => navigate(`/home/profil/${data?.user_unique_id}`)}
                      cursor={"pointer"}
                      name={data?.user?.name}
                      src={data?.user?.picture}
                    />
                  </WrapItem>
                  <VStack w='full' alignItems={"flex-start"} spacing={0}>
                    <HStack w="full" justifyContent={"space-between"}>
                      <HStack
                        cursor={"pointer"}
                        onClick={() =>
                          navigate(`profil/${data?.user_unique_id}`)
                        }
                      >
                        <Text fontSize={"14px"} fontWeight="600">
                          {data?.user?.name}
                        </Text>
                        <Text hidden fontSize={"12px"} color="#65676A">
                          @{data?.user?.username}
                        </Text>
                      </HStack>
                      <Box>
                        <Text fontSize={"12px"} color="#65676A">
                          {data?.publish_time_formatted}
                        </Text>
                      </Box>
                      <Popover onClose={pop.onClose}>
                        <PopoverTrigger>
                          <Button p={0} bg="transparent">
                            <DotsThree size={24} />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverBody>
                            <VStack w='full' onClick={pop.onClose} spacing={4}>
                              <CopyToClipboard
                                text={coderef?.current?.textContent}
                                onCopy={copy}
                              >
                                <HStack w="full" cursor={"pointer"} color="#005591">
                                  {" "}
                                  <Text ref={coderef} hidden>
                                    {data?.share_link}
                                  </Text>
                                  <Copy size={14} />
                                  <Text fontSize={"sm"}>{lang.copy_link}</Text>{" "}
                                </HStack>
                              </CopyToClipboard>
                              <PostEditButton postId={data?.post_id} postTab={post} />
                              {profile?.is_document_verified === 2 && (
                                <PostDeleteButton postId={data?.post_id} />
                              )}
                            </VStack>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </HStack>
                    {data?.content?.length > 65 ? (
                      <VStack spacing={0} alignItems="flex-start">
                        <Text fontSize={"14px"} color="#65676A">
                          {data?.content?.substring(0, 65)}{" "}
                          <span
                            hidden={!show}
                            onClick={() => setShow(!show)}
                            style={{
                              color: "blue",
                              marginLeft: "2px",
                              cursor: "pointer",
                            }}
                          >
                            {lang?.read_more}
                          </span>
                        </Text>
                        <Text hidden={show} fontSize={"14px"} color="#65676A">
                          {data?.content.substring(65)}{" "}
                          <span
                            onClick={() => setShow(!show)}
                            style={{
                              color: "blue",
                              marginLeft: "2px",
                              cursor: "pointer",
                            }}
                          >
                            {lang?.read_less}
                          </span>
                        </Text>
                      </VStack>
                    ) : (
                      <HStack>
                        <Text fontSize={"14px"} color="#65676A">
                          {data?.content}
                        </Text>
                      </HStack>
                    )}
                  </VStack>
                </HStack>

              </HStack>
              <>
                {data?.postFiles.length === 1 &&
                  data?.postFiles.map((data: any, i: any) => {

                    return (
                      <Fragment>
                        {data.file_type === "image" && (
                          <VStack key={i} w="full">
                            <LazyLoadImage
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "cover",
                              }}
                              onClick={() =>
                                handleMediaClick(data.post_file)
                              }
                              src={data.post_file}
                            />
                          </VStack>
                        )}
                        {data.file_type === "video" && (data.is_generate ? (
                          <VideoRender postFile={data} />
                        ) : (<VideoLoading />))}
                      </Fragment>
                    );
                  })}
              </>

              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onClick={() => handleMediaClick(data)}
                slidesPerView={1}
                slidesPerGroupSkip={1}
                modules={[Autoplay, Navigation]}
                className={styles.scontainer}
              >
                {data.postFiles.length > 1 &&
                  data.postFiles.map((data: any, i: any, t: any) => {
                    return (
                      <SwiperSlide className={styles.schild}>
                        <>
                          {data.file_type === "image" && (
                            <VStack key={i} w="full" rounded="6px">
                              <img
                                style={{
                                  width: "100%",
                                }}
                                src={data.post_file}
                              />
                            </VStack>
                          )}
                          <HStack
                            w="full"
                            roundedBottom={"4px"}
                            p={0}
                            alignItems={"center"}
                            justifyContent="center"
                          >
                            <Text rounded="full" fontSize={"12px"}>
                              {i + 1} / {t.length}
                            </Text>
                          </HStack>{" "}
                        </>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <HStack w="full" px={2} justifyContent={"space-between"}>
                <HStack spacing={4}>
                  <Likes likeData={data} />
                  <HStack>
                    <img
                      onClick={() =>
                        setShowComment(
                          data.post_id !== showComment
                            ? data.post_id
                            : undefined
                        )
                      }
                      style={{ cursor: "pointer" }}
                      src={comment}
                    />
                    <Text fontSize={"14px"} color="#65676A">
                      {data?.total_comments}
                    </Text>
                  </HStack>
                </HStack>
                <Bookmarks bookData={data} />
              </HStack>
              <VStack
                hidden={showComment}
                p={2}
                w="full"
                alignItems={"flex-start"}
              >
                {data?.total_comments === 0 && (
                  <Text
                    px={12}
                    fontFamily={"Inter"}
                    fontSize={"12px"}
                    color="#1D1929"
                  >
                    aucun commentaire
                  </Text>
                )}

                {data.post_comments.map((data: any, i: any) => {
                  return (
                    <HStack
                      key={i}
                      w="full"
                      alignItems={"flex-start"}
                      justifyContent="flex-start"
                    >
                      <Avatar h="28px" src={data?.user_picture} w="28px" />
                      <VStack w="full">
                        <VStack
                          spacing={0}
                          px={2}
                          w="full"
                          alignItems={"flex-start"}
                          bg="#F2F2F2"
                          py={2}
                          rounded="5px"
                        >
                          <HStack w="full" justifyContent={"space-between"}>
                            <Text fontFamily={"Inter-extra"} fontSize={"10px"}>
                              {data?.user_displayname}
                            </Text>
                            <Text
                              fontFamily={"Inter"}
                              fontSize={"10px"}
                              color="#65676A"
                            >
                              {data?.created}
                            </Text>
                          </HStack>
                          <Text fontFamily={"Inter"} fontSize={"10px"}>
                            {data?.comment}
                          </Text>
                        </VStack>
                        <HStack
                          w="full"
                          hidden
                          spacing={4}
                          justifyContent={"flex start"}
                        >
                          <HStack>
                            <Text fontFamily={"Inter"} fontSize={"8px"}>
                              J'aime
                            </Text>
                            <HStack spacing={1}>
                              <img src={like} height="7.5px" width="8.33px" />
                              <Text fontFamily={"Inter"} fontSize={"8px"}>
                                0
                              </Text>
                            </HStack>
                          </HStack>
                          <Box>
                            <Text
                              fontFamily={"Inter"}
                              cursor="pointer"
                              fontSize={"8px"}
                            >
                              Répondre
                            </Text>
                          </Box>
                        </HStack>
                      </VStack>
                    </HStack>
                  );
                })}

                {getTab &&
                  getTab.map((data: any, i: any) => {
                    return (
                      <HStack
                        w="full"
                        alignItems={"flex-start"}
                        justifyContent="flex-start"
                      >
                        <Avatar h="28px" src={user?.picture} w="28px" />
                        <VStack w="full">
                          <VStack
                            spacing={0}
                            px={2}
                            w="full"
                            alignItems={"flex-start"}
                            bg="#F2F2F2"
                            py={2}
                            rounded="5px"
                          >
                            <HStack w="full" justifyContent={"space-between"}>
                              <Text
                                fontFamily={"Inter-extra"}
                                fontSize={"10px"}
                              >
                                {user?.name}
                              </Text>
                              <Text
                                fontFamily={"Inter"}
                                fontSize={"10px"}
                                color="#65676A"
                              >
                                1 second ago
                              </Text>
                            </HStack>
                            <Text fontFamily={"Inter"} fontSize={"10px"}>
                              {comments}
                            </Text>
                          </VStack>
                        </VStack>
                      </HStack>
                    );
                  })}

                {data?.post_comments.length > 3 && (
                  <Text fontFamily={"Inter-extra"} fontSize={"12px"}>
                    Voir plus de commentaires
                  </Text>
                )}
                <HStack w="full">
                  <Avatar h="28px" w="28px" src={user?.picture} />
                  <HStack w="full">
                    <InputGroup size="md">
                      <Input
                        placeholder="Votre commentaire..."
                        value={comments}
                        onChange={(e: any) => setComments(e.target.value)}
                        color="#65676A"
                        fontSize={"14px"}
                        bg="#f2f2f2"
                        rounded="47px"
                      />
                      <InputRightElement>
                        <Box>
                          <img src={emojis} />
                        </Box>
                      </InputRightElement>
                    </InputGroup>
                  </HStack>
                  <Button
                    onClick={() => postComments(data.post_id)}
                    p={0}
                    rounded='full'
                    cursor="pointer"
                  >
                    <img src={send} />
                  </Button>
                </HStack>
              </VStack>

            </>
          );
        })}
        {hasMore && !isMyLoading && <div ref={ref} />}
        {isMyLoading && post?.length > 0 && (
          <HStack w='full' justifyContent="center">
            <CircularProgress size={"50px"} isIndeterminate color="#005591" />
          </HStack>
        )}
      </>)}




      <Modal onClose={onClose} isCentered size="lg" isOpen={isOpen}>
        <ModalOverlay bg="rgb(0,0,0,0.9)" />
        <ModalContent bg="transparent" p={0} m={0} rounded="0px">
          <ModalBody p={0} m={0}>
            {files?.length > 0 && (
              <img src={files} style={{ height: "100%", width: "100%" }} />
            )}
            {files?.postFiles?.length > 1 && (
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                ref={swiperRef as any}
                navigation={nav}
                slidesPerView={1}
                scrollbar={true}
                slidesPerGroupSkip={1}
                modules={[
                  Autoplay,
                  Scrollbar,
                  Navigation,
                  Keyboard,
                  Pagination,
                ]}
                className={styles.scontainer}
              >
                {files?.postFiles?.length > 1 &&
                  files?.postFiles.map((data: any, index: any, t: any) => {
                    return (
                      <SwiperSlide className={styles.schild}>
                        <>
                          {data.file_type === "image" && (
                            <>
                              {" "}
                              <VStack key={index} w="full" rounded="6px">
                                <img
                                  style={{
                                    width: "100%",
                                  }}
                                  src={data.post_file}
                                />
                              </VStack>
                            </>
                          )}
                        </>
                        <HStack
                          w="full"
                          roundedBottom={"4px"}
                          p={0}
                          alignItems={"center"}
                          justifyContent="center"
                        >
                          <Text rounded="full" color="#fff" fontSize={"12px"}>
                            {index + 1} / {t.length}
                          </Text>
                        </HStack>{" "}
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
