/* eslint-disable jsx-a11y/alt-text */
import {
  HStack,
  Button,
  useDisclosure,
  Box,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import CardSend from "../../img/card-send.svg";
import React from "react";
import AddBankAccount from "../AddBankAccount";
import { useAccountList } from "../../store/store";

export default function CashOutButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { accountList } = useAccountList();
  return (
    <HStack>
      <Button
        onClick={onOpen}
        bg="#005591"
        colorScheme="blue"
        h="55px"
        w="345px"
        color="#fff"
      >
        <Text mr={4}>Retirer l’argent</Text> <img src={CardSend} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          boxShadow={"0px -7.61702px 30.4681px rgba(70, 96, 135, 0.1)"}
          w="350px"
         
          rounded="15px"
        >
          <ModalHeader
            borderBottom={"0.952128px solid #E8E6EA"}
            fontSize={"15px"}
            fontFamily={"Inter"}
          >
            Effectuer un retrait par virement
          </ModalHeader>
          <ModalCloseButton p={0} rounded="full" border="1px solid #344054" />
          <ModalBody>
            <VStack w="full" alignItems={"flex-start"}>
              <Box>
                <Text color="#4F5E7B" fontSize={"11.43px"}>
                  Montant minimum requis : 100,00 €
                </Text>
              </Box>
              <Box>
                <Text color="#4F5E7B" fontSize={"11.43px"}>
                  Montant minimum requis : 100,00 €
                </Text>
              </Box>
              <VStack
                w="full"
                p={2}
                spacing={0}
                rounded="7px"
                alignItems={"flex-start"}
                border="0.952128px solid #E8E6EA"
              >
                <FormLabel fontSize={"7.62px"}>LE MONTANT</FormLabel>
                <Input
                  p={0}
                  fontSize={"13px"}
                  fontWeight="800"
                  defaultValue={"108€"}
                  variant={"outlined"}
                />
              </VStack>
              {accountList?.billing_accounts?.length === 0 && (
                <>
                  <AddBankAccount />
                  <Box>
                    <Text
                      fontSize={"12px"}
                      letterSpacing="-0.02em"
                      color="#4F5E7B"
                    >
                      Aucun compte bancaire ajouté. Pour ajouter un compte, s’il
                      vous plait{" "}
                      <span style={{ color: "blue" }}>Cliquez ici</span>
                    </Text>
                  </Box>
                </>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              bg="grey"
              colorScheme="grey"
              fontSize={"14px"}
              fontFamily={"Inter"}
              w="123px"
              h="52px"
              rounded="3px"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              bg="#34A853"
              colorScheme="green"
              fontSize={"14px"}
              fontFamily={"Inter"}
              disabled={
                accountList?.billing_accounts?.length === 0 ? true : false
              }
              rounded="3px"
              h="52px"
              w="182px"
              color="#fff"
            >
              Envoyer une demande
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}
