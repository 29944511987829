/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-undef */
import {
  HStack,
  Text,
  Input,
  VStack,
  useBreakpointValue,
  FormControl,
  FormLabel,
  Textarea,
  Button,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import kdo from "../../img/kad.svg";
import { useSpeech } from "../../store/store";
import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { TipsPayment } from "../../server/api";
import styles from "../../Styles/home.module.css";

export default function TipsPostButton({ post_id, user_id }: any) {
  const { lang } = useSpeech();
  const toast = useToast();
  const [bool, setBool] = useState<boolean>(false);
  const imgpix = useBreakpointValue({ base: "40px", lg: "50px" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tip, setTip] = useState({
    amount: "5",
    message: "",
  });

  const tipPayment = useCallback(() => {
    setBool(true);
    TipsPayment(tip?.amount, tip?.message, user_id, post_id).then((res) => {
      if (res?.data?.success) {
        setBool(false);
        onClose();
        setTip({ ...tip, message: "" });
        toast({
          title: "Cadeau envoyé.",
          description: `${res?.data?.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setBool(false);
        setTip({ ...tip, message: "" });
        onClose();
     
        toast({
          title: "Echec d'envoi",
          description: `${res?.data?.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    });
  }, [onClose, post_id, tip, toast, user_id]);
  return (
    <HStack>
      <HStack onClick={onOpen} cursor="pointer">
        <img src={kdo} />
        <Text fontFamily={"Inter"} fontSize={"14px"} color="#65676A">
          {lang?.send_kit}
        </Text>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded="15.23px" w="357px">
          <ModalHeader color="#344054" fontFamily={"Inter"} fontSize="15.23px">
            Envoyer un Tip
          </ModalHeader>
          <Divider />
          <ModalCloseButton border="2px solid #000" p={0} rounded="full" />
          <ModalBody>
            <VStack w="full" spacing={4}>
              <VStack w="full" p={1} rounded="6px" border="1px solid #E8E6EA">
                <FormControl>
                  <FormLabel
                    color="#A5A8AC"
                    fontSize="8px"
                    fontFamily={"Inter"}
                  >
                    Montant de la pointe
                  </FormLabel>
                  <HStack w="full">
                    <Input
                      px={0}
                      value={tip.amount}
                      onChange={(e) =>
                        setTip({ ...tip, amount: e.target.value })
                      }
                      className={styles.inputs}
                      defaultValue={5}
                    />
                    <Text>€</Text>
                  </HStack>
                </FormControl>
              </VStack>
              <VStack w="full">
                <FormControl rounded="6px" border="1px solid #E8E6EA">
                  <Textarea
                    fontSize="12px"
                    value={tip.message}
                    onChange={(e) =>
                      setTip({ ...tip, message: e.target.value })
                    }
                    px={2}
                    maxLength={200}
                    fontFamily={"Inter"}
                    className={styles.inputs}
                    placeholder="Ecrire un message"
                  />
                </FormControl>
                <HStack w="full" justifyContent={"flex-end"}>
                  <Text fontSize="14px" fontFamily={"Inter"} color="#A5A8AC">
                    {tip.message.length}/200
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              w="100px"
              h="38px"
              fontSize="14px"
              fontFamily={"Inter"}
              colorScheme="red"
              bg="grey"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              w="149px"
              h="38px"
              colorScheme="green"
              fontSize="14px"
              fontFamily={"Inter"}
              onClick={tipPayment}
              bg="#34A853"
              isLoading={bool}
              color="#fff"
            >
              Envoyer Tip
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}
