/* eslint-disable jsx-a11y/alt-text */
import { HStack, VStack, Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import arrowleft from "../../img/arrowleft.svg";
import {
  Tabs,
  TabList,
  InputGroup,
  Tab,
  InputRightElement,
} from "@chakra-ui/react";
import tees from "../../img/tees.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Minus, Plus } from "phosphor-react";
import { Button } from "react-bootstrap";

export default function SingleMarketProduct() {
  const [count, setCount] = useState(1);
  return (
    <VStack w="full" minH="100vh" paddingTop={8}>
      <HStack
        w="full"
        hidden
        px={8}
        justifyContent={"flex-start"}
        border="1px solid red"
        h="69px"
      >
        <Link to="/home/market">
          <img src={arrowleft} width="17.5px" height={"11.5px"} />
        </Link>
      </HStack>
      <VStack
        h={["50vh", "50vh", "70vh", "70vh"]}
        w="full"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img src={tees} height={"50%"} width="50%" />
      </VStack>
      <VStack
        w="full"
        boxShadow={"0px 2px 14px rgba(0, 0, 0, 0.25)"}
        bg="#F8F7FA"
        roundedTop={"40px"}
        minH="70vh"
      >
        <HStack w="full" p={8}>
          <Box>
            <Text
              fontSize={"20px"}
              justifyContent={"flex-start"}
              fontFamily={"inter-extra"}
            >
              T-shirt{" "}
            </Text>
          </Box>
        </HStack>
        <Tabs w="full" px={8} variant="unstyled">
          <TabList w="full">
            <HStack w="full">
              <Tab
                rounded="60px"
                h="38px"
                bg="#fff"
                w="50%"
                fontSize={"12px"}
                color="#0E5490"
                _selected={{ color: "white", bg: "#0E5490" }}
                fontFamily={"Inter"}
              >
                S
              </Tab>
              <Tab
                rounded="60px"
                h="38px"
                bg="#fff"
                w="50%"
                fontSize={"12px"}
                color="#0E5490"
                _selected={{ color: "white", bg: "#0E5490" }}
                fontFamily={"Inter"}
              >
                M
              </Tab>
              <Tab
                rounded="60px"
                h="38px"
                bg="#fff"
                w="50%"
                fontSize={"12px"}
                color="#0E5490"
                _selected={{ color: "white", bg: "#0E5490" }}
                fontFamily={"Inter"}
              >
                L
              </Tab>
              <Tab
                rounded="60px"
                h="38px"
                bg="#fff"
                w="50%"
                fontSize={"12px"}
                color="#0E5490"
                _selected={{ color: "white", bg: "#0E5490" }}
                fontFamily={"Inter"}
              >
                XS
              </Tab>
              <Tab
                rounded="60px"
                h="38px"
                bg="#fff"
                w="50%"
                fontSize={"12px"}
                color="#0E5490"
                _selected={{ color: "white", bg: "#0E5490" }}
                fontFamily={"Inter"}
              >
                XL
              </Tab>
            </HStack>
          </TabList>
        </Tabs>
        <VStack w="full" py={4}>
          <HStack
            w="159px"
            px={1}
            alignItems={"center"}
            justifyContent={"space-around"}
            rounded="22.5px"
            bg="rgba(159, 201, 242, 0.25)"
            h="45px"
          >
            <Box
              w="37px"
              onClick={() => (count > 0 ? setCount(count - 1) : "")}
              cursor={"pointer"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              rounded="30px"
              h="37px"
              bg="#FFFFFF"
            >
              <Minus />
            </Box>
            <Box
              w="60px"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              h="37px"
              rounded="30px"
              bg="#005591"
            >
              <Text color="#fff" fontFamily={"Inter"}>
                {count}
              </Text>
            </Box>
            <Box
              w="37px"
              onClick={() => setCount(count + 1)}
              cursor={"pointer"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              rounded="30px"
              h="37px"
              bg="#FFFFFF"
            >
              <Plus />
            </Box>
          </HStack>
        </VStack>
        <VStack w="full" p={8} alignItems={"flex-start"} textAlign={"left"}>
          <Box>
            <Text fontSize={"20px"} fontFamily={"inter-extra"}>
              A propos{" "}
            </Text>
          </Box>
          <Box>
            <Text fontSize={"12px"} fontFamily={"inter"}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id ipsum
              vivamus velit lorem amet. Tincidunt cras volutpat aliquam
              porttitor molestie. Netus neque, habitasse id vulputate proin
              cras. Neque, vel duis sit vel pellentesque tempor. A commodo arcu
              tortor arcu, elit.{" "}
            </Text>
          </Box>
        </VStack>
        <VStack w="full" px={8} display="flex" alignItems={"center"} justifyContent={"space-around"}>
          <HStack w="357px" color="#fff" px={8} bg="#005591" rounded="34px" h="64px">
              <Button color="#fff">
             <Text  mr={3}> Ajouter à la commande</Text>
              </Button>
              <Box borderLeft={"2px solid #fff"} px={4}>
            <Text color="#fff" fontSize={"20px"} fontFamily={"inter-extra"}>
               30€{" "}
            </Text>
          </Box>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
}
