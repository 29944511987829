import { VStack, HStack, Text, Button } from "@chakra-ui/react";
import React from "react";
import shop from "../../img/shop.svg";
import { useUser } from "../../store/store";
import { Link } from "react-router-dom";
import MyShopList from "./MyShopList";

export default function MyShop() {
  const { user } = useUser();
  return (
    <VStack w="full">
      <VStack
        w="full"
        minH="200px"
        backgroundImage={user?.cover}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize={"cover"}
        position="relative"
      >
        <VStack
          position="absolute"
          zIndex={99}
          bg="linear-gradient(180deg, rgba(24, 25, 26, 0.6) 0%, rgba(0, 81, 129, 0.09) 121.92%);"
          w="full"
          h="200px"
        >
          <HStack w="full" p={4} justifyContent="flex-start">
            <HStack alignItems={"flex-start"}>
              <VStack alignItems={"flex-start"} fontFamily="Inter" color="#fff">
                <Text fontWeight={"500"}>{user?.name}</Text>
                <HStack>
                  <Text fontSize={"10px"}>
                    {user?.total_posts}{" "}
                    {user?.total_posts > 1 ? "Publications" : "Publication"}
                  </Text>
                  <Text fontSize={"10px"}> - 0 J’aime</Text>
                </HStack>
              </VStack>
            </HStack>
          </HStack>
          <Button
            h={"100px"}
            position="absolute"
            bottom={-12}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize={"cover"}
            w="100px"
            bgImage={shop}
            colorScheme="transparent"
            rounded="full"
          ></Button>
        </VStack>
      </VStack>
      <HStack w='full' justifyContent={"center"} py={12}>
          <Link to="/home/addtoshop"><Button p={4} fontSize={"sm"} colorScheme={"blue"} fontFamily={"Inter"} h='38px' bg="#0E5490" color="#fff" rounded="60px">Ajouter un produit</Button></Link>
      </HStack>
      <MyShopList />
    </VStack>
  );
}
