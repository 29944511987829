/* eslint-disable jsx-a11y/alt-text */
import {
  VStack,
  Box,
  Text,
  HStack,
  Button,
  Input,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel
} from "@chakra-ui/react";
import { Checks, HourglassMedium } from "phosphor-react";
import React,{ useState,useRef } from "react";
import doc from "../img/doc.png";
import down from "../img/downdoc.svg";
import { useUser } from "../store/store";
import { nanoid } from "nanoid";
import { SaveDocument } from "../server/api";


export default function Documents() {
  const docId = nanoid()
  const toast = useToast()
  const [bool, setBool] = useState(false);
  const position = useBreakpointValue({ base: "column", lg: "row" });
  const [adult, setAdult] = useState(1)
  const [url, setUrl] = useState("")
  const [file, setFile] = useState("")
  const [phone, setPhone] = useState("")
  const { user } = useUser();
  const ref = useRef<any>();
 const upload = React.useCallback(
    (e:any) => {
      setFile(e[0]);
      setUrl(URL.createObjectURL(e[0]))
    },
    []
  )

 const docSubmit = React.useCallback(
    () => {
     if(url){
        setBool(true)
        SaveDocument(file,adult,phone).then((res)=>{
          console.log(res.data);
          if(res.data.success){
            setBool(false)
            toast({
              title: "Document envoyé.",
              description: "Vos informations ont été soumises...",
              status: "success",
              position: "top",
              duration: 3000,
              isClosable: true,
            });
            window.location.reload()
          }
          else{
            setBool(false)
            toast({
              title: "Echec d'envoi.",
              description: "Format non conforme !",
              status: "error",
              position: "top",
              duration: 5000,
              isClosable: true,
            });
          }
        }) 
      } 
    },
    [adult, file, phone, toast, url]
  )

  return (
    <VStack w="full" minH="150vh" p={4} spacing={4}>
      {user?.is_document_verified === 0 && (
        <>
          <VStack
            w="full"
            p={2}
            alignItems={"flex-start"}
            minH="280px"
            bg="rgba(159, 201, 242, 0.25)"
            rounded="6px"
          >
            <Box>
              <Text
                fontWeight={"600"}
                fontSize="16px"
                fontFamily="Inter"
                color="#005591"
              >
                Règles, à lire pour une approbation rapide
              </Text>
            </Box>
            <HStack w="full" spacing={4}>
              <img src={doc} height="108px" width="112.62px" />
              <VStack fontFamily={"Inter"} fontSize="12px" color="#344054">
                <ul>
                  <li>L’image doit être claire</li>
                  <li>
                    Votre pièce d'identité doit être entièrement dans le cadre
                    Doit être en couleur
                  </li>
                  <li>Doit être clairement visible</li>
                  <li>L’arrière-plan doit être minimal</li>
                </ul>
              </VStack>
            </HStack>

            <VStack px={4} fontFamily={"Inter"} fontSize="12px" color="#344054">
              <ul>
                <li>
                  L’image ne doit pas être modifiée, redimensionnée ou pivotée
                </li>
                <li>
                  Le fichier doit être au format .png ou Jpg La taille doit être
                  inférieure à 7 Mo
                </li>
                <li>L'ID doit être valide et non expiré</li>
              </ul>
            </VStack>
          </VStack>
          <Input
                type="file"
                multiple
                accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                ref={ref as any}
                hidden
                onChange={(e) => {
                  upload(e.target.files);
                }}
              />
          <VStack
            w="full"
            py={8}
            fontFamily={"Inter"}
            display="flex"
            flexDirection={position as any}
          >
            
            <Button
              m={2}
              w="357px"
              h="55px"
              onClick={() => {
                ref?.current?.click() as any;
              }}
              border="1px dashed #005591"
              fontSize={"16px"}
            >
              <Text fontSize={"16px"} color="#005591" mr={4}>
                Cliquer ici pour télécharger
              </Text>
              <img src={down} />
            </Button>
            <Input
              w="358px"
              h="55px"
              value={phone}
              onChange={(e)=>setPhone(e.target.value)}
              m={2}
              border="1px solid #E8E6EA"
              placeholder="Numéro de téléphone (ex: 0033645802585)"
            />
          </VStack>
          {url && <img src={url} height="100px" width="100px" />}
          <VStack w="full" fontFamily={"Inter"} textAlign={"center"}>
            <Box>
              <Text fontSize={"16px"}>Contenu</Text>
              <Text fontSize={"14px"} color="#A5A8AC">
                Publier du contenu explicite (contenu pour adultes) ?
              </Text>
            </Box>
            <HStack>
            <Tabs w="full" p={0} variant="unstyled">
            <TabList p={2}>
              <Tab
                rounded="8px"
                mr={4}
                w="174px"
                h='35px'
                onClick={()=>setAdult(1)}
                fontFamily={"Inter"}
                _selected={{ color: "#fff", fontWeight: "700", bg: "#005591" }}
                bg="#F5F5F5"
              >
                OUI
              </Tab>
              <Tab
                w="174px"
                h='35px'
                onClick={()=>setAdult(0)}
                rounded="8px"
                fontFamily={"Inter"}
                _selected={{ color: "#fff", fontWeight: "700", bg: "#005591" }}
                bg="#f5f5f5"
                color="#000"
              >
                NON
              </Tab>
            </TabList>
          </Tabs>
            </HStack>
          </VStack>
          <VStack
            p={4}
            fontFamily="Inter"
            alignItems="flex-start"
            w="full"
            rounded="6px"
            bg="rgba(159, 201, 242, 0.25)"
          >
            <Box>
              <Text color="#005591" fontSize={"16px"}>
                Checklist
              </Text>
            </Box>
            <Box>
              <Text fontSize={"14px"} color="#344054">
                Pour vous assurer que votre demande est traitée dans les
                meilleurs délais, Veuillez confirmer les étapes ci-dessous. Si
                cela est incorrect, nous vous demanderons de télécharger à
                nouveau les photos qui peuvent retarder l'acceptation.
              </Text>
            </Box>
            <Box px={4} fontSize={"14px"} color="#344054">
              <ul>
                <li>
                  Toutes les informations sur l'ID sont clairement lisibles.
                </li>
                <li>
                  La photo de vous tenant votre pièce d'identité a une note
                  manuscrite qui dit "for followme.fans" avec la date actuelle
                  comme dans l'exemple fourni.
                </li>
              </ul>
            </Box>
          </VStack>
          <HStack py={4}>
            <Button
              fontFamily={"Inter"}
              fontSize="14px"
              rounded="3px"
              w="277px"
              h="38px"
              colorScheme="green"
              isLoading={bool}
              onClick={()=>docSubmit()}
              bg="#34A853"
              color="#fff"
            >
              Envoyer pour validation
            </Button>
          </HStack>
        </>
      )}
      {user?.is_document_verified === 1 && <HStack borderLeft={"6px solid orange"} w='full' rounded="xl" bg="orange.200" py={4} px={4}>
        <HourglassMedium size={32} />
        <Text fontFamily={"Inter"} color="orange.900">Documents en cours de vérification.</Text>
        </HStack>}
      {user?.is_document_verified === 2 && <HStack borderLeft={"6px solid green"} w='full' rounded="xl" bg="green.200" py={4} px={4}>
        <Checks size={32} />
        <Text fontFamily={"Inter"} color="green.900">Vos documents ont été approuvé.</Text>
        </HStack>}
    </VStack>
  );
}
