/* eslint-disable jsx-a11y/alt-text */
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Text,
  Textarea,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { Checks, WarningCircle, X } from "phosphor-react";
import React, { useRef, useState } from "react";
import {
  AWS_UPLOAD_BASE_URL,
  simpleFileUpload,
  generateFileName,
  getAwsUploadParams,
  MAX_FILE_SIZE,
  multipartUploadFile,
  S3,
  S3_BUCKET,
} from "../../function/aws";
import galery from "../../img/gallery.svg";
import video from "../../img/video.svg";
import { getMyPost, newPost } from "../../server/api";
import { useMyPost, useSpeech, useUser } from "../../store/store";
import styles from "../../Styles/home.module.css";

export default function ToDoPost() {
  const postId = nanoid();
  const { user } = useUser();
  const toast = useToast();
  const { setMyPost } = useMyPost()
  const [content, setContent] = useState("");
  const [bool, setBool] = useState(false);
  const [amount, setAmount] = useState("");
  const [boolmount, setBoolmount] = useState(true);
  const { lang } = useSpeech();
  const ref = useRef<any>();
  const [progress, setProgress] = useState(0);
  const [images, setImages] = useState([]);
  const vidRef = useRef<any>();
  const [videoTitle, setVideoTitle] = useState("");
  const [videosUrls, setVideosUrls] = useState<any>([]);
  const [fileTab, setFileTab] = useState<any>([]);
  const [action, setAction] = useState(true);
  const [files, setFile] = useState<{ file: File; url: string; id: number }[]>(
    []
  );
  const [invalid, setInvalid] = useState(false);
  const [videoFiles, setVideoFiles] = useState<
    { file: File; url: string; id: number }[]
  >([]);

  const upload = async (input: any) => {
    //const resData =  multipartUploadFile(input[0],input[0].name, setProgress)
    const tabLink = [] as any
    for (let i = 0; i < input.length; i++) {
      await S3.putObject(
        {
          ACL: "public-read",
          Body: input[i],
          Bucket: S3_BUCKET as any,
          Key: `inputs/${input[i].name}`,
        },
        (err, data) => {
          if (err) {
            console.error(err);
          }
          if (data) {
            const uploadUrl = `${AWS_UPLOAD_BASE_URL}${input[i].name}`;
            tabLink.push(uploadUrl)
          }
        }
      )
    }
    setImages(tabLink as any);
    setFileTab(input);
    if (input.length > 0) {
      setAction(false);
      setBoolmount(false);
    }
    // setImage_files(input);
    let id = files.length ? files.length - 1 : 0;
    const items: { file: File; url: string; id: number }[] = [];
    for (const file of input) {
      items.push({
        file,
        url: URL.createObjectURL(file),
        id,
      });

      ++id;
    }

    setFile([files, items].flat());

  };

  const uploadVideo = (input: any) => {
    setVideoTitle(input[0].name);
    if (videoFiles.length === 0) {
      setFileTab(input);
      let file = input[0];
      if (input.length > 0) {

        setBoolmount(false);
      }
      let name = generateFileName(input[0].name);
      if (file.size < MAX_FILE_SIZE) {
        S3.putObject(
          {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET as any,
            Key: `inputs/${name}`,
          },
          (err, data) => {
            if (err) {
              console.error(err);
            }
            if (data) {
              console.log("data", data);
              const uploadUrl = `${AWS_UPLOAD_BASE_URL}${name}`;
              console.log("uploadUrl", uploadUrl);
              setVideosUrls((urls: any) => [/* ...urls,  */ name]);
            }
          }
        ).on("httpUploadProgress", (evt) => {
          const _progress = Math.round((evt.loaded / evt.total) * 100);
          //console.log("progress", _progress)
          setProgress(_progress);
          console.log(_progress);
          if (_progress === 100) {
            setAction(false);
            toast({
              title: "Telechargement terminé",
              status: "success",
              position: "top",
              isClosable: true,
              duration: 3000,
            });
          }
        });
      } else {
        const fileName = generateFileName(file.name);
        multipartUploadFile(file, fileName, setProgress);
        const uploadUrl = `${AWS_UPLOAD_BASE_URL}${fileName}`;
        setVideosUrls((urls: any) => [fileName]);
      }

      let id = videoFiles.length ? videoFiles.length - 1 : 0;
      const items: { file: File; url: string; id: number }[] = [];
      for (const file of input) {
        items.push({
          file,
          url: URL.createObjectURL(file),
          id,
        });
        ++id;
      }
      setVideoFiles([videoFiles, items].flat());
    } else {
      console.log("error");
    }
  };
  const removeImageItem = React.useCallback(
    (id: number) => {
      setFileTab([]);
      setFile(files.filter((item) => item.id !== id));
    },
    [files]
  );
  const removeVideoItem = React.useCallback(
    (id: number) => {
      setFileTab([]);
      setVideoFiles(files.filter((item) => item.id !== id));
      setVideoFiles([]);
    },
    [files]
  );

  const post = React.useCallback(async () => {
    setBool(true);
    const videos =
      videosUrls.length > 0
        ? videosUrls.length > 1
          ? videosUrls.join(";")
          : videosUrls[0]
        : "";
    const videos_preview = {};

    /*  if (videosPreview.length > 0) {
        videosPreview.forEach((p, i) => {
          if (p) {
            const url = videosUrls[i];
            const fileName = url.split("/")[url.split("/").length - 1];
            const key = `video-preview${i}`;
            videos_preview[key] = blobToFile(p, fileName);
          }
          //return
        });
      } */
    newPost(
      content ? content : "",
      amount ? amount : "",
      images ? images : "",
      postId,
      videos ? videos : "",
      videos_preview ? videos_preview : ""
    )
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          setBool(false);
          toast({
            position: "top",
            render: () => (
              <HStack color="white" rounded="lg" p={3} bg="green.500">
                <Checks />
                <Text fontFamily={"Inter"}>{res?.data?.message}</Text>
              </HStack>
            ),
          });

          setTimeout(async () => {
            setFile([]);
            setContent("");
            setAmount("");
            setBoolmount(true)
            window.location.assign(`/home/post/${res?.data?.data?.post_unique_id}`);
            const { data } = await getMyPost(0)
            setMyPost(data?.data?.posts)
          }, 2000);
        } else {
          setBool(false);
          toast({
            title: `${res?.data?.message}`,
            status: "error",
            position: "top",
            isClosable: true,
            duration: 3000,
          });
        }
      })
      .catch((error) => {
        setBool(false);
        setFile([]);
        setInvalid(true);
        setTimeout(() => {
          setInvalid(false);
        }, 8000);
      });
  }, [amount, content, images, postId, setMyPost, toast, videosUrls]);

  return (
    <VStack w="full">
      <VStack w="full" px={4}>
        <HStack w="full" display={["flex", "flex", "none", "none", "none"]}>
          <HStack>
            <Avatar h="41px" w="41px" src={user?.picture} />
            <Box>
              <Text fontSize={"14px"} fontFamily={"Inter-extra"}>
                {user?.name}
              </Text>
            </Box>
          </HStack>
        </HStack>
        <FormControl isInvalid={invalid}>
          <Textarea
            p={0}
            fontFamily={"Inter"}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder={lang?.post_input}
            className={styles.inputs}
          />
          <FormErrorMessage fontFamily={"Inter"}>
            Fichiers trop volumineux, veuillez diminuer la taille.
          </FormErrorMessage>
        </FormControl>
        <Divider />
        <FormControl hidden={boolmount}>
          <FormLabel>Prix (Optionnel)</FormLabel>
          <InputGroup>
            <Input
              type="number"
              value={amount}
              inputMode="numeric"
              defaultValue={0}
              onChange={(e: any) => setAmount(e.target.value)}
              placeholder="Entrez un montant"
            />
            <InputRightElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
              children="€"
            />
          </InputGroup>
        </FormControl>
        <HStack w="full" justifyContent={"space-between"}>
          <HStack>
            <Box
              cursor={"pointer"}
              onClick={() => {
                ref?.current?.click() as any;
              }}
            >
              <img height={"24px"} width="24px" src={galery} />
              <Input
                type="file"
                multiple
                accept=".gif,.jpg,.jpeg,.gif,.png,.jpg,.jpeg,.png"
                ref={ref as any}
                hidden
                onChange={(e) => {
                  upload(e.target.files);
                }}
              />
            </Box>
            <Box
              cursor={"pointer"}
              onClick={() => {
                vidRef?.current?.click() as any;
              }}
            >
              <img height={"24px"} width="24px" src={video} />
              <Input
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                multiple
                ref={vidRef as any}
                hidden
                onChange={(e) => {
                  uploadVideo(e.target.files);
                }}
              />
            </Box>
          </HStack>
          <Button
            bg="#005591"
            colorScheme="blue"
            onClick={() => post()}
            isDisabled={action}
            w="117px"
            isLoading={bool}
            h="34px"
            fontSize={"16px"}
            rounded="full"
            color="#fff"
          >
            {lang?.post_btn}
          </Button>
        </HStack>
        <HStack w="full" justifyContent={"flex-start"}>
          <WarningCircle color="red" />
          <Text color="grey" fontSize={"10px"}>
            Photo 10mo max, vidéo 2go max
          </Text>
        </HStack>
      </VStack>
      <Grid w="full" p={0} templateColumns="repeat(1, 1fr)" gap={1}>
        {videoFiles.map((data: any, i: any) => {
          return (
            <GridItem w="100%" key={i} rounded="xl" p={2} position="relative">
              <HStack
                w="full"
                justifyContent={"space-between"}
                p={2}
                rounded="xl"
                bg="rgba(159, 201, 242, 0.25)"
              >
                <HStack>
                  <video
                    src={data?.url}
                    style={{ height: "50px", width: "50px" }}
                  />
                  <Box>
                    <Text fontFamily="Inter" color="#005591" fontSize="10px">
                      {videoTitle}
                    </Text>
                  </Box>
                </HStack>
                {progress === 100 ? (
                  <Checks size={24} color="green" />
                ) : (
                  <CircularProgress
                    size="50px"
                    value={progress}
                    color="#005591"
                  >
                    <CircularProgressLabel>{progress}%</CircularProgressLabel>
                  </CircularProgress>
                )}

                <Box
                  cursor={"pointer"}
                  onClick={() => removeVideoItem(data.id)}
                  bg="#005591"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="24px"
                  w="24px"
                  rounded="full"
                >
                  <X size={18} color="#fff" />
                </Box>
              </HStack>
            </GridItem>
          );
        })}
      </Grid>

      <Grid w="full" p={0} templateColumns="repeat(3, 1fr)" gap={1}>
        {files.map((data: any, i: any) => {
          return (
            <GridItem w="100%" key={i} rounded="xl" p={2} position="relative">
              <img src={data?.url} height="200px" width="200px" />
              <Box
                cursor={"pointer"}
                onClick={() => removeImageItem(data.id)}
                position="absolute"
                top="0"
                right="0"
                bg="#005591"
                display="flex"
                alignItems="center"
                justifyContent="center"
                h="24px"
                w="24px"
                rounded="full"
              >
                <X size={18} color="#fff" />
              </Box>
            </GridItem>
          );
        })}
      </Grid>
    </VStack>
  );
}
