/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import React from "react";
import {
  HStack,
  Text,
  Input,
  VStack,
  useBreakpointValue,
  useToast,
  FormLabel,
  Textarea,
  Button,
} from "@chakra-ui/react";
import pass from "../../img/passwordenter.svg";
import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Box,
} from "@chakra-ui/react";
import { useCards } from "../../store/store";
import { ChatAssetsPayment } from "../../server/api";

export default function ImageLocked({ id, info, amount }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const msgW = useBreakpointValue({ base: "182px", lg: "247px" });
  const tab = [info]
  const { cards } = useCards()
  const toast = useToast();
const [load, setLoad] = React.useState(false)


  const handleAssetPayment = React.useCallback(
    async(id: any) => {
      onOpen()
    },
    [onOpen, tab],
  )

const handleGoPayment =  React.useCallback(
    async(id:any) => {
     setLoad(true)
     const asset = tab.reduce((post: any) => post.chat_message_id === id)
      const { data } = await ChatAssetsPayment(asset?.chat_message_id, asset?.amount, asset?.from_user_id, asset?.to_user_id)
      console.log(data);
      if (data.success) {
        console.log(data?.data?.url_redirect)
      window.location.assign(`${data?.data?.url_redirect}`)
        setLoad(false)
      }
      if (!data.success) {
        setLoad(false)
        toast({
          description: `${data.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        }); }
    },
    [],
  )


  return (
    <VStack
      w={msgW}
      alignItems={"center"}
      justifyContent="center"
      rounded="12px"
      bg="rgba(159, 201, 242, 0.25)"
      minH="300px"
    >
      <Box bg="#fff" p={8} rounded="8px">
        <img src={pass} height="50px" width="80px" />
      </Box>
      <Button
        fontFamily={"Inter"}
        bg="#005591"
        onClick={handleAssetPayment}
        colorScheme="blue"
        size="sm"
        color="#fff"
      >
        Débloquer pour {amount}€
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded="15.23px" w="357px">
          <ModalHeader color="#344054" fontFamily={"Inter"} fontSize="15.23px">
            Payer et voir le Post
          </ModalHeader>
          <Divider />
          <ModalCloseButton border="2px solid #000" p={0} rounded="full" />
          <ModalBody>
            <HStack w="full" spacing={4}>
              <Text fontFamily={"Inter"}>Montant payé:</Text>
              <Text fontFamily={"Inter"} fontWeight="bold" color="#005591">{amount} €</Text>
            </HStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              size="sm"
              fontSize="14px"
              fontFamily={"Inter"}
              colorScheme="red"
              bg="grey"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              size="sm"
              colorScheme="green"
              fontSize="14px"
              fontFamily={"Inter"}
              onClick={() => handleGoPayment(id)}
              isLoading={load}
              bg="#34A853"
              color="#fff"
            >
              Confirmer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
