/* eslint-disable jsx-a11y/alt-text */
import { VStack, HStack, Box, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import {favSaveOption } from "../../server/api";
import stars from "../../img/stars.svg";
import starFav from "../../img/starFav.svg";
import { usePost } from "../../store/store";

export default function ProfilBookmarks({ user }:any) {
  const toast = useToast();
  const [mention, setMention] = useState(false);

  React.useEffect(() => {
    setMention(user?.is_favuser === 1 ? true : false);
  }, [user?.is_favuser]);

  const liked = useCallback(
    async (id: any) => {
      await favSaveOption(user?.user?.user_id);
    },
    [user?.user?.user_id]
  );
  return (
    <VStack>
      <HStack spacing={2} alignItems="center" justifyContent={"center"}>
        <Box cursor={"pointer"}>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              setMention(!mention);
              liked(!mention ? "liker" : "dislike");
            }}
            src={mention === true ? starFav : stars}
          />
        </Box>
      </HStack>
    </VStack>
  );
}
