import {
  HStack,
  Button,
  Text,
  VStack,
  FormLabel,
  Input,
  Box,
  useDisclosure,
  FormControl,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  FormErrorMessage,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { validateBankAccount } from "../function/bankFormControl";
import { AddBillingAccount } from "../server/api";

export default function AddBankAccount() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()
  const [bool, setBool] = useState(false)
  const [billing, setBilling] = useState({
    name: "",
    bic: "",
    iban: "",
  });
  const [errors, setErrors] = useState<any>({});
 
  const onSubmit = useCallback(async() => {
    setBool(true)
    const { valid, errors: validationErrors } = validateBankAccount(billing);
    if (!valid) {
      setErrors(validationErrors);
       setBool(false)
    }
    else{
      try{
        await AddBillingAccount(billing.name,billing.bic,billing.iban).then((res)=>{
          if(res?.data?.success){
            toast({
              title: "Compte ajouté.",
              description: `${res.data.message}`,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
          
        })
      }
      catch(error){
        console.log(error);
        setBool(false)
      }
    }
  }, [billing, toast]);

  
  return (
    <HStack w="full" justifyContent={"center"}>
      <HStack w="full" justifyContent={"center"}>
        {" "}
        <Button
          onClick={onOpen}
          colorScheme="blue"
          fontFamily={"Inter"}
          fontSize="13.33px"
          variant={"outline"}
        >
          Ajouter votre compte en banque
        </Button>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          boxShadow={"0px -7.61702px 30.4681px rgba(70, 96, 135, 0.1)"}
          w="350px"
          minH="400px"
          rounded="15px"
        >
          <ModalHeader
            borderBottom={"0.952128px solid #E8E6EA"}
            fontSize={"15px"}
            fontFamily={"Inter"}
          >
            Ajouter votre compte en banque
          </ModalHeader>
          <ModalCloseButton p={0} rounded="full" border="1px solid #344054" />
          <ModalBody>
            <VStack
              w="full"
              spacing={4}
              h="full"
              alignItems={"center"}
              justifyContent="center"
            >
              <FormControl w="full" isInvalid={errors?.name}>
                <Input
                  fontFamily={"Inter"}
                  value={billing.name}
                  onChange={(e: any) =>
                    setBilling({ ...billing, name: e.target.value })
                  }
                  placeholder={"Nom du compte"}
                  h="52px"
                />
               {errors?.name && <FormErrorMessage fontSize="12px">{errors?.name}</FormErrorMessage>}
              </FormControl>
              <FormControl w="full" isInvalid={errors?.bic}>
                <Input
                  fontFamily={"Inter"}
                  value={billing.bic}
                  minLength={8}
                  onChange={(e: any) =>
                    setBilling({ ...billing, bic: e.target.value })
                  }
                  placeholder={"BIC"}
                  h="52px"
                />
                {errors?.bic && <FormErrorMessage fontSize="12px">{errors.bic}</FormErrorMessage>}
              </FormControl>
              <FormControl w="full" isInvalid={errors.iban}>
                <Input
                  fontFamily={"Inter"}
                  value={billing.iban}
                  minLength={14}
                  onChange={(e: any) =>
                    setBilling({ ...billing, iban: e.target.value })
                  }
                  placeholder={"Numéro IBAN"}
                  h="52px"
                />
               {errors?.iban && <FormErrorMessage fontSize="12px">{errors.iban}</FormErrorMessage>}
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter borderTop={"0.952128px solid #E8E6EA"}>
            <Button
              bg="grey"
              colorScheme="red"
              fontSize={"14px"}
              fontFamily={"Inter"}
              w="125px"
              h="52px"
              rounded="3px"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              bg="#34A853"
              colorScheme="green"
              fontSize={"14px"}
              fontFamily={"Inter"}
              rounded="3px"
              isLoading={bool}
              onClick={onSubmit}
              h="52px"
              w="125px"
              color="#fff"
            >
              Ajouter
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
}
