import { VStack,Text } from '@chakra-ui/react';
import React from 'react';
import styles from "../Styles/home.module.css"

export default function UnknownMessage() {
  return (
    <VStack w='full' h='full' alignItems={"center"} justifyContent="center">
       <VStack>
        <Text fontSize={"32px"} className={styles.fontS} fontFamily={"Inter-extra"}>
          Sélectionnez un message
        </Text>
        <Text fontSize="16px" className={styles.fontSm} fontFamily="Inter">
          Faites un choix dans vos conversations existantes,
        </Text>
      </VStack>
    </VStack>
  );
}
