/* eslint-disable jsx-a11y/alt-text */
import {
    Box,
    HStack,
    VStack,
    Text,
    FormControl,
    Input,
    Button,
    InputGroup,
    InputLeftElement,
    useBreakpointValue,
    useToast,
  } from "@chakra-ui/react";
  import Logo from "../img/folme.svg";
  import fm from "../img/logo.svg";
  import React,{useState} from "react";
  import { Link } from "react-router-dom";
import { MailXender } from "../server/api";

  
  export default function NewPassword() {
    const toast = useToast()
    const position = useBreakpointValue<any>({
      base: "bottom",
      lg: "bottom-right",
    });
    const [start, setStart] = useState(false)
    const device = useBreakpointValue({ base: true, lg: false });
    const width = useBreakpointValue({ base: "100%", lg: "50%" });
    const [password, setPassword] = useState<string>("")
    const [confirm, setConfirm] = useState<string>("")
    const widthContain = useBreakpointValue({ base: "100%", lg: "70%" });
    const Tpix = useBreakpointValue({ base: "24px", lg: "32px" });
/*
const mailXender = React.useCallback(
  () => {
    setStart(true)
    setTimeout(() => {
      MailXender(email).then((res)=>{
        setStart(false)
        if(res.data.success){
          localStorage.setItem("mymail",email)
          toast({
            title: "Mot de passe changé.",
            status: "success",
            position: `${position}` as any,
            duration: 3000,
            isClosable: true,
          });
          setTimeout(()=>{
            window.location.assign("/verify")
          },3000)
        }
        else{
          toast({
            title: "Echec de validation.",
            description: "Veuillez verifier le nombre de caractere !",
            status: "error",
            position: `${position}` as any,
            duration: 3000,
            isClosable: true,
          });
        }
      })
    }, 1000);
  
  },
  [email, position, toast],
) */
    return (
      <HStack
        w="full"
        minH="100vh"
        bgGradient={"linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 53.54%)"}
        spacing={0}
      >
        {!device && (
          <VStack
            w="50%"
            minH="100vh"
            bg="#005591"
            alignItems={"center"}
            justifyContent="space-around"
          >
            <Box cursor={"pointer"}>
              <img src={Logo} />
            </Box>
          </VStack>
        )}
        <VStack
          w={width}
          minH="100vh"
          bg="#f5f5f5"
          alignItems={"center"}
          bgGradient={
            device
              ? ["linear(to-tr, #fff, blue.100)", "linear(to-t, blue.400, #fff)"]
              : "#f5f5f5"
          }
          justifyContent="center"
        >
          {device && (
            <Box
              display="flex"
              mt={8}
              alignItems={"center"}
              justifyContent="center"
              w="full"
            >
              <img src={fm} />
            </Box>
          )}
          <Box py={8}>
            <Text
              fontWeight={"900"}
              color="#005591"
              fontSize={Tpix}
              fontFamily={"Inter"}
            >
             Nouveau mot de passe
            </Text>
          </Box>
          <VStack w={widthContain} h='full' px={8} textAlign="center" spacing={8}>
              <Box>
                  <Text fontFamily={"Inter"}>Entrez votre nouveau mot de passe puis confirmez pour la réinitialisation</Text>
              </Box>
            <VStack w="full" spacing={4}>
             
             
              <Input
                fontFamily={"Inter"}
                type="password"
                bg="#fff"
                isRequired
                value={password}
                onChange={(e)=>{setPassword(e.target.value)}}
                py={6}
                border="1px solid #E8E6EA"
                placeholder="Nouveau mot de passe"
              />
               <Input
                fontFamily={"Inter"}
                type="password"
                bg="#fff"
                isRequired
                value={confirm}
                onChange={(e)=>{setConfirm(e.target.value)}}
                py={6}
                border="1px solid #E8E6EA"
                placeholder="Confirmez le nouveau mot de passe"
              />
             
            </VStack>
            
            <VStack spacing={4} w="full">
              <Button
                colorScheme="blue"
                py={6}
                isLoading={start}
                disabled={password === confirm ? false : true}
                bg="#005591"
                w="full"
                color="#ffffff"
              >
               Réinitialiser le mot de passe
              </Button>
            </VStack>
          </VStack>
         
        </VStack>
      </HStack>
    );
  }
  