/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import SocialLogin from 'react-social-login';


class SocialButton extends React.Component {

    render() {
        return (
            <>
                <button onClick={this.props.triggerLogin} {...this.props}>
                    <span style={{'color': '#105B94', 'position': 'relative'}}>{ this.props.children }</span>
                </button>
            </>
        );
    }
}

export default SocialLogin(SocialButton);