import { HStack, VStack, Box, Text, Divider } from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import moment from "moment";
import { CaretCircleDown } from "phosphor-react";
import React from "react";
import { useWalletsHistory } from "../store/store";

export default function MyTransactions() {
  const {walletsHistory} = useWalletsHistory()
  return (
    <VStack w="full" p={2} bg="#DDE3F2" minH={"80vh"}>
{walletsHistory?.history.length ===0 && <HStack borderLeft={"6px solid #eeeeee"} fontFamily={"Inter"} p={4} rounded="xl" bg="#fff" w='full'>
    <Text>aucune transaction disponible.</Text>
  </HStack>}
     {walletsHistory?.history.map((data:any,i:any)=>{
       let date =  new Date(data?.created_at)
      
       return  <Accordion  key={i} w="full" rounded="lg" bg="#fff" allowToggle>
       <AccordionItem>
         <h2>
           <AccordionButton rounded="lg" w='full' bg="#fff">
           <VStack w="full" spacing={0} fontFamily={"Inter"} rounded="lg" p={2} bg="#fff">
       <HStack w="full" justifyContent={"space-between"}>
         <Text color="#344054" fontWeight={"800"}>
          {data?.message_custom}
         </Text>
         <HStack color="#005591">
           <Text>{data?.paid_amount_formatted}</Text>
         </HStack>
       </HStack>
       <HStack w="full" color="#005591" justifyContent={"space-between"}>
         <HStack fontSize={"12px"} opacity="0.5">
           <Text color="#344054" fontWeight={"800"}>
             {data?.paid_date}
           </Text>
           <Text color="#344054" fontWeight={"800"}>
             {date?.getHours()}:{date?.getMinutes()}
           </Text>
         </HStack>
         <HStack>
           <Text color="#000">{data?.requested_amount_formatted}</Text>
         </HStack>
       </HStack>
     </VStack>
             <AccordionIcon border="2px solid #005591" rounded="full" />
           </AccordionButton>
         </h2>
         <AccordionPanel pb={4}>
          <Divider />
          <HStack>
            <Box>
              <Text color="#344054" fontStyle={"italic"} fontSize="10px" opacity={"0.5"}>{data?.status_formatted} {data?.send_to_username}</Text>
            </Box>
            <Box>
              <Text color="#344054" fontStyle={"italic"} fontSize="10px" opacity={"0.5"}>{data?.payment_id}</Text>
            </Box>
          </HStack>
         </AccordionPanel>
       </AccordionItem>
     </Accordion>
     })}
    </VStack>
  );
}
