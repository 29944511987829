export const french = {
    m_profil: "Mon profil",
    m_book: "Bookmarks",
    m_list: "Listes",
    m_param: "Paramètres",
    m_card: "Vos cartes",
    m_wallet: "Portefeuille",
    m_aboutus: "Aide et support / A propos",
    m_logout: "Se déconnecter",
    home_btn: "Accueil",
    suggesTitle: "Recommandations",
    searchInput: "Rechercher un créateur",
    post_btn: "Publier",
    post_input: "Ecrivez une nouvelle publication",
    send_kit: "Envoyer un cadeau",
    read_more: "lire plus",
    read_less: "lire moins",
    logout_title: "Déconnexion du profil",
    logout_msg: "Voulez-vous vraiment vous déconnecter ?",
    yes: "oui",
    no: "non",
    no_user: "aucun utilisateur trouvé",
    download_doc: "Télécharger vos documents",
    msg: "Messages",
    titleI1: "Transforme tes followers en abonnés",
    titleI2: "Fais de tes contenus des revenus",
    passInput: "Votre mot de passe",
    loginText: "Connectez-vous",
    SingupText: "Inscrivez-vous",
    passForgot: "Mot de passe oublié ?",
    fbLogin: "SE CONNECTER AVEC FACEBOOK",
    fbSignup: "S'INSCRIRE AVEC FACEBOOK",
    loginBtn: "Se connecter",
    signupBtn: "Sinscrire",
    ou: "Ou",
    notAccount: "Vous n'avez pas encore de compte ?",
    yesAccount: "Avez-vous un compte ?",
    signFollow: "S'inscrire sur Followme",
    LoginFollow: "Connectez-vous pour Followme",
    aboutLink: "À propos / FAQ",
    termsText: "Politique de confidentialité",
    namePut: "Nom & Prenoms",
    nameUser: "Nom d'utilisateur",
    adressPut: "Adresse e-mail",
    passPut: "Mot de passe",
    numError: "Au moins 08 caractères requis !",
    terms1: "En vous inscrivant, vous acceptez",
    terms2: "Nos conditions d'utilisation",
    coo1: "Nous utilisons des cookies pour vous offrir une meilleure expérience. Notre",
    coo2: "politique de confidentialité.",
    coo3: "Accepter",
    edit_btn: "Editer le profil",
    view_dat: "Vu le",
    copy_link: "Copier le lien",
    edit_post: "Modifier",
    delete_post: "Supprimer le post",
    pub: "Publications",
    like: "J'aime",
    pay_fail:"Paiement échoué",
    pay_wait:"Paiement en attente",
    pay_success:"Paiement réussi",
    pay_suc_text:"Votre paiement a été validé",
    pay_wait_text:"Votre paiement est en attente.",
    pay_fail_text:"Votre paiement n’a pas pu aboutir.",
    home_return:"RETOUR A L'ACCUEIL"

}