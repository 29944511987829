/* eslint-disable jsx-a11y/alt-text */
import { Box, VStack,Button, Text,Input, HStack,useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import pass from "../img/password.svg";
import styles from "../Styles/home.module.css";

export default function DeleteAccount() {
    const putWidth = useBreakpointValue({ base: "80%", lg: "355px" });
  return (
    <VStack w="full">
      <VStack alignItems={"flex-start"} p={8}>
        <Box>
          <Text fontFamily={"Inter"}>Nous espérons vous revoir bientôt</Text>
        </Box>
        <HStack w="full" bg="rgba(159, 201, 242, 0.25)" rounded="6px" p={4}> 
          <Text fontFamily={"Inter"}>
            <span style={{fontWeight:"900"}}>Remarque :</span> Une fois votre compte supprimé, vous perdez votre
            historique et les détails de facturation
          </Text>
        </HStack>
      </VStack>
      <VStack w='full' spacing={16}>
      <HStack
            w={putWidth}
            h="56px"
            spacing={0}
            alignItems={"center"}
            justifyContent={"center"}
            rounded="4px"
            bg="#ffffff"
            border="1px solid #dddddd"
          >
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              w="50px"
              h="full"
            >
              <img src={pass} />
            </Box>
            <Input
              fontFamily={"Inter"}
              type="password"
              className={styles.inputs}
              py={6}
              
              rounded="none"
              border="none"
              placeholder="Votre mot de passe"
            />
          </HStack>

          <Box>
              <Button w='271px' rounded="3.80px" bg="#005591" color="#fff" fontFamily={"Inter"} h='38px'>Supprimer le compte</Button>
          </Box>
      </VStack>
    </VStack>
  );
}
