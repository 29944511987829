import React,{useCallback} from "react";
import {
  Tabs,
  TabList,
  Text,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
} from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import SubscriptionPrice from "./SubscriptionPrice";
import { WarningCircle } from "phosphor-react";
import { useUser } from "../store/store";
import { Link } from "react-router-dom";

export default function AccountType({callback} :any) {
  const profile = useUser().user;

  return (
    <VStack w="full" fontFamily={"Inter"} py={8}>
      <HStack w="full" justifyContent={"flex-start"}>
        <Text color="#A5A8AC">TYPE DE COMPTE</Text>
      </HStack>
      {profile?.is_document_verified !== 2 && <HStack justifyContent={"flex-start"} w='full'><Link to="/home/documents"><Text color="#005591">Vous devez valider votre document</Text></Link></HStack>}
      {profile?.is_document_verified === 2 && (
        <>
          <HStack w="full" justifyContent={"flex-start"}>
            <WarningCircle color="red" />
            <Text color="red" fontSize={"10px"}>
              Attention après le premier abonné le type de compte ne peut plus
              être changé
            </Text>
          </HStack>
          <Tabs w="full" p={0} variant="unstyled">
            <TabList p={2}>
              <Tab
                w="full"
                rounded="8px"
                mr={4}
                fontFamily={"Inter"}
                _selected={{ color: "#fff", fontWeight: "700", bg: "#005591" }}
                h="50px"
                bg="#F5F5F5"
              >
                GRATUIT
              </Tab>
              <Tab
                w="full"
                rounded="8px"
                fontFamily={"Inter"}
                isDisabled={profile?.tipped_amount==="0.00 €" ? false : true}
                _selected={{ color: "#fff", fontWeight: "700", bg: "#005591" }}
                bg="#f5f5f5"
                color="#000"
              >
                PAYANT
              </Tab>
            </TabList>
            <TabPanels w="full" style={{ padding: 0 }}>
              <TabPanel w="full">
                <VStack w="full" textAlign={"center"} py={4}>
                  <Text color="#A5A8AC" fontSize={"10px"}>
                    Vous pouvez changer à tout moment
                  </Text>

                  <Text color="#005591" fontSize={"14px"}>
                    Information sur le compte bancaire ou le paiement
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <SubscriptionPrice callback={callback}  />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </VStack>
  );
}
