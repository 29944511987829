import { VStack,Text } from '@chakra-ui/react';
import React,{useState,PropsWithChildren} from 'react'
import { Outlet,Navigate, useLocation } from 'react-router-dom'
type Props = PropsWithChildren<{}>;

export default function Protector({children} : Props) {
  const [mustShowHeader, setMustShowHeader] = React.useState(false);
  const location = useLocation().pathname
  React.useEffect(() => {
    let blacklist = [
      "/home",
      "/home/",
      "/chat",
      "/home/chat",
      "/home/chat/",
      "/home/profil",
      "/home/profil/",
      "/home/list",
      "/home/list/",
      "/home/documents",
      "/home/documents/",
      "/home/cards",
      "/home/cards/",
      "/home/settings",
      "/home/settings/",
      "/home/wallet",
      "/home/wallet/",
      "/home/notification",
      "/home/notification/"
    ];
    if (blacklist.includes(location)) {
      setMustShowHeader(true);
    }
  }, [location]);
  return (

        localStorage.getItem("f-token") ? <Outlet /> : <Navigate to="/" />

  )
}
