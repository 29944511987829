export const english ={
    m_profil:"My profile",
    m_book:"Bookmarks",
    m_list:"Lists",
    m_param:"Settings",
    m_card:"Your cards",
    m_wallet:"Wallet",
    m_aboutus:"Help & Support / About",
    m_logout:"Sign out",
    home_btn:"Home",
    suggesTitle:"SUGGESTIONS",
    searchInput:"Search Anything",
    post_btn:"POST",
    post_input:"Compose new post...",
    send_kit:"Send Tip",
    read_more:"read more",
    read_less:"read less",
    logout_title:"Profile Logout",
    logout_msg:"Do you really want to disconnect ?",
    yes:"yes",
    no:"no",
    no_user:"no users found",
    download_doc:"Download your documents",
    msg:"Posts",
    titleI1: "Turn your followers into subscribers",
    titleI2: "Make your contents into revenue",
    passInput:"Your password",
    loginText:"Login",
    SingupText:"Sign up",
    passForgot:"Forgot your password ?",
    fbLogin:"LOGIN WITH FACEBOOK",
    fbSignup:"SIGN UP WITH FACEBOOK",
    loginBtn:"LOG IN",
    signupBtn:"SIGN UP",
    ou:"Or",
    notAccount:"Don't have an account yet?",
    yesAccount:"Already have an account?",
    signFollow:"Sign up for FollowMe",
    LoginFollow:"Login for FollowMe",
    aboutLink:"ABOUT / FAQ",
   termsText:"Privacy policy",
   namePut:"Lastname & firstname",
   nameUser:"username",
   adressPut:"E-mail address",
   passPut:"Password",
   numError:"At least 08 characters required !",
   terms1:"By signing up you agree to our",
   terms2:"Terms of service",
   coo1:"We use cookies to provide you a better experience.  Our",
   coo2:"privacy policy.",
   coo3:"Accept",
   edit_btn: "Edit profile",
    view_dat: "Seen the",
    copy_link: "Copy link",
    edit_post: "Edit",
    delete_post: "Delete post",
    pub: "POST",
    like: "Likes",
    pay_fail:"Payment failed",
    pay_wait:"Payment pending",
    pay_success:"Payment Success",
    pay_suc_text:"Your payment has been validated",
    pay_wait_text:"Your payment is pending.",
    pay_fail_text:"Your payment could not be completed.",
    home_return:"GO TO HOME"
}