/* eslint-disable jsx-a11y/alt-text */
import {
  Avatar,
  HStack,
  Input,
  Text,
  useBreakpointValue,
  VStack,
  Box,
} from "@chakra-ui/react";
import React, { useEffect,useState } from "react";
import search from "../../img/search.svg";
import send from "../../img/msgsend.svg";
import { useNavigate, useParams } from "react-router";
import styles from "../../Styles/home.module.css";
import { useChatUserList, useInbox } from "../../store/store";
import useEchoListener from "../../hooks/useEchoListener";
import { IMessage } from "../../interface/types";
import { getChatUser } from "../../server/api";

export default function Messages() {
  const [mask, setMask] = useState(false)
  const width = useBreakpointValue({ base: "90%", lg: "95%" });
  const navigate = useNavigate();
  const { chatList, setChatList } = useChatUserList();
  const { setActiveTchat } = useInbox();

  const onNewMessage = (message: IMessage) => {
    if (message) {
      getChatUser().then((res) => {
        setChatList(res?.data?.data);
      });
      //console.log("chatList", chatList);
 /*   const { to_user_id, from_user_id } = message;
      //get others thread
     const _users = chatList.users;
      //console.log("_users", _users);
      const othersChatList = _users.filter(
        (t: any) => t.to_user_id != to_user_id && t.to_user_id != from_user_id
      );
      
      //get active thread
      let thread = _users.find(
        (t: any) => t.to_user_id == to_user_id || t.to_user_id == from_user_id
      ) as any;
      const last_message =
        message.message.length > 0 ? message.message : "Media";
      thread.last_message = last_message;
      thread.last_date = message.created_at;
      //console.log(thread, othersChatList);
      const _chatList = [thread, ...othersChatList];
      console.log(_chatList);
 let tabList = [...chatList, _chatList]
    setChatList(tabList); 
      //await getNotificationCount() */
    } 
  };

 useEchoListener(
    "user." + localStorage.getItem("userId"),
    "MessageChatEvent",
    onNewMessage
  ); 
 const onRead = React.useCallback(
    () => {
      setMask(true)
      getChatUser().then((res) => {
        setChatList(res?.data?.data);
      });
    },
    [setChatList],
  )

  return (
    <VStack w="full" borderRight={"1px solid #eee"}>
     
        <HStack display={["none","none","flex","flex"]} px={4} w="full" h="54px" border="1px solid #F1F2F6">
          <Text fontFamily={"Inter-extra"} color="#005591" fontSize="20px">
            Messages
          </Text>
        </HStack>
 
        <HStack
          w="95%"
          h="38px"
          display={["none","none","flex","flex"]}
          px={4}
          py={2}
          rounded="69px"
          bg="rgba(101, 103, 106, 0.05)"
        >
          <img src={search} />
          <Input
            border="none"
            className={styles.inputs}
            bg="rgba(101, 103, 106, 0.01)"
            fontSize={"14px"}
            color="#65676A"
            placeholder="Rechercher une conversation"
          />
        </HStack>

      <VStack w="full" spacing={2} py={4}>
        {chatList?.users?.map((data: any, i: any) => {
          let date = new Date(data?.last_date);
          return (
            <HStack
              key={i}
              cursor={"pointer"}
              onClick={() => {
                setActiveTchat(data);
                onRead()
                navigate(`/chat/${data.to_user_id}`);
              }}
              px={2}
              justifyContent="space-between"
              w={width}
              rounded="8px"
              h="64px"
            >
              <HStack>
                <Box position={"relative"}>
                  <Avatar
                    name={data?.to_displayname}
                    src={data?.to_userpicture}
                    h="48px"
                    w="48px"
                  />
                  <Box
                    position={"absolute"}
                    zIndex="10"
                    hidden
                    bottom="0"
                    right={"1"}
                    w="12px"
                    h="12px"
                    rounded="full"
                    bg="#4CE417"
                  ></Box>
                </Box>
                <VStack spacing={0} alignItems={"flex-start"}>
                  <Text
                    fontSize={"14px"}
                    textTransform={"uppercase"}
                    fontWeight="500"
                    color="#005591"
                    fontFamily="Inter"
                  >
                    {data?.to_displayname}
                  </Text>
                  <Text fontSize={"12px"} color="#4F5E7B" fontFamily="Inter">
                    {data?.last_message !== null
                      ? data?.last_message
                      : "Démarrer la conversation"}
                  </Text>
                </VStack>
              </HStack>
              <VStack>
                <Text
                  fontSize={"12px"}
                  fontWeight="300"
                  color="#4F5E7B"
                  fontFamily="Inter"
                >
                  {date?.getHours()}:{date?.getMinutes()}
                </Text>
                {data?.last_is_read === 0 && data?.nb > 0 && (
                  <Box
                    rounded="full"
                    hidden={mask}
                    bg="#005591"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="#fff"
                    h="24px"
                    w="24px"
                  >
                    <Text
                      fontSize={"12px"}
                      color="#fff"
                      fontFamily="Inter-extra"
                    >
                      {data?.nb}
                    </Text>
                  </Box>
                )}
              </VStack>
            </HStack>
          );
        })}
      </VStack>
      
    </VStack>
  );
}
