import { api } from "./url"
import axios from "axios"
import qs from "qs";

const lang = "fr" || "en"
const login_by = "manual"
const device_type = "web" || "mobile"
const device_token = "123456"
const id = localStorage.getItem("userId")
const token = localStorage.getItem("f-token")

export function ControlData(username: string) {
    let formData = new FormData()
    formData.append("username", username)
    formData.append("lang", lang)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    return axios.post(`${api}/user/username_validation`, formData)
}


export function searchUser(key: string) {
    let formData = new FormData()
    formData.append("key", key)
    formData.append("lang", lang)
    formData.append("id", id as string)
    formData.append("token", token as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/users_search`, formData)
}

export function NewUser(name: any, username: string, email: string, password: string, device:string) {

    let formData = new FormData()
    formData.append("name", name)
    formData.append("username", username)
    formData.append("email", email)
    formData.append("password", password)
    formData.append("lang", lang)
    formData.append("login_by", login_by)
    formData.append("device_type", device)
    return axios.post(`${api}/user/register`, formData)
}

export function EditProfile(username: any, name: string, picture: any, cover: any, address: string, about: string, monthly_amount: any, triannual_amount: any, semiannual_amount: any, yearly_amount: any, vip_url: any) {
    let formData = new FormData()
    formData.append("id", id as string)
    formData.append("token", token as string)
    formData.append("lang", lang)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("username", username)
    formData.append("vip_url", vip_url)
    formData.append("name", name)
    formData.append("about", about)
    formData.append("picture", picture)
    formData.append("cover", cover)
    formData.append("address", address)
    formData.append("monthly_amount", monthly_amount)
    formData.append("triannual_amount", triannual_amount)
    formData.append("semiannual_amount", semiannual_amount)
    formData.append("yearly_amount", yearly_amount)
    return axios.post(`${api}/user/update_profile`, formData)
}

export function ConnectUser(email: any, password: string) {
    let formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    return axios.post(`${api}/user/login`, formData)
}

export function ResendMail(userId: any,tokens:any) {
    let formData = new FormData()
    formData.append("token", tokens as string)
    formData.append("id", userId as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    formData.append("userId", userId)
    return axios.post(`${api}/user/resend_email_code`, formData)
}

export function MailXender(email: string) {
    let formData = new FormData()
    formData.append("email", email)
    return axios.post(`${api}/user/forgot_password`, formData)
}



export function VerifyMail(code: any) {
    let formData = new FormData()
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    formData.append("code", code)
    return axios.post(`${api}/user/verify_email_code`, formData)
}


export function Logout(userId: any, token: any) {
    let formData = new FormData()
    formData.append("id", userId)
    formData.append("token", token)
    formData.append("lang", lang)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/logout`, formData)
}

export function addUserByFacebook(social_unique_id:any,first_name: any, last_name: string, email: string, picture: string) {
    let formData = new FormData()
    formData.append("social_unique_id", social_unique_id)
    formData.append("first_name", first_name)
    formData.append("last_name", last_name)
    formData.append("email", email)
    formData.append("picture", picture)
    formData.append("lang", lang)
    formData.append("login_by", "facebook")
    formData.append("device_type", device_type)
    return axios.post(`${api}/user/register`, formData)
}

export function getPost(skip: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("skip", skip)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/home`, formData)
}

export function getSinglePost(post_unique_id: any) {
    let formData = new FormData()
    formData.append("post_unique_id", post_unique_id)
    formData.append("lang", lang)
    formData.append("id", id as string)
    formData.append("token", token as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/posts_view_for_others`, formData)
}

export function getMyPost(skip: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("skip", skip)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/posts_for_owner`, formData)
}

export function getOthersProfile(user_unique_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("user_unique_id", user_unique_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/other_profile`, formData)
}

export function getSuggestion() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/user_suggestions`, formData)
}

export function getProfile() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/profile`, formData)
}

export function newPost(content: any, amount: any, images: any, post_uniqid: any, videos: any, videos_preview: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("content", content as string)
    formData.append("amount", amount as string)
    formData.append("type", "post" as string)
    console.log(images);
    formData.append("images", images as any)
    /*  for (let i = 0; i < files.length; i++) {
          formData.append(`${i}`, files[i] as string)
      } */
    formData.append("post_uniqid", post_uniqid as string)
    formData.append("files", "" as string)
    formData.append("post_files", "" as string)
    formData.append("videos", videos as string)
    formData.append("videos_preview", videos_preview as string)
    formData.append("preview_file", "" as string)
    return axios.post(`${api}/user/post_multiples_files_upload`, formData)
}

export function getOtherProfile(user_unique_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("user_unique_id", user_unique_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/other_profile`, formData)
}

export function getOtherProfilePost(user_unique_id: any, skip: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("skip", skip)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("type", "all" as string)
    formData.append("user_unique_id", user_unique_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/other_profile_posts`, formData)
}

export function likeOption(post_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("post_id", post_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/post_likes_save`, formData)
}

export function bookmarkOption(post_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("post_id", post_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/post_bookmarks_save`, formData)
}

export function favSaveOption(user_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("user_id", user_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/fav_users_save`, formData)
}

export function postComment(post_id: any, comments: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("post_id", post_id as string)
    formData.append("comment", comments as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/post_comments_save`, formData)
}

export function post_delete(post_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("post_id", post_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/posts_delete_for_owner`, formData)
}

export function getNotificationCount() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("to_user_id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/bell_notifications_count`, formData)
}

export function getNotification(skip: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("skip", skip)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/bell_notifications_index`, formData)
}

export function getCardsList() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/cards_list`, formData)
}

export function getWalletsIndex() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/wallets_index`, formData)
}

export function getWalletsHistory() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/wallets_history`, formData)
}

export function takeDefaultCard(card_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("user_card_id", card_id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/cards_default`, formData)
}

export function unFollow(user_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("user_id", user_id as string)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/unfollow_users`, formData)
}

export function getChatUser() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("to_user_id", id as string)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/chat_users`, formData)
}

export function getChatMessages(data: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
    })
    return axios.post(`${api}/user/chat_messages`, formData)
}

export function sendMessage(data: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("to_user_id", id as string)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
    })
    return axios.post(`${api}/user/chat_assets_save`, formData)
}

export function blockUserTchat(data: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("to_user_id", id as string)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
    })
    return axios.post(`${api}/user/block_users_save`, formData)
}

export function deleteUserTchat(data: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("to_user_id", id as string)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
    })
    return axios.post(`${api}/user/chat_delete`, formData)
}

export function sendTips(data: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key])
    })
    return axios.post(`${api}/user/tips_payment_by_stripe`, formData)
}


export function getActiveFollowing() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/active_followings`, formData)
}

export function getActiveFollower() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/active_followers`, formData)
}

export function getFollowers() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/followers`, formData)
}

export function getFollowings() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/followings`, formData)
}

export function getExpiredFollowing() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/expired_followings`, formData)
}

export function getExpiredFollower() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/expired_followers`, formData)
}

export function getPostBookmark(skip: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("skip", skip as string)
    formData.append("type", "all" as string)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/post_bookmarks`, formData)
}

export function freeFollow(user_unique_id: any, is_free: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("user_unique_id", user_unique_id as string)
    formData.append("is_free", is_free)
    formData.append("plan_type", "months")
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/user_subscriptions_payment_by_stripe`, formData)
}

export function changePassword(old_password: any, password: any, password_confirmation: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("old_password", old_password as string)
    formData.append("password", password as string)
    formData.append("password_confirmation", password_confirmation as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/change_password`, formData)
}

export function getMangoData() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/mango_data_get`, formData)
}

export function cardGetToken(data: any, returnUrl: any, accessKeyRef: any, cardNumber: any, cardExpirationDate: any, cardCvx: any) {
    const formatedCard = {
        cardNumber: cardNumber.split(" ").join(""),
        cardExpirationDate: cardExpirationDate.split("/").join(""),
        cardCvx,
    };
    const _data = {
        data: data,
        accessKeyRef: accessKeyRef,
        returnUrl: returnUrl,
        ...formatedCard
    };
    const formData = qs.stringify(_data);
    return axios.post(`${returnUrl}`, formData)
}

export function AddCards(data: any, mango_pay_card_id: any, errorCode: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("data", data)
    formData.append("mango_pay_card_id", mango_pay_card_id)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("errorCode", errorCode)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/cards_add`, formData)
}

export function SaveDocument(document_file: any, is_adult: any, phone_number: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("document_file", document_file)
    formData.append("document_id", "1")
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("is_adult", is_adult)
    formData.append("device_token", device_token)
    formData.append("phone_number", phone_number)
    return axios.post(`${api}/user/documents_save`, formData)
}

export function getBillingAccountList() {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/billing_accounts_list`, formData)
}

export function AddBillingAccount(account_holder_name: any, bic_code: any, iban_number: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("device_type", device_type)
    formData.append("account_holder_name", account_holder_name)
    formData.append("bic_code", bic_code)
    formData.append("iban_number", iban_number)
    return axios.post(`${api}/user/billing_accounts_save`, formData)
}

export function TipsPayment(amount: any, message: any, user_id: any, post_id: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("amount", amount)
    formData.append("post_id", post_id)
    formData.append("message", message)
    formData.append("user_id", user_id)
    return axios.post(`${api}/user/tips_payment_by_stripe`, formData)
}

export function PostsPayment(user_id:any,post_id: any,amount:any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("post_id", post_id)
    formData.append("user_id", user_id)
    formData.append("amount", amount)
    return axios.post(`${api}/user/posts_payment_by_stripe`, formData)
}

export function ChatAssetsPayment(chat_message_id: any,amount:any,from_user_id:any,to_user_id:any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("device_type", device_type)
    formData.append("chat_message_id", chat_message_id)
    formData.append("amount", amount)
    formData.append("from_user_id", from_user_id)
    formData.append("to_user_id", to_user_id)
    return axios.post(`${api}/user/chat_assets_payment_by_stripe`, formData)
}

export function UserSubscription(user_unique_id: any, plan_type: any) {
    let formData = new FormData()
    formData.append("lang", lang)
    formData.append("token", token as string)
    formData.append("id", id as string)
    formData.append("login_by", login_by)
    formData.append("user_unique_id", user_unique_id as string)
    formData.append("is_free", "0")
    formData.append("plan_type", plan_type)
    formData.append("device_type", device_type)
    formData.append("device_token", device_token)
    return axios.post(`${api}/user/user_subscriptions_payment_by_stripe`, formData)
}

export function edit_post(post_id: any, content: any) {
    let formData = new FormData()
    formData.append("post_id", post_id)
    formData.append("content", content as string)
    formData.append("id", id as string)
    return axios.post(`${api}/user/edit_post_content`, formData)
}
