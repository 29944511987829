/* eslint-disable jsx-a11y/alt-text */
import {
  VStack,
  Text,
  Box,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState, Fragment } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import pass from "../img/passwordenter.svg";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

export default function AllMediaTabs({ media }: any) {
  const place = useBreakpointValue({ base: "2", lg: "3" });
  const size = useBreakpointValue({ base: "169px", lg: "169px" });
  const posts = media?.posts;
  const [files, setFiles] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formatPost = () => {
    const postList: any[] = [];
    if (posts?.length > 0) {
      for (const post of posts) {
        const { postFiles, ...other } = post;
        const imageFiles: any[] = [];
        const videoFiles: any[] = [];

        for (const file of postFiles) {
          if (file.file_type === "image") {
            imageFiles.push(file);
          }
          if (file.file_type === "video") {
            videoFiles.push(file);
          }
        }

        postList.push({
          ...other,
          imageFiles,
          videoFiles,
        });
      }
    }

    return postList;
  };
  const handleMediaClick = (file: any) => {
    onOpen();
    setFiles(file);
  };

  return (
    <VStack w="full" p={0} m={0}>
      <Tabs
        w="full"
        display={"flex"}
        flexDirection={"column"}
        alignItems="flex-start"
        p={0}
        variant="unstyled"
      >
        <TabList p={2}>
          <Tab
            w="64px"
            rounded="8px"
            mr={4}
            fontSize="14px"
            fontFamily={"Inter"}
            _selected={{
              color: "#000",
              fontWeight: "700",
              bg: "rgba(159, 201, 242, 0.25);",
            }}
            h="24px"
            bg="#F5F5F5"
          >
            <HStack>
              <Text>Tout</Text>
              <Text></Text>
            </HStack>
          </Tab>
          <Tab
            w="64px"
            h="24px"
            mr={4}
            fontSize="14px"
            rounded="8px"
            fontFamily={"Inter"}
            _selected={{
              color: "#000",
              fontWeight: "700",
              bg: "rgba(159, 201, 242, 0.25);",
            }}
            bg="#f5f5f5"
            color="#000"
          >
            Photos
          </Tab>
          <Tab
            w="64px"
            h="24px"
            fontSize="14px"
            rounded="8px"
            fontFamily={"Inter"}
            _selected={{
              color: "#000",
              fontWeight: "700",
              bg: "rgba(159, 201, 242, 0.25);",
            }}
            bg="#f5f5f5"
            color="#000"
          >
            Videos
          </Tab>
        </TabList>
        <TabPanels w="full" style={{ padding: 0 }}>
          <TabPanel w="full" p={0}>
            <Grid w="full" templateColumns={`repeat(${place}, 1fr) `} gap={2}>
              {media?.posts?.map((data: any, i: any) => {
                return (
                  <>
                    {data?.payment_info?.is_free_account === 1 &&
                    data?.payment_info?.is_user_needs_pay === 0 ? (
                      <Fragment>
                        {data?.postFiles?.map((data: any, i: any) => {
                          return (
                            <>
                              {data?.file_type === "image" && (
                                <GridItem
                                  key={i}
                                  w="100%"
                                  display="flex"
                                  alignItems={"center"}
                                  my={2}
                                  justifyContent="center"
                                  rounded="6px"
                                  h={size}
                                >
                                  <HStack w="full" justifyContent={"center"}>
                                    <LazyLoadImage
                                      onClick={() =>
                                        handleMediaClick(data?.post_file)
                                      }
                                      style={{
                                        height: "180px",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                      src={data?.post_file}
                                    />
                                  </HStack>
                                </GridItem>
                              )}
                              {data?.file_type === "video" && (
                                <GridItem
                                  key={i}
                                  w="100%"
                                  display="flex"
                                  alignItems={"center"}
                                  justifyContent="center"
                                  rounded="lg"
                                  h="169px"
                                >
                                  <HStack w="full" justifyContent={"center"}>
                                    <video
                                      controls
                                      style={
                                        {
                                          height: `${size}`,
                                          width: "100%",
                                        } as any
                                      }
                                      src={data?.post_file}
                                    />
                                  </HStack>
                                </GridItem>
                              )}
                            </>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <GridItem rounded="6px" key={i} w="100%" h="169px">
                        <VStack
                          w="full"
                          alignItems={"center"}
                          justifyContent="center"
                          p={4}
                          rounded="6px"
                          bg="rgba(159, 201, 242, 0.25)"
                        >
                          <Box bg="#fff" w="full" rounded="8px">
                            <LazyLoadImage
                              src={pass}
                              style={{
                                height: "130px",
                                width: "100%",
                              }}
                            />
                          </Box>
                        </VStack>
                      </GridItem>
                    )}
                  </>
                );
              })}
            </Grid>
          </TabPanel>
          <TabPanel w="full" p={0}>
            <Grid templateColumns={`repeat(${place}, 1fr) `} gap={2}>
              {formatPost()?.map((data: any, i: any) => {
                return (
                  <>
                    {data?.payment_info?.is_free_account === 1 &&
                    data?.payment_info?.is_user_needs_pay === 0 ? (
                      <Fragment>
                        {data?.imageFiles?.map((data: any, i: any) => {
                          return (
                            <GridItem
                              my={1}
                              rounded="6px"
                              key={i}
                              w="100%"
                              h="169px"
                            >
                              <HStack w="full" justifyContent={"center"}>
                                <LazyLoadImage
                                  onClick={() =>
                                    handleMediaClick(data?.post_file)
                                  }
                                  style={{
                                    height: "180px",
                                    width: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={data?.post_file}
                                />
                              </HStack>
                            </GridItem>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <GridItem rounded="6px" key={i} w="100%" h="169px">
                        <VStack
                          w="full"
                          alignItems={"center"}
                          justifyContent="center"
                          p={4}
                          rounded="6px"
                          bg="rgba(159, 201, 242, 0.25)"
                        >
                          <Box bg="#fff" w="full" rounded="8px">
                            <LazyLoadImage
                              src={pass}
                              style={{
                                height: "130px",
                                width: "100%",
                              }}
                            />
                          </Box>
                        </VStack>
                      </GridItem>
                    )}
                  </>
                );
              })}
            </Grid>
          </TabPanel>
          <TabPanel w="full" p={0}>
            <Grid templateColumns={`repeat(${place}, 1fr) `} gap={6}>
              {formatPost()?.map((data: any, i: any) => {
                return (
                  <Fragment>
                    {data?.videoFiles?.map((data: any, i: any) => {
                      return (
                        <GridItem rounded="6px" key={i} w="100%" h="169px">
                          <HStack w="full" justifyContent={"center"}>
                            <video
                              controls
                              style={{
                                height: "169px",
                                width: "100%",
                              }}
                              src={data?.post_file}
                            />
                          </HStack>
                        </GridItem>
                      );
                    })}
                  </Fragment>
                );
              })}
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal onClose={onClose} isCentered size="lg" isOpen={isOpen}>
        <ModalOverlay bg="rgb(0,0,0,0.9)" />
        <ModalContent bg="transparent" rounded="0px">
          <ModalBody p={0} m={0}>
            {files?.length > 0 && <img src={files} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
