import React,{useState} from "react";
import { VStack, Text, Box, Divider, Input } from "@chakra-ui/react";
import { useUser } from "../../store/store";

export default function List() {
  const {user} = useUser()
  const list = [
    {
      title: "Followers",
      person: `${user?.total_followers} personnes`,
    },
    {
      title: "Abonné",
      person: `${user?.total_followings} personnes`,
    },
    {
      title: "Favoris",
      person: `${user?.total_fav_users} personnes`,
    },
    {
      title: "Bookmarks",
      person: `${user?.total_bookmarks} personnes`,
    },
    {
      title: "Utilisateurs boqués",
      person: `${user?.total_block_users} personnes`,
    },
  ];
 

  return (
    <VStack w="full" p={8}>
      {list?.map((data, i) => {
        return (
          <>
            <VStack
              w="full"
              key={i}
              fontFamily={"Inter"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Box>
                <Text fontSize={"18px"} fontWeight="600">
                  {data?.title}
                </Text>
              </Box>
              <Box>
                <Text fontSize={"14px"} color="#65676A" fontWeight="400">
                  {data?.person}
                </Text>
              </Box>
            </VStack>
            <Divider />
          </>
        );
      })}
    </VStack>
  );
}
