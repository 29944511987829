import React, { useState } from 'react';
import ReactPlayer from "react-player/lazy";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from '@chakra-ui/react';
import styles from "../Styles/home.module.css"

export default function VideoRender({ postFile }) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isMobileScreen] = useMediaQuery("(max-width:600px)");
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  function handleStart() {
    if (isMobileScreen) {
      handlePlay();
    }
  }

  function handleVideoPreviewClick() {
    handlePlay();
    //videoRef.current.play()
  }

  function handlePlay() {
    if (!isVideoPlaying) {
      setIsVideoPlaying(true);
    }
  }

  function handlePause() {
    if (isVideoPlaying) {
      setIsVideoPlaying(false);
    }
  }
  const isInFullScreen =
    document.isFullScreen ||
    document.fullscreenElement /* Standard syntax */ ||
    document.webkitFullscreenElement /* Safari and Opera syntax */ ||
    document.msFullscreenElement /* IE11 syntax */ ||
    document.mozFullScreenElement

  React.useEffect(() => {
    const isIphone = navigator.userAgent.toLowerCase().indexOf("iphone") !== -1;
    if (isVideoPlaying && !inView && !isIphone && !isInFullScreen) {
      setIsVideoPlaying(false);
    }
    /* if (!inView && isVideoPlaying && !isInFullScreen) {
      alert(isInFullScreen);
      setIsVideoPlaying(false);
    } */
  }, [isVideoPlaying, inView, isInFullScreen]);
  return (
    <div className={styles.vid} ref={ref}>
      <ReactPlayer
        light={null}
        url={postFile.post_file || postFile}
        controls
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
        width="100%"
        height="auto"
        //playsinline
        onClickPreview={handleVideoPreviewClick}
        playing={isVideoPlaying}
        onPlay={handlePlay}
        onPause={handlePause}
        onStart={handleStart}
      />

    </div>
  );
}
