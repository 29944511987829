import React,{useState} from "react";
import {
  VStack,
  HStack,
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import MyPost from "./post/MyPost";
import { useMyPost, useUser } from "../store/store";
import ToDoPost from "./post/ToDoPost";

export default function MediaTabs() {
  const profile = useUser()
  const mypost = useMyPost()?.mypost
  const titlepix = useBreakpointValue({base:"12px",lg:"18px"})
  const wbtn = useBreakpointValue({base:"66px",lg:"70px"})
  const { user } = useUser()
  const hbtn = useBreakpointValue({base:"24px",lg:"29pxpx"})
  const id = profile?.user?.user_id
 const findTab = mypost?.findIndex((index:any)=> index.user_id === profile?.user?.user_id)

  return (
    <Tabs w='full'>
      <TabList w='full'>
        <Tab w='full' fontSize={titlepix}>{user?.total_posts} POST</Tab>
      </TabList>
      {user?.total_posts ===0 && <Text py={4} fontSize={"12px"} fontFamily={"Inter"}>aucun post.</Text>}
        
      <TabPanels>
        <TabPanel minH='50vh' px={0} py={4}>
          <MyPost />
        </TabPanel>
       
      </TabPanels>
    </Tabs>
  );
}
