/* eslint-disable jsx-a11y/alt-text */
import {
  VStack,
  Button,
  HStack,
  useBreakpointValue,
  Box,
  useDisclosure,
  Text,
  Divider,
  Select,
  Avatar,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import card from "../img/card.svg";
import web from "../img/web.svg";
import wallet from "../img/wallet.svg";
import profil from "../img/profil.svg";
import setting from "../img/setting.svg";
import favoris from "../img/favoris.svg";
import kiz from "../img/kiz.svg";
import logout from "../img/logout.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import homebtn from "../../src/img/homebtn.svg";
import circleprofil from "../../src/img/profilcircle.png";
import notif from "../../src/img/notif.svg";
import msg from "../../src/img/msg.svg";
import menu from "../../src/img/menu.svg";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { useBoxId, useNotificationCount, useUser } from "../store/store";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Logout } from "../server/api";
import { useSpeech } from "../store/store"

export default function MenuMobile() {
  const navigate = useNavigate();
  const { lang, setLang } = useSpeech()
  const { boxId } = useBoxId()
  const device = useBreakpointValue({ base: true, lg: false });
  const drawer = useDisclosure();
  const location = useLocation().pathname;
  const { notificationCount } = useNotificationCount()
  const { user } = useUser()
  const log = useDisclosure();
  const breakpoints = {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }
  const local = localStorage.getItem("lang")
  const Link = [
    {
      icon: `${profil}`,
      name: `${lang?.m_profil}`,
      path: "/home/profil",
    },
    {
      icon: `${favoris}`,
      name: `${lang?.m_book}`,
      path: "/home/bookmarks",
    },
    {
      icon: `${menu}`,
      name: `${lang?.m_list}`,
      path: "/home/list",
    },
    {
      icon: `${setting}`,
      name: `${lang?.m_param}`,
      path: "/home/settings",
    },
    {
      icon: `${card}`,
      name: `${lang?.m_card}`,
      path: "/home/cards",
    },
    {
      icon: `${wallet}`,
      name: `${lang?.m_wallet}`,
      path: "/home/wallet",
    },
    {
      icon: `${kiz}`,
      name: `${lang?.m_aboutus}`,
      path: "/aboutus",
    },
  ];
  const logOut = React.useCallback(() => {
    Logout(
      localStorage?.getItem("userId"),
      localStorage?.getItem("f-token")
    ).then((res) => {
      if (res.data.success) {
        localStorage?.removeItem("userId");
        localStorage?.removeItem("f-token");
        localStorage?.removeItem("profil");
        window.location.assign("/");
      }
    });
  }, []);

  const handleLang = React.useCallback(
    (e) => {
      localStorage.setItem("lang", e.target.value)
      setLang(e.target.value)
    },
    [setLang],
  )

  return (
    <VStack w="full">
      <HStack
        spacing={0}
        display={['flex', 'flex', 'none', 'none', 'none']}
        position={"fixed"}
        hidden={location === `/chat/${boxId}` ? true : false}
        zIndex="10"
        bottom={"0px"}
        boxShadow={"0px -12.48px 10.4px rgba(30, 83, 133, 0.03)"}
        h="69px"
        w="full"
      >
        <Button
          w="full"
          onClick={() => {
            navigate("/home");
          }}
          borderTop={location === "/home" ? "4px solid #005591" : ""}
          rounded="none"
          h="full"
        >
          <img src={homebtn} height="24px" width="24px" />
        </Button>
        <Button
          w="full"
          position="relative"
          borderTop={
            location === "/home/notification" ? "4px solid #005591" : ""
          }
          onClick={() => {
            navigate("/home/notification");
          }}
          rounded="none"
          h="full"
        >
          {notificationCount?.bell > 0 && <Box position="absolute" display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} zIndex={"10"} bg="#005591" color="#fff" fontWeight={"bold"} fontSize={"10px"} top={3} right={8} h='20px' w='20px' rounded="full">{notificationCount?.bell}</Box>}
          <img src={notif} height="24px" width="24px" />
        </Button>
        <Button
          w="full"
          position="relative"
          onClick={() => {
            navigate("/chat");
          }}
          borderTop={
            location === "/chat" ? "4px solid #005591" : ""
          }
          rounded="none"
          h="full"
        >
          {notificationCount?.chat > 0 && <Box position="absolute" display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} zIndex={"10"} bg="#005591" color="#fff" fontWeight={"bold"} fontSize={"10px"} top={3} right={8} h='20px' w='20px' rounded="full">{notificationCount?.chat}</Box>}
          <img src={msg} height="24px" width="24px" />
        </Button>
        <Button w="full" onClick={drawer.onOpen} rounded="none" h="full">
          <img src={menu} height="24px" width="24px" />
        </Button>
      </HStack>
      <Drawer isOpen={drawer.isOpen} placement="right" onClose={drawer.onClose}>
        <DrawerOverlay bg="rgba(255, 255, 255, 0.8)" />
        <DrawerContent width={"50%"}>
          <DrawerHeader py={8}>
            <VStack w="full" alignItems={"flex-start"}>
              <Avatar
                w="83px"
                h="83px"
                src={user?.picture}
              >
              </Avatar>
              <VStack w="full" spacing={0} alignItems={"flex-start"}>
                <Box>
                  <Text fontWeight={"700"} fontFamily="Inter">
                    {user?.name}
                  </Text>
                </Box>
                <Box>
                  <Text
                    fontSize={"12px"}
                    color="#65676A"
                    fontWeight={"700"}
                    fontFamily="Inter"
                  >
                    @{user?.username}
                  </Text>
                </Box>
                <HStack w="full" justifyContent={"flex-start"}>
                  <Box onClick={() => {
                    navigate("/home/fans");
                  }}>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"300"}
                      fontFamily="Inter"
                    >
                      <span style={{ color: "#65676A", fontWeight: "700" }}>
                        {user?.total_followers}
                      </span>{" "}
                      {user?.total_followers > 1 ? "Followers" : "Follower"}
                    </Text>
                  </Box>
                  <Box onClick={() => {
                    navigate("/home/following");
                  }}>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"300"}
                      fontFamily="Inter"
                    >
                      <span style={{ color: "#65676A", fontWeight: "700" }}>
                        {user?.total_followings}
                      </span>{" "}
                      {user?.total_followings > 1 ? "Abonnés" : "Abonné"}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </VStack>
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <VStack
              w="full"
              h="full"
              alignItems={"flex-start"}
              overflowY={"auto"}
              py={4}
              spacing={4}
              fontFamily="Inter"
            >
              {Link.map((data, i) => {
                return (
                  <Box
                    key={i}
                    onClick={() => {
                      window.location.assign(`${data.path}`);
                    }}
                  >
                    <HStack
                      w="full"
                      bg={
                        location === data.path
                          ? "rgba(159, 201, 242, 0.25)"
                          : "#fff"
                      }
                      p={2}
                      rounded="lg"
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Box>
                        <img src={data.icon} />
                      </Box>
                      <Box>
                        <Text
                          _hover={{ color: "#005591" }}
                          fontSize={"sm"}
                          color="#65676A"
                        >
                          {data.name}
                        </Text>
                      </Box>
                    </HStack>
                  </Box>
                );
              })}
              <HStack
                w="full"
                p={2}
                color="#65676A"
                spacing={0}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Box>
                  <img src={web} />
                </Box>
                <Box px={2}>
                  <Select border='none' defaultValue={local} onChange={(e) => handleLang(e)} p={0}>
                    <option value="FR">Français</option>
                    <option value="EN">English</option>
                  </Select>
                </Box>
              </HStack>
              <HStack
                w="full"
                onClick={log.onOpen}
                p={2}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Box>
                  <img src={logout} />
                </Box>
                <Box>
                  <Text
                    _hover={{ color: "#005591" }}
                    fontSize={"sm"}
                    color="#65676A"
                  >
                    {lang.m_logout}
                  </Text>
                </Box>
              </HStack>
            </VStack>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal isOpen={log.isOpen} onClose={log.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>{lang.logout_title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{lang.logout_msg}</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} variant="ghost" onClick={log.onClose}>
              {lang.no}
            </Button>
            <Button size="sm" onClick={logOut} colorScheme="blue">
              {lang.yes}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
