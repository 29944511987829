/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { VStack, Box, Text, HStack, Select } from "@chakra-ui/react";
import folme from "../img/folm.svg";
import insta from "../img/insta.svg";
import { Grid, GridItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSpeech, useDoc } from "../store/store";

export default function SignFooter() {
  const { lang, setLang } = useSpeech();
  const { doc, setDoc } = useDoc();
  const local = localStorage.getItem("lang");
  const handleLang = React.useCallback(
    (e: any) => {
      localStorage.setItem("lang", e.target.value);
      setDoc(e.target.value);
      setLang(e.target.value);
      window.location.reload()
    },
    [setDoc, setLang]
  );

  return (
    <VStack w="full">
      <Grid
        w="full"
        px={8}
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        templateColumns="repeat(3, 1fr)"
        gap={6}
      >
        <GridItem w="100%">
          <VStack w="full" alignItems={["center","center","flex-end","flex-end"]}>
            <Box>
              <img src={folme} />
            </Box>
            <Box>
              <Text fontSize={"12px"}>@2021</Text>
            </Box>
            <Box>
              <a href="https://instagram.com/followme.fans">
                {" "}
                <img src={insta} />
              </a>
            </Box>
          </VStack>
        </GridItem>
        <GridItem
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          <VStack w="full" alignItems={"center"} justifyContent="center">
            <Box>
              <Text fontWeight={"bold"}>followme</Text>
            </Box>
            <VStack fontSize={"12px"}>
              <ul>
                <li>
                  <Link to="/aboutus">{lang?.aboutLink}</Link>
                </li>

                <li>
                  <Link to="/terms">CGU</Link>
                </li>
                <li>
                  <Link to="/privacy">{lang?.termsText}</Link>
                </li>
                <li>
                  <a target="_blank" href="mailto:contact@followme.fans" rel="noreferrer">
                    Contact
                  </a>{" "}
                </li>
              </ul>
            </VStack>
          </VStack>
        </GridItem>
        <GridItem
          w="100%"
          display="flex"
          alignItems="flex-start"
          justifyContent={"flex-start"}
       
        >
          <VStack
            w="full"
            alignItems={"flex-start"}
           
            justifyContent="flex-start"
          >
            <Box w="full">
              <Text fontWeight={"bold"}>Language</Text>
            </Box>
            <VStack fontSize={"12px"}>
              <Select
                border="none"
                defaultValue={local as any}
                onChange={(e) => handleLang(e)}
                p={0}
              >
                <option value="FR">FR</option>
                <option value="EN">EN</option>
              </Select>
            </VStack>
          </VStack>
        </GridItem>
      </Grid>
    </VStack>
  );
}
