import {
  HStack,
  Avatar,
  Text,
  VStack,
  useBreakpointValue,
  CircularProgress,
} from "@chakra-ui/react";
import { useInView } from "react-hook-inview";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useEchoListener from "../../hooks/useEchoListener";
import { getNotification } from "../../server/api";
import { useNotification } from "../../store/store";

export default function Notification() {
  const { notification, setNotification, setIsLoading, isLoading, total } =
    useNotification();
  const hgt = useBreakpointValue({ base: "auto", lg: "200px" });
  const overf = useBreakpointValue({ base: "", lg: "auto" });
  const navigate = useNavigate();
  const [ref, isVisible] = useInView({
    threshold: 1,
  });
  const hasMore =
    notification?.notifications?.length > 9 &&
    notification?.notifications?.length < total;
  React.useEffect(() => {
    const loadMorePosts = async () => {
      //is there is more than 9 posts on first page and all posts haven't been loaded yet
      try {
        const skip = notification?.notifications?.length;
        const { data } = await getNotification(skip);
        console.log(data);
        if (data.success) {
          setNotification([...notification, ...data.data]);
          setIsLoading(false);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (isVisible && hasMore && !isLoading) {
      alert("LoadingMore...");
      loadMorePosts();
    }
  }, [
    hasMore,
    isLoading,
    isVisible,
    notification,
    setIsLoading,
    setNotification,
  ]);

  const onNewNotification = React.useCallback(() => {
    getNotification(0).then((res) => {
      setNotification(res?.data?.data);
    });
  }, [setNotification]);

  useEchoListener(
    "user-notifications-" + localStorage.getItem("userId"),
    "BellEvent",
    onNewNotification
  );
  return (
    <VStack minW="300px" h={hgt} overflowY={overf as any}>
      <HStack
        display={["none", "none", "flex", "flex"]}
        px={4}
        w="full"
        color="#005591"
      >
        <Text fontSize={"20px"} fontFamily="Inter-extra">
          Notifications
        </Text>
      </HStack>

      <VStack w="full" paddingBottom={[32, 32, 0, 0]} px={4}>
        {notification?.notifications?.length === 0 && (
          <Text opacity={"0.5"} fontSize={"14px"} fontFamily={"Montserrat"}>
            aucune notification
          </Text>
        )}
        {notification?.notifications?.map((data: any, i: any) => {
          let date = new Date(data?.updated_at);
          return (
            <HStack
              w="full"
              cursor="pointer"
              py={2}
              key={i}
              onClick={() => navigate(`/chat/${data?.from_user_id}`)}
            >
              <Avatar
                h="38px"
                w="38px"
                name={data?.from_displayname}
                src={data?.from_userpicture}
              />
              <VStack spacing={0} alignItems={"flex-start"}>
                <Text fontSize={"14px"}>{data.message}</Text>
                <Text color="#ABAEB4" fontWeight={"700"} fontSize={"12px"}>
                  {data?.updated_formatted} à {date?.getHours()}:
                  {date?.getMinutes()}
                </Text>
              </VStack>
            </HStack>
          );
        })}
        {hasMore && !isLoading && <div ref={ref} />}
        {isLoading && notification?.notifications?.length > 0 && (
          <HStack w="full" hidden justifyContent="center">
            <CircularProgress size={"20px"} isIndeterminate color="#005591" />
          </HStack>
        )}
      </VStack>
    </VStack>
  );
}
