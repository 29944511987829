import { VStack,HStack,Text,useDisclosure,Button,useToast, Textarea,Avatar,Box } from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { PencilSimple } from "phosphor-react";
import React from "react";
import { edit_post, getMyPost } from "../../server/api";
import { useMyPost, useSpeech } from "../../store/store";

export default function PostEditButton({ postTab,postId }:any) {
    const [bool, setBool] = React.useState(false)
    const toast = useToast();
    const { setMyPost } = useMyPost()
    const [content, setContent] = React.useState("")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const post = postTab;
    const { lang } = useSpeech()
    let filt = post.filter((e:any)=> e.post_id === postId)
    const data = filt[0]

  const handleEditPost = React.useCallback(
        async() => {
            setBool(true)
           await edit_post(postId,content.length > 0 ? content : data?.content).then(res=>{
            if (res.data.success) {
            setContent("")
              getMyPost(0).then(res=> setMyPost(res.data.data.posts))
               console.log(data);
                setBool(false);
                toast({
                  title: `${res.data.message}`,
                  status: "success",
                  isClosable: true,
                  position: "top-right",
                });
              setTimeout(() => {
                  onClose()
                }, 4000); 
              } else {
                setBool(false);
                toast({
                  title: "Echec modification",
                  status: "error",
                  isClosable: true,
                  position: "top-right",
                });
              }
            })
        },
        [content, data, onClose, postId, setMyPost, toast]
    )
  return (
    <VStack w='full'>
      <HStack w="full" onClick={onOpen} px={2} cursor={"pointer"} color="#005591">
        {" "}
        <PencilSimple size={14} />
        <Text fontSize={"sm"}>{lang.edit_post}</Text>{" "}
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgb(0,0,0,0.7)" />
        <ModalContent m={2}>
          <ModalHeader fontSize="sm">
          <HStack w="full">
                <Avatar src={data?.user?.picture} w="28px" h="28px" />
                <VStack alignItems="flex-start" spacing={0}>
                  <Box>
                    <Text
                      fontWeight={"600"}
                      fontSize="12.199px"
                      fontFamily={"Inter"}
                    >
                      {data?.user?.name}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontWeight={"400"}
                      fontSize="8.71px"
                      fontFamily={"Inter"}
                      color="#65676A"
                    >
                      {data?.user?.username}
                    </Text>
                  </Box>
                </VStack>
              </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          
            <Textarea defaultValue={content.length > 0 ? content : data?.content} onChange={(e:any)=>setContent(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <Button isLoading={bool} disabled={bool} onClick={handleEditPost} size="sm" colorScheme='blue'>
              {lang.edit_post}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
