import {
  Container,
  HStack,
  useBreakpointValue,
  Button,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Menu from "../../views/Menu";
import Recommendation, {
  SuggestComponent,
  SuggestMobileComponent,
} from "../../views/Recommendation";
import Header from "./Header";
import styles from "../../Styles/home.module.css";

import { Link } from "react-router-dom";
import { Outlet, useLocation } from "react-router";
import MenuMobile from "../../views/Menu-mobile";
import Inbox from "../messenger/Inbox";

export default function Home() {
  const device = useBreakpointValue({ base: true, lg: false });
  const width = useBreakpointValue({ base: "100%", lg: "100%" });
  let location = useLocation().pathname;
  const breakpoints = {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  };
 
  return (
    <VStack
      w="full"
      h={["", "", "100vh", "100vh", "100vh"]}
      alignItems={"center"}
      justifyContent="center"
      spacing={0}
    >
      <Header />
      <HStack w="full" alignItems={"center"} justifyContent={"center"}>
        <VStack
          w={width}
          className={styles.container}
          alignItems={"center"}
          justifyContent="center"
          spacing={0}
          position="relative"
        >
          <HStack
            w="full"
            spacing={0}
            alignItems="flex-start"
            justifyContent={"flex-start"}
          >
            <VStack
              w="288px"
              display={["none", "none", "flex", "flex", "flex"]}
              h="90vh"
            >
              <Menu />
            </VStack>
            <HStack w="full">
              <VStack w="full" py={0} border={["none","none","1.5px solid #F1F2F6","1.5px solid #F1F2F6"]} spacing={0} h={"90vh"} overflowY={"auto"}>
                <Outlet />
              </VStack>
              <VStack
                h="90vh"
                display={["none", "none", "flex", "flex", "flex"]}
                w="350px"
              >
                <Recommendation />
              </VStack>
            </HStack>
          </HStack>
          {location !== "/chat/" ? (
            <VStack display={["flex", "flex", "none", "none", "none"]}>
              <MenuMobile />
            </VStack>
          ) : (
            <></>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
}
