import { Box, VStack,Text,Container, Heading, HStack, Button } from '@chakra-ui/react';
import { Hourglass, X } from 'phosphor-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSpeech } from '../../store/store';

export default function InProcess() {
    const { lang } = useSpeech()
    return (
        <VStack w='full' h='100vh' alignItems={"center"} justifyContent={"center"} bgGradient={
            "linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 46.28%)"
        }>
            <Container maxW="container.xl" display="flex" alignItems={"center"} justifyContent={"center"}>
            <VStack w={['full','full','60%','50%']} bg="yellow.100" textAlign={"center"}  p={4} rounded="xl">
                <Box bg="yellow.400" p={4} rounded="full" transform={"translate(0,-50px)"}>
                    <Hourglass size={32} color="#fff" />
                </Box>
                <VStack spacing={4}>
                    <Box>
                        <Heading fontFamily={"inter-extra"} color="yellow.900">{lang?.pay_wait}</Heading>
                    </Box>
                    <Box>
                        <Text fontFamily={"inter"} color="yellow.900">{lang?.pay_wait_text}</Text>
                    </Box>
                </VStack>
                <HStack>
                    <Link to="/home">
                        <Button rounded="xl" bg="#005591" colorScheme='blue' fontFamily={"inter"} color="#fff">{lang?.home_return}</Button>
                    </Link>
                </HStack>
            </VStack>
            </Container>
           
        </VStack>
    );
}
