/* eslint-disable jsx-a11y/alt-text */
import { VStack, HStack, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { getPost, likeOption } from "../../server/api";
import { ThumbsUp } from "phosphor-react";
import like from "../../img/likeheart.svg";
import likepty from "../../img/heartemp.svg";
import { usePost } from "../../store/store";

export default function Likes({ likeData }: any) {
  const toast = useToast();
  const [mention, setMention] = useState(false);
  const [total, setTotal] = useState(0);
  const { setPost } = usePost();

  React.useEffect(() => {
      setMention(likeData?.is_user_liked === 1 ? true : false)
      if(likeData?.is_user_liked === 1){
          setTotal(0)
      }
  }, [likeData?.is_user_liked])

  const liked = useCallback(
    async(id: any) => {
      
      if (id === "liker") {
        setTotal(total+1);
      }
      if (id === "dislike") {
        setTotal(total-1);
      }
      await likeOption(likeData?.post_id)
      /*   const likeResponse = await likeOption(id);
          const posts = await getPost(0);
          setPost(posts?.data?.data?.posts ?? []);
          toast({
            position: "bottom-right",
            render: () => (
              <HStack color="white" rounded="lg" p={3} bg="blue.500">
                <ThumbsUp />
                <Text fontFamily={"Inter"}>{likeResponse?.data.message}</Text>
              </HStack>
            ),
          }); */
    },
    [likeData?.post_id, total]
  );
  return (
    <VStack>
      <HStack spacing={2} alignItems="center" justifyContent={"center"}>
        <img
          style={{ cursor: "pointer" }}
          onClick={() => {
            setMention(!mention);
            liked(!mention ? "liker" : "dislike");
          }}
          src={mention === true ? like : likepty}
        />
        <Text fontSize={"14px"} color="#65676A">
          {likeData?.total_likes + total}
        </Text>
      </HStack>
    </VStack>
  );
}
