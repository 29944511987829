/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Divider, VStack } from "@chakra-ui/react";
import {
  Tabs,
  TabList,
  Text,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
} from "@chakra-ui/react";
import check from "../../img/tick-circle.svg";
import danger from "../../img/danger.svg";
import success from "../../img/copy-success.svg";
import Actifs from "../followings/Actifs";
import Unfollow from "../followings/Unfollow";
import All from "../followings/All";

export default function Following() {
  return (
    <VStack w="full" py={8} px={4}>
      <Tabs w="full" p={0} variant="unstyled">
        <TabList p={2}>
          <Tab
            rounded="8px"
            mr={4}
            fontFamily={"Inter"}
            fontSize="12px"
            _selected={{
              color: "#005591",
              fontWeight: "700",
              bg: "rgba(159, 201, 242, 0.5)",
            }}
            h="32px"
            bg="#F5F5F5"
          >
            <HStack>
              <Text>Actif</Text>
              <img src={check} />
            </HStack>
          </Tab>
          <Tab
            rounded="8px"
            h="32px"
            fontSize="12px"
            fontFamily={"Inter"}
            _selected={{
              color: "#005591",
              fontWeight: "700",
              bg: "rgba(159, 201, 242, 0.5)",
            }}
            color="#9FC9F2"
          >
            <HStack>
              <img src={danger} />
              <Text>Désabonné</Text>
            </HStack>
          </Tab>
          <Tab
            rounded="8px"
            h="32px"
            fontSize="12px"
            fontFamily={"Inter"}
            _selected={{
              color: "#005591",
              fontWeight: "700",
              bg: "rgba(159, 201, 242, 0.5)",
            }}
            color="#9FC9F2"
          >
            <HStack>
              <img src={success} />
              <Text>Tout</Text>
            </HStack>
          </Tab>
        </TabList>
        <Divider />
        <TabPanels w="full" style={{ padding: 0 }}>
          <TabPanel w="full" p={0}>
              <Actifs />
          </TabPanel>
          <TabPanel w="full" p={0}>
          <Unfollow />
          </TabPanel>
          <TabPanel w='full' p={0}>
          <All />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}
