import React,{useState} from "react";
import {
  VStack,
  HStack,
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import OtherPost from "../Components/post/OtherPost";
import AllMediaTabs from "./AllMediaTabs";

export default function SingleMediaTabs({post,total,stat}:any) {
  const titlepix = useBreakpointValue({base:"12px",lg:"18px"})

  return (
    <Tabs w='full'>
      <TabList w='full'>
        <Tab w='full' fontSize={titlepix}>{total} POST</Tab>
        <Tab w='full' fontSize={titlepix}> MEDIAS</Tab>
      </TabList>
        
      <TabPanels>
        <TabPanel minH='50vh' p={0}>
          <OtherPost other={post as any} />
        </TabPanel>
        <TabPanel minH='50vh' p={0}>
          <AllMediaTabs media={post as any} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
