/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback } from "react";
import {
  Button,
  HStack,
  VStack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import visa from "../img/visa.svg";
import puce from "../img/magnetic.svg";
import master from "../img/mastercard.svg";
import { CircleWavyCheck, CreditCard } from "phosphor-react";
import { useCards, useLoader } from "../store/store";
import { takeDefaultCard, getCardsList } from "../server/api";
import HashLoader from "react-spinners/HashLoader";
import AddBankCardModal from "./user/AddBankCardModal";

export default function MyCards() {
  const place = useBreakpointValue({ base: "1", lg: "2" });
  const { cards, setCards } = useCards();
  const loaderSize = useBreakpointValue({ base: "30", lg: "50" });
  const { loader } = useLoader();
  const toast = useToast();

  const defaultCard = useCallback(
    async (id: any) => {
      const cardResponse = await takeDefaultCard(id);
      const defaulted = await getCardsList();
      setCards(defaulted?.data?.data?.cards);
      toast({
        position: "bottom-right",
        render: () => (
          <HStack color="white" rounded="lg" p={3} bg="green">
            <CircleWavyCheck />
            <Text fontFamily={"Inter"}>{cardResponse?.data.message}</Text>
          </HStack>
        ),
      });
    },
    [setCards, toast]
  );
  return (
    <VStack w="full" bg="#DDE3F2" paddingBottom={32} minH="100vh">
      <AddBankCardModal />
      <HStack w="full" alignItems={"center"} justifyContent="center" p={0}>
        {loader ? (
          <VStack
            w="full"
            h="full"
            alignItems={"center"}
            justifyContent="center"
          >
            <HashLoader color="#005591" size={loaderSize} />
          </VStack>
        ) : (
          <Grid w="full" templateColumns={`repeat(${place}, 1fr)`} gap={4}>
            {cards?.map((data: any, i: any) => {
           
              return (
                <GridItem
                  key={data.id}
                  p={4}
                  rounded="18px"
                  w="full"
                  bg={data?.card_type === "VISA" ? "#47B2CF" : "#005591"}
                >
                  <HStack w="full" justifyContent={"flex-end"}>
                    {data?.card_type === "VISA" && <img src={visa} />}
                    {data?.card_type !== "VISA" && <Text p={1} fontFamily={"inter"} rounded="xl" fontSize={"10px"} bg="#ddd">{data?.card_type}</Text>}
                  </HStack>
                  <HStack w="full" justifyContent={"flex-start"}>
                    <img src={puce} />
                  </HStack>
                  <HStack
                    w="full"
                    fontSize={"16px"}
                    fontWeight="400"
                    color="#fff"
                    spacing={4}
                  >
                    <Text fontFamily={"Inter-extra"}>XXXX</Text>
                    <Text fontFamily={"Inter-extra"}>XXXX</Text>
                    <Text fontFamily={"Inter-extra"}>XXXX</Text>
                    <Text fontFamily={"Inter-extra"}>{data?.last_four}</Text>
                  </HStack>
                  <HStack w="full" justifyContent={"flex-end"}>
                    {data?.is_default && (
                      <Button
                        w="136px"
                        h="32px"
                        bg="green"
                        colorScheme="green"
                        fontSize={"12px"}
                        color="#fff"
                        rounded="49px"
                      >
                        Carte par défaut
                      </Button>
                    )}
                    {data?.is_default === 0 && (
                      <Button
                        w="136px"
                        h="32px"
                        bg="#fff"
                        onClick={async () => await defaultCard(data?.id)}
                        fontFamily={"Inter"}
                        fontSize={"12px"}
                        color="#005591"
                        rounded="49px"
                      >
                        Marquer par défaut
                      </Button>
                    )}
                  </HStack>
                </GridItem>
              );
            })}
          </Grid>
        )}
      </HStack>
      {cards?.length === 0 && (
        <VStack w="full" rounded="xl" bg="rgba(159, 201, 242, 0.25)" p={4}>
          <CreditCard size={24} />
          <Text fontFamily={"Inter"}>Pas encore de carte</Text>
        </VStack>
      )}
    </VStack>
  );
}
