/* eslint-disable no-unreachable */
import React, { createContext, useEffect, useState } from 'react';
import Echo from "laravel-echo";
import io from "socket.io-client";

const Context = createContext();
export default function EchoContext({ children }) {
    const [echo, setEcho] = useState();

    useEffect(() => {
    
            let _echo = new Echo({
                broadcaster: "socket.io",
              // transports: ["websocket", "polling", "flashsocket"],
                host: "https://admin.followme.fans",
              client: io,
            });
            setEcho(_echo);
    }, [])

    return (
        <Context.Provider value={echo}>
            {echo && children}
        </Context.Provider>
    );
}

export const useEcho = () => {
    const echo = React.useContext(Context);
    if (echo === undefined) {
        throw new Error("useEcho must be used within a EchoProvider");
    }
    return echo;
}

