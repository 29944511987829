/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
  useDisclosure,
  Button,
  HStack,
  Text,
  Box,
  VStack,
  Select,
} from "@chakra-ui/react";
import card from "../img/card.svg";
import web from "../img/web.svg";
import wallet from "../img/wallet.svg";
import profil from "../img/profil.svg";
import setting from "../img/setting.svg";
import notif from "../img/notif.svg";
import menu from "../img/menu.svg";
import favoris from "../img/favoris.svg";
import kiz from "../img/kiz.svg";
import logout from "../img/logout.svg";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { Logout } from "../server/api";
import { useSpeech } from "../store/store"

export default function Menu() {

  const location = useLocation().pathname;
  const log = useDisclosure();
  const { lang, setLang } = useSpeech()
  const local = localStorage.getItem("lang")
  const Link = [
    {
      icon: `${profil}`,
      name: `${lang?.m_profil}`,
      path: "/home/profil",
    },
    {
      icon: `${favoris}`,
      name: `${lang?.m_book}`,
      path: "/home/bookmarks",
    },
    {
      icon: `${menu}`,
      name: `${lang?.m_list}`,
      path: "/home/list",
    },
    {
      icon: `${setting}`,
      name: `${lang?.m_param}`,
      path: "/home/settings",
    },
    {
      icon: `${card}`,
      name: `${lang?.m_card}`,
      path: "/home/cards",
    },
    {
      icon: `${wallet}`,
      name: `${lang?.m_wallet}`,
      path: "/home/wallet",
    },
    {
      icon: `${kiz}`,
      name: `${lang?.m_aboutus}`,
      path: "/aboutus",
    },
  ];

  const logOut = React.useCallback(() => {
    Logout(
      localStorage?.getItem("userId"),
      localStorage?.getItem("f-token")
    ).then((res) => {
      if (res.data.success) {
        localStorage?.removeItem("userId");
        localStorage?.removeItem("f-token");
        localStorage?.removeItem("profil");
        window.location.assign("/");
      }
    });
  }, []);

  const handleLang = React.useCallback(
    (e) => {
      localStorage.setItem("lang", e.target.value)
      setLang(e.target.value)
    },
    [setLang],
  )
  return (
    <VStack
      w="288px"
      h="full"
      alignItems={"flex-start"}
      spacing={4}
      p={2}
      fontFamily="Inter"
    >
      {Link.map((data, i) => {
        return (
          <NavLink key={i} to={data.path}>
            <HStack
              w="228px"
              bg={location === data.path ? "rgba(159, 201, 242, 0.25)" : "#fff"}
              rounded="xl"
              p={2}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Box>
                <img src={data.icon} />
              </Box>
              <Box>
                <Text
                  _hover={{ color: "#005591" }}
                  fontSize={"sm"}
                  color="#65676A"
                >
                  {data.name}
                </Text>
              </Box>
            </HStack>
          </NavLink>
        );
      })}
      <HStack
        w="228px"
        rounded="xl"
        p={2}
        color="#65676A"
        spacing={0}
        alignItems={"center"}
        justifyContent={"flex-start"}
      >
        <Box>
          <img src={web} />
        </Box>
        <Box>
          <Select border='none' defaultValue={local} onChange={(e) => handleLang(e)}>
            <option value="FR">Français</option>
            <option value="EN">English</option>
          </Select>
        </Box>
      </HStack>
      <Button px={0} bg="#fff" colorScheme="transparent" onClick={log.onOpen}>
        <HStack
          w="228px"
          rounded="xl"
          p={2}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Box>
            <img src={logout} />
          </Box>
          <Box>
            <Text _hover={{ color: "#005591" }} fontSize={"sm"} color="#65676A">
              {lang?.m_logout}
            </Text>
          </Box>
        </HStack>
      </Button>
      <Modal isOpen={log.isOpen} onClose={log.onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={4}>
          <ModalHeader>{lang?.logout_title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{lang?.logout_msg}</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} variant="ghost" onClick={log.onClose}>
              {lang?.no}
            </Button>
            <Button size="sm" onClick={logOut} colorScheme="blue">
              {lang?.yes}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
