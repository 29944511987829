import { french } from "./Lang/FR"
import { english } from "./Lang/EN"

export const Translator = (lang:string) => {
    if (lang === 'FR') {
        return french
    }
    else if (lang === 'EN') {
        return english
    }

}

