/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  HStack,
  VStack,
  Text,
  useMediaQuery,
  Input,
  Button,
  useBreakpointValue,
  useToast,
  Container,
} from "@chakra-ui/react";
import folme from "../img/folme.svg";
import fb from "../img/fb.svg";
import fm from "../img/logo.svg";
import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import mail from "../img/mail.svg";
import pass from "../img/password.svg";
import SignFooter from "./SignFooter";
import styles from "../Styles/home.module.css";
import { ConnectUser, addUserByFacebook } from "../server/api";
import SocialButton from "./socialAuth/SocialButton";
import { useSpeech } from "../store/store";
import CookiesTag from "./static/CookiesTag";

export default function Login() {

  const toast = useToast();
  const position = useBreakpointValue<any>({
    base: "bottom",
    lg: "bottom-right",
  });
  const breakpoints = {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  };
  const { lang } = useSpeech();
  const putWidth = useBreakpointValue({ base: "358px", lg: "475px" });
  const putButton = useBreakpointValue({ base: "358px", lg: "418px" });
  const [start, setStart] = useState(false);
  const fontS = useBreakpointValue({ base: "18px", lg: "28px" });
  const Tpix = useBreakpointValue({ base: "22px", lg: "32px" });
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  React.useEffect(() => {
    if (localStorage.getItem("f-token")) {
      window.location.assign("/home");
    }
  }, []);
  const logUser = useCallback(() => {
    setStart(true);
    ConnectUser(login.email, login.password).then((res: any) => {
      if (res.data.data.is_email_verified === 0) {
        setStart(false);
        setLogin({ ...login, email: "", password: "" });
        localStorage.setItem("email", res.data.data.email)
        localStorage.setItem("userId", res.data.data.user_id)
        localStorage.setItem("token", res.data.data.token)
        window.location.href = "/verify"
      }
      else {
        if(res.data.success && res.data.data.is_email_verified){
          setLogin({ ...login, email: "", password: "" });
          setStart(false);
        localStorage.setItem("f-token", res.data.data.token);
        localStorage.setItem("profil", JSON.stringify(res.data.data));
        localStorage.setItem("userId", res.data.data.user_id);
        toast({
          description: "Connexion réussie",
          status: "success",
          position: `${position}` as any,
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.assign("/home");
        }, 3000);
        }
        else{
          toast({
            description: `${res.data.error}`,
            status: "error",
            position: `${position}` as any,
            duration: 5000,
            isClosable: true,
          });
        }
      }

    

    });

  }, [login, position, toast]);

  const handleFacebookLogin = async (user: any) => {
    const { data } = await addUserByFacebook(user._profile.id, user._profile.firstName, user._profile.lastName, user._profile.email, user._profile.profilePicURL)
    console.log(data);
    if (data.success) {
      localStorage.setItem("f-token", data.data.token);
      localStorage.setItem("profil", JSON.stringify(data.data));
      localStorage.setItem("userId", data.data.user_id);
      window.location.assign("/home");
    }
    else {
      toast({
        title: "Echec de connexion.",
        status: "error",
        position: `${position}` as any,
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleSocialLoginFailure = (err: any) => {
    console.error(err);
  };

  return (
    <HStack
      w="full"
      h="100vh"
      position="relative"
      bg="#f5f5f5"
      bgGradient={
        "linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 46.28%)"
      }
      spacing={0}
    >
      <VStack
        w="50%"
        display={["none", "none", "flex", "flex", "flex"]}
        h="100vh"
        position={"relative"}
        bg="#005591"
        alignItems={"center"}
        justifyContent="center"
      >
        <Box cursor={"pointer"}>
          <img src={folme} />
        </Box>
      </VStack>

      <VStack
        w={["100%", "100%", "50%", "50%"]}
        h={"100vh"}
        overflowY={"auto"}
        bg="#f5f5f5"
        bgGradient={
          "linear-gradient(180deg, rgba(159, 201, 242, 0.2) 0%, rgba(159, 201, 242, 0) 46.28%)"
        }
      >
        <CookiesTag />
        <VStack w="full" py={4} paddingBottom={8} spacing={8} textAlign={"center"} fontFamily={"Inter"}>
          <Box
            display={["flex", "flex", "none", "none"]}
            px={10}
            alignItems={"center"}
            justifyContent="center"
            w="full"
          >
            <img src={fm} height="400px" width="400px" />
          </Box>

          <VStack spacing={0}>
            <Text
              fontWeight={"300"}
              fontFamily="Inter"
              fontSize={fontS}
              color="#005591"
            >
              {lang?.titleI1}
            </Text>
            <Text
              fontWeight={"900"}
              fontFamily="Inter-extra"
              fontSize={fontS}
              color="#005591"
            >
              {lang?.titleI2}
            </Text>
          </VStack>
          <Box>
            <Text
              fontWeight={"900"}
              color="#005591"
              fontSize={Tpix}
              fontFamily={"Inter-black"}
            >
              {lang?.loginText}
            </Text>
          </Box>
        </VStack>

        <VStack
          w={"full"}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          spacing={4}
        >
          <HStack
            w={putWidth}
            h="54px"
            spacing={0}
            alignItems={"center"}
            justifyContent={"center"}
            rounded="4px"
            bg="#ffffff"
            border="1px solid #dddddd"
          >
            <Box
              px={2}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              w="50px"
              h="full"
            >
              <img src={mail} />
            </Box>
            <Input
              fontFamily={"Inter"}
              type="email"
              rounded="none"
              className={styles.inputs}
              outline={"none"}
              py={6}
              value={login.email}
              onChange={(e) => {
                setLogin({ ...login, email: e.target.value });
              }}
              border="none"
              placeholder="email@domain.com"
            />
          </HStack>

          <HStack
            w={putWidth}
            h="54px"
            spacing={0}
            alignItems={"center"}
            justifyContent={"center"}
            rounded="4px"
            bg="#ffffff"
            border="1px solid #dddddd"
          >
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              w="50px"
              h="full"
            >
              <img src={pass} />
            </Box>
            <Input
              fontFamily={"Inter"}
              type="password"
              className={styles.inputs}
              value={login.password}
              py={6}
              onChange={(e) => {
                setLogin({ ...login, password: e.target.value });
              }}
              rounded="none"
              border="none"
              placeholder={lang?.passInput}
            />
          </HStack>
          <HStack w={putWidth} justifyContent={"flex-end"}>
            <Link to="/forgot-password">
              <Text color="#005591" fontSize={"14px"} fontFamily={"Inter"}>
                {lang?.passForgot}
              </Text>
            </Link>
          </HStack>
          <HStack py={2}>
            <Box w="150px" border="1px solid #E6E6E6" h="0px"></Box>
            <Text>{lang?.ou}</Text>
            <Box w="150px" border="1px solid #E6E6E6" h="0px"></Box>
          </HStack>
          <VStack w="full" spacing={"40px"}>
            <VStack spacing={4}>
              <SocialButton
                provider="facebook"
                appId={"913868612815977"}
                onLoginSuccess={handleFacebookLogin}
                onLoginFailure={handleSocialLoginFailure}
                className="social-button"
                id="facebook-connect"
              >
                <Button
                  py={6}
                  fontFamily="Inter"
                  w={putButton}
                  h="54px"
                  bg="#fff"
                  border="1px solid #005591"
                >
                  <img src={fb} />
                  <Text ml={4}>{lang?.fbLogin}</Text>
                </Button>
              </SocialButton>

              <Button
                colorScheme="blue"
                w={putButton}
                h="54px"
                onClick={logUser}
                bg="#005591"
                fontFamily="Inter"
                py={6}
                disabled={
                  (login.email.length > 0 && login.password.length > 0) || start
                    ? false
                    : true
                }
                isLoading={start}
                color="#ffffff"
              >
                {lang?.loginBtn}
              </Button>
            </VStack>
            <VStack w="full" py={2} spacing={4}>
              <Text fontFamily={"Inter"} lineHeight="19.36px" fontSize="16px">
                {lang?.notAccount}
              </Text>
              <Link to="signup">
                <Text
                  fontFamily={"Inter"}
                  fontSize="16px"
                  lineHeight="19.36px"
                  color="#005591"
                >
                  {lang?.signFollow}
                </Text>
              </Link>
              <SignFooter />
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </HStack>
  );
}
