/* eslint-disable jsx-a11y/alt-text */

import React, { useCallback, useState } from 'react';
import { Heading, HStack, Box, Text, Button, VStack, useDisclosure } from "@chakra-ui/react";
import pass from "../img/passwordenter.svg";
import { useNavigate } from "react-router";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { PostsPayment } from '../server/api';

export default function OnlyFollow({ post_id, amount, user_id, payment_info }: any) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [load, setLoad] = useState(false)

  const handlePaymentOption = useCallback(
    async (text: any) => {
      if (text === 'ppv' && amount > 0) {
        onOpen()
      }
      else {
        toast({
          description: "Souscription",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [amount, onOpen, toast]
  )

  const confirmPayment = useCallback(
    async () => {
      setLoad(true)
    const { data } = await PostsPayment(user_id,post_id,amount)
      if (data.success) {
        setLoad(false)
        console.log(data?.data?.url_redirect);
      window.location.assign(`${data?.data?.url_redirect}`)
      }
      if (!data.success) {
        setLoad(false)
        toast({
          description: `${data.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [amount, post_id, toast, user_id]
  )
  return (
    <VStack w='full' alignItems={"center"} justifyContent="center" rounded="12px" bg="rgba(159, 201, 242, 0.25)" minH="300px">
      <Box bg="#fff" p={8} rounded="8px">
        <img src={pass} height="50px" width="80px" />
      </Box>
      <Button fontFamily={"Inter"} onClick={() => handlePaymentOption(payment_info?.post_payment_type)} bg="#005591" colorScheme='blue' size="sm" color="#fff">{payment_info?.payment_text}</Button>
      <Text fontSize={"sm"} fontFamily={"Inter"} color="#65676A">pour les publications utilisateurs</Text>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded="15.23px" w="357px">
          <ModalHeader color="#344054" fontFamily={"Inter"} fontSize="15.23px">
            Payer et voir le Post
          </ModalHeader>
          <Divider />
          <ModalCloseButton border="2px solid #000" p={0} rounded="full" />
          <ModalBody>
            <HStack w="full" spacing={4}>
              <Text fontFamily={"Inter"}>Montant payé:</Text>
              <Text fontFamily={"Inter"} fontWeight="bold" color="#005591">{amount} €</Text>
            </HStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button
              w="100px"
              h="38px"
              fontSize="14px"
              fontFamily={"Inter"}
              colorScheme="red"
              bg="grey"
              color="#fff"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              w="149px"
              h="38px"
              colorScheme="green"
              fontSize="14px"
              isLoading={load}
              fontFamily={"Inter"}
              onClick={confirmPayment}
              bg="#34A853"
              color="#fff"
            >
              Confirmer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
