import {
  VStack,
  useBreakpointValue,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { SuggestMobileComponent } from "../../views/Recommendation";
import Post from "../post/Post";

export default function Index() {
  const breakpoints = {
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "80em",
    "2xl": "96em",
  };

  return (
    <VStack w="full">
      <VStack
        w="full"
        display={["flex", "flex", "none", "none", "none"]}
        id="post"
        spacing={0}
        alignItems={"flex-start"}
        textAlign={"left"}
      >
        <Box px={2} py={1}>
          <Text
            fontFamily={"Inter"}
            color="#65676A"
            fontWeight={"400"}
            fontSize="10px"
          >
            SUGGESTIONS
          </Text>
        </Box>
        <SuggestMobileComponent />
      </VStack>
      <VStack w="full" marginBottom={"50px"}>
        <Post />
      </VStack>
    </VStack>
  );
}
