
export const isEmpty = (string:any) => !string || (string && string.trim() === "");

export const validateBankAccount = (bankAccount:any) => {
  const { name,
    bic,
    iban } = bankAccount;
  const errors = {} as any
  if (isEmpty(name)) {
    errors.name = "Nom du titulaire du compte obligatoire" as any;
  }
  if (bic?.length !== 8) {
    errors.bic = "Le code BIC doit contenir 8 caractères";
  }
  if (iban?.length < 10) {
    errors.iban = "Le numéro IBAN doit contenir au moins 10 caractères";
  }
  return {
    errors,
    valid: Object.keys(errors).length === 0,
  }
}