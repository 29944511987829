/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import {
  HStack,
  Text,
  useDisclosure,
  Button,
  useToast,
  VStack,
  FormControl,
  Input,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import pass from "../img/pass.svg";
import { changePassword } from "../server/api";

export default function ChangePassword() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [key, setKey] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });

  const ChangePassword = React.useCallback(() => {
    changePassword(
      key.old_password,
      key.password,
      key.password_confirmation
    ).then((res) => {
      if (res.data.success) {
        toast({
          title: "Changement réussi.",
          description: `${res.data.message}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Echec de changement",
          description: `${res.data.error}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    });
  }, [key.old_password, key.password, key.password_confirmation, toast]);
  return (
    <VStack w="full">
      <HStack w="full">
        <img src={pass} height="21px" width="21px" />
        <Text
          onClick={onOpen}
          cursor={"pointer"}
          fontSize={"14px"}
          color="#344054"
        >
          Changer le mot de passe
        </Text>
      </HStack>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent m={2} bg="#EAEDF6" rounded={"15.23px"}>
          <ModalHeader
            borderBottom={"1px solid #ddd"}
            fontWeight={"800"}
            fontSize="md"
            fontFamily="Inter"
          >
            Changer le mot de passe
          </ModalHeader>
          <ModalCloseButton
            h="20px"
            w="20px"
            p={2}
            color="#344054"
            rounded="full"
            border="1px solid #344054"
          />
          <ModalBody py={8}>
            <VStack w="full" fontFamily={"Inter"} spacing={4}>
              <FormControl>
                <Input
                  type="password"
                  value={key.old_password}
                  onChange={(e) => {
                    setKey({ ...key, old_password: e.target.value });
                  }}
                  placeholder="Ancien mot de passe"
                  h="55px"
                  bg="#fff"
                />
              </FormControl>
              <FormControl>
                <Input
                  type="password"
                  value={key.password}
                  onChange={(e) => {
                    setKey({ ...key, password: e.target.value });
                  }}
                  placeholder="Nouveau mot de passe"
                  h="55px"
                  bg="#fff"
                />
              </FormControl>
              <FormControl>
                <Input
                  type="password"
                  value={key.password_confirmation}
                  onChange={(e) => {
                    setKey({ ...key, password_confirmation: e.target.value });
                  }}
                  placeholder="Confirmer le nouveau mot de passe"
                  h="55px"
                  bg="#fff"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter
            w="full"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
          >
            <Button
              disabled={
                key.password === key.password_confirmation ? false : true
              }
              onClick={() => ChangePassword()}
              bg="#005591"
              colorScheme="blue"
              fontFamily={"Inter"}
              fontSize={"14px"}
              rounded="3px"
              fontWeight="400"
              h="38px"
              w="271px"
              color="#fff"
            >
              Confirmer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
