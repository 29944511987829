/* eslint-disable jsx-a11y/alt-text */
import { HStack, VStack, Box, useBreakpointValue } from "@chakra-ui/react";
import { Grid, GridItem, Text } from "@chakra-ui/react";
import React, { Fragment,useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useBookmark, useLoader } from "../../store/store";
import favo from "../../img/favo.svg";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  
  ModalBody,
  
  useDisclosure,
} from "@chakra-ui/react";
import HashLoader from "react-spinners/HashLoader";

export default function Bookmarks() {
  const { bookmark } = useBookmark();
  const loaderSize = useBreakpointValue({ base: "30", lg: "50" });
  const { loader } = useLoader();
  const [files, setFiles] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const size = useBreakpointValue({ base: "169px", lg: "169px" });
  const place = useBreakpointValue({ base: "2", lg: "3" });
  const handleMediaClick = (file: any) => {
    onOpen();
    setFiles(file);
  };
  return (
    <VStack w="full" p={2} paddingBottom={32}>
      <VStack w="full">
        <HStack py={4} w="full" justifyContent={"space-between"}>
          <Box>
            <Text fontFamily={"Inter-extra"}>Tous les favoris</Text>
          </Box>
          <Box>
            <Text fontFamily={"Inter"}>
              {bookmark?.posts?.length + bookmark?.total}
            </Text>
          </Box>
        </HStack>
      </VStack>
      {loader ? (
        <VStack w="full" h="full" alignItems={"center"} justifyContent="center">
          <HashLoader color="#005591" size={loaderSize} />
        </VStack>
      ) : (
        <Grid w="full" templateColumns={`repeat(${place}, 1fr) `} gap={2}>
          {bookmark?.posts?.map((data: any, i: any) => {
            return (
              <Fragment>
                {data?.postFiles.map((data: any, i: any) => {
                  return (
                    <>
                      {data.file_type === "image" && (
                        <GridItem
                          key={i}
                          w="100%"
                          my={2}
                          display="flex"
                          alignItems={"center"}
                          justifyContent="center"
                          rounded="6px"
                          h={size}
                        >
                      
                            <HStack w="full" justifyContent={"center"}>
                              <LazyLoadImage
                                style={{
                                  width: "100%"
                                }}
                                onClick={() =>
                                  handleMediaClick(data.post_file)
                                }
                                src={data?.post_file}
                              />
                            </HStack>
                        
                        </GridItem>
                      )}
                      {data.file_type === "video" && (
                        <GridItem
                          key={i}
                          w="100%"
                          display="flex"
                          alignItems={"center"}
                          justifyContent="center"
                          rounded="lg"
                          h="169px"
                        >
                          <HStack w="full" justifyContent={"center"}>
                            <video
                              controls
                              style={{ height: "150px", width: "100%" }}
                              src={data?.post_file}
                            />
                          </HStack>
                        </GridItem>
                      )}
                    </>
                  );
                })}
              </Fragment>
            );
          })}
        </Grid>
      )}
      {bookmark?.posts?.length === 0 && (
        <VStack w="full" rounded="xl" bg="rgba(159, 201, 242, 0.25)" p={4}>
          <img src={favo} />
          <Text fontFamily={"Inter"}>Pas encore de favoris</Text>
        </VStack>
      )}
      <Modal onClose={onClose} isCentered size="lg" isOpen={isOpen}>
        <ModalOverlay bg="rgb(0,0,0,0.9)" />
        <ModalContent bg="transparent" rounded="0px">
          <ModalBody  p={0} m={0}>
            {files?.length > 0 && (
              <img src={files} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
