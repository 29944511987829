/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
  Container,
  HStack,
  Box,
  Text,
  useBreakpointValue,
  VStack,
  Avatar,
} from "@chakra-ui/react";
import option from "../img/option.svg";
import HashLoader from "react-spinners/HashLoader";
import { CaretRight } from "phosphor-react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useLoader, useSpeech, useSuggestion } from "../store/store";
import styles from "../Styles/carousel.module.css";
import { useNavigate } from "react-router";

export default function Recommendation() {
  const { lang } = useSpeech();
  const loaderSize = useBreakpointValue({ base: "30", lg: "30" });
  const { loader } = useLoader();
  return (
    <VStack w="350px" h="100%">
      <HStack w="full" p={4} justifyContent={"space-between"}>
        <Box>
          <Text fontFamily={"Inter"} fontWeight="500" color="#005591">
            {lang?.suggesTitle}
          </Text>
        </Box>
        <Box cursor={"pointer"}>
          <img src={option} />
        </Box>
      </HStack>
      <VStack w="full" h="100%" overflowY={"auto"}>
        {loader ? (
          <VStack
            w="full"
            h="full"
            alignItems={"center"}
            justifyContent="center"
          >
            <HashLoader color="#005591" size={loaderSize} />
          </VStack>
        ) : (
          <SuggestComponent />
        )}
      </VStack>
    </VStack>
  );
}

export const SuggestComponent = () => {
  const navigate = useNavigate();
  const suggestion = useSuggestion()?.sugData?.users;

  return (
    <>
      {suggestion?.map((data: any, i: any) => {
        return (
          <VStack
            key={data.user_id}
            onClick={() => navigate(`profil/${data.user_unique_id}`)}
            w="319px"
            h="98.02px"
            position={"relative"}
            rounded="5px"
            p={2}
            bg="rgba(159, 201, 242, 0.25)"
          >
            <VStack
              cursor={"pointer"}
              w="full"
              fontFamily={"Inter"}
              rounded="5px"
              backgroundImage={data?.cover}
              backgroundPosition="center"
              backgroundSize={"cover"}
              backgroundRepeat="no-repeat"
            >
              <HStack
                w="full"
                px={4}
                py={1}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
              >
                <Box bg="#1A1E21" px={1} rounded="5px">
                  <Text fontSize={"12px"} color="#fff">
                    {data?.user_account_type_formatted}
                  </Text>
                </Box>
              </HStack>
              <HStack w="full" justifyContent={"flex-end"}>
                <Box>
                  <CaretRight size={24} color="#fff" />
                </Box>
              </HStack>
              <HStack
                w="full"
                position={"absolute"}
                py={4}
                zIndex="2"
                px={4}
                justifyContent={"flex-start"}
              >
                <Avatar w="64px" h="64px" src={data.picture} rounded="full" />
              </HStack>
            </VStack>
            <HStack
              w="full"
              fontFamily={"Inter"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box ml={6}>
                {data?.name?.length > 12 ? <Text>{data?.name?.substring(0, 12)}...</Text> : <Text>{data?.name}</Text>}
              </Box>
              <Box>
                <Text color="#65676A" fontFamily={"Inter"} fontSize="10px">
                  @{data?.username}
                </Text>
              </Box>
            </HStack>
          </VStack>
        );
      })}
      {suggestion?.length === 0 &&
        <VStack w='full' alignItems={"center"} justifyContent={"center"} bg="red.50" h='100px'>
          <Text>aucune suggestion de compte !</Text>
        </VStack>
      }
    </>
  );
};

export const SuggestMobileComponent = () => {
  const navigate = useNavigate();
  const suggestion = useSuggestion().sugData;
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Navigation]}
        className={styles.myswiper}
      >
        {suggestion?.users?.map((data: any, i: any) => {
          return (
            <SwiperSlide
              onClick={() => navigate(`profil/${data.user_unique_id}`)}
              key={i}
              className={styles.swiper}
            >
              <VStack
                w="full"
                position={"relative"}
                rounded="5px"
                p={2}
                minH={"130px"}
                bg="rgba(159, 201, 242, 0.25)"
              >
                <VStack
                  cursor={"pointer"}
                  w="full"
                  fontFamily={"Inter"}
                  rounded="5px"
                  backgroundImage={data?.cover}
                  backgroundPosition="center"
                  backgroundSize={"cover"}
                  backgroundRepeat="no-repeat"
                >
                  <HStack
                    w="full"
                    px={4}
                    py={1}
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                  >
                    <Box bg="#1A1E21" px={1} rounded="5px">
                      <Text fontSize={"12px"} color="#fff">
                        Gratuit
                      </Text>
                    </Box>
                  </HStack>
                  <HStack w="full" justifyContent={"flex-end"}>
                    <Box visibility={"hidden"}>
                      <CaretRight size={24} color="#fff" />
                    </Box>
                  </HStack>
                  <HStack
                    w="full"
                    position={"absolute"}
                    py={4}
                    zIndex="10"
                    px={4}
                    justifyContent={"flex-start"}
                  >
                    <Avatar
                      w="64px"
                      h="64px"
                      src={data.picture}
                      rounded="full"
                    />
                  </HStack>
                </VStack>
                <HStack
                  w="full"
                  fontFamily={"Inter"}
                  px={4}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box ml={4}>
                    {data?.name?.length > 12 ? <Text>{data?.name?.substring(0, 12)}...</Text> : <Text>{data?.name}</Text>}
                  </Box>
                  <Box>
                    <Text color="#65676A" fontSize={"14px"}>
                      @{data?.username}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {suggestion?.users?.length === 0 &&
        <VStack w='full' alignItems={"center"} justifyContent={"center"} bg="red.50" h='100px'>
          <Text>aucune suggestion de compte !</Text>
        </VStack>
      }
    </>
  );
};
