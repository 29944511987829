/* eslint-disable jsx-a11y/alt-text */
import { Text, VStack, Box, Container, HStack } from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import aboutlogo from "../../img/aboutlogo.svg";
import { Link } from "react-router-dom";
import { useDoc } from "../../store/store";

export default function Privacy() {
  const { doc } = useDoc();

  return doc === "FR" ? <PrivacyFR /> : <PrivacyEN />;
}

export function PrivacyFR() {
  const inboxScrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    inboxScrollRef.current?.scrollIntoView();
  }, []);
  return (
    <Container maxW="container.xl" bg="#fff">
      <div ref={inboxScrollRef} />
      <Link to="/">
        <HStack
          py={4}
          w="full"
          alignItems={"flex-start"}
          justifyContent="flex-start"
        >
          <img src={aboutlogo} height="300px" width="300px" />
        </HStack>
      </Link>
      <VStack w="full" spacing={8}>
        <Box mt={8}>
          <Text fontWeight="bold" sx={{ color: "#105b94" }} variant="h4">
            POLITIQUE DE CONFIDENTIALITÉ ET DE PROTECTION DES DONNÉES {"  "}
            <span className="text-black">Version Août 2021</span>
          </Text>
          <Text mt={2}>
            La présente Politique de confidentialité s’adresse aux utilisateurs
            du de la plateforme followme.fans (ci-après « la Plateforme ») et a
            pour objectif de les informer sur la manière dont leurs informations
            personnelles peuvent être collectées et traitées. Le respect de la
            vie privée et des données à caractère personnel est pour SAS DGO
            (followme.fans) une priorité, raison pour laquelle nous nous
            engageons à traiter celles-ci dans le plus strict respect de la loi
            Informatique et Libertés du 6 janvier 1978 (ci-après « loi IEL »)
            modifiée et du Règlement (UE) général sur la protection des données
            du 27 avril 2016 (ci-après « RGPD »).
          </Text>
          <Box mt={1} p={4} mb={12}>
            <ol>
              <li>
                Généralités et identité du responsable du Traitement SAS DGO,
                Société par actions simplifiée au capital social de 10.000
                euros, immatriculée au Registre du Commerce et des Sociétés de
                Paris sous le numéro 85159275800029, dont le siège social est
                187 rue de Lourmel 75015 Paris. SAS DGO (followme.fans) aura le
                statut de responsable de traitement pour les traitements
                relatifs à :
                <ul style={{ listStyleType: "none" }}>
                  <li>- La gestion et le suivi des comptes et contrats </li>
                  <li>- La fourniture et la gestion des services </li>
                  <li>- La gestion du paiement </li>
                  <li>
                    - La gestion des demandes de renseignement auprès du support{" "}
                  </li>
                  <li>- L’envoi d’e-mailing </li>
                  <li>- La gestion des demandes de droits</li>
                  <li>
                    - Le bon fonctionnement et l’amélioration permanente de la
                    Plateforme{" "}
                  </li>
                </ul>
                <Text mt={2}>
                  Rappel légal : Le responsable du traitement est, au sens de la
                  loi Informatique et Libertés et du RGPD, la personne qui
                  détermine les moyens et les finalités du traitement. Lorsque
                  deux responsables du traitement ou plus déterminent
                  conjointement les finalités et les moyens du traitement, ils
                  sont les responsables conjoints du traitement (ou
                  co-responsables). Le sous-traitant est une personne traitant
                  des données à caractère personnel pour le compte du
                  responsable du traitement, il agit sous l’autorité du
                  responsable du traitement et sur instruction de celui-ci.
                </Text>
              </li>

              <Box mt={5}>
                <li className="mt-5">
                  Définitions:
                  <br />
                  « Données » : désignent les données à caractère personnel
                  faisant l’objet de Traitement dans le cadre de la présente
                  Politique de confidentialité.
                  <br />
                  « Données à Caractère Personnel » : désignent les données à
                  caractère personnel telles que définies à l’article 4 (1) du
                  RGPD.
                  <br />
                  « Règlement général sur la protection des données » ou « RGPD
                  » : désigne le Règlement (UE) 2016/679 du Parlement européen
                  et du Conseil du 27 avril 2016 relatif à la protection des
                  personnes physiques à l'égard du Traitement des Données à
                  Caractère Personnel et à la libre circulation de ces données,
                  et abrogeant la directive 95/46/CE.
                  <br />« Utilisateur »: désigne toute personne naviguant sur la
                  Plateforme www.followme.fans , qu’il soit Fan, Createur, ou
                  simple internaute. « Traitement/Traiter » : désigne l’une
                  quelconque des opérations visées à l’article 4 (2) du RGPD,
                  effectuée sur les Données à Caractère Personnel dans le cadre
                  de l’exécution de la présente Politique de confidentialité. «
                  Sous-traitant » : désigne la personne physique ou morale,
                  l'autorité publique, le service ou un autre organisme qui
                  Traite des Données à Caractère Personnel pour le compte et
                  selon les instructions de SAS DGO (followme.fans) conformément
                  à l’article 4 (8) du RGPD.
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Finalités du traitement et bases légales SAS DGO
                  (followme.fans) collecte uniquement les Données nécessaires
                  pour les finalités explicites et déterminées ci-dessous :
                  <ul style={{ listStyleType: "none" }}>
                    <li>- La gestion et le suivi des comptes et contrats </li>
                    <li>- L’inscription des Fans</li>
                    <li>- Creator registration</li>
                    <li>
                      - La certification des comptes des Créateurs et Fans.{" "}
                    </li>
                    <li>- Consentement de l’Utilisateur</li>
                    <li>- Fourniture et gestion des services</li>
                    <li>- Mise en relation des Fans avec les Créateurs</li>

                    <li>
                      - Exécution d'un contrat ou exécution de mesures
                      précontractuelles
                    </li>
                    <li>- Gestion du paiement</li>
                    <li>- Le paiement</li>
                    <li>- Le suivi de la facturation</li>

                    <li>
                      - La tenue des registres comptables et des justificatifs
                      légaux
                    </li>
                    <li>
                      - Obligation légale : article L.102 B et suivants du Livre
                      des procédures fiscales{" "}
                    </li>
                    <li>
                      - Gestion des demandes de renseignement auprès du support{" "}
                    </li>
                    <li>
                      - Suivi et réponse aux demandes de renseignement auprès du
                      support{" "}
                    </li>
                    <li>
                      - Exécution d'un contrat ou exécution de mesures
                      précontractuelles
                    </li>
                    <li>- Envoi d’emailings</li>
                    <li>
                      - Envoi d’e-mailings et autres alertes aux Utilisateurs
                      qui en ont fait la demande
                    </li>
                  </ul>
                  <Text mt={2}>
                    Gestion des demandes de droits issus du RGPD et de la loi
                    Informatique et Libertés modifiée. Ce traitement couvre
                    l’ensemble des opérations nécessaires au suivi des demandes
                    de droits adressées à SAS DGO (followme.fans) (qualification
                    de la demande, investigations, réalisation d’opérations
                    techniques spécifiques etc.). Il ne concerne que les cas où
                    SAS DGO (followme.fans) agit en qualité de responsable de
                    traitement. Notre obligation légale découlant des articles
                    15 et suivants du RGPD et des articles 48 et suivants de la
                    loi Informatique et Libertés.
                  </Text>
                  <br />
                  <Text>
                    Bon fonctionnement et l’amélioration permanente de la
                    Plateforme, de ses fonctionnalités. Assurer le bon
                    fonctionnement et l’amélioration permanente de la Plateforme
                    et de ses fonctionnalités. Notre intérêt légitime à garantir
                    le meilleur niveau de fonctionnement et de qualité de la
                    Plateforme grâce notamment aux statistiques de visites. Le
                    Consentement de l’Utilisateur lorsque celui-ci est requis.
                  </Text>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Données collectées.
                  <br />
                  Le caractère obligatoire ou facultatif des données
                  personnelles collectées et les éventuelles conséquences d'un
                  défaut de réponse sont indiqués lors des différents contacts
                  avec les personnes concernées. Vous pouvez consulter le détail
                  des données à caractère personnel que nous sommes susceptibles
                  d’avoir sur vous ci-après. NB : le détail des informations
                  fournies ci-après n’a pas vocation à être exhaustif et vise
                  avant tout à informer les Utilisateurs sur les catégories de
                  données que SAS DGO (followme.fans) est susceptible de
                  traiter.
                  <Text mt={2}>
                    Pour la gestion et suivi des comptes et contrats
                  </Text>
                  Données relatives à l’identité des Utilisateurs :
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - Nom, prénom(s), adresse, numéro de téléphone, adresses
                      email, pseudonyme, date de naissance, données indiquées
                      dans la biographie, photographies, copie du passeport /
                      CNI
                    </li>
                    <li>- IBAN</li>
                    <li>
                      - Numéro de carte bancaire (seuls les 4 derniers chiffres
                      sont conservés)
                    </li>
                    <li>- adresse email</li>
                    <li>
                      - Données nécessaires à l’établissement des factures{" "}
                    </li>
                  </ul>
                  <ul style={{ listStyleType: "none" }}>
                    <Text mt={2} ml={-5}>
                      Pour la gestion des demandes de renseignement auprès du
                      support:
                    </Text>
                    <li>- Numéro de commande</li>
                  </ul>
                  <ul style={{ listStyleType: "none" }}>
                    <Text mt={2} ml={-5}>
                      Pour l’envoi d’e-mailing:
                    </Text>
                    - Données de contact de l’Utilisateur (nom, prénom, email)
                    <Text mt={2} ml={-5}>
                      Pour la gestion de demandes de droits:
                    </Text>
                    <li>- Adresse e-mail</li>
                    <li>- Numéro de commande</li>
                    <Text mt={2} ml={-5}>
                      Pour le bon fonctionnement et l’amélioration permanente de
                      la Plateforme:{" "}
                    </Text>
                    <li>
                      - Logs et données de connexions des Utilisateurs et
                      données d’identification du matériel informatique.
                    </li>
                    <li>
                      - Les données collectées via les cookies et autres
                      traceurs présents sur notre Plateforme, pour plus de
                      détail, consultez notre charte de gestion des cookies
                    </li>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Données personnelles collectées automatiquement.
                  <br />
                  Lorsque vous naviguez sur la Plateforme www.followme.fans, SAS
                  DGO (followme.fans) enregistre automatiquement des
                  informations relatives à votre navigation. Lorsque vous
                  utilisez nos services, des données de connexions peuvent-être
                  automatiquement enregistrées dans nos journaux de serveur
                  comme votre adresse IP, votre identifiant unique, votre
                  système d'exploitation et son emplacement, le type de
                  navigateur que vous utilisez, les pages que vous avez
                  consultées.
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Destinataire des données.
                  <br />
                  Seules les personnes habilitées et déterminées mentionnées
                  ci-dessous pourront avoir accès aux données des Utilisateurs:
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - Le personnel habilité de SAS DGO (followme.fans).{" "}
                    </li>
                    <li>
                      - Les prestataires chargés de la gestion, de l’hébergement
                      de la Plateforme et du système informatique de SAS DGO
                      (followme.fans).{" "}
                    </li>
                    <li>
                      - Les prestataires chargés de la gestion de la relation
                      clients et de la modération des contenus.
                    </li>
                    <li>
                      - Les prestataires chargés de la gestion du paiement.{" "}
                    </li>
                    <li>- Les prestataires d’e-mailing.</li>
                    <li>
                      - Le cas échéant, le personnel habilité de nos
                      sous-traitants.{" "}
                    </li>
                    <li>
                      - Le cas échéant, les juridictions concernées, médiateurs,
                      experts-comptables, commissaires aux comptes, avocats,
                      huissiers, sociétés de recouvrement de créances, autorités
                      de police ou de gendarmerie en cas de vol ou de
                      réquisition judiciaire.
                    </li>
                    <li>
                      - Les tiers susceptibles de déposer des cookies sur vos
                      terminaux lorsque vous y consentez
                    </li>
                    <Text mt={2} ml={-5}>
                      Vos données ne sont communiquées, échangées, vendues ou
                      louées à aucune autre personne que celles mentionnées
                      ci-dessus.
                    </Text>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Durée de conservation des données.
                  <br />
                  SAS DGO (followme.fans) s’engage à ce que les données
                  collectées soient conservées sous une forme permettant votre
                  identification pendant une durée qui n’excède pas la durée
                  nécessaire aux finalités pour lesquelles ces données sont
                  collectées et traitées.
                  <br />
                  Les données sont conservées pendant toute la relation
                  contractuelle puis archivées. Elles sont par la suite stockées
                  pendant cinq (5) ans à des fins probatoires conformément aux
                  obligations légales et règlementaires auxquelles SAS DGO
                  (followme.fans) est soumise. Les documents comptables sont par
                  ailleurs conservés pendant 10 ans.
                  <Text mt={2}>
                    Pour les paiements par carte bancaire, conformément à
                    l’article L.133-24 du Code monétaire et financier, ces
                    données peuvent être conservées pour une finalité de preuve
                    en cas d’éventuelle contestation de la transaction ou
                    réclamation, en archives intermédiaires, pendant une durée
                    de treize (13) mois suivant la date de débit (durée portée à
                    15 mois pour les cartes de paiement à débit différé).
                  </Text>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - En cas de paiement unique les données bancaires sont
                      conservées en base active jusqu’au complet paiement.
                    </li>
                    <li>
                      - En cas d’abonnement avec tacite reconduction, les
                      données bancaires sont conservées pendant la durée de
                      l’abonnement.
                    </li>
                    <li>
                      - En cas d’abonnement sans tacite reconduction, les
                      données bancaires sont conservées en base active jusqu’à
                      la dernière échéance de paiement.
                    </li>

                    <Text mt={2}>
                      Les données relatives au PAN et cryptogramme visuel ne
                      sont pas stockées et les données relatives à la date
                      d’expiration sont supprimées lorsque sa date d’expiration
                      est atteinte. Les factures et données comptables émises
                      sont quant à elles conservées dix (10) ans à compter de
                      leur émission.
                    </Text>
                    <Text mt={2} ml={-5}>
                      Pour la gestion des demandes de renseignement auprès du
                      support:{" "}
                    </Text>
                    <li>
                      - Les données sont conservées le temps nécessaire au
                      traitement de la demande puis pendant trois (3) ans à
                      compter du dernier contact avec SAS DGO (followme.fans).
                    </li>
                    <Text mt={2} ml={-5}>
                      Pour l’envoi d’e-mailing:
                    </Text>
                    <li>
                      - Les données sont conservées jusqu’à désinscription de
                      l’Utilisateur via le lien prévu à cet effet.
                    </li>
                    <Text mt={2} ml={-5}>
                      Pour la gestion de demandes de droits:
                    </Text>
                    <li>
                      - Les données relatives à la gestion des demandes de droit
                      sont conservées pour toute la durée nécessaire au
                      traitement de la demande. Elles sont par la suite
                      archivées pour la durée de prescription pénale applicable
                      en archivage intermédiaire
                    </li>
                    <Text mt={2} ml={-5}>
                      Pour le bon fonctionnement et l’amélioration permanente de
                      la Plateforme:{" "}
                    </Text>
                    <li>
                      - Les cookies et autres traceurs commerciaux peuvent être
                      déposés sur le terminal de l’Utilisateur pour une durée
                      maximale de treize (13) mois. Au-delà de ce délai, les
                      données de fréquentation brutes associées à un identifiant
                      sont soit supprimées soit anonymisées. Les informations
                      collectées par l'intermédiaire de cookies et traceurs sont
                      conservées pour une durée de vingt-cinq (25) mois. Au-delà
                      de ce délai, ces données sont supprimées, ou anonymisées.
                    </li>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Vos droits:
                  <br />
                  Conformément à la Loi Informatique et Libertés et au RGPD,
                  vous disposez des droits suivants :
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - Droit d’accès (article 15 RGPD), de rectification
                      (article 16 RGPD) et de mise à jour.{" "}
                    </li>
                    <li>
                      - Droit d’effacement de vos données à caractère personnel
                      (article 17 RGPD), lorsqu’elles sont inexactes,
                      incomplètes, équivoques, périmées, ou dont la collecte,
                      l’utilisation, la communication ou la conservation est
                      interdite.
                    </li>
                    <li>
                      - Droit de retirer à tout moment votre consentement
                      (article 13-2c RGPD).
                    </li>
                    <li>
                      - Droit à la limitation du traitement de vos données
                      (article 18 RGPD).{" "}
                    </li>
                    <li>
                      - Droit d’opposition au traitement de vos données (article
                      21 RGPD).{" "}
                    </li>
                    <li>
                      - Droit à la portabilité des données que vous nous avez
                      fournies, lorsque vos données font l’objet de traitements
                      automatisés fondés sur votre consentement ou sur un
                      contrat (article 20 RGPD).
                    </li>
                    <li>
                      - Droit d’introduire une réclamation auprès de la CNIL
                      (article 77 RGPD).{" "}
                    </li>
                    <li>
                      - Droit de définir le sort de vos données après votre mort
                      et de choisir que nous communiquions (ou non) vos données
                      à un tiers que vous aurez préalablement désigné. En cas de
                      décès et à défaut d’instructions de votre part, nous nous
                      engageons à détruire vos données, sauf si leur
                      conservation s’avère nécessaire à des fins probatoires ou
                      pour répondre à une obligation légale.
                      <Text mt={2} ml={-5}>
                        Ces droits peuvent être exercés par simple demande, par
                        courrier électronique à l’adresse contact@followme.fans
                        ou par courrier à SAS DGO (followme.fans) – 187 rue de
                        Lourmel 75015 Paris. En cas de communication de copie de
                        pièce d’identité pour justifier de votre identité, nous
                        la conserverons un (1) an ou trois (3) ans lorsque cette
                        communication est effectuée dans le cadre de l’exercice
                        d’un droit d’opposition. Pour mieux connaître vos
                        droits, vous pouvez également consulter le site de la
                        Commission Nationale de l’Informatique et des Libertés,
                        accessible à l’adresse suivante :{" "}
                        <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>
                      </Text>
                    </li>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Sécurité.
                  <br />
                  SAS DGO (followme.fans) et ses éventuels sous-traitants
                  s’engagent à mettre en œuvre toutes les mesures techniques et
                  organisationnelles afin d’assurer la sécurité des traitements
                  de données à caractère personnel et la confidentialité de vos
                  données, selon les moyens actuels de la technique et en
                  application de la Loi informatique et Libertés modifiée, du
                  Règlement européen sur la protection des données (RGPD) et de
                  la loi n°2018-133 du 26 février 2018 « portant diverses
                  dispositions d’adaptation au droit de l’Union européenne dans
                  le domaine de la sécurité ».
                  <br />
                  SAS DGO (followme.fans) prend les précautions utiles, au
                  regard de la nature de vos données et des risques présentés
                  par notre traitement, pour préserver la sécurité des données
                  et, notamment, empêcher qu’elles soient déformées,
                  endommagées, ou que des tiers non autorisés y aient accès
                  (protection physique des locaux, procédé d’authentification de
                  nos clients avec accès personnel et sécurisé via des
                  identifiants et mots de passe confidentiels, cryptage des mots
                  de passe, journalisation des connexions...).
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Transferts hors UE.
                  <br />
                  SAS DGO (followme.fans) traite principalement vos données au
                  sein de l’Union Européenne. Au regard de la nature de son
                  activité, SAS DGO (followme.fans) peut être amenée à réaliser
                  des transferts de vos données en dehors de l’Union Européenne.
                  Dans cette hypothèse, ces transferts sont assortis de
                  garanties appropriées conformément à la réglementation. Ces
                  garanties sont disponibles sur demande à l’adresse
                  contact@followme.fans ou par courrier à SAS DGO
                  (followme.fans) - 187 rue de Lourmel 75015 Paris.
                </li>
              </Box>

              <Box mt={5} px={4}>
                <li className="mt-5">
                  Mise à jour de notre politique de confidentialité. <br />
                  La présente Politique de confidentialité est susceptible
                  d’être modifiée notamment suivant les différentes évolutions
                  législatives et réglementaires. À cet effet, l’Utilisateur
                  pourra consulter la mise à jour directement sur la Plateforme.
                </li>
              </Box>
            </ol>
          </Box>
        </Box>
      </VStack>
    </Container>
  );
}

export function PrivacyEN() {
  const inboxScrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    inboxScrollRef.current?.scrollIntoView();
  }, []);
  return (
    <Container maxW="container.xl" bg="#fff">
      <div ref={inboxScrollRef} />
      <Link to="/">
        <HStack
          py={4}
          w="full"
          alignItems={"flex-start"}
          justifyContent="flex-start"
        >
          <img src={aboutlogo} height="300px" width="300px" />
        </HStack>
      </Link>
      <VStack w="full" spacing={8}>
        <Box mt={8}>
          <Text fontWeight="bold" sx={{ color: "#105b94" }} variant="h4">
            PRIVACY AND DATA PROTECTION POLICY {"  "}
            <span className="text-black">Version August 2021</span>
          </Text>
          <Text mt={2}>
            This Privacy Policy is intended for users of the followme.fans
            platform (hereinafter "the Platform") and aims to inform them of how
            their personal information may be collected and processed. Respect
            for privacy and personal data is a priority for SAS DGO
            (followme.fans), which is why we undertake to treat them in the
            strictest respect of the Data Protection Act of January 6, 1978.
            (hereinafter “IEL law”) as amended and the (EU) General Data
            Protection Regulation of 27 April 2016 (hereinafter “GDPR”).
          </Text>
          <Box mt={1} p={4} mb={12}>
            <ol>
              <li>
                General information and identity of the Processing Manager SAS
                DGO, a simplified joint-stock company with a share capital of
                10,000 euros, registered with the Paris Trade and Companies
                Register under number 85159275800029 , whose registered office
                is 187 rue de Lourmel 75015 Paris.SAS DGO (followme.fans) will
                have the status of data controller for processing relating to:
                <ul style={{ listStyleType: "none" }}>
                  <li>- Management and monitoring of accounts and contracts</li>
                  <li>- Provision and management of services</li>
                  <li>- Payment management</li>
                  <li>- Management of support inquiries</li>
                  <li>- Sending e-mail</li>
                  <li>- Management of rights requests</li>
                  <li>
                    - The proper functioning and continuous improvement of the
                    Platform
                  </li>
                </ul>
                <Text mt={2}>
                  Legal reminder: The data controller is, within the meaning of
                  the Data Protection Act and the GDPR, the person who
                  determines the means and purposes of the processing. Where two
                  or more controllers jointly determine the purposes and means
                  of processing, they are joint controllers (or co-controllers).
                  The processor is a person processing personal data on behalf
                  of the controller, he acts under the authority of the
                  controller and on his instructions.
                </Text>
              </li>

              <Box mt={5}>
                <li className="mt-5">
                  Definitions:
                  <br />
                  “Data”: refers to the personal data that is the subject of
                  Processing within the framework of this Privacy Policy.
                  <br />
                  “Personal Data”: means personal data as defined in Article 4
                  (1) of the GDPR.
                  <br />
                  “General Data Protection Regulation” or “GDPR”: means
                  Regulation (EU) 2016/679 of the European Parliament and of the
                  Council of 27 April 2016 on the protection of natural persons
                  with regard to the Processing of Personal Data personnel and
                  on the free movement of such data, and repealing Directive
                  95/46/EC. “User”: refers to any person browsing the
                  www.followme.fans Platform, whether Fan, Creator, or simple
                  Internet user. "Processing/Processing": means any of the
                  operations referred to in Article 4 (2) of the GDPR, carried
                  out on Personal Data in the context of the execution of this
                  Privacy Policy. "Subcontractor": means the natural or legal
                  person, public authority, service or other body which
                  Processes Personal Data on behalf of and according to the
                  instructions of SAS DGO (followme.fans) in accordance with the
                  Article 4 (8) GDPR.
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Purposes of processing and legal bases SAS DGO (followme.fans)
                  only collects the Data necessary for the explicit and
                  determined purposes below:
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - Management and monitoring of accounts and contracts
                    </li>
                    <li>- Fan registration</li>
                    <li>- Creator registration</li>
                    <li>- Certification of Creator and Fan accounts</li>
                    <li>- User Consent</li>
                    <li>- Provision and management of services</li>
                    <li>- Connecting Fans with Creators</li>
                    <li>
                      - Execution of a contract or execution of pre-contractual
                      measures
                    </li>
                    <li>- Payment management</li>
                    <li>- payment</li>
                    <li>- Billing follow-up</li>
                    <li>
                      - Keeping accounting records and legal supporting
                      documents
                    </li>
                    <li>
                      - Legal obligation: article L.102 B and following of the
                      Book of tax procedures
                    </li>
                    <li>- Managing support inquiries</li>
                    <li>- Tracking and responding to support inquiries</li>
                    <li>
                      - Execution of a contract or execution of pre-contractual
                      measures
                    </li>
                    <li>- Sending emails</li>
                    <li>
                      - Sending e-mailings and other alerts to Users who have
                      requested them
                    </li>
                  </ul>
                  <Text mt={2}>
                    Management of requests for rights arising from the GDPR and
                    the amended Data Protection Act. This processing covers all
                    the operations necessary to follow up on requests for rights
                    sent to SAS DGO (followme.fans) (qualification of the
                    request, investigations, performance of specific technical
                    operations, etc.). It only concerns cases where SAS DGO
                    (followme.fans) acts as data controller. Our legal
                    obligation arising from articles 15 and following of the
                    GDPR and articles 48 and following of the Data Protection
                    Act.
                  </Text>
                  <br />
                  <Text>
                    Proper functioning and continuous improvement of the
                    Platform and its functionalities. Ensure the proper
                    functioning and continuous improvement of the Platform and
                    its functionalities. Our legitimate interest in guaranteeing
                    the best level of operation and quality of the Platform
                    thanks in particular to visit statistics. User Consent when
                    required.
                  </Text>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Data collected.
                  <br />
                  The mandatory or optional nature of the personal data
                  collected and the possible consequences of a lack of response
                  are indicated during the various contacts with the persons
                  concerned. You can consult the details of the personal data
                  that we are likely to have about you below. NB: the detail of
                  the information provided below is not intended to be
                  exhaustive and is above all intended to inform Users of the
                  categories of data that SAS DGO (followme.fans) is likely to
                  process.
                  <Text mt={2}>
                    For the management and monitoring of accounts and contracts
                  </Text>
                  Data relating to the identity of Users:
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - Surname, first name(s), address, telephone number, email
                      addresses, pseudonym, date of birth, data indicated in the
                      biography, photographs, copy of passport / CNI
                    </li>
                    <li>- IBAN</li>
                    <li>
                      - Credit card number (only the last 4 digits are kept)
                    </li>
                    <li>- Data necessary for the establishment of invoices</li>
                  </ul>
                  <ul style={{ listStyleType: "none" }}>
                    <Text mt={2} ml={-5}>
                      For the management of support inquiries:
                    </Text>
                    <li>- Order number</li>
                  </ul>
                  <ul style={{ listStyleType: "none" }}>
                    <Text mt={2} ml={-5}>
                      For sending e-mailing:
                    </Text>
                    - User contact data (last name, first name, email)
                    <Text mt={2} ml={-5}>
                      For the management of rights requests:
                    </Text>
                    <li>- e-mail address</li>
                    <li> - Order number </li>
                    <Text mt={2} ml={-5}>
                      For the proper functioning and continuous improvement of
                      the Platform:
                    </Text>
                    <li>
                      - Logs and User connection data and computer hardware
                      identification data.
                    </li>
                    <li>
                      - Data collected via cookies and other tracers present on
                      our Platform, for more details, consult our cookie
                      management charter
                    </li>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Personal Data Collected Automatically.
                  <br />
                  When you browse the www.followme.fans Platform, SAS DGO
                  (followme.fans) automatically records information about your
                  browsing. When you use our services, connection data may be
                  automatically recorded in our server logs such as your IP
                  address, your unique identifier, your operating system and its
                  location, the type of browser you use, the pages you have
                  consulted.
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Recipient of data.
                  <br />
                  Only the authorized and determined persons mentioned below may
                  have access to User data:
                  <ul style={{ listStyleType: "none" }}>
                    <li>- SAS DGO authorized personnel (followme.fans).</li>
                    <li>
                      - The service providers responsible for managing, hosting
                      the Platform and the SAS DGO IT system (followme.fans).
                    </li>
                    <li>
                      - Service providers responsible for managing customer
                      relations and moderating content.
                    </li>
                    <li>- Payment management service providers.</li>
                    <li>- Email service providers.</li>
                    <li>
                      - Where applicable, the authorized personnel of our
                      subcontractors.
                    </li>
                    <li>
                      - Where applicable, the courts concerned, mediators,
                      chartered accountants, auditors, lawyers, bailiffs, debt
                      collection companies, police or gendarmerie authorities in
                      the event of theft or judicial requisition.
                    </li>
                    <li>
                      - Third parties likely to place cookies on your devices
                      when you consent
                    </li>
                    <Text mt={2} ml={-5}>
                      Your data is not communicated, exchanged, sold or rented
                      to any other person than those mentioned above.
                    </Text>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Duration of data retention.
                  <br />
                  SAS DGO (followme.fans) undertakes that the data collected
                  will be kept in a form allowing your identification for a
                  period that does not exceed the period necessary for the
                  purposes for which this data is collected and processed.
                  <br />
                  The data is kept throughout the contractual relationship and
                  then archived. They are then stored for five (5) years for
                  evidentiary purposes in accordance with the legal and
                  regulatory obligations to which SAS DGO (followme.fans) is
                  subject. The accounting documents are also kept for 10 years.
                  <Text mt={2}>
                    For payments by credit card, in accordance with Article
                    L.133-24 of the Monetary and Financial Code, this data may
                    be kept for the purpose of proof in the event of any dispute
                    of the transaction or complaint, in intermediate archives,
                    during a period of thirteen (13) months following the debit
                    date (duration extended to 15 months for deferred debit
                    payment cards).
                  </Text>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - In the event of a single payment, the bank details are
                      kept in an active database until full payment is made.
                    </li>
                    <li>
                      - In the event of a subscription with tacit renewal, bank
                      details are kept for the duration of the subscription.
                    </li>
                    <li>
                      - In the event of a subscription without tacit renewal,
                      bank details are kept in an active database until the last
                      payment date.
                    </li>
                    <Text mt={2}>
                      The data relating to the PAN and visual cryptogram are not
                      stored and the data relating to the expiry date are
                      deleted when its expiry date is reached. Invoices and
                      accounting data issued are kept for ten (10) years from
                      their issue.
                    </Text>
                    <Text mt={2} ml={-5}>
                      For the management of support inquiries:
                    </Text>
                    <li>
                      - The data is kept for the time necessary to process the
                      request and then for three (3) years from the last contact
                      with SAS DGO (followme.fans).
                    </li>
                    <Text mt={2} ml={-5}>
                      For sending e-mailing:
                    </Text>
                    <li>
                      - The data is kept until the User unsubscribes via the
                      link provided for this purpose.
                    </li>
                    <Text mt={2} ml={-5}>
                      For the management of rights requests:
                    </Text>
                    <li>
                      - Data relating to the management of rights requests are
                      kept for the duration necessary to process the request.
                      They are then archived for the applicable criminal
                      limitation period in intermediate archiving.
                    </li>
                    <Text mt={2} ml={-5}>
                      For the proper functioning and continuous improvement of
                      the Platform:
                    </Text>
                    <li>
                      - Cookies and other commercial tracers may be placed on
                      the User's terminal for a maximum period of thirteen (13)
                      months. Beyond this period, the raw traffic data
                      associated with an identifier is either deleted or
                      anonymized. The information collected through cookies and
                      tracers is kept for a period of twenty-five (25) months.
                      Beyond this period, this data is deleted or anonymized.
                    </li>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Your rights:
                  <br />
                  In accordance with the Data Protection Act and the GDPR, you
                  have the following rights:
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      - Right of access (article 15 GDPR), rectification
                      (article 16 GDPR) and update.
                    </li>
                    <li>
                      - Right to erasure of your personal data (article 17
                      GDPR), when they are inaccurate, incomplete, ambiguous,
                      outdated, or whose collection, use, communication or
                      storage is prohibited.
                    </li>
                    <li>
                      - Right to withdraw your consent at any time (article
                      13-2c GDPR).
                    </li>
                    <li>
                      - Right to limit the processing of your data (Article 18
                      GDPR).
                    </li>
                    <li>
                      - Right to oppose the processing of your data (article 21
                      GDPR).
                    </li>
                    <li>
                      - Right to portability of the data you have provided to
                      us, when your data is subject to automated processing
                      based on your consent or on a contract (Article 20 GDPR).
                    </li>
                    <li>
                      - Right to lodge a complaint with the CNIL (article 77
                      GDPR).
                    </li>
                    <li>
                      - Right to define the fate of your data after your death
                      and to choose whether or not we communicate your data to a
                      third party that you have previously designated. In the
                      event of death and in the absence of instructions from
                      you, we undertake to destroy your data, unless their
                      retention proves necessary for evidentiary purposes or to
                      meet a legal obligation.
                      <Text mt={2} ml={-5}>
                        These rights can be exercised by simple request, by
                        e-mail to contact@followme.fans or by post to SAS DGO
                        (followme.fans) – 187 rue de Lourmel 75015 Paris. In the
                        event of communication of a copy of an identity document
                        to prove your identity, we will keep it for one (1) year
                        or three (3) years when this communication is carried
                        out within the framework of the exercise of a right of
                        opposition. . To find out more about your rights, you
                        can also consult the website of the Commission Nationale
                        de l'Informatique et des Libertés, accessible at the
                        following address:{" "}
                        <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>
                      </Text>
                    </li>
                  </ul>
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Security.
                  <br />
                  SAS DGO (followme.fans) and its possible subcontractors
                  undertake to implement all technical and organizational
                  measures to ensure the security of the processing of personal
                  data and the confidentiality of your data, according to
                  current means. technology and in application of the amended
                  Data Protection Act, the European Data Protection Regulation
                  (GDPR) and Law No. 2018-133 of February 26, 2018 "on various
                  provisions for adaptation to European Union in the field of
                  security”. SAS DGO (followme.fans) takes the necessary
                  precautions, with regard to the nature of your data and the
                  risks presented by our processing, to preserve the security of
                  the data and, in particular, to prevent them from being
                  distorted, damaged, or unauthorized third parties have access
                  to it (physical protection of the premises, authentication
                  process for our customers with personal and secure access via
                  confidential identifiers and passwords, encryption of
                  passwords, logging of connections, etc.).
                </li>
              </Box>

              <Box mt={5}>
                <li className="mt-5">
                  Transfers outside the EU.
                  <br />
                  SAS DGO (followme.fans) mainly processes your data within the
                  European Union. Given the nature of its activity, SAS DGO
                  (followme.fans) may be required to transfer your data outside
                  the European Union. In this case, these transfers are
                  accompanied by appropriate guarantees in accordance with the
                  regulations. These guarantees are available on request at
                  contact@followme.fans or by mail to SAS DGO (followme.fans) -
                  187 rue de Lourmel 75015 Paris.
                </li>
              </Box>

              <Box mt={5} px={4}>
                <li className="mt-5">
                  Update to our privacy policy. <br />
                  This Privacy Policy may be modified in particular according to
                  the various legislative and regulatory developments. To this
                  end, the User may consult the update directly on the Platform.
                </li>
              </Box>
            </ol>
          </Box>
        </Box>
      </VStack>
    </Container>
  );
}
