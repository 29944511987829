/* eslint-disable jsx-a11y/alt-text */
import {
  useBreakpointValue,
  VStack,
  FormErrorMessage,
  Input,
  HStack,
  FormControl,
  FormLabel,
  Button,
  useToast,
} from "@chakra-ui/react";
import visa from "../img/visa.svg";
import React, { useState } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import styles from "../Styles/home.module.css";
import valid from "card-validator";
import { cc_format, date_format } from "../function/formatCreditCardNumber";
import { AddCards, cardGetToken, getMangoData } from "../server/api";

export default function AddCard() {
  const toast = useToast()
  const place = useBreakpointValue({ base: 1, lg: 2 });
  const [val, setVal] = React.useState("");
  const [date, setDate] = React.useState("");
  const [errorCode, setErrorCode] = useState("");
  const [mangoId, setMangoId] = useState("");
  const [dataReg, setDataReg] = useState("");
  const [cvv, setCvv] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [bool, setBool] = useState({
    number: false,
    date: false,
    cvv: false,
  });

  const validator = React.useCallback(async () => {
    if (!valid.number(val).isValid) {
      setBool({ ...bool, number: true });
    }
    if (!valid.expirationDate(date).isValid) {
      setBool({ ...bool, date: true });
    }
    if (!valid.cvv(cvv).isValid) {
      setBool({ ...bool, cvv: true });
    }
    if (
      (valid.number(val).isValid === valid.expirationDate(date).isValid) ===
      valid.cvv(cvv).isValid
    ) {
      setLoading(true)
      await getMangoData().then((res) => {
        setMangoId(res?.data?.data?.mango_pay_card_id);
        console.log(res.data.data);
        if (res?.data?.success) {
          cardGetToken(
            res?.data?.data?.PreregistrationData,
            res?.data?.data.CardRegistrationURL,
            res?.data?.data?.AccessKey,
            val,
            date,
            cvv
          ).then(async (res) => {
            if (res.data.includes("errorCode")) {
              console.log(res);
              const errorCode = res.data.split("errorCode=")[1];
              setErrorCode(errorCode)
            } else {
              const dataResult = res.data.split("data=")[1];
              setDataReg(dataResult);
              AddCards(dataReg,mangoId,errorCode ? errorCode : "").then((res)=>{
                if(res.data.success){
                  setLoading(false)
                  setDate("")
                  setCvv("")
                  setVal("")
                  toast({
                    title: "Carte ajoutée.",
                    description: `${res.data.message}`,
                    status: "success",
                    position: "top",
                    duration: 3000,
                    isClosable: true,
                  });
                  setTimeout(() => {
                    window.location.reload()
                  }, 4000);
                }
                else{
                  setLoading(false)
                  toast({
                    title: "Echec d'ajout.",
                    description: "Erreur du réseau, rééssayez !",
                    status: "error",
                    position: "top",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              })
            }
          });
        }
      });
    }
  }, [bool, cvv, dataReg, date, errorCode, mangoId, toast, val]);

  return (
    <VStack w="full" bg="#EAEDF6" minH="100vh" py={8} px={4}>
      <Grid w="full" templateColumns={`repeat(${place}, 1fr)`} gap={6}>
        <GridItem bg="#47B2CF" px={4} rounded="17.95px" w="100%" h="204px">
          <VStack
            w="full"
            h="full"
            display="flex"
            justifyContent="space-around"
          >
            <HStack w="full" justifyContent={"flex-end"}>
              <img src={visa} />
            </HStack>
            <FormControl
              isInvalid={!valid.number(val).isValid}
              w="full"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Input
                w="full"
                autoFocus
                px={6}
                value={cc_format(val)}
                type="text"
                placeholder="Numero de carte ici"
                onChange={(e: any) => setVal(e.target.value)}
                color="#E0E0E0"
                fontFamily={"Inter-extra"}
                className={styles.inputCard}
              />
              <FormErrorMessage>numero de carte invalide</FormErrorMessage>
            </FormControl>
          </VStack>
        </GridItem>
        <GridItem w="100%" h="204px">
          <VStack
            w="full"
            h="full"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <FormControl
              bg="#fff"
              p={2}
              isInvalid={!valid.expirationDate(date).isValid}
              rounded="8px"
              border="1px solid #E8E6EA"
              w="full"
              alignItems={"flex-start"}
            >
              <FormLabel fontSize="14px" fontFamily={"Inter"} color="#A5A8AC">
                date d’expiration
              </FormLabel>
              <Input
                type="text"
                value={date_format(date)}
                p={0}
                onChange={(e: any) => setDate(e.target.value)}
                className={styles.inputs}
              />
              <FormErrorMessage>date invalide</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!valid.cvv(cvv).isValid}
              bg="#fff"
              p={2}
              rounded="8px"
              border="1px solid #E8E6EA"
              w="full"
              alignItems={"flex-start"}
            >
              <FormLabel fontSize="14px" fontFamily={"Inter"} color="#A5A8AC">
                CVV Code
              </FormLabel>
              <Input
                maxLength={4}
                p={0}
                value={cvv}
                onChange={(e: any) => setCvv(e.target.value)}
                className={styles.inputs}
              />
              <FormErrorMessage>cvv incorrect</FormErrorMessage>
            </FormControl>
          </VStack>
        </GridItem>
      </Grid>
      <HStack w="full" py={16} justifyContent={"center"}>
        <Button
          isLoading={loading}
          h="55px"
          onClick={() => validator()}
          colorScheme="blue"
          fontFamily={"Inter"}
          bg="#005591"
          color="#fff"
          w="358px"
        >
          Ajouter la carte
        </Button>
      </HStack>
    </VStack>
  );
}
