/* eslint-disable jsx-a11y/alt-text */
import {
  useBreakpointValue,
  VStack,
  FormErrorMessage,
  Input,
  HStack,
  FormControl,
  FormLabel,
  Button,
  useToast,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import visa from "../../img/visa.svg";

import valid from "card-validator";
import { cc_format, date_format } from "../../function/formatCreditCardNumber";
import { AddCards, cardGetToken, getCardsList, getMangoData } from "../../server/api";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { PlusCircle } from "phosphor-react";
import React, { useState } from "react";
import { useCards } from "../../store/store";

export default function AddBankCardModal() {
  const width = useBreakpointValue({ base: "full", lg: "45%" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const flex = useBreakpointValue({ base: "center", lg: "flex-start" });
  const toast = useToast();
  const { cards, setCards } = useCards();
  const [val, setVal] = React.useState("");
  const [date, setDate] = React.useState("");
  const [cvv, setCvv] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [bool, setBool] = useState({
    number: false,
    date: false,
    cvv: false,
  });

  const validator = React.useCallback(async () => {
    if (!valid.number(val).isValid) {
      setBool({ ...bool, number: true });
    }
    if (!valid.expirationDate(date).isValid) {
      setBool({ ...bool, date: true });
    }
    if (!valid.cvv(cvv).isValid) {
      setBool({ ...bool, cvv: true });
    }
    if (
      (valid.number(val).isValid === valid.expirationDate(date).isValid) ===
      valid.cvv(cvv).isValid
    ) {
      setLoading(true);

      await getMangoData().then((res) => {
        localStorage.setItem("mangoId", res?.data?.data?.mango_pay_card_id);
        if (res?.data?.success) {
          localStorage.setItem("mangoId", res?.data?.data?.mango_pay_card_id);
          cardGetToken(
            res?.data?.data?.PreregistrationData,
            res?.data?.data.CardRegistrationURL,
            res?.data?.data?.AccessKey,
            val,
            date,
            cvv
          ).then(async (res) => {
            if (res.data.includes("errorCode")) {
              const errorCode = res.data.split("errorCode=")[1];
              localStorage.setItem("errorCode", errorCode);
            } else {
              const dataResult = res.data.split("data=")[1];
              await AddCards(
                dataResult,
                localStorage.getItem("mangoId"),
                localStorage.getItem("errorCode")
                  ? localStorage.getItem("errorCode")
                  : ""
              ).then((res) => {
                if (res.data.success) {
                  onClose();
                  setLoading(false);
                  setDate("");
                  setCvv("");
                  setVal("");
                  localStorage.removeItem("mangoId");
                  localStorage.removeItem("errorCode");
                  toast({
                    title: "Carte ajoutée.",
                    description: `${res.data.message}`,
                    status: "success",
                    position: "top",
                    duration: 2000,
                    isClosable: true,
                  });
                  setTimeout(async () => {
                    const { data } = await getCardsList()
                    setCards(data?.data?.cards);
                  }, 3000);

                } else {
                  setLoading(false);
                  toast({
                    title: "Echec d'ajout.",
                    description: "Carte érronée, rééssayez !",
                    status: "error",
                    position: "top",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              });
            }
          });
        }
      });
    }
  }, [bool, cvv, date, onClose, setCards, toast, val]);
  return (
    <VStack w="full">
      <HStack w="full" py={4} justifyContent={flex}>
        <Button
          bg="#DDE3F2"
          onClick={onOpen}
          w={width}
          h="55px"
          fontFamily={"Inter"}
          border="1px dashed #005591"
        >
          <Text color="#005591" mr={4}>
            Ajouter une carte{" "}
          </Text>
          <PlusCircle color="#005591" size={24} />
        </Button>
      </HStack>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent m={2}>
          <ModalHeader bg="#005591">
            <img src={visa} height="24px" width="24px" />
          </ModalHeader>
          <ModalCloseButton color="#fff" />
          <ModalBody>
            <VStack w="full" spacing={4} py={4}>
              <FormControl
                isInvalid={!valid.number(val).isValid}
                w="full"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Input
                  w="full"
                  autoFocus
                  value={cc_format(val)}
                  type="text"
                  placeholder="Numero de carte ici"
                  onChange={(e: any) => setVal(e.target.value)}
                  color="#000"
                  fontFamily={"Inter-extra"}
                />
                <FormErrorMessage>numéro de carte invalide</FormErrorMessage>
              </FormControl>
              <FormControl
                bg="#fff"
                p={2}
                isInvalid={!valid.expirationDate(date).isValid}
                rounded="8px"
                border="1px solid #E8E6EA"
                w="full"
                alignItems={"flex-start"}
              >
                <FormLabel fontSize="14px" fontFamily={"Inter"} color="#A5A8AC">
                  date d’expiration
                </FormLabel>
                <Input
                  type="text"
                  value={date_format(date)}
                  onChange={(e: any) => setDate(e.target.value)}
                />
                <FormErrorMessage>date invalide</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!valid.cvv(cvv).isValid}
                bg="#fff"
                p={2}
                rounded="8px"
                border="1px solid #E8E6EA"
                w="full"
                alignItems={"flex-start"}
              >
                <FormLabel fontSize="14px" fontFamily={"Inter"} color="#A5A8AC">
                  CVV Code
                </FormLabel>
                <Input
                  maxLength={4}
                  value={cvv}
                  onChange={(e: any) => setCvv(e.target.value)}
                />
                <FormErrorMessage>cvv incorrect</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              fontFamily={"Inter"}
              size="sm"
              mr={3}
              onClick={onClose}
            >
              Annuler
            </Button>
            <Button
              colorScheme="blue"
              isLoading={loading}
              onClick={() => validator()}
              size="sm"
              fontFamily={"Inter"}
              bg="#005591"
            >
              Ajouter la carte
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
}
